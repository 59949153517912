import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  Grid,
  Portal,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { CenterPage } from "Components/CenterPage/CenterPage";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { formatNumber } from "Helpers/numbers";
import { groupBy } from "Helpers/arrays";
import { ArrowBack, Person, PersonSearch } from "@mui/icons-material";
import { LeadTimelineList } from "features/leadAnalytics";
import { friendlyDateTime } from "Helpers/dates";
import { useLeadJourneyData } from "features/leadAnalytics";
import GlassCard from "Components/GlassCard/GlassCard";
import { useLeadStatusRules } from "Hooks/useLeadStatusRules";
import {
  ApiChartTemplate,
  ApiChartType,
  ApiConversionType,
  ApiDimension,
  ApiLeadStatusType,
  ApiMetric,
  ApiUserActionConversion,
  ApiUserActionType,
} from "@incendium/api";
import {
  ExtraUserActionType,
  IUserAction,
} from "features/leadAnalytics/types/types";
import { LeadJourneySummary } from "features/leadAnalytics";
import { AnalyticsCard, AnalyticsStatCard } from "features/analytics";
import { StatCardTypography } from "Components/UI/StatCard";
import { useFromToContext } from "Providers/FromToProvider";
import { AnalyticsSpacing } from "consts";

const typeOrder = {
  [ExtraUserActionType.VISIT_START]: 0,
  [ExtraUserActionType.GPROUPED_INTERACTION]: 1,
  [ExtraUserActionType.MACRO_CONVERSION]: 2,
  [ExtraUserActionType.MICRO_CONVERSION]: 3,
  [ApiUserActionType.LEAD_SCORE]: 4,
  [ApiUserActionType.LEAD_STATUS]: 5,
  [ApiUserActionType.RETENTION]: 6,
  [ApiUserActionType.PAGE_VIEW]: 7,
  [ExtraUserActionType.VISIT_END]: 8,
};

function SingleUserDashboard() {
  const location = useLocation<{ page?: number }>();
  const { page } = location.state || {};
  const { leadid } = useParams<{ leadid: string }>();
  const { chartData, loading } = useLeadJourneyData(Number(leadid));
  const { statusToName } = useLeadStatusRules();
  const history = useHistory();
  const [activeSessionId, setActiveSessionId] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const { setLastNDays } = useFromToContext();
  const theme = useTheme();

  useEffect(() => {
    setLastNDays(2000);
    return () => {
      setLastNDays(30);
    };
  }, []);

  const newGroupedData = useMemo(() => {
    // group into visits and gourp interactions
    if (!chartData) {
      return [];
    }
    const items: IUserAction[] = [];

    // handle visits and interactions
    const visits = groupBy(
      chartData.actions || [],
      (v) => `${v.pageView?.sessionId || 0}`
    );

    const sessions = Object.keys(visits).filter((v) => v !== "0");

    for (let i = 0; i < sessions.length; i++) {
      const sessionId = sessions[i];
      const pageviews = visits[sessionId];
      const sessionRevenue = pageviews.reduce(
        (acc, v) => acc + (v.pageView?.revenue || 0),
        0
      );
      const avgSessionTimOnPage =
        pageviews.reduce(
          (acc, v) => acc + (v.pageView?.timeOnPageSeconds || 0),
          0
        ) / pageviews.length;

      const start = pageviews[0];
      const end = pageviews[pageviews.length - 1];
      items.push({
        ...start,
        type: ExtraUserActionType.VISIT_START,
        sessionRevenue,
        sessionPagviewsCount: pageviews.length,
      });

      // handle interactions
      const conversions = pageviews
        .reduce(
          (agg, pv) => [...agg, ...(pv.pageView?.conversions || [])],
          [] as ApiUserActionConversion[]
        )
        .flat();

      // always add interactions
      items.push({
        type: ExtraUserActionType.GPROUPED_INTERACTION,
        timestamp: start.timestamp,
        sessionPagviewsCount: pageviews.length,
        sessionAvgTimOnPageSeconds: avgSessionTimOnPage,
        macroCount: conversions.filter(
          (c) => c.type === ApiConversionType.MACRO
        ).length,
        microCount: conversions.filter(
          (c) => c.type === ApiConversionType.MICRO
        ).length,
      });

      const endDate = new Date(
        new Date(end.timestamp!).getTime() +
          (end.pageView?.timeOnPageSeconds || 0) * 1000
      );
      items.push({
        ...end,
        type: ExtraUserActionType.VISIT_END,
        sessionRevenue,
        sessionPagviewsCount: pageviews.length,
        sessionPurchasedProducts: Array.from(
          new Set(
            pageviews.map((p) => p.pageView?.purchasedProductNames || []).flat()
          )
        ),
        timestamp: start.timestamp,
        endDate: endDate,
      });
    }

    return [...(items || []), ...((visits["0"] as IUserAction[]) || [])]
      .sort((a, b) => {
        const timestampComparison =
          (new Date(a.timestamp!).getTime() || 0) -
          (new Date(b.timestamp!).getTime() || 0);

        if (timestampComparison === 0) {
          return typeOrder[a.type] - typeOrder[b.type];
        }

        return timestampComparison;
      })
      .reduce((arr, v, idx) => {
        let blockId = 0;

        if (idx > 0) {
          blockId = arr[idx - 1].blockId || 0;
          if (arr[idx - 1].type === ExtraUserActionType.VISIT_END) {
            blockId++;
          }
        }
        if (v.type === ExtraUserActionType.VISIT_START) {
          blockId++;
        }

        return [
          ...arr,
          {
            ...v,
            blockId,
          },
        ];
      }, [] as IUserAction[]);
  }, [chartData]);

  const activeSession = useMemo(() => {
    if (!newGroupedData || !chartData || !activeSessionId) {
      return [];
    }
    const items = newGroupedData.filter((v) => v.blockId === activeSessionId);
    const firstDate = new Date(items[0].timestamp!);
    const lastDate = new Date(items[items.length - 1].endDate!);

    // split out macro and Interactions
    const filtered = (chartData?.actions || []).filter(
      (item) =>
        new Date(item.timestamp!) >= firstDate &&
        new Date(item.timestamp!) <= lastDate
    );
    const inner = filtered
      .reduce((agg, v) => {
        const conversions = v.pageView?.conversions;
        return [
          ...agg,
          v,
          (conversions || []).map((c) => ({
            ...v,
            type:
              c.type === ApiConversionType.MACRO
                ? ExtraUserActionType.MACRO_CONVERSION
                : ExtraUserActionType.MICRO_CONVERSION,
            conversionName: c.name,
          })),
        ];
      }, [] as any)
      .flat();
    return [items[0], ...inner, items[items.length - 1]];
  }, [activeSessionId, newGroupedData, chartData]);

  const onUserClick = useCallback(
    (l, page) => {
      history.push(`${l.id}`, { page });
    },
    [history]
  );

  const backToList = useCallback(() => {
    history.push(`../user-dashboard?page=${page}`, {
      page,
    });
  }, [page, history]);

  useEffect(() => {
    if (activeSessionId !== 0 || !newGroupedData) {
      return;
    }

    // find first session
    for (let i = 0; i < newGroupedData.length; i++) {
      if (newGroupedData[i].pageView?.sessionId) {
        setActiveSessionId(newGroupedData[i].blockId || 0);
        break;
      }
    }
  }, [activeSessionId, newGroupedData]);

  return (
    <>
      <Portal container={() => document.getElementById("pageTitle")}>
        <Typography variant="h1">Single User Dashboard</Typography>
        <Typography variant="subtitle1" color={"secondary"}>
          Examine the journey of this user
        </Typography>
      </Portal>
      <Portal container={() => document.getElementById("pageAction")}>
        <Stack direction={"row"} spacing={1}>
          <Button
            onClick={() => backToList()}
            startIcon={<ArrowBack />}
            color="secondary"
          >
            Return To List (page {[page]})
          </Button>
          <Button onClick={() => setOpen(true)} endIcon={<Person />}>
            Search Users
          </Button>
        </Stack>
      </Portal>
      {loading ? (
        <CenterPage>
          <CircularProgress />
          <Typography variant="h3" mt={2}>
            Fetching User Data
          </Typography>
        </CenterPage>
      ) : (
        <>
          <Stack direction="row" spacing={AnalyticsSpacing}>
            <Stack
              spacing={AnalyticsSpacing}
              sx={{
                flex: 1,
                height: 860,
                minHeight: 0,
                minWidth: 0,
              }}
            >
              <GlassCard
                sx={{ height: "auto" }}
                boxProps={{
                  p: 1.5,
                }}
              >
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={1}
                    justifyContent={"center"}
                    display="flex"
                    flexDirection={"column"}
                  >
                    <PersonSearch sx={{ fontSize: 52 }} />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="subtitle2">
                      Key :{" "}
                      <Typography
                        variant="body2"
                        color={"secondary"}
                        display={"inline"}
                      >
                        {chartData?.user?.key}
                      </Typography>
                    </Typography>
                    <Typography variant="subtitle2">
                      Email :{" "}
                      <Typography
                        variant="body2"
                        color={"secondary"}
                        display={"inline"}
                      >
                        {chartData?.user?.email}
                      </Typography>
                    </Typography>
                    <Typography variant="subtitle2">
                      Device :{" "}
                      <Typography
                        variant="body2"
                        color={"secondary"}
                        display={"inline"}
                      >
                        {[
                          chartData?.user?.device,
                          chartData?.user?.os,
                          chartData?.user?.browser,
                        ]
                          .filter((d) => d)
                          .join(" - ")}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2">
                      Current Lead Status :{" "}
                      <Typography
                        variant="body2"
                        color={"secondary"}
                        display={"inline"}
                      >
                        {statusToName(
                          chartData?.user?.status as ApiLeadStatusType
                        )}
                      </Typography>
                    </Typography>
                    <Typography variant="subtitle2">
                      Current Lead Score :{" "}
                      <Typography
                        variant="body2"
                        color={"secondary"}
                        display={"inline"}
                      >
                        {chartData?.user?.score}
                      </Typography>
                    </Typography>
                    <Typography variant="subtitle2">
                      Current Audience(s) :{" "}
                      <Typography
                        variant="body2"
                        color={"secondary"}
                        display={"inline"}
                      >
                        {(chartData?.user?.audiences || []).join(", ")}
                      </Typography>
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    alignItems="center"
                    justifyContent={"center"}
                    display="flex"
                    flexDirection={"column"}
                  >
                    <Typography variant="subtitle1" align="center">
                      Total Revenue
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      align="center"
                      color={"secondary"}
                    >
                      $
                      {formatNumber((chartData?.user?.totalRevenue || 0) / 100)}
                    </Typography>
                  </Grid>
                </Grid>
              </GlassCard>

              <Stack
                direction="row"
                spacing={AnalyticsSpacing}
                sx={{ minHeight: 0, flex: 1 }}
              >
                <Box sx={{ flex: 1, minWidth: 0 }}>
                  <GlassCard
                    boxProps={{
                      p: 2,
                    }}
                  >
                    <Stack sx={{ height: 40 }}>
                      <Typography variant="h3">User timeline</Typography>
                      <Typography variant="body2" color={"secondary"}>
                        Select a visit to view breakdown.
                      </Typography>
                    </Stack>
                    <Box sx={{ width: "85%" }}>
                      <LeadJourneySummary
                        data={newGroupedData}
                        activeSessionId={activeSessionId}
                        onClick={(id) => setActiveSessionId(id)}
                      />
                    </Box>
                  </GlassCard>
                </Box>
                <Box sx={{ flex: 1, minWidth: 0 }}>
                  <GlassCard
                    boxProps={{
                      p: 2,
                    }}
                  >
                    <Box sx={{ height: 40 }}>
                      <Typography variant="h3">
                        Single visit breakdown
                      </Typography>
                      {activeSession.length > 0 && (
                        <Typography variant="body2" color={"secondary"}>
                          Visit: {friendlyDateTime(activeSession[0].timestamp)}
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      <LeadJourneySummary
                        data={activeSession}
                        activeSessionId={activeSessionId}
                        onClick={(id) => setActiveSessionId(id)}
                        readonly
                      />
                    </Box>
                  </GlassCard>
                </Box>
              </Stack>
            </Stack>
            <Stack sx={{ width: 320 }} spacing={AnalyticsSpacing}>
              <AnalyticsStatCard
                chart={{
                  name: "Leads Most Common Source",
                  dimension: [ApiDimension.DIMENSION_SOURCE],
                  template: ApiChartTemplate.SNAPSHOT,
                  type: ApiChartType.GRAPH,
                  attributes: [],
                  leadId: Number(leadid),
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                    },
                  ],
                  limit: 1,
                }}
                renderBody={(data) => (
                  <>
                    <StatCardTypography size="small" mb={1}>
                      Leads Most Common <br />
                      Source
                    </StatCardTypography>
                    <StatCardTypography size="medium">
                      {data[0]?.name}
                    </StatCardTypography>
                  </>
                )}
              />
              <AnalyticsStatCard
                chart={{
                  name: "Leads Top Product Impression",
                  dimension: [ApiDimension.DIMENSION_PRODUCT_NAME],
                  template: ApiChartTemplate.SNAPSHOT,
                  type: ApiChartType.GRAPH,
                  attributes: [],
                  leadId: Number(leadid),
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [ApiMetric.METRIC_PRODUCT_IMPRESSION_COUNT],
                    },
                  ],
                  limit: 1,
                }}
                renderBody={(data) => (
                  <>
                    <StatCardTypography size="small" mb={1}>
                      Leads Top Product <br />
                      Impression
                    </StatCardTypography>
                    <StatCardTypography size="medium" lines={1}>
                      {data[0]?.name}
                    </StatCardTypography>
                  </>
                )}
              />
              <AnalyticsStatCard
                chart={{
                  name: "Leads Most Triggered Interaction",
                  dimension: [ApiDimension.DIMENSION_MICRO_CONVERSION],
                  template: ApiChartTemplate.SNAPSHOT,
                  type: ApiChartType.GRAPH,
                  attributes: [],
                  leadId: Number(leadid),
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT],
                    },
                  ],
                  limit: 1,
                }}
                renderBody={(data) => {
                  return (
                    <>
                      <StatCardTypography size="small" mb={1}>
                        Leads Most Triggered <br />
                        Interaction
                      </StatCardTypography>
                      <StatCardTypography size="medium" lines={1}>
                        {data[0]?.name}
                      </StatCardTypography>
                    </>
                  );
                }}
              />
              <AnalyticsCard
                chart={{
                  name: "Most Visited Locations",
                  dimension: [ApiDimension.DIMENSION_LOCATION],
                  template: ApiChartTemplate.SNAPSHOT,
                  type: ApiChartType.PIE,
                  attributes: [],
                  leadId: Number(leadid),
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [ApiMetric.METRIC_PAGE_VIEWS_COUNT],
                    },
                  ],
                  displayOptions: {
                    showTotals: true,
                  },
                }}
              />
            </Stack>
          </Stack>
          <Stack direction={"row"} spacing={2}></Stack>
        </>
      )}
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            background: theme.palette.background.default,
            width: "60%",
            padding: 2,
          },
        }}
      >
        <LeadTimelineList
          onClick={onUserClick}
          initialPage={page}
          useParamsPagination={false}
        />
      </Drawer>
    </>
  );
}

export default SingleUserDashboard;
