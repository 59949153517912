import { useSelector } from "react-redux";
import { channelSelector } from "Selectors/channelSelector";
import { useClientIdFromUrl } from "./useClientIdFromUrl";
import { useProjectIdFromUrl } from "./useProjectIdFromUrl";
import { useMatchPath } from "Hooks/useMatchPath";

const useChannelPath = () => {
  return useMatchPath<{
    clientId: string;
    projectId: string;
    channelId: string;
  }>("/clients/:clientId/projects/:projectId/train/channels/:channelId");
};

const useChannelIdFromUrl = () => {
  const path = useChannelPath();
  return path?.params.channelId;
};

export const useChannelSelector = () => {
  const clientId = useClientIdFromUrl();
  const projectId = useProjectIdFromUrl();
  const channelId = useChannelIdFromUrl();

  const channel = useSelector(channelSelector(clientId, projectId, channelId));
  return channel;
};
