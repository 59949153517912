import { action } from "typesafe-actions";
import {
  OPEN_MENU,
  CLOSE_MENU,
  TOGGLE_MENU,
  SET_ACTIVE,
  TOGGLE_SECONDARY_MENU,
  TOGGLE_HOVER,
  TOGGLE_ANALYSIS,
  SET_SUB_ACTIVE,
  SET_ANALYSIS,
  OPEN_SECONDARY_MENU,
  CLOSE_SECONDARY_MENU,
} from "./leftMenuActionTypes";

export const openLeftMenu = () => action(OPEN_MENU);
export const closeLeftMenu = () => action(CLOSE_MENU);
export const toggleMenu = () => action(TOGGLE_MENU);
export const toggleHover = () => action(TOGGLE_HOVER);
export const toggleSecondaryMenu = () => action(TOGGLE_SECONDARY_MENU);
export const openSecondaryMenu = () => action(OPEN_SECONDARY_MENU);
export const closeSecondaryMenu = () => action(CLOSE_SECONDARY_MENU);
export const toggleAnalysisMenu = () => action(TOGGLE_ANALYSIS);
export const setAnalysisMenu = (value: boolean) =>
  action(SET_ANALYSIS, { value });
export const setActive = (key: string) => action(SET_ACTIVE, { key });
export const setSubActive = (key: string) => action(SET_SUB_ACTIVE, { key });
