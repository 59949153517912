import { ApiComponentUISource, ApiSimpleComponent } from "@incendium/api";
import { Code, ViewComfy } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import Loading from "Components/Loading/Loading";
import { useNotification, useSelectedProject } from "Hooks";
import { useComponents } from "Hooks/useComponents";
import { useLayoutContext } from "Providers/LayoutProvider";
import produce from "immer";
import { useCallback, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useConfirmationContext } from "Providers/ConfirmationProvider";
import { useCampaigns } from "Hooks/useCampaigns";
import {
  ComponentDialog,
  ComponentsList,
  deleteComponent,
} from "features/campaigns";

function ComponentsPage() {
  const { selectedProject } = useSelectedProject();
  const [open, setOpen] = useState(false);
  const [component, setComponent] = useState<ApiSimpleComponent>({});
  const { components, setComponents, loading } = useComponents();
  const { setTitleAction } = useLayoutContext();
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const history = useHistory();
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const { campaigns } = useCampaigns();

  useLayoutEffect(() => {
    setTitleAction(
      <>
        <Typography variant="subtitle2" gutterBottom>
          Create a New Component
        </Typography>
        <Stack direction={"row"} spacing={1}>
          <Button startIcon={<Code />} onClick={newCodeComponent}>
            From Code
          </Button>
          <Button startIcon={<ViewComfy />} onClick={newBuilderComponent}>
            From Builder
          </Button>
        </Stack>
      </>
    );
    return () => {
      setTitleAction(undefined);
    };
  }, []);

  const newBuilderComponent = useCallback(() => {
    setComponent({
      uiSource: ApiComponentUISource.GRAPES,
    });
    setOpen(true);
  }, []);

  const newCodeComponent = useCallback(() => {
    setComponent({
      uiSource: ApiComponentUISource.CODE,
    });
    setOpen(true);
  }, []);

  const gotoComponent = useCallback(
    (component) => {
      history.push(
        `/clients/${selectedProject!.client!.id}/projects/${
          selectedProject?.id
        }/publish/components/${component.id}`
      );
    },
    [history, selectedProject]
  );

  const onDeleteComponent = useCallback(
    (component) => {
      openConfirmation({
        title: `Are you sure you want to delete ${component.name}`,
        body: `This action can not be undone`,
        callback: async () => {
          try {
            await deleteComponent(
              selectedProject?.id as number,
              component.id as number
            );

            setComponents(
              produce(components, (draft) => {
                const idx = draft.findIndex((c) => c.id === component.id);
                if (idx >= 0) {
                  draft.splice(idx, 1);
                }
              })
            );
            showSuccessNotification(`${component.name} Deleted`);
            closeConfirmation();
          } catch (error: any) {
            const t = await error.json();
            if (t.code === 9) {
              openConfirmation({
                title: `This Component is used in active campaign.`,
                body: "Deleting this component will deactivate the campaign. Are you sure you want to proceed?",
                callback: async () => {
                  try {
                    await deleteComponent(
                      selectedProject?.id as number,
                      component.id as number,
                      t.details[0].message
                    );
                    setComponents(
                      produce(components, (draft) => {
                        const idx = draft.findIndex(
                          (c) => c.id === component.id
                        );
                        if (idx >= 0) {
                          draft.splice(idx, 1);
                        }
                      })
                    );
                    showSuccessNotification(`${component.name} Deleted`);
                    closeConfirmation();
                  } catch (error) {
                    showErrorNotification(
                      `Internal Error deleting component, please try again`
                    );
                  }
                  closeConfirmation();
                },
              });
              return;
            }
            showErrorNotification(
              `Internal Error deleting campaign, please try again`
            );
          }
        },
      });
    },
    [
      selectedProject?.id,
      components,
      openConfirmation,
      closeConfirmation,
      setComponents,
      showErrorNotification,
      showSuccessNotification,
    ]
  );

  if (loading) {
    return <Loading text="Fetching Components" />;
  }

  return (
    <>
      <Box>
        <ComponentsList
          project={selectedProject!}
          components={components}
          campaigns={campaigns}
          onGoToComponent={gotoComponent}
          onDeleteComponent={onDeleteComponent}
        />
      </Box>
      {open && (
        <ComponentDialog
          open={open}
          setOpen={setOpen}
          component={component}
          setComponents={setComponents}
          project={selectedProject!}
          onSaved={gotoComponent}
        />
      )}
    </>
  );
}

export default ComponentsPage;
