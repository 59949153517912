import { ApiEngagementRule } from "@incendium/api";
import { Box, CircularProgress, Portal } from "@mui/material";
import { engagementService } from "Apis";
import ConditionGroupProvider from "Components/ConditionalGroup/ConditionGroupProvider";
import EngagementForm from "Components/Engagement/EngagementForm";
import { TypographyHelp } from "Components/UI/TypographyHelp";
import { useProjectSelector } from "Hooks";
import { useConversions } from "Hooks/useConversions";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useMount } from "react-use";

function EngagementPage() {
  const project = useProjectSelector();
  const { engagementId } = useParams<{ engagementId: string }>();
  const [engagement, setEngagement] = useState<ApiEngagementRule | undefined>(
    undefined
  );
  const { conversions } = useConversions();

  useMount(() => {
    if (!engagementId) {
      return;
    }
    const loadEngagement = async () => {
      const res = await engagementService.engagementServiceReadEngagement({
        projectId: project?.id as number,
        engagementId: Number(engagementId),
      });
      setEngagement(res);
    };
    loadEngagement();
  });

  const filteredConversions = useMemo(() => {
    return [...conversions];
  }, [engagement, conversions]);

  if (!project || !engagement) {
    return <CircularProgress />;
  }

  return (
    <Box pt={5}>
      <Portal container={() => document.getElementById("pageTitle")}>
        <TypographyHelp
          variant="h1"
          text=" Pageview Lead Scoring Setup"
          width={335}
          tooltip={
            <>
              <p>
                In order to measure how well a particular lead engages with a
                page, you can allocate points to various interactions taken by
                the viewer. The score can be given if the viewer takes a grouped
                set of actions assigned by you (i.e. action 1{" "}
                <strong>AND</strong> action 2) or a grouped set of different
                actions (i.e. action 1 and action 2, OR action 3, 4 and 5). You
                can have as many or as few actions as you want in each set of
                grouped actions. This score is then added to the leads total
                Lead Score
              </p>
              <strong>Example</strong>
              <p>
                You wish to allocate the points if the viewer spent more than 60
                seconds on the page <strong>AND</strong> they viewed 100% of the
                page, <strong>OR</strong> a conversion was achieved - the viewer
                made a booking enquiry.
              </p>
              <p>
                In the first group you would select: time (seconds) on page = 60
              </p>
              <p>Amount (%) page viewed = 100 </p>
              <p>
                You would then click <strong>OR</strong> to create a new group,
                and in the new group you would select
              </p>
              <p>Conversion (goal achieved) = booking enquiry</p>
            </>
          }
        />
      </Portal>
      <ConditionGroupProvider>
        <EngagementForm
          engagement={engagement}
          setEngagement={setEngagement}
          conversions={filteredConversions}
          project={project}
        />
      </ConditionGroupProvider>
    </Box>
  );
}

export default EngagementPage;
