import { MenuItem, Stack, TextField, Typography } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import IncreaseDecrease from "Components/IncreaseDecrease/IncreaseDecrease";
import StyledDataGrid from "Components/UI/StyledDataGrid";
import { formatNumber } from "Helpers/numbers";
import { percentageChange } from "Helpers/percentage";
import { useSelectedProject } from "Hooks";
import { useFromToContext } from "Providers/FromToProvider";
import { useMemo, useState } from "react";
import AnalyticsCard, {
  AnalyticsCardTitle,
  AnalyticsCardTitleWrapper,
} from "../AnalyticsCard";
import { ApiDimension, ApiMetric } from "@incendium/api";
import { dimensionToName, useChartData } from "features/analytics";

function OutOfStockBiggestDropChart({
  organicChannelName,
}: {
  organicChannelName: string;
}) {
  const { chartOutput, chartComparisonOutput } = useFromToContext();
  const { selectedProject } = useSelectedProject();

  const [dimension, setDimension] = useState<ApiDimension>(
    ApiDimension.DIMENSION_PATH
  );
  const dimensions = [
    ApiDimension.DIMENSION_PATH,
    ApiDimension.DIMENSION_PRODUCT_NAME,
    ApiDimension.DIMENSION_PRODUCT_CATEGORY,
    ApiDimension.DIMENSION_PRODUCT_BRAND,
  ];

  const chart = useMemo(
    () => ({
      name: "",
      dimension: [dimension],
      orderBy: `${ApiMetric.METRIC_PAGE_VIEWS_COUNT} DESC`,
      yAxisKeys: [
        {
          key: "l",
          fields: [
            ApiMetric.METRIC_PAGE_VIEWS_COUNT,
            ApiMetric.METRIC_OUT_OF_STOCK_PAGEVIEW_COUNT,
            ApiMetric.METRIC_LEADS_COUNT,
          ],
        },
      ],
      attributes: [
        {
          key: ApiDimension.DIMENSION_CHANNEL,
          value: organicChannelName,
        },
      ],
    }),
    [dimension, organicChannelName]
  );

  const { chartData, loading } = useChartData(
    selectedProject,
    chart,
    chartOutput
  );
  const { chartData: compChartData } = useChartData(
    selectedProject,
    chart,
    chartComparisonOutput
  );

  const rows = useMemo(() => {
    return (chartData?.data || [])
      .filter(
        (d) =>
          d[ApiMetric.METRIC_OUT_OF_STOCK_PAGEVIEW_COUNT] &&
          Number(d[ApiMetric.METRIC_OUT_OF_STOCK_PAGEVIEW_COUNT]) > 0
      )
      .map((d) => {
        const { name, ...rest } = d;
        const comp = (compChartData?.data || []).find((c) => c.name === d.name);
        const compPageviews = comp
          ? comp[ApiMetric.METRIC_PAGE_VIEWS_COUNT]
          : 0;
        const compLeads = comp ? comp[ApiMetric.METRIC_LEADS_COUNT] : 0;
        const diffPageviews = percentageChange(
          Number(compPageviews) || 0,
          Number(d[ApiMetric.METRIC_PAGE_VIEWS_COUNT]) || 0
        );
        const diffLeads = percentageChange(
          Number(compLeads) || 0,
          Number(d[ApiMetric.METRIC_LEADS_COUNT]) || 0
        );
        return {
          id: d.name,
          name: name,
          diffPageviews,
          diffLeads,
          ...rest,
        };
      })
      .sort((a, b) => a.diffPageviews - b.diffPageviews);
  }, [chartData, compChartData]);

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        headerName: "End Path",
        field: "name",
        width: 300,
      },
      {
        headerName: "Increase / Decrease in pageviews",
        field: "diffPageviews",
        align: "center",
        width: 180,
        renderCell: (rowData: GridCellParams) => {
          return (
            <Stack>
              <Typography variant={"body2"} align="center">
                {formatNumber(rowData.row[ApiMetric.METRIC_PAGE_VIEWS_COUNT])}
              </Typography>
              <IncreaseDecrease
                direction={Number(rowData.value) >= 0 ? "up" : "down"}
                value={Number(rowData.value)}
              />
            </Stack>
          );
        },
      },
      {
        headerName: "Increase / Decrease in no. leads",
        field: "diffLeads",
        width: 180,
        align: "center",
        renderCell: (rowData: GridCellParams) => {
          return (
            <Stack>
              <Typography variant={"body2"} align="center">
                {formatNumber(rowData.row[ApiMetric.METRIC_LEADS_COUNT])}
              </Typography>
              <IncreaseDecrease
                direction={Number(rowData.value) >= 0 ? "up" : "down"}
                value={Number(rowData.value)}
              />
            </Stack>
          );
        },
      },
      {
        headerName: "Out Of Stock Pageview Count",
        field: ApiMetric.METRIC_OUT_OF_STOCK_PAGEVIEW_COUNT,
        width: 200,
        align: "center",
      },
    ];
  }, []);

  return (
    <AnalyticsCard>
      <AnalyticsCardTitleWrapper>
        <AnalyticsCardTitle
          title={"Out of Stock Pages with Biggest Organic Traffic Drop"}
        />

        <TextField
          size="small"
          sx={{ width: 200 }}
          select
          value={dimension}
          label="change dimension"
          onChange={(e) => setDimension(e.target.value as ApiDimension)}
        >
          {dimensions.map((d) => (
            <MenuItem key={d} value={d}>
              {dimensionToName(d)}
            </MenuItem>
          ))}
        </TextField>
      </AnalyticsCardTitleWrapper>
      <StyledDataGrid
        loading={loading}
        rows={rows}
        columns={columns}
        autoHeight
        pageSizeOptions={[5]}
        disableColumnMenu
      />
    </AnalyticsCard>
  );
}

export default OutOfStockBiggestDropChart;
