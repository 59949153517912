import { ApiDimension } from "@incendium/api";
import { analyticsService } from "Apis";
import { TFilterableDimensions } from "Interfaces";

const getFilterableDimensions = async (
  projectId: number,
  dimension?: ApiDimension
): Promise<TFilterableDimensions> => {
  let result = {} as TFilterableDimensions;
  if (dimension) {
    const names = await analyticsService.analyticsServiceGetDimensionNames({
      projectId,
      dimension,
    });
    result[dimension] = names.result || [];
    return result;
  }

  const res = await analyticsService.analyticsServiceGetFilterableDimensions({
    projectId,
  });
  for (let i = 0; i < (res.result || []).length; i++) {
    const dimension = (res.result || [])[i];
    try {
      const names = await analyticsService.analyticsServiceGetDimensionNames({
        projectId,
        dimension,
      });
      result[dimension] = names.result || [];
    } catch (error) {
      continue;
    }
  }
  return result;
};

export default getFilterableDimensions;
