import { ApiEngagementRule, ApiLocation } from "@incendium/api";
import { Box, Grid, Typography } from "@mui/material";
import { ClickableTooltip } from "Components/ClickableTooltip";
import { StyledHelp } from "Components/ClickableTooltip/StyledHelp";
import {
  SidebarContainer,
  SidebarContentWrapper,
  SidebarStyledDivider,
  SiderBarHeader,
} from "Components/UI/Sidebar";
import produce from "immer";
import { useEffect, useState } from "react";
import { TextValidator } from "react-material-ui-form-validator";
import LocationSidebarSelector from "Components/Location/LocationSidebarSelector";

interface IEngagementSidebarProps {
  engagement: ApiEngagementRule;
  setEngagement: React.Dispatch<
    React.SetStateAction<ApiEngagementRule | undefined>
  >;
}

function EngagementSidebar({
  engagement,
  setEngagement,
}: IEngagementSidebarProps) {
  const [locations, setLocations] = useState<ApiLocation[]>(
    engagement.locations || []
  );

  useEffect(() => {
    setEngagement(
      produce(engagement, (draft) => {
        draft.locations = locations;
      })
    );
  }, [locations]);

  return (
    <SidebarContainer>
      <SiderBarHeader>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography variant="h2">Set Parameters</Typography>
          <Box ml={1} display="flex" alignItems="center">
            <ClickableTooltip
              placement="bottom"
              width={335}
              text={
                <>
                  <p>
                    Assign a name to this engagement score, choose the amount of
                    points it is worth, and select the pages it applies to. You
                    can also assign a maximum score - this is for instances
                    where a conversion is likely to happen several times on one
                    page. If you don’t assign a maximum score, it will
                    automatically cap at 100. This is also the highest maximum
                    score you can set.
                  </p>
                  <p>
                    <strong>Example</strong>
                  </p>
                  <p>
                    You want to assign 20 points if the viewer clicks ‘next’ on
                    an image carousel. You set up the conversion
                  </p>
                  <p>conversion = click next</p>
                  <p>
                    If the viewer clicks ‘next’ 10 times, you may want the
                    points to cap out at 60, so you would set the score as 20,
                    and the max score as 60.
                  </p>
                </>
              }
              icon={<StyledHelp />}
            />
          </Box>
        </Box>
        <Typography>Define score and relevant locations</Typography>
      </SiderBarHeader>
      <SidebarContentWrapper px={3}>
        <Box mb={3.5} mt={2}>
          <TextValidator
            label="name of pageview score"
            variant="outlined"
            name="name"
            fullWidth
            validators={["required"]}
            errorMessages={["name is required"]}
            value={engagement.name || ""}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setEngagement(
                produce(engagement, (draft) => {
                  draft.name = e.currentTarget.value;
                })
              );
            }}
          />
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextValidator
              label="score"
              variant="outlined"
              name="score"
              fullWidth
              type="number"
              validators={["required"]}
              errorMessages={["score is required"]}
              value={engagement.score || ""}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setEngagement(
                  produce(engagement, (draft) => {
                    draft.score = Number(e.currentTarget.value);
                  })
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextValidator
              label="max score"
              variant="outlined"
              name="score"
              fullWidth
              type="number"
              value={engagement.maxScore || ""}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setEngagement(
                  produce(engagement, (draft) => {
                    draft.maxScore = Number(e.currentTarget.value);
                  })
                );
              }}
            />
          </Grid>
        </Grid>
        <Box mt={4.5} mb={2}>
          <SidebarStyledDivider />
        </Box>
        <LocationSidebarSelector
          locations={locations}
          setLocations={setLocations}
        />
      </SidebarContentWrapper>
    </SidebarContainer>
  );
}

export default EngagementSidebar;
