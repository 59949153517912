import { Paper, PaperProps, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { HTMLMotionProps } from "framer-motion";
import { forwardRef } from "react";

export interface IGlassProps
  extends PaperProps,
    Pick<HTMLMotionProps<any>, "exit" | "initial" | "animate" | "transition"> {
  blur?: number;
  square?: boolean;
  noBorder?: boolean;
  noShadow?: boolean;
  opacity?: number;
  height?: string | number;
  boxShadowOpactity?: number;
  backgroundColor?: string;
  overflow?: string;
}

const useGlassStyles = makeStyles<Theme, IGlassProps>((theme) => ({
  glass: (props) => ({
    boxShadow: props.noShadow
      ? "inherit"
      : `10px 10px 22px rgb(196 202 230 / ${props.boxShadowOpactity}%)`,
    backgroundColor:
      props.backgroundColor || `rgba(255, 255, 255, ${props.opacity || 0.3})`,
    // backdropFilter: `blur(${props.blur}px)`, causing performance issues
    border: props.noBorder
      ? "none"
      : `1px solid  rgba(255, 255, 255, ${
          props?.opacity && props?.opacity > 0.5 ? 1 : 0.7
        })`,
    borderRadius: props.square ? 0 : 10,
    height: props.height,
    overflowY: props.overflow ? props.overflow : ("auto" as any),
    overflowX: props.overflow ? props.overflow : ("hidden" as any),
  }),
}));

export const Glass = forwardRef<HTMLDivElement, IGlassProps>(
  (
    {
      blur,
      noBorder,
      noShadow,
      elevation = 0,
      height = "100%",
      boxShadowOpactity = "40%",
      backgroundColor,
      overflow,
      ...props
    },
    ref
  ) => {
    const { glass } = useGlassStyles({
      blur: blur || 6,
      noBorder,
      noShadow,
      height,
      backgroundColor,
      overflow,
      ...props,
    });
    let classNames = [props.className, glass].join(" ");

    return (
      <Paper ref={ref} elevation={elevation} {...props} className={classNames}>
        {props.children}
      </Paper>
    );
  }
);
