import { ApiLocation } from "@incendium/api";
import { AddCircle, Delete } from "@mui/icons-material";
import { Box, Switch, Typography } from "@mui/material";
import { ClickableTooltip } from "Components/ClickableTooltip";
import { StyledHelp } from "Components/ClickableTooltip/StyledHelp";
import {
  SidebarActionButton,
  SidebarListButton,
  SidebarSubtitle,
} from "Components/UI/Sidebar";
import { StyledFlexBox } from "Components/UI/StylesFlexBox";
import { AnimatePresence, motion } from "framer-motion";
import produce from "immer";
import { useEffect } from "react";
import { useState } from "react";
import { useUpdateEffect } from "react-use";
import MultipleLocationSelector from "./MultipleLocationSelector";

interface ILocationSidebarSelectorProps {
  locations: ApiLocation[];
  setLocations: (value: React.SetStateAction<ApiLocation[]>) => void;
}

function LocationSidebarSelector({
  locations,
  setLocations,
}: ILocationSidebarSelectorProps) {
  const [open, setOpen] = useState(false);
  const [showLocationSelect, setShowLocationSelect] = useState(false);

  useEffect(() => {
    if (!locations) {
      return;
    }
    setShowLocationSelect((locations || []).length > 0);
  }, [locations]);

  useUpdateEffect(() => {
    if (!showLocationSelect) {
      setLocations([]);
    }
  }, [showLocationSelect]);

  return (
    <>
      <Box mb={2}>
        <SidebarSubtitle>Apply to locations</SidebarSubtitle>
      </Box>
      <StyledFlexBox mb={4}>
        <Box display="flex" alignItems="center">
          <Typography color={"secondary"}>all</Typography>
          <Switch
            checked={showLocationSelect}
            onChange={(e, checked) => setShowLocationSelect(checked)}
          />
          <Typography color={"primary"}>selected</Typography>
        </Box>
        <ClickableTooltip
          placement="bottom"
          width={335}
          text="If you don’t want this rule to apply to all pages, you can choose ‘selected’ and then add the specific pages below using the (+) button."
          icon={<StyledHelp />}
        />
      </StyledFlexBox>
      {showLocationSelect && (
        <>
          <Box
            component={motion.div}
            whileHover={{
              scale: 1.03,
            }}
          >
            <SidebarActionButton
              endIcon={<AddCircle color="primary" />}
              onClick={() => setOpen(true)}
            >
              add new location
            </SidebarActionButton>
          </Box>
          <AnimatePresence>
            {(locations || []).map((location) => (
              <Box
                key={location.id}
                component={motion.div}
                animate={{
                  x: 0,
                  opacity: 1,
                }}
                initial={{ x: -20, opacity: 0 }}
                exit={{ x: -50, opacity: 0 }}
                transition={{
                  delay: 0.1,
                }}
                whileHover={{
                  scale: 1.03,
                }}
              >
                <SidebarListButton
                  color="secondary"
                  endIcon={<Delete />}
                  onClick={() => {
                    setLocations(
                      produce(locations, (draft) => {
                        const idx = locations.findIndex(
                          (l) => l.id === location.id
                        );
                        draft.splice(idx, 1);
                      })
                    );
                  }}
                >
                  {location.name}
                </SidebarListButton>
              </Box>
            ))}
          </AnimatePresence>
        </>
      )}
      <MultipleLocationSelector
        locations={locations}
        setLocations={setLocations}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}

export default LocationSidebarSelector;
