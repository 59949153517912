import { ApiDimension, ApiMetric } from "@incendium/api";
import { Box } from "@mui/material";
import FullWidthHeightGlass from "Components/Page/FullWidthHeightGlass";
import { dimensionNameToEnum, dimensionToName } from "features/analytics";
import {
  ExplorerSidebar,
  ExplorerTable,
  useExplorerContext,
} from "features/explorer";
import { useEffect } from "react";
import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

interface IContentExplorerProps {
  noTabs?: boolean;
  metrics: ApiMetric[];
  limit?: number;
}

function ContentExplorer({ noTabs, metrics, limit }: IContentExplorerProps) {
  const { dimension, attributes, setAttributes, setDimension } =
    useExplorerContext();
  const history = useHistory();
  const location = useLocation();
  const onClick = useCallback(
    (s, o) => {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set(
        [
          ApiDimension.DIMENSION_CONVERTING_SESSION_JOURNEY,
          ApiDimension.DIMENSION_SALES_SESSION_JOURNEY,
        ].includes(dimension)
          ? dimensionToName(ApiDimension.DIMENSION_LOCATION)
          : dimensionToName(dimension),
        typeof s === "string" ? s : s[dimension]
      );
      const updatedSearchString = searchParams.toString();
      history.push({
        pathname: "./content-performance-analysis",
        search: updatedSearchString,
      });
    },
    [dimension, history]
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.forEach((v, k) => {
      if (k !== "tab") {
        setAttributes([
          {
            key: dimensionNameToEnum(k),
            value: v || "",
          },
        ]);
        setDimension(ApiDimension.DIMENSION_BROWSER);
        return;
      }
    });
  }, [location.search, setAttributes, setDimension]);

  return (
    <FullWidthHeightGlass height={`calc(100vh - ${noTabs ? 128 : 165}px)`}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: `73% 27%`,
          height: "100%",
        }}
      >
        <Box pr={1} sx={{ flex: 1 }}>
          <ExplorerTable
            onClick={
              attributes.length === 0 &&
              ![
                ApiDimension.DIMENSION_CHANNEL_MACRO_CONVERSION_JOURNEY,
                ApiDimension.DIMENSION_CHANNEL_SALES_JOURNEY,
              ].includes(dimension)
                ? { [dimension]: onClick }
                : undefined
            }
            metrics={metrics}
            limit={limit}
          />
        </Box>
        <ExplorerSidebar />
      </Box>
    </FullWidthHeightGlass>
  );
}

export default ContentExplorer;
