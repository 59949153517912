import {
  ApiLocation,
  ApiChannel,
  ApiEngagementRule,
  ApiClassificationGroup,
  ApiClassification,
  ApiConversion,
  ApiProject,
  ApiAudience,
  ApiPageType,
  ApiAlert,
  ApiLeadStatusRule,
  ApiLeadScoreAdjustmentRule,
  ApiReportResponse,
  ApiEffectivenessRule,
  ApiSessionEffectivenessRule,
  ApiLocationPageTagger,
  ApiAPITokenResponse,
  ApiBucketResponse,
  ApiTabElementResponse,
  ApiCSVUploadSession,
  ApiCSVRule,
  ApiUser,
  ApiProduct,
  ApiUserOauthClient,
  ApiSync,
  ApiService,
  ApiPageviewMetaTagger,
  ApiAttributionTag,
  ApiJob,
  ApiGeoLocation,
  ApiSimpleComponent,
  ApiSimpleCampaign,
  ApiMedia,
  ApiDomainResponse,
  ApiReadLanderResponse,
  ApiSimpleUTM,
  ApiDomain,
  // ApiTokenData,
} from "@incendium/api";
import { useSelectedProject } from "./useSelectedProject";
import { useDispatch, useSelector } from "react-redux";
import {
  CallbackOrVal,
  IChart,
  IStoreState,
  TFilterableAttributionDimensions,
  TFilterableDimensions,
} from "Interfaces";
import { fetchStateThunk, setApplicationState } from "Actions";
import { get } from "lodash";
import { useMount } from "./useMount";

// export type TMappedApiUserOauthClient = {
//   [key in ApiService]: ApiUserOauthClient[];
// };
export type TMappedApiUserOauthClient = Record<
  ApiService,
  ApiUserOauthClient[]
>;

type Data =
  | ApiUser
  | ApiLocation
  | ApiChannel
  | ApiBucketResponse
  | ApiEngagementRule
  | ApiEffectivenessRule
  | ApiSessionEffectivenessRule
  | ApiClassificationGroup
  | ApiClassification
  | ApiConversion
  | ApiAudience
  | ApiPageType
  | ApiAlert
  | ApiLeadStatusRule
  | ApiLeadScoreAdjustmentRule
  | ApiReportResponse
  | ApiLocationPageTagger
  | ApiAPITokenResponse
  | ApiTabElementResponse
  | ApiCSVUploadSession
  | IChart
  | ApiCSVRule
  | ApiProduct
  | ApiSync
  | ApiPageviewMetaTagger
  | ApiAttributionTag
  | ApiGeoLocation
  | ApiJob
  | ApiSimpleComponent
  | ApiSimpleCampaign
  | ApiMedia
  | ApiDomainResponse
  | ApiReadLanderResponse
  | ApiDomain
  | TFilterableDimensions
  | TFilterableAttributionDimensions
  | ApiSimpleUTM;

type StateData<T> =
  | TMappedApiUserOauthClient
  | TFilterableDimensions
  | TFilterableAttributionDimensions
  | T[];

export function useSharedState<
  T extends Data | TMappedApiUserOauthClient,
  T2 extends unknown
>(
  key: Exclude<keyof IStoreState["application"], "clients" | "projects">,
  defaultVal: StateData<T>,
  fetch: (proj: ApiProject | null) => Promise<T2>,
  itemId?: number,
  fetchItem?: (proj: ApiProject | null) => Promise<Data>,
  defaultItemVal?: T
) {
  const { selectedProject } = useSelectedProject();

  const appState = useSelector((state: IStoreState) => state.application[key]);

  const clientId = selectedProject?.clientId?.toFixed() as string;
  const projectId = selectedProject?.id?.toFixed() as string;
  const dispatch = useDispatch();
  const setState = (val: CallbackOrVal<T2>) => {
    dispatch(setApplicationState(clientId, projectId, key, val));
  };
  const setItemState = (val: CallbackOrVal<StateData<T> | Data>) => {
    if (typeof itemId === "undefined") {
      return;
    }
    dispatch(
      setApplicationState(
        clientId,
        projectId,
        key,
        val,
        isNaN(itemId) ? 0 : itemId
      )
    );
  };

  const resetEmpty = () => {
    dispatch(
      setApplicationState(clientId, projectId, key, defaultItemVal || {}, 0)
    );
  };

  const state = get(appState, [clientId, projectId, "state"], defaultVal);
  const childState = get(
    appState,
    [clientId, projectId, itemId || 0, "state"],
    defaultItemVal || {}
  );
  const status = get(appState, [clientId, projectId, "status"], "UNFETCHED");

  const fetchFromApi = async () => {
    const data = await fetch(selectedProject);
    setState(data);
  };
  const fetchItemFromApi = async () => {
    if (!fetchItem) {
      return;
    }
    const data = await fetchItem(selectedProject);
    setItemState(data);
  };

  const populateDefault = async () => {
    setItemState(defaultItemVal || {});
  };

  const refetch = async () => {
    await dispatch(fetchStateThunk(clientId, projectId, key, fetchFromApi));
    if (typeof itemId === "undefined") {
      return;
    }

    if (isNaN(itemId) || itemId === 0) {
      await dispatch(
        fetchStateThunk(clientId, projectId, key, populateDefault, 0)
      );
      return;
    }

    await dispatch(
      fetchStateThunk(clientId, projectId, key, fetchItemFromApi, itemId)
    );
  };
  useMount(() => {
    refetch();
  });
  return {
    state: state as T2,
    setState,
    childState,
    setChildState: setItemState,
    resetEmpty,
    loading: ["UNFETCHED", "FETCHING"].indexOf(status) > -1,
    refetch: fetchFromApi,
    selectedProject,
  };
}
