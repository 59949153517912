import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiReadLanderResponse,
} from "@incendium/api";
import { appendQueryParams, noTrackParam } from "@incendium/inc-ts-helpers";
import {
  Box,
  Grid,
  MenuItem,
  Portal,
  TextField,
  Typography,
} from "@mui/material";
import { CenterPage } from "Components/CenterPage/CenterPage";
import Loading from "Components/Loading/Loading";
import { PageActionPreSlot } from "consts";
import {
  AnalyticsCard,
  desktop,
  dimensionToName,
  mobile,
  table4Rows,
} from "features/analytics";
import { getDomain } from "features/subdomains/utils";
import { AnimatePresence, motion } from "framer-motion";
import withDatepicker from "HoC/withDatepicker";
import withPagetitle, { IWithPagetitleProps } from "HoC/withPagetitle";
import { useSelectedProject } from "Hooks";
import { useLanders } from "Hooks/useLanders";
import IframeResizer from "iframe-resizer-react";
import moment from "moment";
import { useFromToContext } from "Providers/FromToProvider";
import { Suspense, useCallback, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";

interface ILightningLandersReportPageInnerProps extends IWithPagetitleProps {
  landingPath: ApiReadLanderResponse;
  from: moment.Moment;
  to: moment.Moment;
}

function LightningLandersPageSelector({
  landers,
  value,
  width,
  size,
}: {
  landers: ApiReadLanderResponse[];
  value?: string;
  width: number;
  size?: "small" | "medium";
}) {
  const { selectedClient, selectedProject } = useSelectedProject();
  const history = useHistory();
  const onSelect = useCallback(
    (id) => {
      history.push(
        `/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/optimization/lightning-landers/${id}`
      );
    },
    [history, selectedClient, selectedProject]
  );
  return (
    <TextField
      sx={{ width }}
      size={size}
      value={value}
      label="Select Lightning Lander"
      select
      onChange={(e) => {
        onSelect(e.target.value);
      }}
    >
      {landers.map((l) => (
        <MenuItem key={l.id} value={l.id}>
          {l.url}
        </MenuItem>
      ))}
    </TextField>
  );
}

function LightningLandersReportPageInner({
  landingPath,
  from,
  to,
}: ILightningLandersReportPageInnerProps) {
  const domain = useMemo(
    () => getDomain(landingPath.url || ""),
    [landingPath.url]
  );
  const trendDimension = useMemo(() => {
    const diff = to.diff(from, "days");
    return diff > 90
      ? ApiDimension.DIMENSION_SESSION_START_BY_MONTH
      : ApiDimension.DIMENSION_SESSION_START_BY_DAY;
  }, [from, to]);

  const noTrailingPath = useMemo(
    () =>
      (landingPath.path || "").slice(-1) === "/"
        ? (landingPath.path || "").slice(0, -1)
        : `/${landingPath.path}`,
    [landingPath.path]
  );

  const landingFilter = useMemo(() => {
    return [
      {
        key: ApiDimension.DIMENSION_DOMAIN,
        value: domain,
      },
      {
        key: ApiDimension.DIMENSION_LANDING_PAGE_PATH,
        value: `/${noTrailingPath}/`,
      },
      {
        key: ApiDimension.DIMENSION_LANDING_PAGE_PATH,
        value: `/${noTrailingPath}`,
      },
    ];
  }, [noTrailingPath, domain]);
  return (
    <Box
      mb={2}
      component={motion.div}
      initial={{ scale: 0.7, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
    >
      <Grid container spacing={3}>
        <Grid item xs={7} sx={{ overflow: "hidden" }}>
          {landingPath?.url && (
            <IframeResizer
              src={appendQueryParams(landingPath?.url || "", [
                noTrackParam,
                "true",
              ])}
              scrolling
              autoResize
              style={{
                width: 1,
                minWidth: "200%",
                minHeight: "200%",
                transform: "scale(0.5)",
                transformOrigin: `top left`,
                border: 0,
              }}
            />
          )}
        </Grid>
        <Grid item xs={5}>
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{ minHeight: table4Rows }}>
              <AnalyticsCard
                chart={{
                  name: "Lightning Lander - Primary Metrics",
                  dimension: [trendDimension],
                  template: ApiChartTemplate.TREND,
                  type: ApiChartType.TABLE,
                  attributes: landingFilter,
                  includeSubdomains: true,
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [
                        ApiMetric.METRIC_SESSIONS_COUNT,
                        ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                        ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_COUNT,
                        ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
                        ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE,
                        ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT,
                        ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
                        ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT,
                        ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_RATE,
                      ],
                    },
                  ],
                  displayOptions: {
                    noAttributionToggle: true,
                    rowsPerPage: 4,
                    noFilters: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} sx={{ minHeight: 270 }}>
              <AnalyticsCard
                chart={{
                  name: "Mobile Traffic Composition %",
                  dimension: [ApiDimension.DIMENSION_CHANNEL],
                  template: ApiChartTemplate.SNAPSHOT,
                  type: ApiChartType.PIE,
                  attributes: [
                    ...landingFilter,
                    {
                      key: ApiDimension.DIMENSION_DEVICE,
                      value: mobile,
                    },
                  ],
                  includeSubdomains: true,
                  limit: 5,
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                    },
                  ],
                  displayOptions: {
                    noAttributionToggle: true,
                    noFilters: true,
                    showTotals: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} sx={{ minHeight: 270 }}>
              <AnalyticsCard
                chart={{
                  name: "Mobile Conversion Composition %",
                  dimension: [ApiDimension.DIMENSION_CHANNEL],
                  template: ApiChartTemplate.SNAPSHOT,
                  type: ApiChartType.PIE,
                  attributes: [
                    ...landingFilter,
                    {
                      key: ApiDimension.DIMENSION_DEVICE,
                      value: mobile,
                    },
                  ],
                  includeSubdomains: true,
                  limit: 5,
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [
                        ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT,
                      ],
                    },
                  ],
                  displayOptions: {
                    noAttributionToggle: true,
                    noFilters: true,
                    showTotals: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} sx={{ minHeight: 270 }}>
              <AnalyticsCard
                chart={{
                  name: "Desktop Traffic Composition %",
                  dimension: [ApiDimension.DIMENSION_CHANNEL],
                  template: ApiChartTemplate.SNAPSHOT,
                  type: ApiChartType.PIE,
                  attributes: [
                    ...landingFilter,
                    {
                      key: ApiDimension.DIMENSION_DEVICE,
                      value: desktop,
                    },
                  ],
                  includeSubdomains: true,
                  limit: 5,
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                    },
                  ],
                  displayOptions: {
                    noAttributionToggle: true,
                    noFilters: true,
                    showTotals: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} sx={{ minHeight: 270 }}>
              <AnalyticsCard
                chart={{
                  name: "Desktop Conversion Composition %",
                  dimension: [ApiDimension.DIMENSION_CHANNEL],
                  template: ApiChartTemplate.SNAPSHOT,
                  type: ApiChartType.PIE,
                  attributes: [
                    ...landingFilter,
                    {
                      key: ApiDimension.DIMENSION_DEVICE,
                      value: desktop,
                    },
                  ],
                  includeSubdomains: true,
                  limit: 5,
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [
                        ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT,
                      ],
                    },
                  ],
                  displayOptions: {
                    noAttributionToggle: true,
                    noFilters: true,
                    showTotals: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ minHeight: table4Rows }}>
              <AnalyticsCard
                chart={{
                  name: "Lightning Lander - Interactions",
                  dimension: [ApiDimension.DIMENSION_MICRO_CONVERSION],
                  template: ApiChartTemplate.TABLE,
                  type: ApiChartType.TABLE,
                  attributes: landingFilter,
                  includeSubdomains: true,
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [
                        ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                        ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT,
                        ApiMetric.METRIC_PAGE_MICRO_CONVERSION_RATE,
                      ],
                    },
                  ],
                  displayOptions: {
                    noAttributionToggle: true,
                    noFilters: true,
                    rowsPerPage: 4,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={7} sx={{ minHeight: table4Rows }}>
          <AnalyticsCard
            customTitleFn={(d) => `${dimensionToName(d)} Performance`}
            chart={{
              name: "Channel / Campaign / Keywords",
              dimension: [ApiDimension.DIMENSION_CHANNEL],
              template: ApiChartTemplate.TABLE,
              type: ApiChartType.TABLE,
              attributes: landingFilter,
              includeSubdomains: true,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [
                    ApiMetric.METRIC_SESSIONS_COUNT,
                    ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                    ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_COUNT,
                    ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
                    ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE,
                    ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT,
                    ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT,
                    ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
                    ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT,
                    ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_RATE,
                  ],
                },
              ],
              displayOptions: {
                noAttributionToggle: true,
                noFilters: true,
                rowsPerPage: 4,
                availableDimensions: [
                  ApiDimension.DIMENSION_CHANNEL,
                  ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
                  ApiDimension.DIMENSION_TERM,
                ],
              },
            }}
          />
        </Grid>
        <Grid item xs={2.5}>
          <AnalyticsCard
            chart={{
              name: "Total Channel Traffic Composition %",
              dimension: [ApiDimension.DIMENSION_CHANNEL],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.PIE,
              attributes: landingFilter,
              includeSubdomains: true,
              limit: 10,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [ApiMetric.METRIC_PAGE_VIEWS_COUNT],
                },
              ],
              displayOptions: {
                noAttributionToggle: true,
                noFilters: true,
                showTotals: true,
              },
            }}
          />
        </Grid>
        <Grid item xs={2.5}>
          <AnalyticsCard
            chart={{
              name: "Total Channel Traffic Composition %",
              dimension: [ApiDimension.DIMENSION_CHANNEL],
              template: ApiChartTemplate.SNAPSHOT,
              type: ApiChartType.PIE,
              attributes: landingFilter,
              includeSubdomains: true,
              limit: 5,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [
                    ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT,
                  ],
                },
              ],
              displayOptions: {
                noAttributionToggle: true,
                noFilters: true,
                showTotals: true,
              },
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

const EnhancedComponent = withPagetitle(
  LightningLandersReportPageInner
) as React.ComponentType<ILightningLandersReportPageInnerProps>;

function LightningLandersReportPage() {
  const { landers, loading } = useLanders();
  const { calculatedFrom, to } = useFromToContext();
  const { landerId } = useParams<{ landerId: string }>();

  const selectedLander = useMemo(() => {
    if (!landers) {
      return null;
    }
    return landers.find((l) => l.id === Number(landerId));
  }, [landers, landerId]);

  const renderBody = useCallback(() => {
    if (loading) {
      return <Loading />;
    }

    if (!landerId || landerId === "") {
      return (
        <CenterPage calcHeight>
          <Typography variant="subtitle1" mb={2}>
            Select a Lightning lander to get started.
          </Typography>
          <LightningLandersPageSelector width={350} landers={landers} />
        </CenterPage>
      );
    }

    return (
      <>
        <AnimatePresence>
          <EnhancedComponent
            title="Lightning Landers Report"
            subTitle={selectedLander?.url || ""}
            landingPath={selectedLander!}
            from={calculatedFrom}
            to={to || moment().utc()}
          />
        </AnimatePresence>
      </>
    );
  }, [loading, landerId, selectedLander, calculatedFrom, to, landers]);

  return (
    <>
      <Portal container={() => document.getElementById(PageActionPreSlot)}>
        <LightningLandersPageSelector
          size="small"
          width={250}
          landers={landers}
          value={landerId}
        />
      </Portal>
      <Suspense fallback={<Loading />}>{renderBody()}</Suspense>
    </>
  );
}

export default withDatepicker(LightningLandersReportPage);
