import { Box } from "@mui/material";
import { ChartLegend, useChartData } from "features/analytics";
import StyledBarChart from "Components/StyledBarChart/StyledBarChart";
import { groupBy } from "Helpers/arrays";
import { formatFloat } from "Helpers/numbers";
import { truncate } from "Helpers/truncate";
import { useSelectedProject } from "Hooks";
import { useFromToContext } from "Providers/FromToProvider";
import { useMemo } from "react";
import { Tooltip } from "recharts";
import AnalyticsCard, {
  AnalyticsCardLegendContainer,
  AnalyticsCardTitle,
  AnalyticsCardTitleWrapper,
} from "../../../Components/Analytics/AnalyticsCard";
import { ApiDimension, ApiMetric } from "@incendium/api";

interface IPrimaryTopMicroConversionsProps {
  byDimension: string;
  dimension: ApiDimension;
  dimensionString: string;
}

function PrimaryTopMicroConversions({
  byDimension,
  dimension,
  dimensionString,
}: IPrimaryTopMicroConversionsProps) {
  const { selectedProject } = useSelectedProject();
  const { chartOutput } = useFromToContext();

  const chart = useMemo(
    () => ({
      name: "",
      dimension: [dimension, ApiDimension.DIMENSION_MICRO_CONVERSION],
      yAxisKeys: [
        {
          fields: [ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT],
        },
      ],
      attributes: [],
    }),
    [dimension]
  );

  const { chartData } = useChartData(selectedProject, chart, chartOutput);

  const current = useMemo(() => {
    if (!chartData?.data) {
      return [];
    }
    const gg = groupBy(chartData?.data || [], "name");
    return (gg[byDimension] || []).filter(
      (d) => d[ApiDimension.DIMENSION_MICRO_CONVERSION]
    );
  }, [chartData, byDimension]);

  const average = useMemo(() => {
    if (!chartData?.data) {
      return [];
    }
    const gg = groupBy(
      chartData?.data || [],
      ApiDimension.DIMENSION_MICRO_CONVERSION
    );

    return Object.keys(gg)
      .map((k) => {
        const arr = gg[k];
        const sum = (arr || []).reduce((acc: any, v, i, a) => {
          const { name, ...rest } = v;

          acc[name] = name;
          acc[ApiDimension.DIMENSION_MICRO_CONVERSION] =
            rest[ApiDimension.DIMENSION_MICRO_CONVERSION];

          if (!acc[ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT]) {
            acc[ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT] = 0;
          }
          acc[ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT] =
            acc[ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT] +
            Number(rest[ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT]);
          return acc;
        }, {});

        sum[ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT] =
          sum[ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT] / arr.length;
        return sum;
      })
      .filter((d) => d[ApiDimension.DIMENSION_MICRO_CONVERSION]);
  }, [chartData]);

  const parsedChartData = useMemo(() => {
    return current
      .sort(
        (a, b) =>
          Number(b[ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT] || 0) -
          Number(a[ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT] || 0)
      )
      .slice(0, 6)
      .map((c) => ({
        name: truncate(
          `${c[ApiDimension.DIMENSION_MICRO_CONVERSION]}` || "",
          15
        ),
        current: c[ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT] || 0,
        average:
          average.find(
            (a) =>
              a[ApiDimension.DIMENSION_MICRO_CONVERSION] ===
              c[ApiDimension.DIMENSION_MICRO_CONVERSION]
          )[ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT] || 0,
      }));
  }, [current, average]);

  return (
    <AnalyticsCard>
      <AnalyticsCardTitleWrapper>
        <Box>
          <AnalyticsCardTitle title="Top 6 Interactions" />
          <AnalyticsCardLegendContainer>
            <ChartLegend items={[`this ${dimensionString}`, "site average"]} />
          </AnalyticsCardLegendContainer>
        </Box>
      </AnalyticsCardTitleWrapper>
      <StyledBarChart
        data={parsedChartData}
        yAxisKeys={[
          {
            key: "l",
            fields: ["average", "current"],
          },
        ]}
        xAxisProps={{
          interval: 0,
        }}
        noLegend
        noReOrder
      >
        <Tooltip
          formatter={(value, key) => {
            return [
              formatFloat(value as number),
              key === "average" ? "site average" : `this ${dimensionString}`,
            ];
          }}
        />
      </StyledBarChart>
    </AnalyticsCard>
  );
}

export default PrimaryTopMicroConversions;
