/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiDataPersonalisationFromJSON, ApiDataPersonalisationToJSON, } from './ApiDataPersonalisation';
import { ApiEngagementRuleFromJSON, ApiEngagementRuleToJSON, } from './ApiEngagementRule';
import { ApiFingerprintFromJSON, ApiFingerprintToJSON, } from './ApiFingerprint';
import { ApiGeoLocationFromJSON, ApiGeoLocationToJSON, } from './ApiGeoLocation';
import { ApiJobFromJSON, ApiJobToJSON, } from './ApiJob';
import { ApiLeadFromJSON, ApiLeadToJSON, } from './ApiLead';
import { ApiLeadConversionFromJSON, ApiLeadConversionToJSON, } from './ApiLeadConversion';
import { ApiOpeStatusFromJSON, ApiOpeStatusToJSON, } from './ApiOpeStatus';
import { ApiPageFromJSON, ApiPageToJSON, } from './ApiPage';
import { ApiPageTypeFromJSON, ApiPageTypeToJSON, } from './ApiPageType';
import { ApiPageviewMetaFromJSON, ApiPageviewMetaToJSON, } from './ApiPageviewMeta';
import { ApiProductFromJSON, ApiProductToJSON, } from './ApiProduct';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
import { ApiSaleFromJSON, ApiSaleToJSON, } from './ApiSale';
import { ApiSessionFromJSON, ApiSessionToJSON, } from './ApiSession';
import { ApiUserAgentFromJSON, ApiUserAgentToJSON, } from './ApiUserAgent';
import { DataPageTypeCalculatedStatusFromJSON, DataPageTypeCalculatedStatusToJSON, } from './DataPageTypeCalculatedStatus';
export function ApiDataFromJSON(json) {
    return ApiDataFromJSONTyped(json, false);
}
export function ApiDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'lead': !exists(json, 'lead') ? undefined : ApiLeadFromJSON(json['lead']),
        'session': !exists(json, 'session') ? undefined : ApiSessionFromJSON(json['session']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'page': !exists(json, 'page') ? undefined : json['page'],
        'referrer': !exists(json, 'referrer') ? undefined : json['referrer'],
        'conversions': !exists(json, 'conversions') ? undefined : (json['conversions'].map(ApiLeadConversionFromJSON)),
        'pageTypes': !exists(json, 'pageTypes') ? undefined : (json['pageTypes'].map(ApiPageTypeFromJSON)),
        'pageTypeCalculatedStatus': !exists(json, 'pageTypeCalculatedStatus') ? undefined : DataPageTypeCalculatedStatusFromJSON(json['pageTypeCalculatedStatus']),
        'geoLocation': !exists(json, 'geoLocation') ? undefined : ApiGeoLocationFromJSON(json['geoLocation']),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'leadId': !exists(json, 'leadId') ? undefined : json['leadId'],
        'rootPage': !exists(json, 'rootPage') ? undefined : ApiPageFromJSON(json['rootPage']),
        'pageId': !exists(json, 'pageId') ? undefined : json['pageId'],
        'ope': !exists(json, 'ope') ? undefined : json['ope'],
        'opeStatus': !exists(json, 'opeStatus') ? undefined : ApiOpeStatusFromJSON(json['opeStatus']),
        'sessionId': !exists(json, 'sessionId') ? undefined : json['sessionId'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'geoLocationId': !exists(json, 'geoLocationId') ? undefined : json['geoLocationId'],
        'engagementRules': !exists(json, 'engagementRules') ? undefined : (json['engagementRules'].map(ApiEngagementRuleFromJSON)),
        'isEffective': !exists(json, 'isEffective') ? undefined : json['isEffective'],
        'userAgent': !exists(json, 'userAgent') ? undefined : ApiUserAgentFromJSON(json['userAgent']),
        'userAgentId': !exists(json, 'userAgentId') ? undefined : json['userAgentId'],
        'requestReferrer': !exists(json, 'requestReferrer') ? undefined : json['requestReferrer'],
        'requestLeadKey': !exists(json, 'requestLeadKey') ? undefined : json['requestLeadKey'],
        'requestIp': !exists(json, 'requestIp') ? undefined : json['requestIp'],
        'metaUpdatedAt': !exists(json, 'metaUpdatedAt') ? undefined : (new Date(json['metaUpdatedAt'])),
        'averageScrollSpeed': !exists(json, 'averageScrollSpeed') ? undefined : json['averageScrollSpeed'],
        'timeOnPageSeconds': !exists(json, 'timeOnPageSeconds') ? undefined : json['timeOnPageSeconds'],
        'scrolledPercentage': !exists(json, 'scrolledPercentage') ? undefined : json['scrolledPercentage'],
        'viewedPercentage': !exists(json, 'viewedPercentage') ? undefined : json['viewedPercentage'],
        'metas': !exists(json, 'metas') ? undefined : (json['metas'].map(ApiPageviewMetaFromJSON)),
        'fingerprints': !exists(json, 'Fingerprints') ? undefined : (json['Fingerprints'].map(ApiFingerprintFromJSON)),
        'broprintjsKey': !exists(json, 'broprintjsKey') ? undefined : json['broprintjsKey'],
        'fingerprintjsKey': !exists(json, 'fingerprintjsKey') ? undefined : json['fingerprintjsKey'],
        'analyticsSynced': !exists(json, 'analyticsSynced') ? undefined : json['analyticsSynced'],
        'productImpressions': !exists(json, 'productImpressions') ? undefined : (json['productImpressions'].map(ApiProductFromJSON)),
        'jobImpressions': !exists(json, 'jobImpressions') ? undefined : (json['jobImpressions'].map(ApiJobFromJSON)),
        'personalisations': !exists(json, 'personalisations') ? undefined : (json['personalisations'].map(ApiDataPersonalisationFromJSON)),
        'requestHashedIp': !exists(json, 'requestHashedIp') ? undefined : json['requestHashedIp'],
        'requestCountryCode': !exists(json, 'requestCountryCode') ? undefined : json['requestCountryCode'],
        'requestRegion': !exists(json, 'requestRegion') ? undefined : json['requestRegion'],
        'requestCity': !exists(json, 'requestCity') ? undefined : json['requestCity'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'countryId': !exists(json, 'countryId') ? undefined : json['countryId'],
        'regionId': !exists(json, 'regionId') ? undefined : json['regionId'],
        'cityId': !exists(json, 'cityId') ? undefined : json['cityId'],
        'clsValue': !exists(json, 'clsValue') ? undefined : json['clsValue'],
        'clsScore': !exists(json, 'clsScore') ? undefined : json['clsScore'],
        'inpValue': !exists(json, 'inpValue') ? undefined : json['inpValue'],
        'inpScore': !exists(json, 'inpScore') ? undefined : json['inpScore'],
        'lcpValue': !exists(json, 'lcpValue') ? undefined : json['lcpValue'],
        'lcpScore': !exists(json, 'lcpScore') ? undefined : json['lcpScore'],
        'fcpValue': !exists(json, 'fcpValue') ? undefined : json['fcpValue'],
        'fcpScore': !exists(json, 'fcpScore') ? undefined : json['fcpScore'],
        'sales': !exists(json, 'sales') ? undefined : (json['sales'].map(ApiSaleFromJSON)),
    };
}
export function ApiDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'project': ApiProjectToJSON(value.project),
        'lead': ApiLeadToJSON(value.lead),
        'session': ApiSessionToJSON(value.session),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'page': value.page,
        'referrer': value.referrer,
        'conversions': value.conversions === undefined ? undefined : (value.conversions.map(ApiLeadConversionToJSON)),
        'pageTypes': value.pageTypes === undefined ? undefined : (value.pageTypes.map(ApiPageTypeToJSON)),
        'pageTypeCalculatedStatus': DataPageTypeCalculatedStatusToJSON(value.pageTypeCalculatedStatus),
        'geoLocation': ApiGeoLocationToJSON(value.geoLocation),
        'projectId': value.projectId,
        'leadId': value.leadId,
        'rootPage': ApiPageToJSON(value.rootPage),
        'pageId': value.pageId,
        'ope': value.ope,
        'opeStatus': ApiOpeStatusToJSON(value.opeStatus),
        'sessionId': value.sessionId,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'geoLocationId': value.geoLocationId,
        'engagementRules': value.engagementRules === undefined ? undefined : (value.engagementRules.map(ApiEngagementRuleToJSON)),
        'isEffective': value.isEffective,
        'userAgent': ApiUserAgentToJSON(value.userAgent),
        'userAgentId': value.userAgentId,
        'requestReferrer': value.requestReferrer,
        'requestLeadKey': value.requestLeadKey,
        'requestIp': value.requestIp,
        'metaUpdatedAt': value.metaUpdatedAt === undefined ? undefined : (value.metaUpdatedAt.toISOString()),
        'averageScrollSpeed': value.averageScrollSpeed,
        'timeOnPageSeconds': value.timeOnPageSeconds,
        'scrolledPercentage': value.scrolledPercentage,
        'viewedPercentage': value.viewedPercentage,
        'metas': value.metas === undefined ? undefined : (value.metas.map(ApiPageviewMetaToJSON)),
        'Fingerprints': value.fingerprints === undefined ? undefined : (value.fingerprints.map(ApiFingerprintToJSON)),
        'broprintjsKey': value.broprintjsKey,
        'fingerprintjsKey': value.fingerprintjsKey,
        'analyticsSynced': value.analyticsSynced,
        'productImpressions': value.productImpressions === undefined ? undefined : (value.productImpressions.map(ApiProductToJSON)),
        'jobImpressions': value.jobImpressions === undefined ? undefined : (value.jobImpressions.map(ApiJobToJSON)),
        'personalisations': value.personalisations === undefined ? undefined : (value.personalisations.map(ApiDataPersonalisationToJSON)),
        'requestHashedIp': value.requestHashedIp,
        'requestCountryCode': value.requestCountryCode,
        'requestRegion': value.requestRegion,
        'requestCity': value.requestCity,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'countryId': value.countryId,
        'regionId': value.regionId,
        'cityId': value.cityId,
        'clsValue': value.clsValue,
        'clsScore': value.clsScore,
        'inpValue': value.inpValue,
        'inpScore': value.inpScore,
        'lcpValue': value.lcpValue,
        'lcpScore': value.lcpScore,
        'fcpValue': value.fcpValue,
        'fcpScore': value.fcpScore,
        'sales': value.sales === undefined ? undefined : (value.sales.map(ApiSaleToJSON)),
    };
}
