import {
  Box,
  Button,
  CircularProgress,
  Stack,
  styled,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import GlassCard from "Components/GlassCard/GlassCard";
import { StyledFlexBox } from "Components/UI/StylesFlexBox";
import Image from "mui-image";
import { ClickableTooltip } from "Components/ClickableTooltip";
import { StyledHelp } from "Components/ClickableTooltip/StyledHelp";
import { useMemo, useState } from "react";
import { Assignment, AssignmentTurnedIn } from "@mui/icons-material";
import { useAPITokens } from "Hooks/useAPITokens";
import { ApiAPITokenType, ApiAPITokenValueType } from "@incendium/api";
import { useSave } from "Hooks/useSave";
import { APITokenService } from "Apis";
import produce from "immer";

const StyledCopyButton = styled(Button)(({}) => ({
  minWidth: "auto",
  whiteSpace: "nowrap",
}));

interface IAPITokenCardProps {
  type: ApiAPITokenType;
  clientId: number;
  projectId: number;
  logo: string;
  info: React.ReactChild;
  hideURL?: boolean;
}

function APITokenCard({
  type,
  clientId,
  projectId,
  logo,
  info,
}: IAPITokenCardProps) {
  const [tokenCopied, setTokenCopied] = useState(false);
  const [urlCopied, setUrlCopied] = useState(false);
  const { tokens, setTokens } = useAPITokens();
  const { saving, save } = useSave();
  const token = useMemo(() => {
    return tokens.find((t) => t.type === type);
  }, [tokens, type]);

  const handleChecked = async (checked: boolean) => {
    save(() => {
      if (checked) {
        newToken();
      } else if (token) {
        removeToken(token?.id as number);
      }
    });
  };

  const newToken = async () => {
    const res = await APITokenService.aPITokenServiceCreateAPIToken({
      clientId,
      payload: {
        type,
        values: [
          {
            type: ApiAPITokenValueType.API_TOKEN_VALUE_TYPE_PROJECT_ID,
            value: `${projectId as number}`,
          },
        ],
      },
    });
    setTokens(
      produce(tokens, (draft) => {
        draft.push(res);
      })
    );
  };

  const removeToken = async (id: number) => {
    await APITokenService.aPITokenServiceDeleteAPIToken({
      clientId,
      tokenId: id,
    });
    setTokens(
      produce(tokens, (draft) => {
        const idx = tokens.findIndex((t) => t.id === id);
        draft.splice(idx, 1);
      })
    );
  };

  return (
    <GlassCard>
      <Box p={2}>
        <Stack direction="row" justifyContent={"space-between"}>
          <Image height={55} width="auto" src={logo} duration={50} />
          {saving && <CircularProgress size={"28px"} />}
        </Stack>

        <Box my={2}>
          <StyledFlexBox>
            <Box display="flex" alignItems="center">
              <Switch
                checked={!!token}
                onChange={(e, checked) => handleChecked(checked)}
              />
              <Typography color={!!token ? "primary" : "secondary"}>
                Use Webhook
              </Typography>
            </Box>
            <ClickableTooltip
              placement="right"
              text={info}
              icon={<StyledHelp />}
            />
          </StyledFlexBox>
        </Box>
        {!!token && (
          <Stack spacing={1}>
            {token.url && (
              <Stack
                direction="row"
                justifyContent={"space-between"}
                alignItems="center"
                spacing={1}
              >
                <Typography noWrap variant="caption">
                  URL : {token.url}
                </Typography>
                <StyledCopyButton
                  size="extraSmall"
                  onClick={() => {
                    setUrlCopied(true);
                    navigator.clipboard.writeText(token.url!);
                  }}
                  endIcon={urlCopied ? <AssignmentTurnedIn /> : <Assignment />}
                >
                  Copy URL
                </StyledCopyButton>
              </Stack>
            )}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems="center"
              spacing={1}
            >
              <Tooltip title={token.value} arrow>
                <Typography noWrap variant="caption">
                  token : {token.value}
                </Typography>
              </Tooltip>

              <StyledCopyButton
                size="extraSmall"
                onClick={() => {
                  setTokenCopied(true);
                  navigator.clipboard.writeText(token?.value || "");
                }}
                endIcon={tokenCopied ? <AssignmentTurnedIn /> : <Assignment />}
              >
                Copy Token
              </StyledCopyButton>
            </Stack>
          </Stack>
        )}
      </Box>
    </GlassCard>
  );
}

export default APITokenCard;
