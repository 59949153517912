import {
  Box,
  Button,
  CircularProgress,
  GridSize,
  Portal,
  Theme,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useLayoutContext } from "Providers/LayoutProvider";
import { useLayoutEffect, useState } from "react";
import {
  useSelectedProject,
  useClassifications,
  useClassificationGroups,
} from "Hooks";
import {
  ApiClassification,
  ApiClassificationGroup,
  ApiClassificationItem,
  ApiClassificationPayload,
} from "@incendium/api";
import produce from "immer";
import { audienceService } from "Apis";
import { useSnackbar } from "notistack";
import ClassificationRulesCol from "Components/Classifications/ClassificationRulesCol";
import ClassificationItemsCol from "Components/Classifications/ClassificationItemsCol";
import { usePreviousDistinct, useUpdateEffect } from "react-use";
import ClassificationsCol from "Components/Classifications/ClassificationsCol";
import ClassificationGroupsCol from "Components/Classifications/ClassificationGroupsCol";
import FullWidthPage from "Components/Page/FullWidthPage";
import { ColsCol, ColsLayout } from "Components/ColsLayout/ColsLayout";
import { Glass } from "Components/Glass";

export const appearDown = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.1,
    },
  },
};

export const useClassificationsStyles = makeStyles((theme: Theme) => ({
  listInActive: {
    background: "rgba(0,0,0,0.03)",
    "&:hover": {
      background: "transparent",
    },
  },
  listActive: {
    background: theme.palette.secondary.main,
    // background:
    //   "transparent linear-gradient(142deg, rgba(255,255,255,0) 0%, #FFFFFF35 70%) ",
    // borderRight: `3px solid ${theme.palette.secondary.main}`,
    "&:hover": {
      background: "transparent",
    },
  },
  groupRow: {
    minHeight: 73,
  },
}));

function ClassificationsPage() {
  const { selectedProject } = useSelectedProject();
  const {
    classifications,
    // setClassifications,
    refetchClassifications,
  } = useClassifications();
  const { classificationGroups: groups, setClassificationGroups: setGroups } =
    useClassificationGroups();
  const { setContentRight, setHasPadding } = useLayoutContext();
  const [colWidths, setColWidths] = useState<GridSize[]>([7, 2, 2, 1]);
  const [move, setMove] = useState<ApiClassification | null>(null);

  const [selectedGroup, setSelectedGroup] =
    useState<ApiClassificationGroup | null>(null);
  const [selectedClassification, setSelectedClassification] =
    useState<ApiClassification | null>(null);
  const [selectedClassificationItem, setSelectedClassificationItem] =
    useState<ApiClassificationItem | null>(null);
  const prevClassification = usePreviousDistinct(selectedClassification);

  const [selectedClassifications, setSelectedClassifications] = useState<
    ApiClassification[]
  >([]);

  const { enqueueSnackbar } = useSnackbar();

  useLayoutEffect(() => {
    setHasPadding(false);
    return () => {
      setHasPadding(true);
    };
  }, [setContentRight, setHasPadding]);

  useUpdateEffect(() => {
    if (!selectedGroup) {
      if (selectedClassifications.length > 0) {
        setColWidths([7, 2, 2, 1]);
        setSelectedClassifications([]);
      }

      return;
    }

    setSelectedClassifications(
      classifications.filter(
        (cls) => cls.classificationGroupId === selectedGroup.id
      )
    );

    if (!selectedClassification) {
      setColWidths([3, 6, 2, 1]);
      return;
    }
    if (!selectedClassificationItem) {
      setColWidths([1, 3, 7, 1]);
      return;
    }
    setColWidths([1, 2, 2, 7]);
  }, [
    classifications,
    selectedGroup,
    selectedClassification,
    selectedClassificationItem,
  ]);

  useUpdateEffect(() => {
    // on selectedClassification update update all existing items, if order changes
    if (!prevClassification?.classificationItems?.length) {
      return;
    }
    // check id order is same or change is note due to new one added
    const prevOrder = prevClassification?.classificationItems.map(
      (pr) => `${pr.id}-${pr.order}`
    );
    const currentOrder = (
      selectedClassification?.classificationItems || []
    ).map((pr) => `${pr.id}-${pr.order}`);
    if (
      prevOrder.join("-") === currentOrder.join("-") ||
      prevOrder.length < currentOrder.length
    ) {
      return;
    }

    const update = async () => {
      try {
        await audienceService.audienceServiceReorderClassificationItems({
          projectId: selectedProject?.id as number,
          classificationId: selectedClassification?.id as number,
          payload: {
            input: (selectedClassification?.classificationItems || []).map(
              (c) => ({
                classificationItemId: c.id,
                order: c.order,
              })
            ),
          },
        });

        enqueueSnackbar("Items Order Updated", {
          variant: "success",
          autoHideDuration: 2000,
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
        refetchClassifications();
      } catch (error) {
        enqueueSnackbar(
          "There was an error updating you Items order, please try again",
          {
            variant: "error",
            autoHideDuration: 2000,
            anchorOrigin: { horizontal: "right", vertical: "top" },
          }
        );
      }
    };
    if ((selectedClassification?.classificationItems || []).length) {
      update();
    }
  }, [selectedClassification]);

  const cancel = () => {
    setSelectedGroup(null);
    setSelectedClassification(null);
    setSelectedClassificationItem(null);
  };

  const selectClassificationItem = (i: ApiClassificationItem) => {
    setSelectedClassificationItem(i);
  };

  const onClassGroupSaved = (group: ApiClassificationGroup) => {
    const idx = groups.findIndex((g) => g.id === group.id);
    if (idx >= 0) {
      setGroups(
        produce(groups, (draft) => {
          draft[idx] = group;
        })
      );
    } else {
      setGroups(
        produce(groups, (draft) => {
          draft.push(group);
        })
      );
    }
    setSelectedGroup(group);
  };

  const onClassSaved = (classification: ApiClassification) => {
    refetchClassifications();
    setSelectedClassification(classification);
  };

  const onClassItemSaved = (classificationItem: ApiClassificationItem) => {
    const items = produce(
      [...(selectedClassification?.classificationItems || [])],
      (draft) => {
        const idx = draft.findIndex((ci) => ci.id === classificationItem.id);
        if (idx >= 0) {
          draft[idx] = classificationItem;
        } else {
          draft.push(classificationItem);
        }
      }
    );

    onClassSaved(
      produce(selectedClassification, (draft) => {
        (draft || {}).classificationItems = items;
      }) as ApiClassificationItem
    );

    setSelectedClassificationItem(classificationItem);
  };

  const moveClassification = async (group: ApiClassificationGroup) => {
    if (!move) {
      return;
    }

    let payload: ApiClassificationPayload = {
      name: move.name,
      description: move.description,
    };
    if (group && group.id) {
      payload.classificationGroupId = group.id;
    }

    try {
      await audienceService.audienceServiceUpdateClassification({
        projectId: selectedProject?.id as number,
        classificationId: move.id as number,
        payload,
      });
      enqueueSnackbar(`Successfully moved ${move.name} to ${group.name}`, {
        variant: "success",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    } catch (error) {
      enqueueSnackbar("Something Went Wrong", {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    } finally {
      setMove(null);
      setSelectedGroup(null);
      setSelectedClassification(null);
      refetchClassifications();
    }
  };

  if (!selectedProject) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress size={"2rem"} />
      </Box>
    );
  }

  return (
    <>
      <FullWidthPage>
        <Portal container={() => document.getElementById("pageTitle")}>
          <Box>
            <Typography variant="h1">Traits</Typography>
            <Typography variant="body1">
              Traits are a way of assigning an attribute to a lead and a value
              to that attribute, based on their actions and behavior.
            </Typography>
            <Typography variant="body1">
              You can then use combinations of these attributes and values to
              build audience segments for analytics or personalization.
            </Typography>
          </Box>
        </Portal>
        <Portal container={() => document.getElementById("pageAction")}>
          <Button variant="contained" color="secondary" onClick={cancel}>
            Cancel
          </Button>
        </Portal>
        <br />
        <Glass elevation={1}>
          <ColsLayout>
            <ColsCol width={colWidths[0]}>
              <ClassificationGroupsCol
                selectedProject={selectedProject}
                selectedGroup={selectedGroup}
                setSelectedGroup={setSelectedGroup}
                setSelectedClassification={setSelectedClassification}
                setSelectedClassificationItem={setSelectedClassificationItem}
                groups={groups || []}
                setGroups={setGroups}
                onSave={onClassGroupSaved}
                move={move}
                moveClassification={moveClassification}
              />
            </ColsCol>
            <ColsCol width={colWidths[1]}>
              <ClassificationsCol
                selectedProject={selectedProject}
                selectedGroup={selectedGroup}
                selectedClassification={selectedClassification}
                setSelectedClassification={setSelectedClassification}
                selectedClassifications={selectedClassifications}
                setSelectedClassificationItem={setSelectedClassificationItem}
                onSaved={onClassSaved}
                move={move}
                setMove={setMove}
              />
            </ColsCol>
            <ColsCol width={colWidths[2]}>
              <ClassificationItemsCol
                selectedClassification={selectedClassification}
                selectClassificationItem={selectClassificationItem}
                selectedClassificationItem={selectedClassificationItem}
                setSelectedClassificationItem={setSelectedClassificationItem}
                selectedProject={selectedProject}
                setSelectedClassification={setSelectedClassification}
                onClassItemSaved={onClassItemSaved}
              />
            </ColsCol>
            <ColsCol width={colWidths[3]}>
              <ClassificationRulesCol
                classification={selectedClassification}
                project={selectedProject}
                classificationItem={selectedClassificationItem}
                setClassificationItem={setSelectedClassificationItem}
                onSaved={refetchClassifications}
              />
            </ColsCol>
          </ColsLayout>
        </Glass>
      </FullWidthPage>
    </>
  );
}

export default ClassificationsPage;
