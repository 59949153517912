import { ApiAttributionClicksDimension, ApiDimension } from "@incendium/api";

export const primaryToAttribution = (
  primaryDimension: ApiDimension
): ApiAttributionClicksDimension => {
  const map = {
    [ApiAttributionClicksDimension.ATTRIBUTION_CLICKS_DIMENSION_SOURCE]:
      ApiDimension.DIMENSION_SOURCE,
    [ApiAttributionClicksDimension.ATTRIBUTION_CLICKS_DIMENSION_MEDIUM]:
      ApiDimension.DIMENSION_MEDIUM,
    [ApiAttributionClicksDimension.ATTRIBUTION_CLICKS_DIMENSION_CAMPAIGN]:
      ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
    [ApiAttributionClicksDimension.ATTRIBUTION_CLICKS_DIMENSION_AD_GROUP]:
      ApiDimension.DIMENSION_CONTENT,
    [ApiAttributionClicksDimension.ATTRIBUTION_CLICKS_DIMENSION_KEYWORD]:
      ApiDimension.DIMENSION_TERM,
  };
  const entry = Object.entries(map).find(
    ([_, dim]) => dim === primaryDimension
  );

  if (entry) {
    return entry[0] as ApiAttributionClicksDimension;
  }

  return ApiAttributionClicksDimension.ATTRIBUTION_CLICKS_DIMENSION_UNDEFINED;
};
