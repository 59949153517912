/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiComponentUISourceFromJSON, ApiComponentUISourceToJSON, } from './ApiComponentUISource';
import { ApiPersonalisationFromJSON, ApiPersonalisationToJSON, } from './ApiPersonalisation';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
import { ApiUserFromJSON, ApiUserToJSON, } from './ApiUser';
export function ApiComponentFromJSON(json) {
    return ApiComponentFromJSONTyped(json, false);
}
export function ApiComponentFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'html': !exists(json, 'html') ? undefined : json['html'],
        'css': !exists(json, 'css') ? undefined : json['css'],
        'js': !exists(json, 'js') ? undefined : json['js'],
        'htmlMin': !exists(json, 'htmlMin') ? undefined : json['htmlMin'],
        'cssMin': !exists(json, 'cssMin') ? undefined : json['cssMin'],
        'jsMin': !exists(json, 'jsMin') ? undefined : json['jsMin'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'ownerId': !exists(json, 'ownerId') ? undefined : json['ownerId'],
        'owner': !exists(json, 'owner') ? undefined : ApiUserFromJSON(json['owner']),
        'lastUserId': !exists(json, 'lastUserId') ? undefined : json['lastUserId'],
        'lastUser': !exists(json, 'lastUser') ? undefined : ApiUserFromJSON(json['lastUser']),
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'personalisations': !exists(json, 'personalisations') ? undefined : (json['personalisations'].map(ApiPersonalisationFromJSON)),
        'grapeJs': !exists(json, 'grapeJs') ? undefined : json['grapeJs'],
        'uiSource': !exists(json, 'uiSource') ? undefined : ApiComponentUISourceFromJSON(json['uiSource']),
        'head': !exists(json, 'head') ? undefined : json['head'],
        'seoTitle': !exists(json, 'seoTitle') ? undefined : json['seoTitle'],
        'seoDescrition': !exists(json, 'seoDescrition') ? undefined : json['seoDescrition'],
        'favicon': !exists(json, 'favicon') ? undefined : json['favicon'],
    };
}
export function ApiComponentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'html': value.html,
        'css': value.css,
        'js': value.js,
        'htmlMin': value.htmlMin,
        'cssMin': value.cssMin,
        'jsMin': value.jsMin,
        'name': value.name,
        'description': value.description,
        'ownerId': value.ownerId,
        'owner': ApiUserToJSON(value.owner),
        'lastUserId': value.lastUserId,
        'lastUser': ApiUserToJSON(value.lastUser),
        'project': ApiProjectToJSON(value.project),
        'projectId': value.projectId,
        'personalisations': value.personalisations === undefined ? undefined : (value.personalisations.map(ApiPersonalisationToJSON)),
        'grapeJs': value.grapeJs,
        'uiSource': ApiComponentUISourceToJSON(value.uiSource),
        'head': value.head,
        'seoTitle': value.seoTitle,
        'seoDescrition': value.seoDescrition,
        'favicon': value.favicon,
    };
}
