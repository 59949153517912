import { ApiCampaignType, ApiSimpleCampaign } from "@incendium/api";
import { Button, Portal, Stack } from "@mui/material";
import Loading from "Components/Loading/Loading";
import { useMount, useSelectedProject } from "Hooks";
import { useCampaigns } from "Hooks/useCampaigns";
import { CampaignList, CampaignSetup } from "features/campaigns";
import { useCallback, useState } from "react";
import { PageAction } from "consts";

function ConversionLabPage() {
  const { selectedProject } = useSelectedProject();
  const [open, setOpen] = useState(false);
  const [campaign, setCampaign] = useState<ApiSimpleCampaign>({});
  const { campaigns, loading, refetch } = useCampaigns();

  useMount(() => {
    if (campaigns.length > 0) {
      refetch();
    }
  });

  const newCampaign = useCallback((type: ApiCampaignType) => {
    setCampaign({
      type,
    });
    setOpen(true);
  }, []);

  if (loading) {
    return <Loading text="Fetching Conversion Lab Campaigns" />;
  }

  return (
    <>
      <Portal container={() => document.getElementById(PageAction)}>
        <Stack direction={"row"} spacing={2}>
          <Button onClick={() => newCampaign(ApiCampaignType.CAMPAIGN_TYPE_AB)}>
            New A/B Campaign
          </Button>
          <Button
            onClick={() => newCampaign(ApiCampaignType.CAMPAIGN_TYPE_SPLIT)}
          >
            New Split Campaign
          </Button>
        </Stack>
      </Portal>
      <CampaignList
        project={selectedProject!}
        setOpen={setOpen}
        setCampaign={setCampaign}
        types={[
          ApiCampaignType.CAMPAIGN_TYPE_AB,
          ApiCampaignType.CAMPAIGN_TYPE_SPLIT,
        ]}
      />

      <CampaignSetup
        open={open}
        setOpen={setOpen}
        campaign={campaign}
        setCampaign={setCampaign}
      />
    </>
  );
}

export default ConversionLabPage;
