import { ApiLeadStatusRule, ApiLeadStatusType } from "@incendium/api";
import { ArrowForward } from "@mui/icons-material";
import Delete from "@mui/icons-material/Delete";
import {
  Grid,
  Typography,
  Alert,
  CardActions,
  Button,
  CardContent,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import { leadStatusService } from "Apis";
import CreateNameDialog from "Components/CreateNameDialog/CreateNameDialog";
import GlassCard from "Components/GlassCard/GlassCard";
import { useSelectedProject } from "Hooks";
import { useDeleteConfirmation } from "Hooks/useDeleteConfirmation";
import { useLeadStatusRules } from "Hooks/useLeadStatusRules";
import produce from "immer";
import { useSnackbar } from "notistack";
import { useLayoutContext } from "Providers/LayoutProvider";
import { useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";

type TSalesType = { type: ApiLeadStatusType; label: string };

function LeadsPage() {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { selectedProject: project } = useSelectedProject();
  const handleDelete = useDeleteConfirmation();
  const { leadStatusRules, setLeadStatusRules } = useLeadStatusRules();
  const [activeStatus, setActiveStatus] = useState<TSalesType | null>(null);
  const types: TSalesType[] = [
    {
      type: ApiLeadStatusType.PROSPECT,
      label: "Prospect",
    },
    {
      type: ApiLeadStatusType.MQL,
      label: "MQL",
    },
    {
      type: ApiLeadStatusType.SQL,
      label: "SQL",
    },
    {
      type: ApiLeadStatusType.SAL,
      label: "SAL",
    },
    {
      type: ApiLeadStatusType.CUSTOMER,
      label: "Customer",
    },
  ];
  const { setPageTitle } = useLayoutContext();

  useLayoutEffect(() => {
    setPageTitle(`Lead Status Setup`);
    return () => {
      setPageTitle("");
    };
  }, []);

  const onSave = async (name: string) => {
    const payload = {
      name,
      type: activeStatus?.type,
    };
    const res = await leadStatusService.leadStatusServiceCreateLeadStatusRule({
      projectId: project?.id as number,
      payload,
    });

    setLeadStatusRules(
      produce(leadStatusRules, (draft) => {
        draft.push(res);
      })
    );

    enqueueSnackbar(`Status (${name}) Created, redirecting`, {
      variant: "success",
      autoHideDuration: 2000,
      anchorOrigin: { horizontal: "right", vertical: "top" },
    });
    onEdit(res);
  };

  const matchingStatus = (t: ApiLeadStatusType) =>
    (leadStatusRules || []).find((s) => s.type === t);

  const onClick = (t: TSalesType) => {
    const m = matchingStatus(t.type);
    if (m) {
      onEdit(m);
      return;
    }
    setActiveStatus(t);
    setOpen(true);
  };

  const onEdit = (status: ApiLeadStatusRule) => {
    history.push(
      `/clients/${project?.clientId}/projects/${project?.id}/train/leads-status/${status.id}`
    );
  };

  const onDelete = (status: ApiLeadStatusRule) => {
    handleDelete({
      title: `Are you sure you want to delete this status`,
      body: "This action can not be undone",
      callback: async () => {
        await leadStatusService.leadStatusServiceDeleteLeadStatusRule({
          projectId: project?.id as number,
          leadStatusRuleId: status.id as number,
        });

        setLeadStatusRules(
          produce(leadStatusRules, (draft) => {
            const idx = leadStatusRules.findIndex((s) => status.id === s.id);
            if (idx !== -1) draft.splice(idx, 1);
          })
        );

        return "Status Rule Deleted";
      },
    });
  };

  return (
    <Box py={5}>
      <Grid
        container
        spacing={3}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Grid item xs={12}>
          <Alert severity="warning">This is TMP UI</Alert>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            These are the available statuses for B2B, to initiaise the status
            click "create", you will then be able to add a nickname to the
            status and add rules
          </Typography>
        </Grid>
        {types.map((t, i) => {
          const m = matchingStatus(t.type);
          return (
            <Grid key={t.type} item xs={2} sx={{ position: "relative" }}>
              {i > 0 && (
                <Box
                  sx={{
                    position: "absolute",
                    left: -14,
                    top: "50%",
                    transform: "translate(-50%)",
                  }}
                >
                  <ArrowForward />
                </Box>
              )}
              <GlassCard opacity={m ? 0.8 : 0.1}>
                <CardContent>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"end"}
                    sx={{ minHeight: 34 }}
                  >
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      Status
                    </Typography>
                    {m && (
                      <IconButton size="small" onClick={() => onDelete(m)}>
                        <Delete />
                      </IconButton>
                    )}
                  </Box>
                  <Typography variant="h3" component="div">
                    {m ? m.name : t.label}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    onClick={() => {
                      onClick(t);
                    }}
                    fullWidth
                    size="small"
                  >
                    {m ? "Edit" : "Create"}
                  </Button>
                </CardActions>
              </GlassCard>
            </Grid>
          );
        })}
      </Grid>
      <CreateNameDialog
        open={open}
        setOpen={setOpen}
        onSaved={onSave}
        title={`Add Reporting name for ${activeStatus?.label} status`}
      />
    </Box>
  );
}

export default LeadsPage;
