import { Delete, Download, Edit, Fullscreen } from "@mui/icons-material";
import {
  ClickAwayListener,
  IconButton,
  Stack,
  StackProps,
  styled,
  Tooltip,
} from "@mui/material";
import { Glass } from "Components/Glass";
import Loading from "Components/Loading/Loading";
import { HTMLMotionProps, motion } from "framer-motion";
import { IChart, TFramerAnimationProps } from "Interfaces";
import { useCallback, useState } from "react";

interface IStyledAnalyticsCardToolBarWrapperProps
  extends StackProps,
    Pick<HTMLMotionProps<any>, TFramerAnimationProps> {
  fullScreen?: boolean;
}

const StyledAnalyticsCardToolBarWrapper = styled(
  Stack
)<IStyledAnalyticsCardToolBarWrapperProps>(({ theme, fullScreen }) => ({
  position: fullScreen ? "relative" : "absolute",
  top: fullScreen ? 0 : 1,
  bottom: fullScreen ? 0 : 1,
  right: 0,
  background: theme.palette.primary.main,
  color: "white",
  borderTopRightRadius: 10,
  borderBottomRightRadius: 10,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: "#afb0ff",
  "&:hover": {
    color: "white",
    background: "transparent",
  },
}));

interface IAnalyticsCardToolBarProps {
  chart: IChart;
  setOpen: (b: boolean) => void;
  fullScreen?: boolean;
  setFullScreen?: (b: boolean) => void;
  onEdit?: () => Promise<void>;
  onDelete?: () => Promise<void>;
  onExport?: () => Promise<void>;
}

function AnalyticsCardToolBar({
  chart,
  setOpen,
  fullScreen,
  setFullScreen,
  onEdit,
  onDelete,
  onExport,
}: IAnalyticsCardToolBarProps) {
  const [isProcessing, setIsProcessing] = useState<
    "export" | "edit" | "delete" | null
  >(null);

  const editChart = useCallback(async () => {
    if (!onEdit) {
      return;
    }
    setIsProcessing("edit");
    await onEdit();

    setIsProcessing(null);
  }, [onEdit]);

  const deleteChart = useCallback(async () => {
    if (!onDelete) {
      return;
    }
    setIsProcessing("delete");
    await onDelete();

    setIsProcessing(null);
  }, [onDelete]);
  const exportChart = useCallback(async () => {
    if (!onExport) {
      return;
    }
    setIsProcessing("export");
    await onExport();

    setIsProcessing(null);
  }, [onExport]);

  const renderBody = () => {
    return (
      <StyledAnalyticsCardToolBarWrapper
        justifyContent={"flex-end"}
        py={1}
        fullScreen={fullScreen}
        component={motion.div}
        initial={{
          width: 0,
        }}
        animate={{
          width: 40,
        }}
        exit={{
          width: 0,
        }}
      >
        {onEdit && (
          <Tooltip title="Edit" placement="left-end">
            <StyledIconButton onClick={editChart}>
              <Edit />
            </StyledIconButton>
          </Tooltip>
        )}
        {onDelete && (
          <Tooltip title="Delete" placement="left-end">
            <StyledIconButton onClick={deleteChart}>
              <Delete />
            </StyledIconButton>
          </Tooltip>
        )}
        {setFullScreen && (
          <Tooltip title="Enlarge" placement="left-end">
            <StyledIconButton onClick={() => setFullScreen(!fullScreen)}>
              <Fullscreen />
            </StyledIconButton>
          </Tooltip>
        )}
        {onExport && (
          <Tooltip title="Export" placement="left-end">
            <StyledIconButton onClick={exportChart}>
              <Download />
            </StyledIconButton>
          </Tooltip>
        )}
      </StyledAnalyticsCardToolBarWrapper>
    );
  };

  return (
    <>
      {isProcessing && (
        <Glass
          opacity={0.9}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading
            text={`${
              isProcessing === "export"
                ? "Exporting"
                : isProcessing === "edit"
                ? "Editing"
                : "Deleting"
            }, please wait`}
          />
        </Glass>
      )}

      {fullScreen ? (
        renderBody()
      ) : (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          {renderBody()}
        </ClickAwayListener>
      )}
    </>
  );
}

export default AnalyticsCardToolBar;
