import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
} from "@incendium/api";
import { Box, Portal, Typography } from "@mui/material";
import { AnalyticsSpacing } from "consts";
import {
  AnalyticsCard,
  JobCompareWrapper,
  JobTopCategoryComp,
} from "features/analytics";
import withDatepicker from "HoC/withDatepicker";
import { useSelectedProject } from "Hooks";
import { useJobs } from "Hooks/useJobs";
import { useHistory } from "react-router-dom";

function JobsOverviewPage() {
  const { selectedClient, selectedProject } = useSelectedProject();
  const { jobs } = useJobs();
  const history = useHistory();

  const toCategory = (c: string) => {
    history.push(
      `/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/jobs-category-deep-dive?job_category=${c}`
    );
  };
  const toJob = (n: string) => {
    history.push(
      `/clients/${selectedClient?.id}/projects/${
        selectedProject?.id
      }/analyse/standard-reports/jobs-deep-dive?job_id=${
        jobs.find((j) => j.title === n)?.id
      }`
    );
  };

  return (
    <>
      <Portal container={() => document.getElementById("pageTitle")}>
        <Typography variant="h1">Jobs Overview</Typography>
        <Typography variant="h3" color={"secondary"}>
          Compare job performance with last equal time period
        </Typography>
      </Portal>

      <Box
        mt={1}
        mb={3}
        sx={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: `repeat(12, 1fr)`,
          gridTemplateRows: `minmax(127px, 1fr) repeat(11, 127px)`,
          rowGap: 3,
          columnGap: AnalyticsSpacing,
        }}
      >
        <JobCompareWrapper />

        <Box sx={{ gridArea: "4 / 1 / 7 / 13" }}>
          <JobTopCategoryComp />
        </Box>
        <Box sx={{ gridArea: "7 / 1 / 11 / 7" }}>
          <AnalyticsCard
            onClick={{
              [ApiDimension.DIMENSION_JOB_CATEGORY]: toCategory,
            }}
            chart={{
              name: `Job Category Insights`,
              dimension: [ApiDimension.DIMENSION_JOB_CATEGORY],
              template: ApiChartTemplate.TABLE,
              type: ApiChartType.TABLE,
              attributes: [],
              yAxisKeys: [
                {
                  key: "l",
                  fields: [
                    ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                    ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
                    ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_COUNT,
                    ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_RATE,
                    ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT,
                    ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_RATE,
                    ApiMetric.METRIC_JOB_IMPRESSION_COUNT,
                    ApiMetric.METRIC_JOB_IMPRESSION_RATE,
                    ApiMetric.METRIC_JOB_CLICK_THROUGH_COUNT,
                    ApiMetric.METRIC_JOB_CLICK_THROUGH_RATE,
                    ApiMetric.METRIC_VISIT_TO_APPLICATION_RATE,
                  ],
                },
              ],
              displayOptions: {
                noFilters: true,
                noAttributionToggle: true,
                rowsPerPage: 6,
              },
            }}
          />
        </Box>
        <Box sx={{ gridArea: "7 / 7 / 11 / 13" }}>
          <AnalyticsCard
            onClick={{
              [ApiDimension.DIMENSION_JOB_TITLE]: toJob,
            }}
            chart={{
              name: `Job Insights`,
              dimension: [ApiDimension.DIMENSION_JOB_TITLE],
              template: ApiChartTemplate.TABLE,
              type: ApiChartType.TABLE,
              attributes: [],
              yAxisKeys: [
                {
                  key: "l",
                  fields: [
                    ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                    ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
                    ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_COUNT,
                    ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_RATE,
                    ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT,
                    ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_RATE,
                    ApiMetric.METRIC_JOB_IMPRESSION_COUNT,
                    ApiMetric.METRIC_JOB_IMPRESSION_RATE,
                    ApiMetric.METRIC_JOB_CLICK_THROUGH_COUNT,
                    ApiMetric.METRIC_JOB_CLICK_THROUGH_RATE,
                    ApiMetric.METRIC_VISIT_TO_APPLICATION_RATE,
                  ],
                },
              ],
              displayOptions: {
                noFilters: true,
                noAttributionToggle: true,
                rowsPerPage: 6,
              },
            }}
          />
        </Box>
      </Box>
    </>
  );
}

export default withDatepicker(JobsOverviewPage);
