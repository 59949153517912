/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiAttributionClicksDimensionFromJSON, ApiAttributionClicksDimensionToJSON, } from './ApiAttributionClicksDimension';
import { ApiAttributionClicksMetricFromJSON, ApiAttributionClicksMetricToJSON, } from './ApiAttributionClicksMetric';
import { ApiChartAttributeFromJSON, ApiChartAttributeToJSON, } from './ApiChartAttribute';
export function ApiAttributionClicksAnalyticsRequestPayloadFromJSON(json) {
    return ApiAttributionClicksAnalyticsRequestPayloadFromJSONTyped(json, false);
}
export function ApiAttributionClicksAnalyticsRequestPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'from': !exists(json, 'from') ? undefined : (new Date(json['from'])),
        'to': !exists(json, 'to') ? undefined : (new Date(json['to'])),
        'lastNDays': !exists(json, 'lastNDays') ? undefined : json['lastNDays'],
        'metrics': !exists(json, 'metrics') ? undefined : (json['metrics'].map(ApiAttributionClicksMetricFromJSON)),
        'dimensions': !exists(json, 'dimensions') ? undefined : (json['dimensions'].map(ApiAttributionClicksDimensionFromJSON)),
        'attributes': !exists(json, 'attributes') ? undefined : (json['attributes'].map(ApiChartAttributeFromJSON)),
        'withTotals': !exists(json, 'withTotals') ? undefined : json['withTotals'],
    };
}
export function ApiAttributionClicksAnalyticsRequestPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'from': value.from === undefined ? undefined : (value.from.toISOString()),
        'to': value.to === undefined ? undefined : (value.to.toISOString()),
        'lastNDays': value.lastNDays,
        'metrics': value.metrics === undefined ? undefined : (value.metrics.map(ApiAttributionClicksMetricToJSON)),
        'dimensions': value.dimensions === undefined ? undefined : (value.dimensions.map(ApiAttributionClicksDimensionToJSON)),
        'attributes': value.attributes === undefined ? undefined : (value.attributes.map(ApiChartAttributeToJSON)),
        'withTotals': value.withTotals,
    };
}
