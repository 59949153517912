import { primaryToAttribution } from "features/attributionAnalytics";

export const combinePrimaryAndAttributionData = (
  primaryData,
  attributionData,
  dimensions
) => {
  let combinedArr = (primaryData || []).map((ch) => {
    const foundAtt = (attributionData || []).find((ad) =>
      dimensions.every(
        (dimension) => ad[primaryToAttribution(dimension)] === ch[dimension]
      )
    );
    return {
      ...ch,
      ...(foundAtt || {}),
    };
  });

  (attributionData || []).forEach((ad) => {
    const idx = (primaryData || []).findIndex((ch) =>
      dimensions.every(
        (dimension) => ad[primaryToAttribution(dimension)] === ch[dimension]
      )
    );

    if (idx < 0) {
      combinedArr.push({ ...ad });
    }
  });

  return combinedArr;
};
