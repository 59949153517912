export const OPEN_MENU = "OPEN_MENU";
export const CLOSE_MENU = "CLOSE_MENU";
export const TOGGLE_MENU = "TOGGLE_MENU";
export const TOGGLE_HOVER = "TOGGLE_HOVER";
export const TOGGLE_SECONDARY_MENU = "TOGGLE_SECONDARY_MENU";
export const OPEN_SECONDARY_MENU = "OPEN_SECONDARY_MENU";
export const CLOSE_SECONDARY_MENU = "CLOSE_SECONDARY_MENU";
export const TOGGLE_ANALYSIS = "TOGGLE_ANALYSIS";
export const SET_ANALYSIS = "SET_ANALYSIS";
export const SET_ACTIVE = "SET_ACTIVE";
export const SET_SUB_ACTIVE = "SET_SUB_ACTIVE";
