import {
  ApiChartRankDrection,
  ApiDimension,
  ApiMetric,
  ApiOperator,
} from "@incendium/api";
import { Portal, Tooltip, Typography } from "@mui/material";
import { TabButton } from "Components/UI/TabButton";
import { TabContainer } from "Components/UI/TabContainer";
import { AttributionType } from "features/analytics/types/types";
import { ExplorerProvider } from "features/explorer";
import { AttributionExplorer } from "features/explorer";
import { TExploreConfig } from "features/explorer/types";
import { useMemo, useState } from "react";
import { useSearchParam, useUpdateEffect } from "react-use";
import FullWidthPage from "Components/Page/FullWidthPage";
import { CampaignAnalysis } from "features/attributionAnalytics";
import withDatepicker from "HoC/withDatepicker";
import withPaidChannelAttributes from "HoC/withPaidChannelAttributes";

export type AttributionPerformanceExplorers =
  | "Attribution Explorer"
  | "Geographic"
  | "Landing Pages";

export type AttributionPerformanceAnalysisTab =
  | AttributionPerformanceExplorers
  | "Campaign Analysis";

function PerformanceAnalysisPage({ paidChannelAttributes }) {
  const tab = useSearchParam("tab");
  const [type, setType] = useState<AttributionPerformanceAnalysisTab>(
    (tab as AttributionPerformanceAnalysisTab) || "Campaign Analysis"
  );

  const config: TExploreConfig<AttributionPerformanceExplorers> =
    useMemo(() => {
      return {
        "Attribution Explorer": {
          title: "Attribution Explorer",
          desc: "Dig deeper into attribution, compare models and measure the value of all multiple touchpoints in the path to purchase.",
          ranking: [],
          defaultAttributionTypes: [AttributionType.FIRST],
          analyseBy: {
            description: "",
            grouping: [
              {
                option: "",
                dimensions: [
                  ApiDimension.DIMENSION_CHANNEL,
                  ApiDimension.DIMENSION_SOURCE,
                  ApiDimension.DIMENSION_MEDIUM,
                  ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
                  ApiDimension.DIMENSION_TERM,
                ],
              },
            ],
          },
        },
        Geographic: {
          title: "Geographic",
          desc: "Uncover geographic insights and find problem/opportuinity locations.",
          ranking: [
            {
              label: "Opps",
              tooltip: "good performance but lower traffic",
              ranks: [
                {
                  metric: ApiMetric.METRIC_SESSIONS_COUNT,
                  direction: ApiChartRankDrection.DESC,
                  weight: 0.1,
                },
                {
                  metric: ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                  direction: ApiChartRankDrection.ASC,
                  weight: 0.1,
                },
                {
                  metric: ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
                  direction: ApiChartRankDrection.ASC,
                  weight: 0.1,
                },
                {
                  metric: ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_COUNT,
                  direction: ApiChartRankDrection.ASC,
                  weight: 0.1,
                },
                {
                  metric: ApiMetric.METRIC_SESSION_SALES_COUNT,
                  direction: ApiChartRankDrection.ASC,
                  weight: 0.1,
                },
                {
                  metric: ApiMetric.METRIC_SESSION_REVENUE,
                  direction: ApiChartRankDrection.ASC,
                  weight: 0.1,
                },
              ],
            },
            {
              type: "bottom",
              label: "Problems",
              tooltip: "bad performance high traffic",
              ranks: [
                {
                  metric: ApiMetric.METRIC_SESSIONS_COUNT,
                  direction: ApiChartRankDrection.ASC,
                  weight: 0.2,
                },
                {
                  metric: ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                  direction: ApiChartRankDrection.DESC,
                  weight: 0.1,
                },
                {
                  metric: ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
                  direction: ApiChartRankDrection.DESC,
                  weight: 0.1,
                },
                {
                  metric: ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_COUNT,
                  direction: ApiChartRankDrection.DESC,
                  weight: 0.1,
                },
                {
                  metric: ApiMetric.METRIC_SESSION_SALES_COUNT,
                  direction: ApiChartRankDrection.DESC,
                  weight: 0.1,
                },
                {
                  metric: ApiMetric.METRIC_SESSION_REVENUE,
                  direction: ApiChartRankDrection.DESC,
                  weight: 0.1,
                },
              ],
            },
          ],
          default: [
            ApiMetric.METRIC_SESSIONS_COUNT,
            ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
            ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
          ],
          analyseBy: {
            description: "",
            grouping: [
              {
                option: "",
                dimensions: [
                  ApiDimension.DIMENSION_COUNTRY,
                  ApiDimension.DIMENSION_REGION,
                  ApiDimension.DIMENSION_CITY,
                ],
              },
            ],
          },
          having: {
            description: "Exclude results based on sessions or sales etc.",
            havings: [
              {
                label: "Min No. of sessions",
                metric: ApiMetric.METRIC_SESSIONS_COUNT,
                operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
                value: undefined,
              },
              {
                label: "Max No. of sessions",
                metric: ApiMetric.METRIC_SESSIONS_COUNT,
                operator: ApiOperator.IS_LESS_THAN_OR_EQUAL,
                value: undefined,
              },
              {
                label: "Min No. of sales",
                metric: ApiMetric.METRIC_SESSION_SALES_COUNT,
                operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
                value: undefined,
              },
              {
                label: "Max No. of sales",
                metric: ApiMetric.METRIC_SESSION_SALES_COUNT,
                operator: ApiOperator.IS_LESS_THAN_OR_EQUAL,
                value: undefined,
              },
            ],
          },
          attributes: [...paidChannelAttributes],
        },
        "Landing Pages": {
          title: "Landing Pages",
          desc: "Understand how different campaigns and landing pages are interacting, and quickly discover areas for optimisation or upweighting.",
          ranking: [
            {
              label: "Opps",
              tooltip: "good performance but lower traffic",
              ranks: [
                {
                  metric: ApiMetric.METRIC_SESSIONS_COUNT,
                  direction: ApiChartRankDrection.DESC,
                  weight: 0.1,
                },
                {
                  metric: ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                  direction: ApiChartRankDrection.ASC,
                  weight: 0.1,
                },
                {
                  metric: ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
                  direction: ApiChartRankDrection.ASC,
                  weight: 0.1,
                },
                {
                  metric: ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_COUNT,
                  direction: ApiChartRankDrection.ASC,
                  weight: 0.1,
                },
                {
                  metric: ApiMetric.METRIC_SESSION_SALES_COUNT,
                  direction: ApiChartRankDrection.ASC,
                  weight: 0.1,
                },
                {
                  metric: ApiMetric.METRIC_SESSION_REVENUE,
                  direction: ApiChartRankDrection.ASC,
                  weight: 0.1,
                },
                {
                  metric: ApiMetric.METRIC_AVERAGE_CWV_PERFORMANCE_SCORE,
                  direction: ApiChartRankDrection.ASC,
                  weight: 0.1,
                },
              ],
            },
            {
              label: "Problems",
              tooltip: "bad performance high traffic",
              ranks: [
                {
                  metric: ApiMetric.METRIC_SESSIONS_COUNT,
                  direction: ApiChartRankDrection.ASC,
                  weight: 0.2,
                },
                {
                  metric: ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
                  direction: ApiChartRankDrection.DESC,
                  weight: 0.1,
                },
                {
                  metric: ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
                  direction: ApiChartRankDrection.DESC,
                  weight: 0.1,
                },
                {
                  metric: ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_COUNT,
                  direction: ApiChartRankDrection.DESC,
                  weight: 0.1,
                },
                {
                  metric: ApiMetric.METRIC_SESSION_SALES_COUNT,
                  direction: ApiChartRankDrection.DESC,
                  weight: 0.1,
                },
                {
                  metric: ApiMetric.METRIC_SESSION_REVENUE,
                  direction: ApiChartRankDrection.DESC,
                  weight: 0.1,
                },
                {
                  metric: ApiMetric.METRIC_AVERAGE_CWV_PERFORMANCE_SCORE,
                  direction: ApiChartRankDrection.ASC,
                  weight: 0.1,
                },
              ],
            },
          ],
          default: [
            ApiMetric.METRIC_SESSIONS_COUNT,
            ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
            ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
          ],
          analyseBy: {
            description: "",
            grouping: [
              {
                option: "",
                dimensions: [
                  ApiDimension.DIMENSION_LANDING_PAGE_LOCATION,
                  ApiDimension.DIMENSION_LANDING_PAGE_PATH,
                ],
              },
            ],
          },
          attributes: [...paidChannelAttributes],
        },
      };
    }, [paidChannelAttributes]);

  useUpdateEffect(() => {
    if (!type) {
      return;
    }
    const newUrl = `?tab=${type}`;
    window.history.replaceState(
      {
        ...window.history.state,
        as: newUrl,
        url: newUrl,
      },
      "",
      newUrl
    );
  }, [type]);

  return (
    <>
      {" "}
      <Portal container={() => document.getElementById("pageTitle")}>
        <Typography variant="h1" mb={2}>
          Marketing Performance Analysis
        </Typography>

        <TabContainer sx={{ minWidth: 800 }}>
          <TabButton
            onClick={() => setType("Campaign Analysis")}
            isActive={type === "Campaign Analysis"}
          >
            <Tooltip
              arrow
              title={
                <>
                  <Typography variant="subtitle2" color={"inherit"}>
                    Campaign Analysis
                  </Typography>
                  <Typography variant="body2" color={"inherit"}>
                    todo
                  </Typography>
                </>
              }
            >
              <span>Campaign Analysis</span>
            </Tooltip>
          </TabButton>
          {Object.keys(config).map((k) => (
            <TabButton
              onClick={() => setType(k as AttributionPerformanceAnalysisTab)}
              isActive={type === k}
            >
              <Tooltip
                arrow
                title={
                  <>
                    <Typography variant="subtitle2" color={"inherit"}>
                      {config[k as AttributionPerformanceAnalysisTab].title}
                    </Typography>
                    <Typography variant="body2" color={"inherit"}>
                      {config[k as AttributionPerformanceAnalysisTab].desc}
                    </Typography>
                  </>
                }
              >
                <span>
                  {config[k as AttributionPerformanceAnalysisTab].title}
                </span>
              </Tooltip>
            </TabButton>
          ))}
        </TabContainer>
      </Portal>
      {type && type === "Campaign Analysis" ? (
        <FullWidthPage>
          <CampaignAnalysis />
        </FullWidthPage>
      ) : (
        <ExplorerProvider config={config[type]}>
          <AttributionExplorer
            tab={type}
            includeBubble={type === "Attribution Explorer"}
          />
        </ExplorerProvider>
      )}
    </>
  );
}

export default withDatepicker(
  withPaidChannelAttributes(PerformanceAnalysisPage)
);
