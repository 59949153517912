import { ApiDimension } from "@incendium/api";
import { useSelectedProject } from "Hooks";
import { useSharedState } from "Hooks/useSharedState";
import { TFilterableDimensions } from "Interfaces";
import getFilterableDimensions from "features/analytics/services/getFilterableDimensions";
import { useCallback, useState } from "react";
import { useUpdateEffect } from "react-use";

const useFilterableDimensions = (dimension?: ApiDimension) => {
  const { selectedProject } = useSelectedProject();
  const [allFetched, setAllFetched] = useState(false);

  const fetch = async () => {
    if (selectedProject) {
      const res = await getFilterableDimensions(
        selectedProject.id as number,
        dimension
      );
      return res;
    }
    return {} as TFilterableDimensions;
  };
  const { state, setState, refetch, loading } = useSharedState<
    TFilterableDimensions,
    TFilterableDimensions
  >("filterableDimensions", {} as TFilterableDimensions, fetch);

  useUpdateEffect(() => {
    if (!allFetched && selectedProject && dimension && state[dimension]) {
      // at this point we get all the dimensions in the background
      populateAll();
    }
  }, [allFetched, selectedProject, dimension, state]);

  const populateAll = useCallback(async () => {
    const res = await getFilterableDimensions(selectedProject!.id as number);
    setState(res);
    setAllFetched(true);
  }, [selectedProject, setState]);

  return {
    filterableDimensions: state as TFilterableDimensions,
    setFilterableDimensions: setState,
    refetch,
    loading,
  };
};

export default useFilterableDimensions;
