import {
  ApiAudience,
  ApiDimension,
  ApiMetric,
  ApiProduct,
} from "@incendium/api";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import SpacedList, { SpacedLinkButton } from "Components/UI/SpacedList";
import { formatMetric, useChartData } from "features/analytics";
import { useCallback } from "react";
import { useFromToContext } from "Providers/FromToProvider";
import { IChartData } from "Interfaces";
import { cell1Icon } from "consts";
import CopyClipboardBtn from "Components/CopyClipboardBtn/CopyClipboardBtn";
import { audienceParam } from "@incendium/inc-ts-helpers";

type onEditFn = (a: ApiAudience) => void;
type onDeleteFn = (a: ApiAudience) => void;

function AudienceList({
  audiences,
  onEdit,
  onDelete,
  project,
  onClick,
}: {
  audiences: ApiAudience[];
  onEdit: onEditFn;
  onDelete: onDeleteFn;
  onClick: () => void;
  project: ApiProduct;
}) {
  const { chartOutput } = useFromToContext();
  const { chartData, loading } = useChartData(
    project,
    {
      dimension: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
      attributes: [],
      yAxisKeys: [
        {
          key: "l",
          fields: [
            ApiMetric.METRIC_LEADS_COUNT,
            ApiMetric.METRIC_ACTIVE_LEADS_LAST_SEVEN_DAYS,
            ApiMetric.METRIC_ACTIVE_LEADS_LAST_THIRTY_DAYS,
            ApiMetric.METRIC_SESSION_REVENUE,
          ],
        },
      ],
    },
    chartOutput
  );

  const { chartData: recentChartData, loading: recentLoading } = useChartData(
    project,
    {
      dimension: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
      attributes: [],
      yAxisKeys: [
        {
          key: "l",
          fields: [ApiMetric.METRIC_SESSION_REVENUE],
        },
      ],
    },
    {
      lastNDays: 30,
      from: undefined,
      to: undefined,
    }
  );

  const fetchAudienceMeta = useCallback(
    (
      audience: ApiAudience,
      metric: ApiMetric,
      chartData: IChartData | null
    ) => {
      const row = (chartData?.data || []).find((a) => a.name === audience.name);
      return formatMetric(metric, row ? Number(row[metric]) : 0);
    },
    []
  );

  return (
    <SpacedList
      title="Audience List"
      action={
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={onClick}
        >
          New Audience
        </Button>
      }
    >
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          {loading ? (
            <TableCell>Loading Meta...</TableCell>
          ) : (
            <>
              <TableCell width={140}>Total Matches</TableCell>
              <TableCell width={140}>7-day active</TableCell>
              <TableCell width={140}>30-day active</TableCell>
              <TableCell width={140}>Total revenue</TableCell>
              <TableCell width={140}>30-day revenue</TableCell>
            </>
          )}
          <TableCell></TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {audiences.map((audience) => {
          return (
            <TableRow key={audience.id}>
              <TableCell>
                <SpacedLinkButton onClick={() => onEdit(audience)}>
                  {audience.name || ""}
                </SpacedLinkButton>
                <Box my={0.5}>
                  <Divider />
                </Box>
                <Stack>
                  <Typography variant="subtitle2" mb={1}>
                    Manually set Audience
                  </Typography>
                  <Typography variant="body2">
                    <strong>Key</strong> : {audience.param}
                    <CopyClipboardBtn
                      color="secondary"
                      text={`${audience.param}`}
                      stopPropagation
                      isIconButton
                      size="extraSmall"
                      sx={{ padding: 0, paddingLeft: 1 }}
                    />
                  </Typography>
                  <Typography variant="body2">
                    <strong>URL Search Param</strong> :{" "}
                    {`${audienceParam}=${audience.param}`}
                    <CopyClipboardBtn
                      color="secondary"
                      text={`${audienceParam}=${audience.param}`}
                      stopPropagation
                      isIconButton
                      size="extraSmall"
                      sx={{ padding: 0, paddingLeft: 1 }}
                    />
                  </Typography>
                  <Typography variant="body2">
                    <strong>JS Function</strong> :{" "}
                    <i>inc("add_audience", "{audience.param}");</i>
                    <CopyClipboardBtn
                      color="secondary"
                      text={`inc("add_audience", "${audience.param}");`}
                      stopPropagation
                      isIconButton
                      size="extraSmall"
                      sx={{ padding: 0, paddingLeft: 1 }}
                    />
                  </Typography>
                </Stack>
              </TableCell>
              {loading || recentLoading ? (
                <TableCell>
                  <CircularProgress size={"small"} />
                </TableCell>
              ) : (
                <>
                  <TableCell>
                    {fetchAudienceMeta(
                      audience,
                      ApiMetric.METRIC_LEADS_COUNT,
                      chartData
                    )}
                  </TableCell>
                  <TableCell>
                    {fetchAudienceMeta(
                      audience,
                      ApiMetric.METRIC_ACTIVE_LEADS_LAST_SEVEN_DAYS,
                      chartData
                    )}
                  </TableCell>
                  <TableCell>
                    {fetchAudienceMeta(
                      audience,
                      ApiMetric.METRIC_ACTIVE_LEADS_LAST_THIRTY_DAYS,
                      chartData
                    )}
                  </TableCell>
                  <TableCell>
                    {fetchAudienceMeta(
                      audience,
                      ApiMetric.METRIC_SESSION_REVENUE,
                      chartData
                    )}
                  </TableCell>
                  <TableCell>
                    {fetchAudienceMeta(
                      audience,
                      ApiMetric.METRIC_SESSION_REVENUE,
                      recentChartData
                    )}
                  </TableCell>
                </>
              )}
              <TableCell align="right" style={{ width: cell1Icon }}>
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={() => onDelete(audience)}
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </SpacedList>
  );
}

export default AudienceList;
