import { useSharedState } from "./useSharedState";
import { ApiTabElementResponse } from "@incendium/api";
import { useSelectedProject } from "./useSelectedProject";
import { reportService } from "Apis";

export const useTabElements = (
  reportId: number,
  tabId: number,
  projectId?: number
) => {
  const { selectedProject } = useSelectedProject();
  const fetchTabElements = async () => {
    if ((selectedProject || projectId) && reportId && tabId) {
      const res = await reportService.reportServiceListElements({
        projectId: projectId || (selectedProject?.id as number),
        reportId: reportId as number,
        tabId: tabId as number,
      });
      return res.results || [];
    }
    return [];
  };
  const { state, setState, refetch } = useSharedState(
    "tabElements",
    [],
    fetchTabElements
  );

  return {
    tabElements: state as ApiTabElementResponse[],
    setTabElements: setState,
    refetchTabElements: refetch,
  };
};
