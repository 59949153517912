import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import AnalyticsCard, {
  AnalyticsCardLegendContainer,
} from "Components/Analytics/AnalyticsCard";
import { AnalyticsCardCustomTooltip } from "features/analytics";
import {
  AnalyticsCardTitle,
  AnalyticsCardTitleWrapper,
} from "Components/Analytics/AnalyticsCard";
import AttributionToggle from "Components/AttributionToggle/AttributionToggle";
import { ChartLegend } from "features/analytics";
import GlassCard from "Components/GlassCard/GlassCard";
import StyledAreaChart from "Components/StyledAreaChart/StyledAreaChart";
import StyledBarChart from "Components/StyledBarChart/StyledBarChart";
import StyledDataGrid from "Components/UI/StyledDataGrid";
import StyledDoughnutChart from "Components/StyledDoughnutChart/StyledDoughnutChart";
import StyledScatterChart from "Components/StyledScatterChart";
import { TAttributionModel } from "Interfaces";
import { useState } from "react";
import { Tooltip } from "recharts";
import StyledComposedChart from "Components/StyledComposedChart/StyledComposedChart";
import { ApiYAxisChartType } from "@incendium/api";

function StylesPage() {
  const theme = useTheme();
  const [models, setModels] = useState<TAttributionModel[]>([]);
  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h1">Heading 1 default</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="h1" color={"primary"}>
          Heading 1 primary
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="h1" color={"secondary"}>
          Heading 1 secondary
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="h1">Heading 1</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="h2">Heading 2</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="h3">Heading 3</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="h4">Heading 4</Typography>
      </Box>
      {/* <Box mb={2}>
        <Typography variant="h3">Heading 5</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="h2">Heading 6</Typography>
      </Box> */}
      <Box mb={2}>
        <Divider />
      </Box>
      <Box mb={2}>
        <Typography variant="subtitle1">Sub Title 1</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="subtitle2">Sub Title 2</Typography>
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
      <Box mb={2}>
        <Typography variant="body1">Body 1 text.primary</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="body1" color="text.secondary">
          Body 1 text.secondary
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="body2">Body 2 text.primary</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="body2" color="text.secondary">
          Body 2 text.secondary
        </Typography>
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
      <Box mb={2}>
        <Button color="primary" size="large">
          Button primary Large
        </Button>
      </Box>
      <Box mb={2}>
        <Button color="primary" size="medium">
          Button primary medium
        </Button>
      </Box>
      <Box mb={2}>
        <Button color="primary" size="small">
          Button primary small
        </Button>
      </Box>
      <Box mb={2}>
        <Button color="secondary" size="large">
          Button secondary large
        </Button>
      </Box>
      <Box mb={2}>
        <Button color="secondary" size="medium">
          Button secondary medium
        </Button>
      </Box>
      <Box mb={2}>
        <Button color="secondary" size="small">
          Button secondary small
        </Button>
      </Box>
      <Box mb={2}>
        <Button color="primary" size="extraSmall">
          Button extra small
        </Button>
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
      <Box mb={2}>
        <TextField label="normal" />
      </Box>
      <Box mb={2}>
        <TextField label="small" size="small" />
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
      <Box mb={2} sx={{ height: 450 }}>
        <AnalyticsCard>
          <StyledScatterChart
            dimension="device"
            yAxisKeys={[
              {
                key: "l",
                fields: ["sessions", "conversions", "rate"],
              },
            ]}
            data={[
              {
                sessions: 1200,
                conversions: 200,
                rate: 1.2,
                name: "mobile",
              },
              {
                sessions: 2198,
                conversions: 302,
                rate: 300.2,
                name: "desktop",
              },
              {
                sessions: 1298,
                conversions: 58,
                rate: 13.2,
                name: "tablet",
              },
              {
                sessions: 0,
                conversions: 0,
                rate: 13.2,
                name: "tablet",
              },
            ]}
          >
            <Tooltip
              cursor={{
                strokeDasharray: "3 3",
                stroke: theme.palette.info.main,
              }}
              content={<AnalyticsCardCustomTooltip />}
            />
          </StyledScatterChart>
        </AnalyticsCard>
      </Box>
      <Box mb={2}>
        <StyledBarChart
          layout="vertical"
          aspect={2}
          data={[
            {
              visits: Math.round(Math.random() * 100),
              name: "a",
            },
            {
              visits: Math.round(Math.random() * 100),
              name: "b",
            },
            {
              visits: Math.round(Math.random() * 100),
              name: "c",
            },
            {
              visits: Math.round(Math.random() * 100),
              name: "d",
            },
            {
              visits: Math.round(Math.random() * 100),
              name: "e",
            },
            {
              visits: Math.round(Math.random() * 100),
              name: "f",
            },
          ]}
        />
      </Box>
      <Box mb={2}>
        <StyledAreaChart
          aspect={2}
          data={[
            {
              o: Math.round(Math.random() * 100),
              d: Math.round(Math.random() * 100),
              y: Math.round(Math.random() * 100),
              f: Math.round(Math.random() * 100),
              name: "01-01-2022",
            },
            {
              o: Math.round(Math.random() * 100),
              d: Math.round(Math.random() * 100),
              y: Math.round(Math.random() * 100),
              f: Math.round(Math.random() * 100),
              name: "02-01-2022",
            },
            {
              o: Math.round(Math.random() * 100),
              d: Math.round(Math.random() * 100),
              y: Math.round(Math.random() * 100),
              f: Math.round(Math.random() * 100),
              name: "03-01-2022",
            },
            {
              o: Math.round(Math.random() * 100),
              d: Math.round(Math.random() * 100),
              y: Math.round(Math.random() * 100),
              f: Math.round(Math.random() * 100),
              name: "04-01-2022",
            },
            {
              o: Math.round(Math.random() * 100),
              d: Math.round(Math.random() * 100),
              y: Math.round(Math.random() * 100),
              f: Math.round(Math.random() * 100),
              name: "05-01-2022",
            },
          ]}
        >
          <Tooltip
            formatter={(value, key) => {
              return key === "rate"
                ? `${(value as number).toFixed(2)}%`
                : `${(value as number).toFixed(2)}`;
            }}
          />
        </StyledAreaChart>
      </Box>
      <Box mb={2} sx={{ height: 500, width: 500 }}>
        <AnalyticsCard>
          <StyledDataGrid
            rows={[
              { id: 1, name: "test" },
              { id: 2, name: "test2" },
              { id: 3, name: "test3" },
            ]}
            columns={[
              { field: "id", flex: 1, align: "center", headerAlign: "center" },
              {
                field: "name",
                flex: 1,
                align: "center",
                headerAlign: "center",
              },
            ]}
          />
        </AnalyticsCard>
      </Box>
      <Box mb={2} sx={{ width: 454, height: 488 }}>
        <AnalyticsCard>
          <AnalyticsCardTitleWrapper>
            <Box mr={1} sx={{ flex: 1, overflow: "hidden", minHeight: 23 }}>
              {<AnalyticsCardTitle title={"pie chart"} />}
            </Box>
          </AnalyticsCardTitleWrapper>
          <Stack sx={{ minHeight: 0, justifyContent: "flex-end" }} mt={2}>
            <StyledDoughnutChart
              data={[
                {
                  name: "Group A a very ong name that wont fit in it",
                  value: 2400,
                },
                { name: "Group B", value: 4567 },
                { name: "Group C", value: 1398 },
                { name: "Group D", value: 9800 },
                { name: "Group E", value: 3908 },
                { name: "Group F", value: 4800 },
                { name: "Group G", value: 2800 },
              ]}
            ></StyledDoughnutChart>

            <AnalyticsCardLegendContainer>
              <ChartLegend
                items={[
                  "Group A a very ong name that wont fit in it",
                  "Group B",
                  "Group C",
                  "Group D",
                  "Group E",
                  "Group F",
                  "Group G",
                ]}
              />
            </AnalyticsCardLegendContainer>
          </Stack>
        </AnalyticsCard>
      </Box>
      <Box mb={2} sx={{ height: 500, width: 500 }}>
        <AnalyticsCard>
          <AnalyticsCardTitleWrapper>
            <AnalyticsCardTitle title="Comparisons chart" />
          </AnalyticsCardTitleWrapper>
          <StyledComposedChart
            aspect={2}
            comparismentChart={{
              data: [
                {
                  previous_rate: Math.round(Math.random() * 10000),
                  name: "01-01-2022",
                },
                {
                  previous_rate: Math.round(Math.random() * 10000),
                  name: "02-01-2022",
                },
                {
                  previous_rate: Math.round(Math.random() * 10000),
                  name: "03-01-2022",
                },
                {
                  previous_rate: Math.round(Math.random() * 10000),
                  name: "04-01-2022",
                },
                {
                  previous_rate: Math.round(Math.random() * 10000),
                  name: "05-01-2022",
                },
              ],
              y: [
                {
                  fields: ["rate"],
                },
              ],
            }}
            yAxisKeys={[
              {
                key: "l",
                fields: ["rate", "previous_rate"],
                chart: {
                  rate: ApiYAxisChartType.BAR,
                  previous_rate: ApiYAxisChartType.BAR,
                },
              },
            ]}
            data={[
              {
                rate: Math.round(Math.random() * 10000),
                name: "01-01-2022",
              },
              {
                rate: Math.round(Math.random() * 10000),
                name: "02-01-2022",
              },
              {
                rate: Math.round(Math.random() * 10000),
                name: "03-01-2022",
              },
              {
                rate: Math.round(Math.random() * 10000),
                name: "04-01-2022",
              },
              {
                rate: Math.round(Math.random() * 10000),
                name: "05-01-2022",
              },
            ]}
            showTooltip
          />
        </AnalyticsCard>
      </Box>
      <Box mt={3}>
        <GlassCard>
          <AttributionToggle models={models} multiple setModels={setModels} />
        </GlassCard>
      </Box>
    </Box>
  );
}

export default StylesPage;
