/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiExternalCampaignFromJSON, ApiExternalCampaignToJSON, } from './ApiExternalCampaign';
import { ApiExternalClickFromJSON, ApiExternalClickToJSON, } from './ApiExternalClick';
import { ApiOauthClientFromJSON, ApiOauthClientToJSON, } from './ApiOauthClient';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
import { ApiSyncStatusFromJSON, ApiSyncStatusToJSON, } from './ApiSyncStatus';
import { ApiSyncTypeFromJSON, ApiSyncTypeToJSON, } from './ApiSyncType';
export function ApiSyncFromJSON(json) {
    return ApiSyncFromJSONTyped(json, false);
}
export function ApiSyncFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'client': !exists(json, 'client') ? undefined : ApiOauthClientFromJSON(json['client']),
        'oauthClientId': !exists(json, 'oauthClientId') ? undefined : json['oauthClientId'],
        'account': !exists(json, 'account') ? undefined : json['account'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'status': !exists(json, 'status') ? undefined : ApiSyncStatusFromJSON(json['status']),
        'type': !exists(json, 'type') ? undefined : ApiSyncTypeFromJSON(json['type']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'syncedAt': !exists(json, 'syncedAt') ? undefined : (new Date(json['syncedAt'])),
        'clicks': !exists(json, 'clicks') ? undefined : (json['clicks'].map(ApiExternalClickFromJSON)),
        'campaigns': !exists(json, 'campaigns') ? undefined : (json['campaigns'].map(ApiExternalCampaignFromJSON)),
        'accountName': !exists(json, 'accountName') ? undefined : json['accountName'],
        'campaignsCount': !exists(json, 'campaignsCount') ? undefined : json['campaignsCount'],
        'clicksCount': !exists(json, 'clicksCount') ? undefined : json['clicksCount'],
    };
}
export function ApiSyncToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'client': ApiOauthClientToJSON(value.client),
        'oauthClientId': value.oauthClientId,
        'account': value.account,
        'project': ApiProjectToJSON(value.project),
        'projectId': value.projectId,
        'error': value.error,
        'status': ApiSyncStatusToJSON(value.status),
        'type': ApiSyncTypeToJSON(value.type),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'syncedAt': value.syncedAt === undefined ? undefined : (value.syncedAt.toISOString()),
        'clicks': value.clicks === undefined ? undefined : (value.clicks.map(ApiExternalClickToJSON)),
        'campaigns': value.campaigns === undefined ? undefined : (value.campaigns.map(ApiExternalCampaignToJSON)),
        'accountName': value.accountName,
        'campaignsCount': value.campaignsCount,
        'clicksCount': value.clicksCount,
    };
}
