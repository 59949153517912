import grapesjs, { Editor, ProjectData, StorageOptions } from "grapesjs";
import basicBlocks from "grapesjs-blocks-basic";
import scriptPlugin from "grapesjs-script-editor";
import customCodePlugin from "grapesjs-custom-code";
import styleBgPlugin from "grapesjs-style-bg";
import tooltipPlugin from "grapesjs-tooltip";
import presetPlugin from "grapesjs-preset-webpage";
import parserPostCSS from "grapesjs-parser-postcss";
// import suggestClasses from "grapesjs-ui-suggest-classes";
import gjsForms from "grapesjs-plugin-forms";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMedias } from "Hooks/useMedia";
import { ApiMediaType, ApiProject, ApiSimpleComponent } from "@incendium/api";
import { Box, useTheme } from "@mui/material";
import {
  customAssetManager,
  customOnEditor,
  customStyleManagerConfig,
  StyledGrapesjsEditor,
} from "features/uiBuilder";
import Loading from "Components/Loading/Loading";
import { sortByDate } from "Helpers/arrays";
import { saveComponent } from "features/campaigns";

interface IGrapesComponentBuilderProps {
  component: ApiSimpleComponent;
  saveToken: string | null;
  project: ApiProject;
}

function GrapesComponentBuilder({
  component,
  saveToken,
  project,
}: IGrapesComponentBuilderProps) {
  const theme = useTheme();
  const { medias, setMedias } = useMedias();
  const [editor, setEditor] = useState<Editor | null>(null);
  const [editorLoaded, seteditorLoaded] = useState(false);

  const images = useMemo(
    () =>
      sortByDate(
        (medias || []).filter(
          (m) => m.files && m.type === ApiMediaType.IMAGE_MEDIA
        ),
        "createdAt"
      ).map((im) => (im.files || [])[0].fullPath || ""),
    [medias]
  );

  const onSaveComponent = useCallback(
    (editor) => (data: ProjectData, options: StorageOptions) => {
      return saveComponent(
        project?.id as number,
        {
          ...component,
          grapeJs: JSON.stringify(data),
          html: editor?.getHtml(),
          css: editor?.getCss(),
          js: editor?.getJs(),
        },
        saveToken || undefined
      );
    },
    [component, project, saveToken]
  );

  const onEditor = (editor: Editor) => {
    editor = customOnEditor(
      editor,
      project!,
      Number(component.id),
      onSaveComponent
    );
    editor.on("load", () => {
      seteditorLoaded(true);
    });
    setEditor(editor);
  };

  useEffect(() => {
    if (!editor) {
      return;
    }
    images.forEach((img) => {
      if (!editor.AssetManager.get(img)) {
        editor.AssetManager.add(img);
      }
    });
  }, [editor, images]);

  useEffect(() => {
    if (editor && editorLoaded && component.head && component.head !== "") {
      let head = editor.Canvas.getDocument().head.innerHTML;
      if (
        !head.includes("<!-- start custom -->") ||
        !head.includes("<!-- end custom -->")
      ) {
        head += "<!-- start custom --><!-- end custom -->";
      }

      let pattern = /<!-- start custom -->([\s\S]*?)<!-- end custom -->/;
      let result = head.replace(
        pattern,
        `\n<!-- start custom -->\n${component.head}\n<!-- end custom -->\n`
      );

      editor.Canvas.getDocument().head.innerHTML = result;
    }
  }, [component.head, editorLoaded, editor]);

  return (
    <Box sx={{ position: "relative", height: "100%" }}>
      {!editorLoaded && (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            background: theme.palette.background.default,
            zIndex: 999,
            boxShadow: `0 0 0px 10px ${theme.palette.background.default}`,
          }}
        >
          <Loading />
        </Box>
      )}
      <StyledGrapesjsEditor
        // Pass the core GrapesJS library to the wrapper (required).
        // You can also pass the CDN url (eg. "https://unpkg.com/grapesjs")
        grapesjs={grapesjs}
        // Load the GrapesJS CSS file asynchronously from URL.
        // This is an optional prop, you can always import the CSS directly in your JS if you wish.
        grapesjsCss="https://unpkg.com/grapesjs/dist/css/grapes.min.css"
        // GrapesJS init options
        options={{
          // @ts-ignore, ignore as for some reason interface does not have it.
          allowScripts: true,
          height: "100%",
          storageManager: {
            type: "custom",
            autoload: true,
          },
          assetManager: customAssetManager(
            project.client!,
            project!,
            images,
            setMedias
          ),
          styleManager: customStyleManagerConfig(),
        }}
        onEditor={onEditor}
        plugins={[
          parserPostCSS,
          // suggestClasses, // this plugin is cuasing performance issues when pasting html
          (editor) =>
            basicBlocks(editor, {
              flexGrid: true,
              blocks: [
                "column1",
                "column2",
                "column3",
                "column3-7",
                "text",
                "link",
                "image",
                "video",
              ],
            }),
          scriptPlugin,
          customCodePlugin,
          styleBgPlugin,
          (editor) => gjsForms(editor, {}),
          tooltipPlugin,
          (editor) =>
            presetPlugin(editor, {
              modalImportTitle: "Import Template",
              modalImportLabel:
                '<div style="margin-bottom: 10px; font-size: 13px;">Paste here your HTML/CSS and click Save</div>',
              modalImportButton: "Save",
              modalImportContent: function (editor) {
                return (
                  editor.getHtml() + "<style>" + editor.getCss() + "</style>"
                );
              },
            }),
        ]}
      />
    </Box>
  );
}

export default GrapesComponentBuilder;
