import { IChart, TChartData } from "Interfaces";
import { analyticsService } from "Apis";
import {
  ApiChartTemplate,
  ApiChartType,
  ApiChartYAxisKey,
} from "@incendium/api";
import {
  filterNonTrendDimensions,
  getUniqueDimensionValues,
  hasDayTrend,
  isDimension,
} from "features/analytics/";
import { groupBy } from "Helpers/arrays";
import moment from "moment";
import { friendlyDate, getDatesBetween } from "Helpers/dates";

export default async function getPrimaryAnalytics(
  projectId: number,
  chart: IChart,
  from?: Date,
  to?: Date,
  lastNDays?: number
): Promise<{
  chartData: TChartData[];
  dimensions: string[];
  totals?: TChartData;
}> {
  const res = await analyticsService.analyticsServiceGetPrimaryMetrics({
    projectId,
    chartId: 0,
    payload: {
      from,
      to,
      lastNDays,
      chart: {
        ...chart,
        attributes: (chart.attributes || []).filter((a) => a.value),
        having: chart.having?.filter((h) => typeof h.value !== "undefined"),
        yAxisKeys: (chart.yAxisKeys as ApiChartYAxisKey[]).map((y) => ({
          ...y,
          fields: y.fields?.filter((f) => f),
        })),
      },
      leadId: chart.leadId,
    },
  });

  if (!res.rows || res.rows?.length === 0) {
    return {
      chartData: [],
      dimensions: res.dimensions as string[],
      totals: undefined,
    };
  }

  let chartData = (res.rows || []).map((row) => {
    let dim: any = {};
    (row.dimensions || []).forEach((d, i) => {
      dim[(res.dimensions || [])[i]] = d;
    });

    let met: any = {};

    (row.metrics || []).forEach((m, i) => {
      met[(res.metrics || [])[i]] = m.type?.includes("Int")
        ? Number(m.value)
        : m.value;
    });

    const o = {
      name: row.dimensions ? row.dimensions[0] : "",
      ...dim,
      ...met,
    };

    return o;
  });

  // we are making a assumption here that the first dimension is the trend as this is a restriction in th ui
  const secondDimension = filterNonTrendDimensions(res.dimensions || [])[0];

  // when returning rows for trend we should make sure we have a value per every dimension, currently we only suppprt 1 extra dimension ontop of the trend
  // this only works for by DAY
  if (
    chart.template === ApiChartTemplate.TREND &&
    chart.type === ApiChartType.GRAPH &&
    hasDayTrend(res.dimensions || [])
  ) {
    // get all possible dimension value
    const startDate = lastNDays
      ? moment().clone().subtract(lastNDays, "days").utc().startOf("day")
      : moment(from as Date).utc();

    const endDate = lastNDays
      ? moment().utc().startOf("day")
      : moment(to as Date).utc();

    const dates = getDatesBetween(startDate, endDate);

    const gg = groupBy(chartData, "name");
    const data: any = [];

    const dimensionValues = getUniqueDimensionValues(res.rows, 1);

    dates.forEach((d) => {
      const key = friendlyDate(d);
      const dateRows = gg[key];

      if (!secondDimension) {
        if (dateRows) {
          data.push(dateRows[0]);
        } else {
          let o: any = {
            name: key,
          };
          (res.metrics || []).forEach((m) => {
            o[m] = 0;
          });
          data.push(o);
        }
        return;
      }

      dimensionValues.forEach((dv) => {
        if (secondDimension) {
          const row = dateRows?.find((g) => g[secondDimension] === dv);
          if (row) {
            data.push(row);
          } else {
            let o: any = {
              name: key,
              [secondDimension]: dv,
            };
            (res.metrics || []).forEach((m) => {
              o[m] = 0;
            });
            data.push(o);
          }
        }
      });
    });
    chartData = data;
  }

  const aggregate = {
    id: "Total",
    name: "Total",
  };
  if (
    chart.withTotals &&
    res.totalsRow &&
    (res.totalsRow.metrics || []).length > 0
  ) {
    let idx = 0;
    Object.entries(chartData[0]).forEach(([key, value]) => {
      if (["id", "name"].includes(key)) {
        return;
      }
      if (isDimension(key)) {
        aggregate[key] = "Total";
        return;
      }

      let totalMetric = res.totalsRow!.metrics![idx];
      aggregate[key] = (totalMetric.type || "").includes("Int")
        ? Number(totalMetric.value)
        : totalMetric.value;
      idx++;
    });
  }

  return {
    dimensions: res.dimensions || [],
    chartData,
    totals: chart.withTotals ? aggregate : undefined,
  };
}
