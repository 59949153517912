/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * total_revenue / first_conversion_count
 *  - MetricCAC: spend / new customers
 *  - MetricECR: no. sales / visits
 *  - MetricCPNV: cost per new visit (visit from new person, this is no. of sessions that is leads first session)
 *  - MetricLinearPredicted30DayLtvRevenue: 30 days in the future (from now) predicted ltv, this is linear, so is by lead and will fall into all dimensions that lead belongs to
 *  - MetricLinearPredicted60DayLtvRevenue: 60 days in the future (from now) predicted ltv, this is linear, so is by lead and will fall into all dimensions that lead belongs to
 *  - MetricLinearPredicted90DayLtvRevenue: 90 days in the future (from now) predicted ltv, this is linear, so is by lead and will fall into all dimensions that lead belongs to
 *  - MetricPredictedROAS: MetricLinearLTVRevenue = 192; // life time revenue base on any lead that fits into this dimension
 *  MetricLinearROASLTV = 193; // ROAS but using linear ltv revenue instead of session revenue
 *
 * ROAS but using ltv revenue + 90 day predicted ltv
 *  - MetricAttributionFirstClickRevenue: revenue from first click attribution
 *  - MetricAttributionLastClickRevenue: revenue from last click attribution
 *  - MetricAttributionLinearRevenue: revenue from linear attribution
 *  - MetricAttributionLastNonDirectClickRevenue: revenue from last non direct click attribution
 *  - MetricAttributionLastPositionClickRevenue: revenue from last position click attribution
 *  - MetricAttributionImpactedClickRevenue: linear revenue but not divided between dimension in block
 *  - MetricAttributionFirstClickSalesCount: revenue from first click attribution
 *  - MetricAttributionLastClickSalesCount: revenue from last click attribution
 *  - MetricAttributionLinearSalesCount: revenue from linear attribution
 *  - MetricAttributionLastNonDirectClickSalesCount: revenue from last non direct click attribution
 *  - MetricAttributionLastPositionClickSalesCount: revenue from last position click attribution
 *  - MetricAttributionImpactedClickSalesCount: linear sales count but not divided between dimension in block
 *  - MetricAttributionFirstClickROAS: (first_click_revenue / spend) / spend
 *  - MetricAttributionLastClickROAS: (last_click_revenue / spend) / spend
 *  - MetricAttributionLinearROAS: (linear_click_revenue / spend) / spend
 *  - MetricAttributionLastNonDirectClickROAS: (last_non_direct_click_revenue / spend) / spend
 *  - MetricAttributionLastPositionClickROAS: (position_click_revenue / spend) / spend
 *  - MetricAttributionImpactedClickROAS: linear ROAS but not divided between dimension in block
 *  - MetricAttributionFirstClickLTV: total revenue from lead where this session was their first sessions leading to their first purchase
 *  - MetricAttributionLastClickLTV: total revenue from lead where this session was their last sessions leading to their first purchase
 *  - MetricAttributionLinearLTV: total revenue from lead where this session was their linear sessions leading to their first purchase
 *  - MetricAttributionLastNonDirectClickLTV: total revenue from lead where this session was their last non direct sessions leading to their first purchase
 *  - MetricAttributionLastPositionClickLTV: total revenue from lead where this session was their position sessions leading to their first purchase
 *  - MetricAttributionImpactedClickLTV: linear LTV but not divided between dimension in block
 *  - MetricAttributionFirstClickROASLTV: (first_click_ltv / spend) / spend, ROAS but using ltv revenue instead of session revenue
 *  - MetricAttributionLastClickROASLTV: (last_click_ltv / spend) / spend, ROAS but using ltv revenue instead of session revenue
 *  - MetricAttributionLinearROASLTV: (linear_click_ltv / spend) / spend, ROAS but using ltv revenue instead of session revenue
 *  - MetricAttributionLastNonDirectClickROASLTV: (last_non_direct_click_ltv / spend) / spend, ROAS but using ltv revenue instead of session revenue
 *  - MetricAttributionLastPositionClickROASLTV: (position_click_ltv / spend) / spend, ROAS but using ltv revenue instead of session revenue
 *  - MetricAttributionImpactedClickROASLTV: linear ROASLTV but not divided between dimension in block
 *  - MetricAttributionFirstClickMacroConversionCount: number of conversions with reserved macro conversion in session
 *  - MetricAttributionLastClickMacroConversionCount: number of conversions with reserved macro conversion in session
 *  - MetricAttributionLinearMacroConversionCount: number of conversions with reserved macro conversion in session
 *  - MetricAttributionLastNonDirectClickMacroConversionCount: number of conversions with reserved macro conversion in session
 *  - MetricAttributionLastPositionClickMacroConversionCount: number of conversions with reserved macro conversion in session
 *  - MetricAttributionImpactedClickMacroConversionCount: linear MacroConversionCount but not divided between dimension in block
 *  - MetricSessionAllInclusiveCost: [ie: aic] session_cost + shipping + margin
 *  - MetricSessionContributionMargin: (session_revenue - session_cost - shipping - margin)
 *  - MetricSessionEfficiencyRation: session_revenue / session_cost
 *  - MetricPredictedROI: MetricLinearROILTV = 229; // as ROILTV but using AIC
 *
 * as ROAS but using AIC
 *  - MetricAttributionFirstClickROI: (first_click_revenue - aic) / aic
 *  - MetricAttributionLastClickROI: (last_click_revenue - aic) / aic
 *  - MetricAttributionLinearROI: (linear_click_revenue - aic) / aic
 *  - MetricAttributionLastNonDirectClickROI: (last_non_direct_click_revenue - aic) / aic
 *  - MetricAttributionLastPositionClickROI: (position_click_revenue - aic) / aic
 *  - MetricAttributionImpactedClickROI: linear ROI but not divided between dimension in block
 *  - MetricAttributionFirstClickROILTV: (first_click_ltv - aic) / aic, ROAS but using ltv revenue instead of session revenue
 *  - MetricAttributionLastClickROILTV: (last_click_ltv - aic) / aic, ROAS but using ltv revenue instead of session revenue
 *  - MetricAttributionLinearROILTV: (linear_click_ltv - aic) / aic, ROAS but using ltv revenue instead of session revenue
 *  - MetricAttributionLastNonDirectClickROILTV: (last_non_direct_click_ltv - aic) / aic, ROAS but using ltv revenue instead of session revenue
 *  - MetricAttributionLastPositionClickROILTV: (position_click_ltv - aic) / aic, ROAS but using ltv revenue instead of session revenue
 *  - MetricAttributionImpactedClickROILTV: linear ROILTV but not divided between dimension in block
 *  - MetricLTVCACRatio: LTV / CAC(spend / new customers), is currently using linear ltv but LTV needs re thinking.
 *  - MetricExistingCustomerRevenueGrowthRate: (existing_customer_revenue - existing_customer_revenue_last_period) / existing_customer_revenue_last_period * 100
 *  - MetricAttributionFirstNonDirectClickRevenue: revenue from first non direct click attribution
 *  - MetricAttributionFirstNonDirectClickSalesCount: revenue from first non direct click attribution
 *  - MetricAttributionFirstNonDirectClickROAS: (first_non_direct_click_revenue / spend) / spend
 *  - MetricAttributionFirstNonDirectClickLTV: total revenue from lead where this session was their first non direct sessions leading to their first purchase
 *  - MetricAttributionFirstNonDirectClickROASLTV: (first_non_direct_click_ltv / spend) / spend, ROAS but using ltv revenue instead of session revenue
 *  - MetricAttributionFirstNonDirectClickMacroConversionCount: number of conversions with reserved macro conversion in session
 *  - MetricAttributionFirstNonDirectClickROI: (first_non_direct_click_revenue - aic) / aic
 *  - MetricAttributionFirstNonDirectClickROILTV: (first_non_direct_click_ltv - aic) / aic, ROAS but using ltv revenue instead of session revenue
 *  - MetricJobImpressionCount: number of impressions by dimension that are linked to a job
 *  - MetricJobImpressionRate: percentage. of leads who saw job impression
 *  - MetricToNewCustomerRate: no. of new customers / no. of all non returning customer leads (total leads - returning customers)
 *  - MetricReturningCustomerSessionCount: no. of sessions by returning customers (sessions after first sale)
 *  - MetricReturningCustomerPageMacroConversionCount: no. of macro conversions by returning customers (sessions after first sale)
 *  - MetricReturningCustomerSessionMacroConversionCount: no. of macro conversions by returning customers (sessions after first sale)
 *  - MetricReturningCustomerSessionMacroConversionRate: returning customer session macro conversions / returning customer sessions
 *  - MetricReturningCustomerRate: no. of returning customers / total customers
 *  - MetricPublisherImpressions: "Number of impressions reported by publisher for ads for given dimension
 *  - MetricPublisherCTR: Number of impressions / number of clicks reported by publisher for ads for given dimension
 *  - MetricCheckoutStartCount: This is the number of sessions where one of it's location has the reserved checkout page type or the reserved checkout started conversion fired during the sessions
 *  - MetricCheckoutStartRate: the rate at which visitors start checkouts. Calculated: (MetricCheckoutStartCount / total sessions) *100
 *  - MetricCheckoutCompletionRate: The rate at which started checkout processes get completed: (total session sales / MetricCheckoutStartCount )*100
 *  - MetricPaidSessionCount: sessions from a paid channel
 *  - MetricOrganicSessionCount: sessions not from a paid channel
 *  - MetricProductPageEffectivenessRate: pageview effectivness for product pages only
 *  - MetricCartAbandonmentRate: (number of sessions with an add to cart without sales / Total number of sessions with an add to cart) x100
 *  - MetricCheckoutAbandonmentRate: (number of checkout starts without sales / total checkout starts) x100
 * @export
 * @enum {string}
 */
export var ApiMetric;
(function (ApiMetric) {
    ApiMetric["METRIC_NOT_SET"] = "MetricNotSet";
    ApiMetric["METRIC_PAGE_VIEWS_COUNT"] = "MetricPageViewsCount";
    ApiMetric["METRIC_SESSIONS_COUNT"] = "MetricSessionsCount";
    ApiMetric["METRIC_LEADS_COUNT"] = "MetricLeadsCount";
    ApiMetric["METRIC_NEW_LEADS_COUNT"] = "MetricNewLeadsCount";
    ApiMetric["METRIC_RETURNING_LEADS_COUNT"] = "MetricReturningLeadsCount";
    ApiMetric["METRIC_SESSION_REVENUE"] = "MetricSessionRevenue";
    ApiMetric["METRIC_SESSION_COUNT_PER_LEAD"] = "MetricSessionCountPerLead";
    ApiMetric["METRIC_NEW_SESSION_COUNT"] = "MetricNewSessionCount";
    ApiMetric["METRIC_RETURNING_SESSION_COUNT"] = "MetricReturningSessionCount";
    ApiMetric["METRIC_SESSION_PAGEVIEW_COUNT"] = "MetricSessionPageviewCount";
    ApiMetric["METRIC_AVERAGE_PAGEVIEWS_PER_SESSION"] = "MetricAveragePageviewsPerSession";
    ApiMetric["METRIC_AVERAGE_SESSION_DURATION"] = "MetricAverageSessionDuration";
    ApiMetric["METRIC_EFFECTIVE_SESSION_COUNT"] = "MetricEffectiveSessionCount";
    ApiMetric["METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD"] = "MetricEffectiveSessionCountPerLead";
    ApiMetric["METRIC_EFFECTIVE_SESSION_RATE"] = "MetricEffectiveSessionRate";
    ApiMetric["METRIC_EFFECTIVE_PAGEVIEW_COUNT"] = "MetricEffectivePageviewCount";
    ApiMetric["METRIC_EFFECTIVE_PAGEVIEW_COUNT_PER_LEAD"] = "MetricEffectivePageviewCountPerLead";
    ApiMetric["METRIC_EFFECTIVE_PAGEVIEW_RATE"] = "MetricEffectivePageviewRate";
    ApiMetric["METRIC_AVERAGE_TIME_ON_PAGE"] = "MetricAverageTimeOnPage";
    ApiMetric["METRIC_AVERAGE_VIEWED_PERCENTAGE"] = "MetricAverageViewedPercentage";
    ApiMetric["METRIC_AVERAGE_SCROLL_PERCENTAGE"] = "MetricAverageScrollPercentage";
    ApiMetric["METRIC_AVERAGE_SCROLL_SPEED"] = "MetricAverageScrollSpeed";
    ApiMetric["METRIC_RETURNING_LEAD_PERCENTAGE"] = "MetricReturningLeadPercentage";
    ApiMetric["METRIC_EFFECTIVE_SESSION_TO_CONVERSION_RATE"] = "MetricEffectiveSessionToConversionRate";
    ApiMetric["METRIC_SESSION_MACRO_CONVERSION_COUNT"] = "MetricSessionMacroConversionCount";
    ApiMetric["METRIC_SESSION_MICRO_CONVERSION_COUNT"] = "MetricSessionMicroConversionCount";
    ApiMetric["METRIC_SESSION_MACRO_CONVERSION_RATE"] = "MetricSessionMacroConversionRate";
    ApiMetric["METRIC_SESSION_MICRO_CONVERSION_RATE"] = "MetricSessionMicroConversionRate";
    ApiMetric["METRICMACRO_CONVERSION_COUNT_PER_LEAD"] = "MetricmacroConversionCountPerLead";
    ApiMetric["METRICMICRO_CONVERSION_COUNT_PER_LEAD"] = "MetricmicroConversionCountPerLead";
    ApiMetric["METRICEFFECTIVE_PAGEVIEW_TO_CONVERSION_RATE"] = "MetriceffectivePageviewToConversionRate";
    ApiMetric["METRIC_PAGE_MACRO_CONVERSION_COUNT"] = "MetricPageMacroConversionCount";
    ApiMetric["METRIC_PAGE_MICRO_CONVERSION_COUNT"] = "MetricPageMicroConversionCount";
    ApiMetric["METRIC_PAGE_MACRO_CONVERSION_RATE"] = "MetricPageMacroConversionRate";
    ApiMetric["METRIC_PAGE_MICRO_CONVERSION_RATE"] = "MetricPageMicroConversionRate";
    ApiMetric["METRIC_LEAD_MACRO_CONVERSION_PERCENTAGE"] = "MetricLeadMacroConversionPercentage";
    ApiMetric["METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_COUNT_DURING_SESSION"] = "MetricAverageEffectivePageviewCountDuringSession";
    ApiMetric["METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_RATE_DURING_SESSION"] = "MetricAverageEffectivePageviewRateDuringSession";
    ApiMetric["METRIC_AVERAGE_TIME_ON_PAGE_DURING_SESSION"] = "MetricAverageTimeOnPageDuringSession";
    ApiMetric["METRIC_AVERAGE_VIEWED_PERCENTAGE_DURING_SESSION"] = "MetricAverageViewedPercentageDuringSession";
    ApiMetric["METRIC_AVERAGE_SCROLL_PERCENTAGE_DURING_SESSION"] = "MetricAverageScrollPercentageDuringSession";
    ApiMetric["METRIC_AVERAGE_SCROLL_SPEED_DURING_SESSION"] = "MetricAverageScrollSpeedDuringSession";
    ApiMetric["METRIC_NEW_CUSTOMER_COUNT"] = "MetricNewCustomerCount";
    ApiMetric["METRIC_SESSION_SALES_COUNT"] = "MetricSessionSalesCount";
    ApiMetric["METRIC_SALES_REVENUE"] = "MetricSalesRevenue";
    ApiMetric["METRIC_VISIT_TO_PURCHASE_RATE"] = "MetricVisitToPurchaseRate";
    ApiMetric["METRIC_AVERAGE_SALE_VALUE"] = "MetricAverageSaleValue";
    ApiMetric["METRIC_FIRST_VISIT_SESSION_MACRO_CONVERSION_COUNT"] = "MetricFirstVisitSessionMacroConversionCount";
    ApiMetric["METRIC_THIRTY_DAY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricThirtyDaySessionMacroConversionCount";
    ApiMetric["METRIC_SIXTY_DAY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricSixtyDaySessionMacroConversionCount";
    ApiMetric["METRIC_NINETY_DAY_SESSION_MACRO_CONVERSION_COUNT"] = "MetricNinetyDaySessionMacroConversionCount";
    ApiMetric["METRIC_THIRTY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricThirtyDaySessionMacroConversionCountAdditional";
    ApiMetric["METRIC_SIXTY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricSixtyDaySessionMacroConversionCountAdditional";
    ApiMetric["METRIC_NINETY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricNinetyDaySessionMacroConversionCountAdditional";
    ApiMetric["METRIC_FIRST_VISIT_SESSION_SALES_COUNT"] = "MetricFirstVisitSessionSalesCount";
    ApiMetric["METRIC_THIRTY_DAY_SESSION_SALES_COUNT"] = "MetricThirtyDaySessionSalesCount";
    ApiMetric["METRIC_SIXTY_DAY_SESSION_SALES_COUNT"] = "MetricSixtyDaySessionSalesCount";
    ApiMetric["METRIC_NINETY_DAY_SESSION_SALES_COUNT"] = "MetricNinetyDaySessionSalesCount";
    ApiMetric["METRIC_THIRTY_DAY_SESSION_SALES_COUNT_ADDITIONAL"] = "MetricThirtyDaySessionSalesCountAdditional";
    ApiMetric["METRIC_SIXTY_DAY_SESSION_SALES_COUNT_ADDITIONAL"] = "MetricSixtyDaySessionSalesCountAdditional";
    ApiMetric["METRIC_NINETY_DAY_SESSION_SALES_COUNT_ADDITIONAL"] = "MetricNinetyDaySessionSalesCountAdditional";
    ApiMetric["METRIC_FIRST_VISIT_PAGE_MACRO_CONVERSION_COUNT"] = "MetricFirstVisitPageMacroConversionCount";
    ApiMetric["METRI_THIRTY_DAY_MACRO_CONVERSION_COUNT"] = "MetriThirtyDayMacroConversionCount";
    ApiMetric["METRIC_SIXTY_DAY_MACRO_CONVERSION_COUNT"] = "MetricSixtyDayMacroConversionCount";
    ApiMetric["METRIC_NINETY_DAY_MACRO_CONVERSION_COUNT"] = "MetricNinetyDayMacroConversionCount";
    ApiMetric["METRIC_THIRTY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricThirtyDayMacroConversionCountAdditional";
    ApiMetric["METRIC_SIXTY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricSixtyDayMacroConversionCountAdditional";
    ApiMetric["METRIC_NINETY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL"] = "MetricNinetyDayMacroConversionCountAdditional";
    ApiMetric["METRIC_PRODUCT_REVENUE"] = "MetricProductRevenue";
    ApiMetric["METRIC_RETURNING_NINETY_DAY_CUSTOMER_COUNT"] = "MetricReturningNinetyDayCustomerCount";
    ApiMetric["METRIC_RETURNING_NINETY_DAY_CUSTOMER_PERCENTAGE"] = "MetricReturningNinetyDayCustomerPercentage";
    ApiMetric["METRIC_PRODUCT_PAGEVIEW_COUNT"] = "MetricProductPageviewCount";
    ApiMetric["METRIC_JOB_PAGEVIEW_COUNT"] = "MetricJobPageviewCount";
    ApiMetric["METRIC_LANDING_SESSION_COUNT"] = "MetricLandingSessionCount";
    ApiMetric["METRIC_CRAWLER_VISIT_COUNT"] = "MetricCrawlerVisitCount";
    ApiMetric["METRIC_OUT_OF_STOCK_PAGEVIEW_COUNT"] = "MetricOutOfStockPageviewCount";
    ApiMetric["METRIC_OUT_OF_STOCK_PAGES_COUNT"] = "MetricOutOfStockPagesCount";
    ApiMetric["METRIC_AVERAGE_DAYS_TO_SECOND_VISIT"] = "MetricAverageDaysToSecondVisit";
    ApiMetric["METRIC_SESSION_JOB_APPLICATIONS_COUNT"] = "MetricSessionJobApplicationsCount";
    ApiMetric["METRIC_EFFECTIVE_PAGEVIEW_RATE_RANK"] = "MetricEffectivePageviewRateRank";
    ApiMetric["METRIC_SESSION_JOB_APPLY_CLICK_COUNT"] = "MetricSessionJobApplyClickCount";
    ApiMetric["METRIC_SESSION_ADD_TO_CART_CLICK_COUNT"] = "MetricSessionAddToCartClickCount";
    ApiMetric["METRIC_SESSION_ADD_TO_CART_CLICK_RATE"] = "MetricSessionAddToCartClickRate";
    ApiMetric["METRIC_PAGE_JOB_APPLICATIONS_COUNT"] = "MetricPageJobApplicationsCount";
    ApiMetric["METRIC_PAGE_JOB_APPLY_CLICK_COUNT"] = "MetricPageJobApplyClickCount";
    ApiMetric["METRIC_PAGE_ADD_TO_CART_CLICK_COUNT"] = "MetricPageAddToCartClickCount";
    ApiMetric["METRIC_PAGE_ADD_TO_CART_CLICK_RATE"] = "MetricPageAddToCartClickRate";
    ApiMetric["METRIC_PRODUCT_CLICK_THROUGH_COUNT"] = "MetricProductClickThroughCount";
    ApiMetric["METRIC_PRODUCT_IMPRESSION_COUNT"] = "MetricProductImpressionCount";
    ApiMetric["METRIC_RETURNING_CUSTOMER_COUNT"] = "MetricReturningCustomerCount";
    ApiMetric["METRIC_DESKTOP_SESSION_COUNT"] = "MetricDesktopSessionCount";
    ApiMetric["METRIC_MOBILE_SESSION_COUNT"] = "MetricMobileSessionCount";
    ApiMetric["METRIC_SALES_COMPLETION_RATE"] = "MetricSalesCompletionRate";
    ApiMetric["METRIC_ALL_TIME_PRODUCT_REVENUE"] = "MetricAllTimeProductRevenue";
    ApiMetric["METRIC_PRODUCT_IMPRESSION_RATE"] = "MetricProductImpressionRate";
    ApiMetric["METRIC_PRODUCT_CLICK_THROUGH_RATE"] = "MetricProductClickThroughRate";
    ApiMetric["METRIC_LANDING_PAGE_SESSION_MACRO_CONVERSION_COUNT"] = "MetricLandingPageSessionMacroConversionCount";
    ApiMetric["METRIC_LANDING_PAGE_SESSION_MACRO_CONVERSION_RATE"] = "MetricLandingPageSessionMacroConversionRate";
    ApiMetric["METRIC_NEW_CAMPAIGN_LEAD_COUNT"] = "MetricNewCampaignLeadCount";
    ApiMetric["METRIC_AVERAGE_CLS_SCORE"] = "MetricAverageCLSScore";
    ApiMetric["METRIC_AVERAGE_INP_SCORE"] = "MetricAverageINPScore";
    ApiMetric["METRIC_AVERAGE_LCP_SCORE"] = "MetricAverageLCPScore";
    ApiMetric["METRIC_AVERAGE_FCP_SCORE"] = "MetricAverageFCPScore";
    ApiMetric["METRIC_AVERAGE_CWV_PERFORMANCE_SCORE"] = "MetricAverageCWVPerformanceScore";
    ApiMetric["METRIC_BOUNCED_SESSION_COUNT"] = "MetricBouncedSessionCount";
    ApiMetric["METRIC_BOUNCE_RATE"] = "MetricBounceRate";
    ApiMetric["METRIC_EXIT_PAGEVIEW_COUNT"] = "MetricExitPageviewCount";
    ApiMetric["METRIC_EXIT_RATE"] = "MetricExitRate";
    ApiMetric["METRIC_SPEND"] = "MetricSpend";
    ApiMetric["METRIC_CPA"] = "MetricCPA";
    ApiMetric["METRIC_CAC"] = "MetricCAC";
    ApiMetric["METRIC_ECR"] = "MetricECR";
    ApiMetric["METRIC_CPNV"] = "MetricCPNV";
    ApiMetric["METRIC_LINEAR_PREDICTED30_DAY_LTV_REVENUE"] = "MetricLinearPredicted30DayLtvRevenue";
    ApiMetric["METRIC_LINEAR_PREDICTED60_DAY_LTV_REVENUE"] = "MetricLinearPredicted60DayLtvRevenue";
    ApiMetric["METRIC_LINEAR_PREDICTED90_DAY_LTV_REVENUE"] = "MetricLinearPredicted90DayLtvRevenue";
    ApiMetric["METRIC_PREDICTED_ROAS"] = "MetricPredictedROAS";
    ApiMetric["METRIC_ATTRIBUTION_FIRST_CLICK_REVENUE"] = "MetricAttributionFirstClickRevenue";
    ApiMetric["METRIC_ATTRIBUTION_LAST_CLICK_REVENUE"] = "MetricAttributionLastClickRevenue";
    ApiMetric["METRIC_ATTRIBUTION_LINEAR_REVENUE"] = "MetricAttributionLinearRevenue";
    ApiMetric["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_REVENUE"] = "MetricAttributionLastNonDirectClickRevenue";
    ApiMetric["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_REVENUE"] = "MetricAttributionLastPositionClickRevenue";
    ApiMetric["METRIC_ATTRIBUTION_IMPACTED_CLICK_REVENUE"] = "MetricAttributionImpactedClickRevenue";
    ApiMetric["METRIC_ATTRIBUTION_FIRST_CLICK_SALES_COUNT"] = "MetricAttributionFirstClickSalesCount";
    ApiMetric["METRIC_ATTRIBUTION_LAST_CLICK_SALES_COUNT"] = "MetricAttributionLastClickSalesCount";
    ApiMetric["METRIC_ATTRIBUTION_LINEAR_SALES_COUNT"] = "MetricAttributionLinearSalesCount";
    ApiMetric["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_SALES_COUNT"] = "MetricAttributionLastNonDirectClickSalesCount";
    ApiMetric["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_SALES_COUNT"] = "MetricAttributionLastPositionClickSalesCount";
    ApiMetric["METRIC_ATTRIBUTION_IMPACTED_CLICK_SALES_COUNT"] = "MetricAttributionImpactedClickSalesCount";
    ApiMetric["METRIC_ATTRIBUTION_FIRST_CLICK_ROAS"] = "MetricAttributionFirstClickROAS";
    ApiMetric["METRIC_ATTRIBUTION_LAST_CLICK_ROAS"] = "MetricAttributionLastClickROAS";
    ApiMetric["METRIC_ATTRIBUTION_LINEAR_ROAS"] = "MetricAttributionLinearROAS";
    ApiMetric["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROAS"] = "MetricAttributionLastNonDirectClickROAS";
    ApiMetric["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROAS"] = "MetricAttributionLastPositionClickROAS";
    ApiMetric["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROAS"] = "MetricAttributionImpactedClickROAS";
    ApiMetric["METRIC_ATTRIBUTION_FIRST_CLICK_LTV"] = "MetricAttributionFirstClickLTV";
    ApiMetric["METRIC_ATTRIBUTION_LAST_CLICK_LTV"] = "MetricAttributionLastClickLTV";
    ApiMetric["METRIC_ATTRIBUTION_LINEAR_LTV"] = "MetricAttributionLinearLTV";
    ApiMetric["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_LTV"] = "MetricAttributionLastNonDirectClickLTV";
    ApiMetric["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_LTV"] = "MetricAttributionLastPositionClickLTV";
    ApiMetric["METRIC_ATTRIBUTION_IMPACTED_CLICK_LTV"] = "MetricAttributionImpactedClickLTV";
    ApiMetric["METRIC_ATTRIBUTION_FIRST_CLICK_ROASLTV"] = "MetricAttributionFirstClickROASLTV";
    ApiMetric["METRIC_ATTRIBUTION_LAST_CLICK_ROASLTV"] = "MetricAttributionLastClickROASLTV";
    ApiMetric["METRIC_ATTRIBUTION_LINEAR_ROASLTV"] = "MetricAttributionLinearROASLTV";
    ApiMetric["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROASLTV"] = "MetricAttributionLastNonDirectClickROASLTV";
    ApiMetric["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROASLTV"] = "MetricAttributionLastPositionClickROASLTV";
    ApiMetric["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROASLTV"] = "MetricAttributionImpactedClickROASLTV";
    ApiMetric["METRIC_ATTRIBUTION_FIRST_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionFirstClickMacroConversionCount";
    ApiMetric["METRIC_ATTRIBUTION_LAST_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionLastClickMacroConversionCount";
    ApiMetric["METRIC_ATTRIBUTION_LINEAR_MACRO_CONVERSION_COUNT"] = "MetricAttributionLinearMacroConversionCount";
    ApiMetric["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionLastNonDirectClickMacroConversionCount";
    ApiMetric["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionLastPositionClickMacroConversionCount";
    ApiMetric["METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionImpactedClickMacroConversionCount";
    ApiMetric["METRIC_SESSION_ALL_INCLUSIVE_COST"] = "MetricSessionAllInclusiveCost";
    ApiMetric["METRIC_SESSION_CONTRIBUTION_MARGIN"] = "MetricSessionContributionMargin";
    ApiMetric["METRIC_SESSION_EFFICIENCY_RATION"] = "MetricSessionEfficiencyRation";
    ApiMetric["METRIC_PREDICTED_ROI"] = "MetricPredictedROI";
    ApiMetric["METRIC_ATTRIBUTION_FIRST_CLICK_ROI"] = "MetricAttributionFirstClickROI";
    ApiMetric["METRIC_ATTRIBUTION_LAST_CLICK_ROI"] = "MetricAttributionLastClickROI";
    ApiMetric["METRIC_ATTRIBUTION_LINEAR_ROI"] = "MetricAttributionLinearROI";
    ApiMetric["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROI"] = "MetricAttributionLastNonDirectClickROI";
    ApiMetric["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROI"] = "MetricAttributionLastPositionClickROI";
    ApiMetric["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROI"] = "MetricAttributionImpactedClickROI";
    ApiMetric["METRIC_ATTRIBUTION_FIRST_CLICK_ROILTV"] = "MetricAttributionFirstClickROILTV";
    ApiMetric["METRIC_ATTRIBUTION_LAST_CLICK_ROILTV"] = "MetricAttributionLastClickROILTV";
    ApiMetric["METRIC_ATTRIBUTION_LINEAR_ROILTV"] = "MetricAttributionLinearROILTV";
    ApiMetric["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROILTV"] = "MetricAttributionLastNonDirectClickROILTV";
    ApiMetric["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROILTV"] = "MetricAttributionLastPositionClickROILTV";
    ApiMetric["METRIC_ATTRIBUTION_IMPACTED_CLICK_ROILTV"] = "MetricAttributionImpactedClickROILTV";
    ApiMetric["METRIC_LTVCAC_RATIO"] = "MetricLTVCACRatio";
    ApiMetric["METRIC_EXISTING_CUSTOMER_REVENUE_GROWTH_RATE"] = "MetricExistingCustomerRevenueGrowthRate";
    ApiMetric["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_REVENUE"] = "MetricAttributionFirstNonDirectClickRevenue";
    ApiMetric["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_SALES_COUNT"] = "MetricAttributionFirstNonDirectClickSalesCount";
    ApiMetric["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROAS"] = "MetricAttributionFirstNonDirectClickROAS";
    ApiMetric["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_LTV"] = "MetricAttributionFirstNonDirectClickLTV";
    ApiMetric["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROASLTV"] = "MetricAttributionFirstNonDirectClickROASLTV";
    ApiMetric["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_MACRO_CONVERSION_COUNT"] = "MetricAttributionFirstNonDirectClickMacroConversionCount";
    ApiMetric["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROI"] = "MetricAttributionFirstNonDirectClickROI";
    ApiMetric["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_ROILTV"] = "MetricAttributionFirstNonDirectClickROILTV";
    ApiMetric["METRIC_RANK"] = "MetricRank";
    ApiMetric["METRIC_PRODUCT_GATEWAY_LTV"] = "MetricProductGatewayLTV";
    ApiMetric["METRIC_ATTRIBUTION_FIRST_CLICK_PROFIT"] = "MetricAttributionFirstClickProfit";
    ApiMetric["METRIC_ATTRIBUTION_LAST_CLICK_PROFIT"] = "MetricAttributionLastClickProfit";
    ApiMetric["METRIC_ATTRIBUTION_LINEAR_PROFIT"] = "MetricAttributionLinearProfit";
    ApiMetric["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_PROFIT"] = "MetricAttributionLastNonDirectClickProfit";
    ApiMetric["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_PROFIT"] = "MetricAttributionLastPositionClickProfit";
    ApiMetric["METRIC_ATTRIBUTION_IMPACTED_CLICK_PROFIT"] = "MetricAttributionImpactedClickProfit";
    ApiMetric["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_PROFIT"] = "MetricAttributionFirstNonDirectClickProfit";
    ApiMetric["METRIC_ATTRIBUTION_FIRST_CLICK_LTV_PROFIT"] = "MetricAttributionFirstClickLTVProfit";
    ApiMetric["METRIC_ATTRIBUTION_LAST_CLICK_LTV_PROFIT"] = "MetricAttributionLastClickLTVProfit";
    ApiMetric["METRIC_ATTRIBUTION_LINEAR_LTV_PROFIT"] = "MetricAttributionLinearLTVProfit";
    ApiMetric["METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_LTV_PROFIT"] = "MetricAttributionLastNonDirectClickLTVProfit";
    ApiMetric["METRIC_ATTRIBUTION_LAST_POSITION_CLICK_LTV_PROFIT"] = "MetricAttributionLastPositionClickLTVProfit";
    ApiMetric["METRIC_ATTRIBUTION_IMPACTED_CLICK_LTV_PROFIT"] = "MetricAttributionImpactedClickLTVProfit";
    ApiMetric["METRIC_ATTRIBUTION_FIRST_NON_DIRECT_CLICK_LTV_PROFIT"] = "MetricAttributionFirstNonDirectClickLTVProfit";
    ApiMetric["METRIC_SESSION_PROFIT"] = "MetricSessionProfit";
    ApiMetric["METRIC_JOB_IMPRESSION_COUNT"] = "MetricJobImpressionCount";
    ApiMetric["METRIC_JOB_IMPRESSION_RATE"] = "MetricJobImpressionRate";
    ApiMetric["METRIC_VISIT_TO_APPLICATION_RATE"] = "MetricVisitToApplicationRate";
    ApiMetric["METRIC_PAGE_JOB_APPLY_CLICK_RATE"] = "MetricPageJobApplyClickRate";
    ApiMetric["METRIC_PAGE_JOB_APPLICATIONS_RATE"] = "MetricPageJobApplicationsRate";
    ApiMetric["METRIC_JOB_CLICK_THROUGH_COUNT"] = "MetricJobClickThroughCount";
    ApiMetric["METRIC_JOB_CLICK_THROUGH_RATE"] = "MetricJobClickThroughRate";
    ApiMetric["METRIC_ACTIVE_LEADS_LAST_SEVEN_DAYS"] = "MetricActiveLeadsLastSevenDays";
    ApiMetric["METRIC_ACTIVE_LEADS_LAST_THIRTY_DAYS"] = "MetricActiveLeadsLastThirtyDays";
    ApiMetric["METRIC_AVERAGE_LEAD_SCORE"] = "MetricAverageLeadScore";
    ApiMetric["METRIC_LEADS_COUNT_WITH_LEADS_SCORE"] = "MetricLeadsCountWithLeadsScore";
    ApiMetric["METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_RATE"] = "MetricAttributionImpactedClickMacroConversionRate";
    ApiMetric["METRIC_PUBLISHER_CLICKS"] = "MetricPublisherClicks";
    ApiMetric["METRIC_PUBLISHER_CONVERSIONS"] = "MetricPublisherConversions";
    ApiMetric["METRIC_PUBLISHER_ALL_CONVERSIONS"] = "MetricPublisherAllConversions";
    ApiMetric["METRIC_PUBLISHER_CPC"] = "MetricPublisherCPC";
    ApiMetric["METRIC_TO_NEW_CUSTOMER_RATE"] = "MetricToNewCustomerRate";
    ApiMetric["METRIC_RETURNING_CUSTOMER_SESSION_COUNT"] = "MetricReturningCustomerSessionCount";
    ApiMetric["METRIC_RETURNING_CUSTOMER_PAGE_MACRO_CONVERSION_COUNT"] = "MetricReturningCustomerPageMacroConversionCount";
    ApiMetric["METRIC_RETURNING_CUSTOMER_SESSION_MACRO_CONVERSION_COUNT"] = "MetricReturningCustomerSessionMacroConversionCount";
    ApiMetric["METRIC_RETURNING_CUSTOMER_SESSION_MACRO_CONVERSION_RATE"] = "MetricReturningCustomerSessionMacroConversionRate";
    ApiMetric["METRIC_RETURNING_CUSTOMER_RATE"] = "MetricReturningCustomerRate";
    ApiMetric["METRIC_PUBLISHER_IMPRESSIONS"] = "MetricPublisherImpressions";
    ApiMetric["METRIC_PUBLISHER_CTR"] = "MetricPublisherCTR";
    ApiMetric["METRIC_CHECKOUT_START_COUNT"] = "MetricCheckoutStartCount";
    ApiMetric["METRIC_CHECKOUT_START_RATE"] = "MetricCheckoutStartRate";
    ApiMetric["METRIC_CHECKOUT_COMPLETION_RATE"] = "MetricCheckoutCompletionRate";
    ApiMetric["METRIC_PAID_SESSION_COUNT"] = "MetricPaidSessionCount";
    ApiMetric["METRIC_ORGANIC_SESSION_COUNT"] = "MetricOrganicSessionCount";
    ApiMetric["METRIC_PRODUCT_PAGE_EFFECTIVENESS_RATE"] = "MetricProductPageEffectivenessRate";
    ApiMetric["METRIC_CART_ABANDONMENT_RATE"] = "MetricCartAbandonmentRate";
    ApiMetric["METRIC_CHECKOUT_ABANDONMENT_RATE"] = "MetricCheckoutAbandonmentRate";
})(ApiMetric || (ApiMetric = {}));
export function ApiMetricFromJSON(json) {
    return ApiMetricFromJSONTyped(json, false);
}
export function ApiMetricFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiMetricToJSON(value) {
    return value;
}
