import {
  ApiAuthPayload,
  ApiService,
  SyncServiceAuthServiceEnum,
  SyncServiceUrlServiceEnum,
} from "@incendium/api";
import { ElectricalServices } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import { syncService } from "Apis";
import { motion } from "framer-motion";
import { useNotification } from "Hooks";
import { TMappedApiUserOauthClient } from "Hooks/useSharedState";
import produce from "immer";
import { CallbackOrVal } from "Interfaces";
import { useCallback, useEffect, useState } from "react";
import NewWindow from "react-new-window";

interface IConnectButtonProps {
  service: ApiService;
  syncClients: TMappedApiUserOauthClient;
  setSyncClients: (val: CallbackOrVal<TMappedApiUserOauthClient>) => void;
  oauthClientId?: number;
}

function ConnectButton({
  service,
  syncClients,
  setSyncClients,
  oauthClientId,
}: IConnectButtonProps) {
  const redirect = `${window.location.protocol}//${window.location.host}/oauth-sync`;
  const [authURL, setAuthURL] = useState<string | null>(null);
  const [code, setCode] = useState<string | null>(null);
  const [authenticating, setAuthenticating] = useState(false);
  const { showSuccessNotification, showErrorNotification } = useNotification();

  const handleClick = useCallback(async () => {
    const res = await syncService.syncServiceUrl({
      service: service as unknown as SyncServiceUrlServiceEnum,
      redirectUrl: redirect,
    });

    if (res.authUrl) {
      setAuthURL(res.authUrl);
    }
  }, [service, redirect]);

  const onOpenAuth = useCallback(
    (win) => {
      let pollTimer = window.setInterval(function () {
        try {
          if (win?.document.URL.indexOf(redirect) !== -1) {
            const urlSearchParams = new URL(win?.document.URL).searchParams;
            setCode(urlSearchParams.get("code"));
            window.clearInterval(pollTimer);
            setAuthURL(null);
          }
        } catch (error) {}
      }, 500);
    },
    [redirect]
  );

  const authClient = useCallback(
    async (code: string) => {
      setAuthenticating(true);
      try {
        const payload: ApiAuthPayload = {
          code,
          redirectUrl: redirect,
        };
        // oauthClientId is used to reconnect to existing oauth client
        if (oauthClientId) {
          payload.oauthClientId = oauthClientId;
        }
        const res = await syncService.syncServiceAuth({
          service: service as unknown as SyncServiceAuthServiceEnum,
          payload,
        });
        if (res) {
          setSyncClients(
            produce(syncClients, (draft) => {
              draft[res.service as ApiService] = [
                ...draft[res.service as ApiService],
                res,
              ];
            })
          );
          showSuccessNotification(`${service} connected`);
        }
      } catch (error) {
        showErrorNotification(`Failed to connect ${service}`);
      }
    },
    [
      service,
      redirect,
      syncClients,
      setSyncClients,
      showSuccessNotification,
      showErrorNotification,
      oauthClientId,
    ]
  );

  useEffect(() => {
    if (!code || authenticating) {
      return;
    }

    authClient(code);
  }, [code, authClient, authenticating]);

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        startIcon={
          !!authURL ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <ElectricalServices />
          )
        }
        onClick={handleClick}
        disabled={!!authURL}
        component={motion.div}
        initial={{
          opacity: 0,
          scale: 0.9,
        }}
        animate={{
          opacity: 1,
          scale: 1,
        }}
      >
        {oauthClientId && <>Re </>}connect
      </Button>
      {authURL && <NewWindow name="auth" url={authURL} onOpen={onOpenAuth} />}
    </>
  );
}

export default ConnectButton;
