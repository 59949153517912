import {
  ApiConversion,
  ApiConversionType,
  ApiProjectDefault,
  ApiReservedConversion,
} from "@incendium/api";
import { Delete, Edit, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Portal,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { locationService } from "Apis";
import { LocationTabs } from "Components/TagPages";
import { SpacedLinkButton } from "Components/UI/SpacedList";
import { TypographyUnlined } from "Components/UI/StyledTypograpy";
import { cell1Icon } from "consts";
import { groupBy } from "Helpers/arrays";
import { friendlyDateTime } from "Helpers/dates";
import { formatEnumVal } from "Helpers/enumToText";
import { useSelectedProject } from "Hooks";
import { useConversions } from "Hooks/useConversions";
import { useDeleteConfirmation } from "Hooks/useDeleteConfirmation";
import useProjectTimezone from "Hooks/useProjectTimezone";
import produce from "immer";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { usePrevious } from "react-use";

const StyledAccordian = styled(Accordion)(({ theme }) => ({
  background: "rgba(255,255,255,0.45)",
  "&.Mui-expanded": {
    borderRadius: theme.shape.borderRadius * 2,
    overflow: "hidden",
  },
  ":first-of-type": {
    borderTopLeftRadius: theme.shape.borderRadius * 2,
    borderTopRightRadius: theme.shape.borderRadius * 2,
    overflow: "hidden",
  },
  ":last-of-type": {
    borderBottomLeftRadius: theme.shape.borderRadius * 2,
    borderBottomRightRadius: theme.shape.borderRadius * 2,
    overflow: "hidden",
  },
}));

const Row = ({
  name,
  groupedConversions,
  expandAll,
  onDelete,
  onEdit,
  onNew,
}: {
  name: string;
  groupedConversions: Record<string, ApiConversion[]>;
  expandAll: boolean;
  onDelete: (conversion: ApiConversion) => void;
  onEdit: (conversion: ApiConversion) => void;
  onNew: (locationId: number) => void;
}) => {
  const theme = useTheme();
  const preExpandAll = usePrevious(expandAll);
  const [expanded, setExpanded] = useState(false);
  const timezone = useProjectTimezone();

  useEffect(() => {
    if (expandAll !== preExpandAll) {
      setExpanded(expandAll);
    }
  }, [expandAll, preExpandAll]);

  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };
  return (
    <StyledAccordian
      elevation={0}
      defaultExpanded={expandAll}
      expanded={expanded}
      onChange={handleChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMore sx={{ color: "white" }} />}
        sx={{
          background: theme.palette.secondary.main,
          color: "white",
        }}
      >
        <Link
          color={"inherit"}
          variant="subtitle2"
          onClick={() => onNew(groupedConversions[name][0].locationId || 0)}
        >
          Location - {name}
        </Link>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <Table>
          <TableBody>
            {groupedConversions[name].map((conv) => (
              <TableRow key={conv.id}>
                <TableCell>
                  <SpacedLinkButton onClick={() => onEdit(conv)}>
                    {conv.name || ""}
                  </SpacedLinkButton>

                  {conv.reserved !== ApiReservedConversion.NOT_RESERVED && (
                    <Typography variant="body2">
                      (Reserved : {formatEnumVal(conv.reserved || "")})
                    </Typography>
                  )}
                </TableCell>
                <TableCell width={200}>
                  {conv.type === ApiConversionType.MACRO
                    ? "Conversion"
                    : "Interaction"}
                </TableCell>

                <TableCell width={250}>
                  Last Fired :
                  {conv.lastFiredAt
                    ? friendlyDateTime(conv.lastFiredAt, timezone)
                    : "-"}
                </TableCell>
                <TableCell
                  width={cell1Icon}
                  size="small"
                  align="right"
                  color="primary"
                >
                  {conv._default !== ApiProjectDefault.UNSPECIFIED ? (
                    <Tooltip
                      arrow
                      title="This is a system default conversion that cannot be deleted"
                    >
                      <div>
                        <IconButton disabled size="small" color="secondary">
                          <Delete />
                        </IconButton>
                      </div>
                    </Tooltip>
                  ) : (
                    <IconButton
                      size="small"
                      color="secondary"
                      onClick={() => onDelete(conv)}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </StyledAccordian>
  );
};

function ConversionsPage() {
  const { selectedClient } = useSelectedProject();
  const { conversions, setConversions } = useConversions();
  const handleDelete = useDeleteConfirmation();
  const history = useHistory();

  const [expandAll, setExpandAll] = useState(true);

  const groupedConversions = useMemo(() => {
    return groupBy(conversions, (v) => v.location?.name || "");
  }, [conversions]);

  const onDelete = useCallback(
    (conversion: ApiConversion) => {
      if (!conversion.id) {
        return;
      }
      handleDelete({
        title: `Are you sure you want to delete this conversion`,
        body: `This action can not be undone`,
        callback: async () => {
          await locationService.locationServiceDeleteConversion({
            locationId: conversion.locationId as number,
            projectId: conversion.projectId as number,
            clientId: selectedClient?.id as number,
            conversionId: conversion.id as number,
          });
          setConversions(
            produce(conversions, (draft) => {
              const idx = draft.findIndex((d) => d.id === conversion.id);
              if (idx >= 0) {
                draft.splice(idx, 1);
              }
            })
          );
          return "Conversion deleted";
        },
      });
    },
    [selectedClient, setConversions, conversions, handleDelete]
  );

  const onNew = useCallback(
    (locationId: number) => {
      history.push(
        `../train/locations/${locationId}/location-tagging?tab=${
          "Conversion Tab" as LocationTabs
        }`
      );
    },
    [history]
  );
  const onEdit = useCallback(
    (conversion: ApiConversion) => {
      history.push(
        `../train/locations/${conversion.locationId}/location-tagging?tab=${
          "Conversion Tab" as LocationTabs
        }`,
        conversion
      );
    },
    [history]
  );

  return (
    <>
      <Portal container={() => document.getElementById("pageTitle")}>
        <Typography variant="h1">Conversions</Typography>
      </Portal>
      <Grid
        spacing={4}
        container
        mb={5}
        sx={{ minHeight: "100%", position: "relative" }}
      >
        <Grid item xs={5}>
          <Box
            sx={{
              position: "sticky",
              top: "50%",
              transform: "translateY(-50%)",
              width: "100%",
            }}
            pr={2}
          >
            <TypographyUnlined
              variant="h2"
              color={"primary"}
              mb={5}
              display="inline-block"
            >
              View conversions
            </TypographyUnlined>
            <Typography variant="body1" color={"secondary"}>
              Click The location name to go to the conversions tab for that
              location, here you can add a new or update an existing
              conversions.
            </Typography>
            <Typography variant="body1" color={"secondary"}>
              Click <Edit fontSize="small" /> to Update the conversion from
              within it's location.
            </Typography>
            <Typography variant="body1" color={"secondary"} mb={4}>
              Click <Delete fontSize="small" /> to remove conversion.
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={expandAll}
                  onChange={(e, checked) => setExpandAll(checked)}
                />
              }
              label="Expand All"
            />
          </Box>
        </Grid>
        <Grid item xs={7}>
          {Object.keys(groupedConversions)
            .sort((a, b) => a.localeCompare(b))
            .map((key) => (
              <Row
                key={key}
                name={key}
                groupedConversions={groupedConversions}
                expandAll={expandAll}
                onDelete={onDelete}
                onEdit={onEdit}
                onNew={onNew}
              />
            ))}
        </Grid>
      </Grid>
    </>
  );
}

export default ConversionsPage;
