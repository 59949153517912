import { ApiAttributionClicksMetric } from "@incendium/api";
import { TAttributionClickMetricMap } from "features/attributionAnalytics/types";
import { formatNumber } from "Helpers/numbers";

const metricMap: {
  [key in ApiAttributionClicksMetric]?: TAttributionClickMetricMap;
} = {
  [ApiAttributionClicksMetric.ATTRIBUTION_CLICKS_METRIC_CLICKS]: {
    name: "Publisher Clicks",
    description: "Number of Clicks registered with publisher",
  },
  [ApiAttributionClicksMetric.ATTRIBUTION_CLICKS_METRIC_AVG_CPC]: {
    name: "CPC's",
    description: "Average CPC",
    format: (n) => `$${formatNumber(n / 100)}`,
  },
  [ApiAttributionClicksMetric.ATTRIBUTION_CLICKS_METRIC_SPEND]: {
    name: "Total Spend",
    description: "Total Spend",
    format: (n) => `$${formatNumber(n / 100)}`,
  },
  [ApiAttributionClicksMetric.ATTRIBUTION_CLICKS_METRIC_CONVERSIONS]: {
    name: "Conversions",
    description:
      "Conversions (Data will be excluded from the Conversions report column for any conversion goal with the ExcludeFromBidding property set to true)",
  },
  [ApiAttributionClicksMetric.ATTRIBUTION_CLICKS_METRIC_ALL_CONVERSIONS]: {
    name: "All Conversions",
    description:
      "Conversions (Including Conversions with the ExcludeFromBidding property set to true)",
  },
};

export const formatAttributionMetric = (
  metric: ApiAttributionClicksMetric,
  value?: number
) => {
  const m =
    metricMap[
      ((metric as string) || "").split("\\")[0] as ApiAttributionClicksMetric
    ];
  return m && m.format ? m.format(value || 0) : formatNumber(value || 0);
};
