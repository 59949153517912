import { Box } from "@mui/material";
import { ChartLegend, useChartData } from "features/analytics";
import StyledComposedChart from "Components/StyledComposedChart/StyledComposedChart";
import { groupBy } from "Helpers/arrays";
import { formatFloat } from "Helpers/numbers";
import moment from "moment";
import { useMemo } from "react";
import { Tooltip } from "recharts";
import AnalyticsCard, {
  AnalyticsCardLegendContainer,
  AnalyticsCardTitle,
  AnalyticsCardTitleWrapper,
} from "../../../Components/Analytics/AnalyticsCard";
import { friendlyDate } from "Helpers/dates";
import { ApiChartTemplate, ApiDimension, ApiMetric } from "@incendium/api";
import { useSelectedProject } from "Hooks";
import { useFromToContext } from "Providers/FromToProvider";
import { TChartData } from "Interfaces";

interface IImpressionsByDayChartProps {
  dimension: ApiDimension;
  impressionField: string;
  type: "product" | "job";
  selected: string;
}

const ImpressionsByDayChart = ({
  dimension,
  impressionField,
  type,
  selected,
}: IImpressionsByDayChartProps) => {
  const { selectedProject } = useSelectedProject();
  const { chartOutput } = useFromToContext();

  const chart = useMemo(
    () => ({
      name: "",
      dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY, dimension],
      template: ApiChartTemplate.TREND,
      yAxisKeys: [
        {
          fields: [impressionField],
        },
      ],
      attributes: [],
      includeEmpty: false,
    }),
    [dimension, impressionField]
  );

  const { chartData } = useChartData(selectedProject, chart, chartOutput);

  const allDates = useMemo(() => {
    if (!chartData?.data) {
      return [];
    }
    let current = moment(chartData.data[0]?.name);

    const max = moment(chartData.data[chartData.data.length - 1]?.name);
    const dates: moment.Moment[] = [];
    while (current.isBefore(max)) {
      dates.push(current);
      current = current.clone().add(1, "day");
    }
    return dates;
  }, [chartData]);

  const average = useMemo(() => {
    const gg = groupBy(chartData?.data || [], "name");
    return Object.keys(gg).map((k) => {
      const arr = gg[k];
      return (arr || []).reduce((acc: any, v, i, a) => {
        const { name, ...rest } = v;
        acc["name"] = k;
        for (const key in rest) {
          if (!acc[key]) {
            acc[key] = 0;
          }
          acc[key] = acc[key] + Number(rest[key]) / a.length;
        }
        return acc;
      }, {});
    });
  }, [chartData]);

  const data: TChartData[] | undefined = useMemo(() => {
    if (!chartData?.data) {
      return undefined;
    }
    return chartData?.data.filter((d) => d[dimension] === selected);
  }, [chartData, dimension, selected]);

  const parsedChartData = useMemo(() => {
    if (!data || !average) {
      return [];
    }

    return allDates.map((dt) => {
      const c = (data || []).find((c) => moment(c.name).isSame(dt));
      const a = average.find((c) => moment(c.name).isSame(dt));
      return {
        name: friendlyDate(dt),
        current: c ? Number(c[impressionField as ApiMetric]) : 0,
        average: a ? Number(a[impressionField as ApiMetric]) : 0,
      };
    });
  }, [allDates, data, average, impressionField]);

  return (
    <AnalyticsCard>
      <AnalyticsCardTitleWrapper>
        <Box>
          <AnalyticsCardTitle title={`Daily ${type} impressions`} />
          <AnalyticsCardLegendContainer>
            <ChartLegend
              items={[
                `this ${type} ${
                  dimension.toString().includes("category") ? "category" : ""
                }`,
                "site average",
              ]}
            />
          </AnalyticsCardLegendContainer>
        </Box>
      </AnalyticsCardTitleWrapper>

      <StyledComposedChart
        noReOrder
        data={parsedChartData}
        yAxisKeys={[
          {
            key: "l",
            fields: ["average", "current"],
          },
        ]}
        noLegend
      >
        <Tooltip
          formatter={(value, key) => {
            return [
              formatFloat(value as number),
              key === "average"
                ? "site average"
                : `this ${type} ${
                    dimension.toString().includes("category") ? "category" : ""
                  }`,
            ];
          }}
        />
      </StyledComposedChart>
    </AnalyticsCard>
  );
};

export default ImpressionsByDayChart;
