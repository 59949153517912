/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiLocationFromJSON, ApiLocationToJSON, } from './ApiLocation';
import { ApiPageviewMetaFromJSON, ApiPageviewMetaToJSON, } from './ApiPageviewMeta';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
import { ApiReservedPageviewMetaFromJSON, ApiReservedPageviewMetaToJSON, } from './ApiReservedPageviewMeta';
export function ApiPageviewMetaTaggerFromJSON(json) {
    return ApiPageviewMetaTaggerFromJSONTyped(json, false);
}
export function ApiPageviewMetaTaggerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'reserved': !exists(json, 'reserved') ? undefined : ApiReservedPageviewMetaFromJSON(json['reserved']),
        'selector': !exists(json, 'selector') ? undefined : json['selector'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'location': !exists(json, 'location') ? undefined : ApiLocationFromJSON(json['location']),
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'metas': !exists(json, 'metas') ? undefined : (json['metas'].map(ApiPageviewMetaFromJSON)),
    };
}
export function ApiPageviewMetaTaggerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'reserved': ApiReservedPageviewMetaToJSON(value.reserved),
        'selector': value.selector,
        'value': value.value,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'location': ApiLocationToJSON(value.location),
        'locationId': value.locationId,
        'project': ApiProjectToJSON(value.project),
        'projectId': value.projectId,
        'metas': value.metas === undefined ? undefined : (value.metas.map(ApiPageviewMetaToJSON)),
    };
}
