import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
} from "@incendium/api";
import { Box, Portal } from "@mui/material";
import { useSelectedProject } from "Hooks";
import { useProducts } from "Hooks/useProducts";
import {
  AnalyticsCard,
  ProductBrandsDropdown,
  ProductSinglePage,
} from "features/analytics";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import withDatepicker from "HoC/withDatepicker";
import { PageActionPreSlot } from "consts";

function ProductBrandReportPage() {
  const history = useHistory();
  const { products } = useProducts();
  const { selectedClient, selectedProject } = useSelectedProject();
  const [selectedProductBrand, setSelectedProductBrand] = useState<
    string | undefined
  >();

  const { state } = useLocation<{
    selectedProductBrand: string;
  }>();

  useEffect(() => {
    if (state) {
      setSelectedProductBrand(state.selectedProductBrand);
    }
  }, [state]);

  const onClick = (productName: string) => {
    history.push(
      `/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/product-deep-dive`,
      {
        selectedProductId: products.find((p) => p.name === productName)?.id,
      }
    );
  };

  return (
    <>
      <Portal container={() => document.getElementById(PageActionPreSlot)}>
        <ProductBrandsDropdown
          sx={{ minWidth: 230 }}
          fullWidth
          size="small"
          variant="outlined"
          label="Select Product Brand"
          value={selectedProductBrand}
          onChange={(n) => {
            setSelectedProductBrand(n);
          }}
        />
      </Portal>
      <ProductSinglePage
        selectedProductBrand={selectedProductBrand}
        setSelectedProductBrand={setSelectedProductBrand}
        dimension={ApiDimension.DIMENSION_PRODUCT_BRAND}
      />
      {selectedProductBrand && (
        <Box my={3} sx={{ height: 436 }}>
          <AnalyticsCard
            onClick={{
              [ApiDimension.DIMENSION_PRODUCT_NAME]: onClick,
            }}
            chart={{
              name: ``,
              dimension: [ApiDimension.DIMENSION_PRODUCT_NAME],
              template: ApiChartTemplate.TABLE,
              type: ApiChartType.TABLE,
              attributes: [
                {
                  key: ApiDimension.DIMENSION_PRODUCT_BRAND,
                  value: selectedProductBrand,
                },
              ],
              comparison: true,
              yAxisKeys: [
                {
                  key: "l",
                  fields: [
                    ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                    ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_COUNT,
                    ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_RATE,
                    ApiMetric.METRIC_PRODUCT_REVENUE,
                    ApiMetric.METRIC_PRODUCT_IMPRESSION_COUNT,
                    ApiMetric.METRIC_PRODUCT_IMPRESSION_RATE,
                    ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_COUNT,
                    ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_RATE,
                    ApiMetric.METRIC_SESSION_SALES_COUNT, // needs checking
                    ApiMetric.METRIC_SALES_COMPLETION_RATE,
                    ApiMetric.METRIC_SALES_REVENUE,
                    ApiMetric.METRIC_VISIT_TO_PURCHASE_RATE,
                    ApiMetric.METRIC_PRODUCT_GATEWAY_LTV,
                  ],
                },
              ],
              displayOptions: {
                noFilters: true,
                noTitle: true,
              },
            }}
          />
        </Box>
      )}
    </>
  );
}

export default withDatepicker(ProductBrandReportPage);
