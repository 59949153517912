/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiCrawlerFromJSON, ApiCrawlerToJSON, } from './ApiCrawler';
import { ApiDataFromJSON, ApiDataToJSON, } from './ApiData';
export function ApiUserAgentFromJSON(json) {
    return ApiUserAgentFromJSONTyped(json, false);
}
export function ApiUserAgentFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userAgent': !exists(json, 'userAgent') ? undefined : json['userAgent'],
        'browser': !exists(json, 'browser') ? undefined : json['browser'],
        'isMobile': !exists(json, 'isMobile') ? undefined : json['isMobile'],
        'os': !exists(json, 'os') ? undefined : json['os'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'data': !exists(json, 'data') ? undefined : ApiDataFromJSON(json['data']),
        'crawler': !exists(json, 'crawler') ? undefined : ApiCrawlerFromJSON(json['crawler']),
        'crawlerId': !exists(json, 'crawlerId') ? undefined : json['crawlerId'],
    };
}
export function ApiUserAgentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'userAgent': value.userAgent,
        'browser': value.browser,
        'isMobile': value.isMobile,
        'os': value.os,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'data': ApiDataToJSON(value.data),
        'crawler': ApiCrawlerToJSON(value.crawler),
        'crawlerId': value.crawlerId,
    };
}
