import { Portal, Typography } from "@mui/material";
import { PageTitle } from "consts";

export interface IWithPagetitleProps {
  title: string;
  subTitle?: string;
}

const withPagetitle = <T extends IWithPagetitleProps>(
  Comp: React.ComponentType<T>
) => {
  return (props: T) => {
    const { title, subTitle } = props;
    return (
      <>
        <Portal container={() => document.getElementById(PageTitle)}>
          <Typography variant="h1">{title}</Typography>
          {subTitle && (
            <Typography variant="subtitle1" fontWeight={500}>
              {subTitle}
            </Typography>
          )}
        </Portal>
        <Comp {...(props as T)} />
      </>
    );
  };
};

export default withPagetitle;
