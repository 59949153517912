import { Box, Stack, Switch, Typography } from "@mui/material";
import { ChartLegend, formatMetric, useChartData } from "features/analytics";
import { StatCardRound, StatCardRoundTypography } from "Components/UI/StatCard";
import StyledBarChart from "Components/StyledBarChart/StyledBarChart";
import { TChartData } from "Interfaces";
import {
  formatPercentage,
  percentageChange,
  percentageOf,
} from "Helpers/percentage";
import { useSelectedProject } from "Hooks";
import { useFromToContext } from "Providers/FromToProvider";
import { useMemo } from "react";
import { Tooltip } from "recharts";

import { ApiDimension, ApiMetric } from "@incendium/api";
import AnalyticsCard, {
  AnalyticsCardTitle,
  AnalyticsCardTitleWrapper,
} from "Components/Analytics/AnalyticsCard";
import { AnalyticsSpacing } from "consts";
import { StyledFlexBox } from "Components/UI/StylesFlexBox";
import { useLocalstorageState } from "Hooks/useLocalstorageState";

function ProductTopCategoryBrandComp() {
  const { selectedProject } = useSelectedProject();
  const { chartOutput, chartComparisonOutput } = useFromToContext();
  const [mode, setMode] = useLocalstorageState<"category" | "brand">(
    "ProductTopCategoryBrandComp-mode",
    "category"
  );

  const chart = useMemo(
    () => ({
      name: `Top Product ${
        mode === "category" ? "Categories" : "Brands"
      } Comparison`,
      dimension: [
        mode === "category"
          ? ApiDimension.DIMENSION_PRODUCT_CATEGORY
          : ApiDimension.DIMENSION_PRODUCT_BRAND,
      ],
      yAxisKeys: [
        {
          fields: [ApiMetric.METRIC_PRODUCT_REVENUE],
        },
      ],
      attributes: [],
    }),
    [mode]
  );

  const { chartData: currentChartData } = useChartData(
    selectedProject,
    chart,
    chartOutput
  );
  const { chartData: compChartData } = useChartData(
    selectedProject,
    chart,
    chartComparisonOutput
  );

  const chartData: TChartData[] = useMemo(() => {
    if (!currentChartData || !compChartData) {
      return [];
    }

    return (currentChartData.data || []).map((d) => {
      const o: TChartData = {
        name: d.name || "",
        current: d[ApiMetric.METRIC_PRODUCT_REVENUE],
      };

      const f = (compChartData.data || []).find((v) => v.name === d.name);
      if (f) {
        o.previous = f[ApiMetric.METRIC_PRODUCT_REVENUE];
      }
      return o;
    });
  }, [currentChartData, compChartData]);

  const topRevenueCurrent = useMemo(
    () =>
      chartData.sort(
        (a, b) => ((b.current as number) || 0) - ((a.current as number) || 0)
      )[0],
    [chartData]
  );
  const topRevenuePrevious = useMemo(
    () =>
      chartData.sort(
        (a, b) => ((b.previous as number) || 0) - ((a.previous as number) || 0)
      )[0],
    [chartData]
  );

  const topShareOfRevenue = useMemo(() => {
    const total = chartData.reduce((agg, v) => agg + (v.current as number), 0);
    return percentageOf((topRevenueCurrent?.current as number) || 0, total);
  }, [topRevenueCurrent, chartData]);

  const biggestDifference = useMemo(() => {
    return chartData
      .filter((v) => v.current || v.previous)
      .map((v) => {
        const diff = percentageChange(
          (v.previous as number) || 0,
          (v.current as number) || 0
        );
        return {
          ...v,
          diff,
        };
      })
      .sort((a, b) => {
        return b.diff - a.diff;
      })[0];
  }, [chartData]);

  return (
    <AnalyticsCard>
      <Stack direction={"row"} alignItems="center">
        <Box sx={{ flex: 1 }}>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"flex-start"}
          >
            <Stack
              mb={3}
              direction={"row"}
              alignItems="baseline"
              spacing={AnalyticsSpacing}
            >
              <AnalyticsCardTitleWrapper>
                <Box>
                  <AnalyticsCardTitle
                    title={`Top Product ${
                      mode === "category" ? "Categories" : "Brands"
                    } Comparison`}
                  />
                  <Typography variant="body2" color={"secondary"}>
                    See which product{" "}
                    {mode === "category" ? "categories" : "brands"} performed
                    best
                  </Typography>
                </Box>
              </AnalyticsCardTitleWrapper>
              <ChartLegend
                items={["This time Period", "Previous time Period"]}
              />
            </Stack>
            <StyledFlexBox compact sx={{ maxWidth: 300 }}>
              <Box display="flex" alignItems="center">
                <Typography>Categories</Typography>
                <Switch
                  checked={mode === "brand"}
                  onChange={(e, checked) =>
                    setMode(checked ? "brand" : "category")
                  }
                />
                <Typography>Brands</Typography>
              </Box>
            </StyledFlexBox>
          </Stack>

          <StyledBarChart
            data={chartData}
            height={320}
            yAxisKeys={[
              {
                key: "l",
                fields: ["current"],
              },
            ]}
            yAxisProps={[
              {
                tickFormatter: (value) =>
                  formatMetric(ApiMetric.METRIC_PRODUCT_REVENUE, value),
              },
            ]}
          >
            <Tooltip
              formatter={(value, key) =>
                formatMetric(
                  ApiMetric.METRIC_PRODUCT_REVENUE,
                  Number(value || 0)
                )
              }
            />
          </StyledBarChart>
        </Box>
        <Box
          sx={{
            minWidth: 330,
            height: 390,
            position: "relative",
          }}
        >
          <StatCardRound
            size={217}
            sx={{ position: "absolute", top: 5, left: -20 }}
          >
            <StatCardRoundTypography size="small">
              Looks like
            </StatCardRoundTypography>
            <StatCardRoundTypography size="medium" mb={0.5} lines={2}>
              {topRevenueCurrent?.name}
            </StatCardRoundTypography>
            <StatCardRoundTypography size="small" lines={3}>
              {topRevenueCurrent?.name === topRevenuePrevious?.name ? (
                <>performed best</>
              ) : (
                <>
                  overtook <br /> {topRevenuePrevious?.name}
                </>
              )}
              <br /> in this period
            </StatCardRoundTypography>
          </StatCardRound>
          <StatCardRound
            size={179}
            backgroundColor={2}
            sx={{ position: "absolute", bottom: 0, right: 10 }}
          >
            <StatCardRoundTypography size="small">
              Revenue from
            </StatCardRoundTypography>
            <StatCardRoundTypography size="medium" mb={0.5} lines={1}>
              {biggestDifference?.name}
            </StatCardRoundTypography>
            <StatCardRoundTypography size="small">
              {biggestDifference?.diff >= 0 ? "increased" : "decreased"} by
            </StatCardRoundTypography>
            <StatCardRoundTypography size="medium">
              {formatPercentage(Math.abs(biggestDifference?.diff))}
            </StatCardRoundTypography>
          </StatCardRound>
          <StatCardRound
            size={155}
            backgroundColor={1}
            sx={{ position: "absolute", top: 217 - 155 + 5, right: 10 }}
          >
            <StatCardRoundTypography size="small">
              generating
            </StatCardRoundTypography>
            <StatCardRoundTypography size="medium">
              {formatPercentage(topShareOfRevenue)}
            </StatCardRoundTypography>
            <StatCardRoundTypography size="small">
              of the total revenue
            </StatCardRoundTypography>
          </StatCardRound>
        </Box>
      </Stack>
    </AnalyticsCard>
  );
}

export default ProductTopCategoryBrandComp;
