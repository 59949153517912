import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
  ApiYAxisChartType,
} from "@incendium/api";
import { Grid } from "@mui/material";
import OutOfStockBiggestDropChart from "Components/Analytics/Stock/OutOfStockBiggestDropChart";
import { AnalyticsSpacing } from "consts";
import { AnalyticsCard } from "features/analytics";

function StockImpactInsights({
  organicChannelName,
}: {
  organicChannelName: string;
}) {
  return (
    <Grid container spacing={AnalyticsSpacing} mb={4}>
      <Grid item xs={12} sx={{ minHeight: 450 }}>
        <AnalyticsCard
          chart={{
            name: `Out of Stock Pages vs. Organic Traffic`,
            dimension: [ApiDimension.DIMENSION_SESSION_START_BY_DAY],
            template: ApiChartTemplate.TREND,
            type: ApiChartType.GRAPH,
            attributes: [
              {
                key: ApiDimension.DIMENSION_CHANNEL,
                value: organicChannelName,
              },
            ],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_SESSIONS_COUNT],
              },
              {
                key: "r",
                fields: [ApiMetric.METRIC_OUT_OF_STOCK_PAGES_COUNT],
                chart: {
                  [ApiMetric.METRIC_OUT_OF_STOCK_PAGES_COUNT]:
                    ApiYAxisChartType.BAR,
                },
              },
            ],
            displayOptions: {
              noFilters: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={8}>
        <OutOfStockBiggestDropChart organicChannelName={organicChannelName} />
      </Grid>
      <Grid item xs={4} sx={{ minHeight: 500 }}>
        <AnalyticsCard
          chart={{
            name: `Out of Stock Pages by Organic Traffic`,
            dimension: [ApiDimension.DIMENSION_PATH],
            template: ApiChartTemplate.TABLE,
            type: ApiChartType.TABLE,
            attributes: [
              {
                key: ApiDimension.DIMENSION_CHANNEL,
                value: organicChannelName,
              },
            ],
            yAxisKeys: [
              {
                key: "l",
                fields: [ApiMetric.METRIC_OUT_OF_STOCK_PAGEVIEW_COUNT],
              },
            ],
            displayOptions: {
              noFilters: true,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default StockImpactInsights;
