import { Portal, Stack, Typography } from "@mui/material";
import { CenterPage } from "Components/CenterPage/CenterPage";
import JobCategoriesDropdown from "Components/Job/JobsCategoryDropdown";
import { useEffect, useState } from "react";
import { useSearchParam } from "react-use";
import JobSingleAndCategoryPage from "./JobSingleAndCategoryPage";
import { ApiDimension } from "@incendium/api";
import withDatepicker from "HoC/withDatepicker";
import { PageActionPreSlot } from "consts";

function JobCategoryReportPage() {
  const [selectedJobCat, setSelectedJobCat] = useState<string | undefined>();
  const jobCat = useSearchParam("job_category");

  useEffect(() => {
    if (jobCat) {
      setSelectedJobCat(jobCat);
    }
  }, [jobCat]);

  return (
    <>
      <Portal container={() => document.getElementById(PageActionPreSlot)}>
        <JobCategoriesDropdown
          sx={{ minWidth: 200 }}
          fullWidth
          size="small"
          variant="outlined"
          label="Select Job category"
          value={selectedJobCat}
          onChange={(n) => {
            setSelectedJobCat(n);
          }}
        />
      </Portal>
      <Portal container={() => document.getElementById("pageTitle")}>
        <Stack>
          <Typography variant="h1">Single Job Category Dashboard</Typography>
          <Stack direction="row" alignItems={"baseline"}>
            <Typography variant="h2" color={"secondary"}>
              {selectedJobCat}
            </Typography>
          </Stack>
        </Stack>
      </Portal>

      {selectedJobCat ? (
        <JobSingleAndCategoryPage
          dimension={ApiDimension.DIMENSION_JOB_CATEGORY}
          byDimension={selectedJobCat}
        />
      ) : (
        <CenterPage calcHeight>
          <Typography variant="subtitle1" mb={2}>
            Select a Job category to get started
          </Typography>
          <JobCategoriesDropdown
            sx={{ maxWidth: 300 }}
            fullWidth
            size="small"
            variant="outlined"
            label="Select Job category"
            value={selectedJobCat}
            onChange={(n) => {
              setSelectedJobCat(n);
            }}
          />
        </CenterPage>
      )}
    </>
  );
}

export default withDatepicker(JobCategoryReportPage);
