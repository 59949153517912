/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiCampaignStatusFromJSON, ApiCampaignStatusToJSON, } from './ApiCampaignStatus';
import { ApiCampaignTypeFromJSON, ApiCampaignTypeToJSON, } from './ApiCampaignType';
import { ApiPathStatusFromJSON, ApiPathStatusToJSON, } from './ApiPathStatus';
import { ApiSimplePersonalisationFromJSON, ApiSimplePersonalisationToJSON, } from './ApiSimplePersonalisation';
import { ApiSimpleProjectFromJSON, ApiSimpleProjectToJSON, } from './ApiSimpleProject';
import { ApiSimpleUserFromJSON, ApiSimpleUserToJSON, } from './ApiSimpleUser';
import { ApiSplitCampaignUrlFromJSON, ApiSplitCampaignUrlToJSON, } from './ApiSplitCampaignUrl';
export function ApiSimpleCampaignFromJSON(json) {
    return ApiSimpleCampaignFromJSONTyped(json, false);
}
export function ApiSimpleCampaignFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'project': !exists(json, 'project') ? undefined : ApiSimpleProjectFromJSON(json['project']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'status': !exists(json, 'status') ? undefined : ApiCampaignStatusFromJSON(json['status']),
        'type': !exists(json, 'type') ? undefined : ApiCampaignTypeFromJSON(json['type']),
        'maxNumberOfLeads': !exists(json, 'maxNumberOfLeads') ? undefined : json['maxNumberOfLeads'],
        'probability': !exists(json, 'probability') ? undefined : json['probability'],
        'personalisations': !exists(json, 'personalisations') ? undefined : (json['personalisations'].map(ApiSimplePersonalisationFromJSON)),
        'owner': !exists(json, 'owner') ? undefined : ApiSimpleUserFromJSON(json['owner']),
        'lastUser': !exists(json, 'lastUser') ? undefined : ApiSimpleUserFromJSON(json['lastUser']),
        'audienceIds': !exists(json, 'audienceIds') ? undefined : json['audienceIds'],
        'subdomainId': !exists(json, 'subdomainId') ? undefined : json['subdomainId'],
        'pathStatus': !exists(json, 'pathStatus') ? undefined : ApiPathStatusFromJSON(json['pathStatus']),
        'url': !exists(json, 'url') ? undefined : json['url'],
        'urls': !exists(json, 'urls') ? undefined : (json['urls'].map(ApiSplitCampaignUrlFromJSON)),
        'fallbackUrl': !exists(json, 'fallbackUrl') ? undefined : json['fallbackUrl'],
    };
}
export function ApiSimpleCampaignToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'project': ApiSimpleProjectToJSON(value.project),
        'name': value.name,
        'description': value.description,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'status': ApiCampaignStatusToJSON(value.status),
        'type': ApiCampaignTypeToJSON(value.type),
        'maxNumberOfLeads': value.maxNumberOfLeads,
        'probability': value.probability,
        'personalisations': value.personalisations === undefined ? undefined : (value.personalisations.map(ApiSimplePersonalisationToJSON)),
        'owner': ApiSimpleUserToJSON(value.owner),
        'lastUser': ApiSimpleUserToJSON(value.lastUser),
        'audienceIds': value.audienceIds,
        'subdomainId': value.subdomainId,
        'pathStatus': ApiPathStatusToJSON(value.pathStatus),
        'url': value.url,
        'urls': value.urls === undefined ? undefined : (value.urls.map(ApiSplitCampaignUrlToJSON)),
        'fallbackUrl': value.fallbackUrl,
    };
}
