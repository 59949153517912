import DataTable from "Components/UI/DataTable";
import {
  styled,
  Box,
  Divider,
  Tooltip,
  Typography,
  Link,
  Stack,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { IChartData, TChartData } from "Interfaces";
import {
  isDimension,
  isJourneyDimension,
  isTrendDimension,
} from "features/analytics/utils/utils";
import {
  AnalyticsUserJourneyColumns,
  dimensionToName,
  formatDimension,
  formatMetric,
  metricToName,
} from "features/analytics";
import { ApiDimension, ApiMetric } from "@incendium/api";
import { percentageChange } from "Helpers/percentage";
import IncreaseDecrease from "Components/IncreaseDecrease/IncreaseDecrease";
import moment from "moment";

interface ICompare {
  direction?: "up" | "down" | "same";
  value?: number;
}

function Cell({
  valueFormatted,
  value,
  onClick,
  compare,
  headerName,
  isDimension,
}: {
  valueFormatted: string;
  value: any;
  onClick?: () => void;
  compare?: ICompare;
  headerName: string;
  isDimension?: boolean;
}) {
  return (
    <Tooltip
      title={
        <Stack>
          <Typography variant="body2" color={"inherit"} gutterBottom>
            {headerName}
          </Typography>
          <Typography variant="body1" color={"inherit"}>
            {valueFormatted}
          </Typography>
        </Stack>
      }
      arrow
    >
      <Stack
        sx={{
          alignItems: isDimension ? "flex-start" : "center",
          height: "100%",
        }}
      >
        {onClick ? (
          <Link component={"button"} onClick={onClick}>
            <Typography variant="body1" noWrap sx={{ fontSize: "13px" }}>
              {valueFormatted}
            </Typography>
          </Link>
        ) : (
          <Typography variant="body1" noWrap sx={{ fontSize: "13px" }}>
            {valueFormatted}
          </Typography>
        )}
        {compare && compare.value ? (
          <Box>
            <IncreaseDecrease
              direction={compare?.direction}
              value={compare.value}
              useThemeColours
            />
          </Box>
        ) : (
          ""
        )}
      </Stack>
    </Tooltip>
  );
}

const StyledWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: -14,
  right: -14,
  bottom: -47,
  top: 10,
}));

interface IAnalyticsDataTableProps {
  data: TChartData[];
  totals?: TChartData;
  onClick?: { [field: string]: (v: string, o?: string) => void };
  pageSize?: number;
  comparison?: boolean;
  comparisonChartData?: IChartData;
}

function AnalyticsDataTable({
  data,
  totals,
  onClick,
  comparison,
  comparisonChartData,
  pageSize,
}: IAnalyticsDataTableProps) {
  const [columns, setColumns] = useState<(ColDef | ColGroupDef)[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [totalRow, setTotalRow] = useState<any[] | undefined>(undefined);

  const cellRenderer = useCallback(
    (
      key,
      { valueFormatted, value, colDef, data },
      colIsDimension,
      comparison,
      comparisonChartData
    ) => {
      let compare: ICompare = {};
      if (comparison && comparisonChartData) {
        let found = comparisonChartData.data?.find((d) => d.name === data.name);

        if (!colIsDimension && found && typeof value === "number") {
          let change = percentageChange(
            Number(found[colDef.field]) || 0,
            value || 0
          );
          compare = {
            direction: change > 0 ? "up" : change < 0 ? "down" : "same",
            value: Number(change) || 0,
          };
        }
      }
      const fn = onClick && onClick[key];
      return colIsDimension && isJourneyDimension(key as ApiDimension) ? (
        <AnalyticsUserJourneyColumns
          formatedValue={valueFormatted}
          onClick={fn ? () => fn(value) : undefined}
        />
      ) : (
        <Cell
          valueFormatted={valueFormatted}
          value={value}
          onClick={fn ? () => fn(value) : undefined}
          compare={compare}
          headerName={colDef.headerName}
          isDimension={colIsDimension}
        />
      );
    },
    [onClick]
  );

  useEffect(() => {
    if (!data || !data[0]) {
      return;
    }
    const cols: (ColDef | ColGroupDef)[] = Object.keys(data[0])
      .filter((k) => k !== "name")
      .map((d) => {
        const colIsDimension = isDimension(d);
        let headerName = colIsDimension
          ? dimensionToName(d as ApiDimension)
          : metricToName(d as ApiMetric);

        const c: ColDef = {
          headerName,
          field: d,
          pinned: colIsDimension ? "left" : undefined,
          minWidth: colIsDimension
            ? isJourneyDimension(d as ApiDimension)
              ? 500
              : 200
            : 130,
          valueFormatter: ({ value }) => {
            return colIsDimension
              ? formatDimension(d as ApiDimension, value)
              : value === "..."
              ? "..."
              : formatMetric(d as ApiMetric, value);
          },
          cellRenderer: (params) =>
            cellRenderer(
              d,
              params,
              colIsDimension,
              comparison,
              comparisonChartData
            ),
        };

        if (isTrendDimension(d as ApiDimension)) {
          c.comparator = (date1: string, date2: string) => {
            return moment(date1).diff(moment(date2));
          };
        }

        return c;
      });

    const rows = (data || []).map((v, i) => {
      return {
        id: i,
        ...v,
      };
    });

    setColumns(cols);
    setRows(rows);
    if (totals) {
      setTotalRow([totals]);
    }
  }, [data, totals, comparison, comparisonChartData, cellRenderer]);

  return (
    <StyledWrapper>
      <Divider />
      <DataTable
        colDefs={columns}
        rows={rows}
        totalRow={totalRow}
        pageSize={pageSize}
      />
    </StyledWrapper>
  );
}

export default AnalyticsDataTable;
