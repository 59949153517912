import { ApiCampaignType, ApiProject, ApiSimpleCampaign } from "@incendium/api";
import {
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { personalisationService } from "Apis";
import SpacedList from "Components/UI/SpacedList";
import { CampaignListRow } from "features/campaigns";
import { useNotification } from "Hooks";
import { useCampaigns } from "Hooks/useCampaigns";
import produce from "immer";
import { useConfirmationContext } from "Providers/ConfirmationProvider";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

interface ICampaignListProps {
  setCampaign: (c: ApiSimpleCampaign) => void;
  setOpen: (b: boolean) => void;
  project: ApiProject;
  types: ApiCampaignType[];
}

function CampaignList({
  project,
  setCampaign,
  setOpen,
  types,
}: ICampaignListProps) {
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const { campaigns, setCampaigns } = useCampaigns();
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const history = useHistory();

  const filteredCampaigns = useMemo(() => {
    return (campaigns || []).filter((c) => c.type && types.includes(c.type));
  }, [campaigns, types]);

  const editCampaign = useCallback((campaign) => {
    setCampaign(campaign);
    setOpen(true);
  }, []);

  const deleteCampaign = useCallback(
    (campaign) => {
      openConfirmation({
        title: `Are you sure you want to delete this ${campaign.name}`,
        body: `This action can not be undone`,
        callback: async () => {
          try {
            await personalisationService.personalisationServiceDeleteCampaign({
              projectId: project.id!,
              campaignId: campaign.id as number,
            });

            setCampaigns(
              produce(campaigns, (draft) => {
                const idx = draft.findIndex((c) => c.id === campaign.id);
                if (idx >= 0) {
                  draft.splice(idx, 1);
                }
              })
            );
            showSuccessNotification(`${campaign.name} Deleted`);
            closeConfirmation();
          } catch (error) {
            showErrorNotification(
              `Internal Error deleting campaign, please try again`
            );
          }
        },
      });
    },
    [
      project,
      campaigns,
      openConfirmation,
      closeConfirmation,
      setCampaigns,
      showErrorNotification,
      showSuccessNotification,
    ]
  );

  const activateCampaign = useCallback(
    async (campaign) => {
      try {
        const res =
          await personalisationService.personalisationServiceActivateCampaign({
            projectId: project.id!,
            campaignId: campaign.id as number,
          });

        setCampaigns(
          produce(campaigns, (draft) => {
            const idx = draft.findIndex((c) => c.id === campaign.id);
            if (idx >= 0) {
              draft[idx] = res.campaign!;
            }
          })
        );

        showSuccessNotification(`${campaign.name} Activated`);
      } catch (error) {
        showErrorNotification(
          `Internal Error activating campaign, please try again`
        );
      }
    },
    [
      project,
      campaigns,
      setCampaigns,
      showSuccessNotification,
      showErrorNotification,
    ]
  );

  const deactivateCampaign = useCallback(
    async (campaign) => {
      try {
        const res =
          await personalisationService.personalisationServiceDeactivateCampaign(
            {
              projectId: project.id!,
              campaignId: campaign.id as number,
            }
          );

        setCampaigns(
          produce(campaigns, (draft) => {
            const idx = draft.findIndex((c) => c.id === campaign.id);
            if (idx >= 0) {
              draft[idx] = res.campaign!;
            }
          })
        );

        showSuccessNotification(`${campaign.name} Deactivated`);
      } catch (error) {
        showErrorNotification(
          `Internal Error deactivating campaign, please try again`
        );
      }
    },
    [
      project,
      campaigns,
      setCampaigns,
      showSuccessNotification,
      showErrorNotification,
    ]
  );

  const toConversionLabAnalytics = useCallback(
    (id: number) => {
      const campaign = campaigns.find((c) => c.id === id);
      history.push(
        `/clients/${project.clientId}/projects/${
          project.id
        }/analyse/optimization/conversion-lab?campaign=${campaign?.name || ""}`
      );
    },
    [project, campaigns, history]
  );
  const toRezonateAnalytics = useCallback(
    (id: number) => {
      history.push(
        `/clients/${project.clientId}/projects/${project.id}/analyse/optimization/rezonate-report/${id}`
      );
    },
    [project, history]
  );

  return (
    <SpacedList>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Dates</TableCell>
          <TableCell>Details</TableCell>
          <TableCell>Status</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {filteredCampaigns.length === 0 && (
          <TableRow>
            <TableCell colSpan={7}>
              <Box p={2}>
                <Typography align="center">
                  Looks like you have no Campaigns setup yet.
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
        )}
        {(filteredCampaigns || []).map((campaign) => {
          return (
            <CampaignListRow
              key={campaign.id}
              campaign={campaign}
              editCampaign={editCampaign}
              deleteCampaign={deleteCampaign}
              activateCampaign={activateCampaign}
              deactivateCampaign={deactivateCampaign}
              toAnalytics={
                campaign.type === ApiCampaignType.CAMPAIGN_TYPE_AB
                  ? toConversionLabAnalytics
                  : campaign.type === ApiCampaignType.CAMPAIGN_TYPE_NONE
                  ? toRezonateAnalytics
                  : undefined
              }
              projectId={project.id!}
            />
          );
        })}
      </TableBody>
    </SpacedList>
  );
}

export default CampaignList;
