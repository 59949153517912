import { ButtonGroup, styled } from "@mui/material";
import Manage from "Assets/icons/manage-icon.png";
import Analyse from "Assets/icons/analyse-icon.png";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { leftMenuSelector } from "Selectors/leftMenuSelector";
import { useCallback, useMemo } from "react";
import { StyledButtonNoTextTransform } from "Components/Button/StyledButtons";
import { useHistory } from "react-router-dom";
import { useSelectedProject } from "Hooks";
import Image from "mui-image";

const StyledButtonGroup = styled(ButtonGroup)<{ isOpen: boolean }>(
  ({ theme, isOpen }) => ({
    color: "white",
    marginBottom: theme.spacing(3),
    background: "transparent",
    // transform: !isOpen ? "translateX(-10px)" : undefined,
    "& .MuiButtonBase-root": {
      background: "transparent",
      border: 0,
      fontWeight: 600,
      fontSize: 14,
      minWidth: 28,
      color: "white",
      height: 46,

      "& .MuiButton-iconSizeMedium": {
        margin: !isOpen ? 0 : undefined,
      },
      "&::before": {
        content: "''",
        position: "absolute",
        bottom: 0,
        borderRadius: 4,
        height: 4,
        width: "100%",
        background: "#292748",
      },
      "&::after": {
        content: "''",
        position: "absolute",
        bottom: 0,
        borderRadius: 4,
        height: 4,
        background: theme.palette.info.main,
        width: 0,
        transition: "all 0.2s",
        left: 0,
      },
      "&:hover": {
        "&::after": {
          width: "100%",
        },
      },
    },

    "& .active": {
      "&::after": {
        width: "100%",
      },
    },
    "& .left": {
      marginRight: isOpen ? 4 : 0,
    },
    "& .MuiButtonGroup-grouped:not(:first-of-type)": {
      marginLeft: isOpen ? 4 : 0,
    },
  })
);

function SetupAnalyticsSwapper() {
  const history = useHistory();
  const { selectedClient, selectedProject } = useSelectedProject();
  // const dispatch = useDispatch();
  const { isMenuOpen, debouncedIsHover, isAnalysisActive } =
    useSelector(leftMenuSelector);

  const onClick = useCallback(
    (value: "manage" | "analyse") => {
      let url = `/clients/${selectedClient?.id}/projects/${selectedProject?.id}/`;
      if (value === "analyse") {
        url += "analyse";
      }
      history.push(url);
    },
    [history, selectedClient, selectedProject]
  );

  const isOpen = useMemo(
    () => isMenuOpen || debouncedIsHover,
    [isMenuOpen, debouncedIsHover]
  );

  return (
    <StyledButtonGroup fullWidth isOpen={isOpen}>
      {(isOpen || !isAnalysisActive) && (
        <StyledButtonNoTextTransform
          className={clsx("left", {
            active: !isAnalysisActive,
          })}
          color="inherit"
          onClick={() => onClick("manage")}
          sx={{ padding: !isOpen ? 0 : undefined }}
        >
          {isOpen ? (
            <>Manage</>
          ) : (
            <Image height={30} color="transparent" src={Manage} fit="contain" />
          )}
        </StyledButtonNoTextTransform>
      )}
      {(isOpen || isAnalysisActive) && (
        <StyledButtonNoTextTransform
          className={clsx("right", {
            active: isAnalysisActive,
          })}
          color="inherit"
          onClick={() => onClick("analyse")}
          sx={{ padding: !isOpen ? 0 : undefined }}
        >
          {isOpen ? (
            <>Analyse</>
          ) : (
            <Image
              height={30}
              color="transparent"
              src={Analyse}
              fit="contain"
            />
          )}
        </StyledButtonNoTextTransform>
      )}
    </StyledButtonGroup>
  );
}

export default SetupAnalyticsSwapper;
