import { IStoreState } from "Interfaces";

export const projectSelector = (clientId?: string, projectId?: string) => (
  state: IStoreState
) => {
  if (!clientId || !projectId) {
    return null;
  }
  const project = state.application.projects[clientId];
  if (!project) {
    return null;
  }
  const list = project.list;
  const index = list.findIndex(
    (item) => item.project.id === parseInt(projectId)
  );
  if (index === -1) {
    return null;
  }
  return list[index].project;
};
