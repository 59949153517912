import {
  ApiAudience,
  ApiCondition,
  ApiProject,
  ApiTestAudienceResponse,
} from "@incendium/api";
import {
  Box,
  Button,
  CircularProgress,
  Portal,
  Stack,
  Typography,
} from "@mui/material";
import { audienceService } from "Apis";
import AudienceForm from "Components/Audience/AudienceForm";
import ConditionGroupProvider, {
  useConditionGroupContext,
} from "Components/ConditionalGroup/ConditionGroupProvider";
import GlassCard from "Components/GlassCard/GlassCard";
import { useSelectedProject } from "Hooks";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const Stat = ({
  title,
  stat,
  loading,
  total,
}: {
  title: string;
  stat: Number;
  loading: boolean;
  total?: number;
}) => {
  return (
    <GlassCard sx={{ width: "100%" }} boxProps={{ p: 2 }}>
      <Typography variant="subtitle2" color={"secondary"}>
        {title}
      </Typography>
      <Box sx={{ minHeight: 50 }}>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Typography
              variant="body2"
              color={"secondary"}
              sx={{ display: "block", transform: "translateY(-4px)" }}
            >
              (of last {total || "n"} leads)
            </Typography>
            <Typography variant="h5" color={"primary"}>
              {stat.toLocaleString()}
            </Typography>
          </>
        )}
      </Box>
    </GlassCard>
  );
};

interface IAudiencePageInnerProps {
  audience: ApiAudience;
  setAudience: React.Dispatch<React.SetStateAction<ApiAudience>>;
  setAudienceTest: React.Dispatch<
    React.SetStateAction<ApiTestAudienceResponse>
  >;
  audienceTest: ApiTestAudienceResponse;
  selectedProject: ApiProject;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const AudiencePageInner = ({
  audience,
  setAudience,
  audienceTest,
  setAudienceTest,
  selectedProject,
  loading,
  setLoading,
}: IAudiencePageInnerProps) => {
  const { conditionGroups } = useConditionGroupContext();
  const [initialFetched, setInitialFetched] = useState<boolean>(false);
  const testAudience = useCallback(async () => {
    if (loading) {
      return;
    }
    setInitialFetched(true);
    setLoading(true);

    try {
      const res = await audienceService.audienceServiceTestAudience({
        projectId: selectedProject?.id as number,
        payload: {
          ...audience,
          conditionGroups,
        },
      });
      setAudienceTest(res);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [
    audience,
    conditionGroups,
    setAudienceTest,
    setLoading,
    loading,
    selectedProject,
  ]);

  useEffect(() => {
    if (
      initialFetched ||
      !conditionGroups ||
      conditionGroups.length === 0 ||
      conditionGroups.filter(
        (cg) =>
          ((cg.conditions as ApiCondition[]) || []).filter(
            (c) => !!c.value || !!c.classificationId
          ).length > 0
      ).length === 0
    ) {
      return;
    }

    testAudience();
  }, [conditionGroups, initialFetched, testAudience]);

  return (
    <>
      <Stack
        spacing={3}
        mb={2}
        direction={"row"}
        justifyContent="center"
        style={{ width: 865, marginLeft: "auto", marginRight: "auto" }}
      >
        <Stat
          title="Matching Leads"
          stat={audienceTest.nLeads || 0}
          loading={loading}
          total={audienceTest.nCalculatedLeads}
        />
        <Stat
          title="With Emails"
          stat={audienceTest.haveEmails || 0}
          loading={loading}
          total={audienceTest.nCalculatedLeads}
        />
        <Stat
          title="Have Conversion"
          stat={audienceTest.haveMacro || 0}
          loading={loading}
          total={audienceTest.nCalculatedLeads}
        />
        <Stat
          title="Have Revenue Conversion"
          stat={audienceTest.haveRevenue || 0}
          loading={loading}
          total={audienceTest.nCalculatedLeads}
        />
      </Stack>
      <Stack
        direction={"row"}
        spacing={2}
        style={{
          maxWidth: 850,
          marginLeft: "auto",
          marginRight: "auto",
        }}
        mb={5}
        justifyContent="flex-end"
        alignItems={"end"}
      >
        <Typography variant="body2">
          This will see how of the last {audienceTest.nCalculatedLeads || "n"}{" "}
          active leads would fall into this audience.
        </Typography>
        <Button
          variant="outlined"
          size="small"
          onClick={testAudience}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            "Test Audience"
          )}
        </Button>
      </Stack>

      <AudienceForm
        audience={audience}
        setAudience={setAudience}
        project={selectedProject}
      />
    </>
  );
};

function AudiencePage() {
  const { audienceid } = useParams<{ audienceid: string }>();
  const [audience, setAudience] = useState<ApiAudience>({});
  const { selectedProject } = useSelectedProject();
  const [loading, setLoading] = useState<boolean>(false);
  const [testLoading, setTestLoading] = useState<boolean>(false);
  const [audienceTest, setAudienceTest] = useState<ApiTestAudienceResponse>({});

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      try {
        const res = await audienceService.audienceServiceReadAudience({
          projectId: selectedProject?.id as number,
          audienceId: parseInt(audienceid),
        });
        setAudience(res);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    load();
  }, [selectedProject, audienceid]);

  if (loading || !selectedProject) {
    return <CircularProgress />;
  }

  return (
    <>
      <Portal container={() => document.getElementById("pageTitle")}>
        <Stack>
          <Typography variant="h1" gutterBottom>
            {audience.name}
          </Typography>
          <Typography variant="body1">
            Audiences are collections of values assigned to a user based on
            their behaviour
          </Typography>
        </Stack>
      </Portal>
      <Box>
        <ConditionGroupProvider>
          <AudiencePageInner
            audience={audience}
            setAudience={setAudience}
            audienceTest={audienceTest}
            setAudienceTest={setAudienceTest}
            selectedProject={selectedProject}
            loading={testLoading}
            setLoading={setTestLoading}
          />
        </ConditionGroupProvider>
      </Box>
    </>
  );
}

export default AudiencePage;
