import {
  ApiComponentUISource,
  ApiProject,
  ApiSimpleComponent,
} from "@incendium/api";
import { Code, ViewComfy } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import NameAndDescriptionDialog from "Components/NameAndDescriptionDialog/NameAndDescriptionDialog";
import { saveComponent } from "features/campaigns";
import { useNotification } from "Hooks";
import produce from "immer";
import { CallbackOrVal, GenericDialoagProps } from "Interfaces";
import { useConfirmationContext } from "Providers/ConfirmationProvider";
import { useCallback } from "react";

interface IComponentDialogProps extends GenericDialoagProps {
  project: ApiProject;
  component: ApiSimpleComponent;
  setComponents: (val: CallbackOrVal<ApiSimpleComponent[]>) => void;
  onSaved?: (v: ApiSimpleComponent) => void;
}

function ComponentDialog({
  open,
  setOpen,
  component,
  setComponents,
  project,
  onSaved,
}: IComponentDialogProps) {
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const { openConfirmation, closeConfirmation } = useConfirmationContext();

  const onSaveComponent = useCallback(
    async (name, description) => {
      try {
        const res = await saveComponent(project.id as number, {
          ...component,
          name,
          description,
        });

        setComponents((components) =>
          produce(components, (draft) => {
            if (!res.component) {
              return;
            }
            const idx = draft.findIndex((d) => d.id === res.component?.id);

            if (idx >= 0) {
              draft[idx] = res.component;
            } else {
              draft.push(res.component);
            }
          })
        );
        showSuccessNotification(`${name} Saved`);
        onSaved && onSaved(res.component!);
      } catch (error: any) {
        const t = await error.json();
        if (t.code === 9) {
          openConfirmation({
            title: `This Component is used in active campaign.`,
            body: "Making changes to this component might result in inaccuracies in your analytics. Do you wish to proceed?",
            callback: async () => {
              try {
                const res = await saveComponent(
                  project.id as number,
                  {
                    ...component,
                    name,
                    description,
                  },
                  t.details[0].message
                );
                setComponents((components) =>
                  produce(components, (draft) => {
                    if (!res.component) {
                      return;
                    }
                    const idx = draft.findIndex(
                      (d) => d.id === res.component?.id
                    );

                    if (idx >= 0) {
                      draft[idx] = res.component;
                    } else {
                      draft.push(res.component);
                    }
                  })
                );
                showSuccessNotification(`${name} Saved`);
              } catch (error) {
                showErrorNotification(
                  `Internal Error deleting component, please try again`
                );
              }
              closeConfirmation();
            },
          });
          return;
        }
        showErrorNotification("Failed to save component, please try again.");
      }
    },
    [
      closeConfirmation,
      component,
      openConfirmation,
      project.id,
      setComponents,
      showErrorNotification,
      showSuccessNotification,
      onSaved,
    ]
  );

  return (
    <NameAndDescriptionDialog
      open={open}
      setOpen={setOpen}
      title="Give this Component a reconizable name and description, you will be able to edit it's content after."
      name={component.name || ""}
      description={component.description || ""}
      onSaved={onSaveComponent}
      footer={
        <Stack direction={"row"} spacing={1}>
          {component.uiSource === ApiComponentUISource.GRAPES ? (
            <>
              <ViewComfy fontSize="small" />
              <Typography>Builder</Typography>
            </>
          ) : (
            <>
              <Code fontSize="small" />
              <Typography>Code</Typography>
            </>
          )}
        </Stack>
      }
    />
  );
}

export default ComponentDialog;
