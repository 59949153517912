import {
  ApiLocation,
  ApiPageviewMetaTagger,
  ApiProject,
  ApiReservedPageviewMeta,
} from "@incendium/api";
import { Button, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { SidebarStyledDivider } from "Components/UI/Sidebar";
import { useIframeContext } from "Components/SmartIframe";
import { useDebounce } from "react-use";
import { pageviewMetaService } from "Apis";
import { useNotification } from "Hooks";
import produce from "immer";
import { usePageviewMetaTaggers } from "Hooks/usePageviewMetaTaggers";

function PageMetaTab({
  project,
  location,
}: {
  location: ApiLocation;
  project: ApiProject;
}) {
  const { pageviewMetaTaggers, setPageviewMetaTaggers } =
    usePageviewMetaTaggers(location.id);
  const [tagger, setTagger] = useState<ApiPageviewMetaTagger>({
    reserved: ApiReservedPageviewMeta.OUT_OF_STOCK,
  });
  const { showSuccessNotification, showErrorNotification } = useNotification();

  useEffect(() => {
    if (!pageviewMetaTaggers) {
      return;
    }
    const idx = pageviewMetaTaggers.findIndex(
      (t) => t.reserved === ApiReservedPageviewMeta.OUT_OF_STOCK
    );
    if (idx >= 0) {
      setTimeout(() => {
        setTagger(pageviewMetaTaggers[idx]);
      }, 1);
    }
  }, [pageviewMetaTaggers]);

  useDebounce(
    () => {
      activateIframe(undefined, tagger.selector);
    },
    500,
    [tagger.selector]
  );

  const { activateIframe, deactivateIframe, selectedSelector } =
    useIframeContext();

  useEffect(() => {
    deactivateIframe();
    activateIframe(undefined);
    return () => deactivateIframe();
  }, []);

  useEffect(() => {
    if (selectedSelector === tagger.selector) {
      return;
    }

    setTagger(
      produce(tagger, (draft) => {
        draft.selector = selectedSelector || "";
      })
    );
  }, [selectedSelector]);

  const onSelectorChange = (e: React.FormEvent<HTMLInputElement>) => {
    setTagger(
      produce(tagger, (draft) => {
        draft.selector = e.currentTarget.value;
      })
    );
  };
  const onValueChange = (e: React.FormEvent<HTMLInputElement>) => {
    setTagger(
      produce(tagger, (draft) => {
        draft.value = e.currentTarget.value;
      })
    );
  };

  const handleSubmit = useCallback(async () => {
    try {
      const fn = tagger.id
        ? pageviewMetaService.pageviewMetaServiceUpdatePageviewMetaTagger({
            projectId: project.id as number,
            pageviewMetaId: tagger.id as number,
            payload: {
              reserved: tagger.reserved,
              selector: tagger.selector,
              value: tagger.value,
              locationId: location.id as number,
            },
          })
        : pageviewMetaService.pageviewMetaServiceCreatePageviewMetaTagger({
            projectId: project.id as number,
            payload: {
              reserved: tagger.reserved,
              selector: tagger.selector,
              value: tagger.value,
              locationId: location.id as number,
            },
          });
      const res = await fn;
      setPageviewMetaTaggers(
        produce(pageviewMetaTaggers, (draft) => {
          const idx = draft.findIndex((d) => d.id === res.id);
          if (idx >= 0) {
            draft[idx] = res;
          } else {
            draft.push(res);
          }
        })
      );

      showSuccessNotification("Out of stock set");
    } catch (e) {
      showErrorNotification("Failed to save Out of stock info");
    }
  }, [
    project,
    location,
    tagger,
    pageviewMetaTaggers,
    setPageviewMetaTaggers,
    showErrorNotification,
    showSuccessNotification,
  ]);

  return (
    <>
      <SidebarStyledDivider />
      <Typography mt={2} variant="subtitle1" gutterBottom>
        Is out of stock?
      </Typography>
      <Typography variant="body1">
        Select an area on the page and text it should contain.
      </Typography>
      <Typography variant="body1">
        If that area contains that text we shall mark this page visit as out of
        stock.
      </Typography>
      <ValidatorForm onSubmit={handleSubmit}>
        <Stack spacing={2} mt={3}>
          <TextValidator
            value={tagger.selector || ""}
            variant="outlined"
            name="selector"
            label="Selector"
            fullWidth
            validators={["required"]}
            errorMessages={["selector is required"]}
            onChange={onSelectorChange}
          />
          <TextValidator
            value={tagger.value || ""}
            variant="outlined"
            name="selector"
            label="Contains Value"
            fullWidth
            validators={["required"]}
            errorMessages={["value is required"]}
            onChange={onValueChange}
          />
          <Button type="submit">Save</Button>
        </Stack>
      </ValidatorForm>
    </>
  );
}

export default PageMetaTab;
