/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiAttributionClicksDimension;
(function (ApiAttributionClicksDimension) {
    ApiAttributionClicksDimension["ATTRIBUTION_CLICKS_DIMENSION_UNDEFINED"] = "AttributionClicksDimensionUndefined";
    ApiAttributionClicksDimension["ATTRIBUTION_CLICKS_DIMENSION_SOURCE"] = "AttributionClicksDimensionSource";
    ApiAttributionClicksDimension["ATTRIBUTION_CLICKS_DIMENSION_CAMPAIGN"] = "AttributionClicksDimensionCampaign";
    ApiAttributionClicksDimension["ATTRIBUTION_CLICKS_DIMENSION_KEYWORD"] = "AttributionClicksDimensionKeyword";
    ApiAttributionClicksDimension["ATTRIBUTION_CLICKS_DIMENSION_MEDIUM"] = "AttributionClicksDimensionMedium";
    ApiAttributionClicksDimension["ATTRIBUTION_CLICKS_DIMENSION_AD_GROUP"] = "AttributionClicksDimensionAdGroup";
    ApiAttributionClicksDimension["ATTRIBUTION_CLICKS_DIMENSION_CHANNEL"] = "AttributionClicksDimensionChannel";
    ApiAttributionClicksDimension["ATTRIBUTION_CLICKS_DIMENSION_SOURCE_BUCKET"] = "AttributionClicksDimensionSourceBucket";
})(ApiAttributionClicksDimension || (ApiAttributionClicksDimension = {}));
export function ApiAttributionClicksDimensionFromJSON(json) {
    return ApiAttributionClicksDimensionFromJSONTyped(json, false);
}
export function ApiAttributionClicksDimensionFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiAttributionClicksDimensionToJSON(value) {
    return value;
}
