import {
  Box,
  CircularProgress,
  Portal,
  Typography,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSelectedProject } from "Hooks";
import { TabWrapper } from "Components/UI/TabContainer";
import {
  AudienceOverviewTab,
  AudienceEngagementTab,
  AudienceGeographicTab,
  AudienceNewVsReturningTab,
  AudienceDeviceAndBrowsersTab,
  AudienceTrafficAndConversionsTab,
} from "features/analytics";
import { ChartLegend, getChartColor, useChartData } from "features/analytics";
import TabbedAnalysesPage from "../TabbedAnalysesPage";
import { Redirect, useParams } from "react-router-dom";
import { CenterPage } from "Components/CenterPage/CenterPage";
import { enumToArray } from "Helpers/enumToText";
import { Stack } from "@mui/system";
import AudiencePicker from "Components/Audience/AudiencePicker";
import AudienceAnalyticsProvider, {
  useAudienceAnalyticsContext,
} from "Providers/AudienceAnalyticsProvider";
import { TColourMap, useAnalyticsContext } from "Providers/AnalyticsProvider";
import { useUnmount } from "react-use";
import { useFromToContext } from "Providers/FromToProvider";
import { ApiDimension, ApiMetric } from "@incendium/api";
import withDatepicker from "HoC/withDatepicker";
import { PageSubAction } from "consts";

export const AudiencePickerSubAction = () => {
  const { audienceNames, setAudienceNames } = useAudienceAnalyticsContext();
  return (
    <Portal container={() => document.getElementById(PageSubAction)}>
      <Stack direction="row" spacing={1}>
        <Box sx={{ maxWidth: 350 }}>
          <ChartLegend items={audienceNames} />
        </Box>
        <Box>
          <AudiencePicker
            audienceNames={audienceNames}
            setAudienceNames={setAudienceNames}
            btnProps={{
              fullWidth: true,
            }}
          />
        </Box>
      </Stack>
    </Portal>
  );
};

enum Insight {
  overview = "Overview",
  engagement = "Engagement",
  traffic_and_conversions = "Traffic & Conversions",
  geographic_insights = "Geographic Insights",
  new_vs_returning = "New vs. Returning",
  devices_and_browsers = "Device & Browsers",
}

const chart = {
  dimension: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
  yAxisKeys: [
    {
      fields: [ApiMetric.METRIC_LEADS_COUNT],
    },
  ],
  attributes: [],
  limit: 5,
};

const AudienceReportPageInner = ({ insight }: { insight: string }) => {
  const [activeInsight, setActiveInsight] = useState(insight);
  const { setColourMap } = useAnalyticsContext();
  const { setAudienceNames, setAudienceCfgs } = useAudienceAnalyticsContext();
  const theme = useTheme();
  const { selectedProject } = useSelectedProject();
  const { chartOutput } = useFromToContext();

  const { chartData, loading } = useChartData(
    selectedProject,
    chart,
    chartOutput
  );

  useEffect(() => {
    if (loading || !chartData?.data) {
      return;
    }
    setAudienceNames((chartData?.data || []).map((d) => d.name));
    setAudienceCfgs(
      (chartData?.data || []).map((d, i) => ({
        name: d.name || "",
        fill: getChartColor(i, theme.palette.charts),
      }))
    );
    let cMap: TColourMap = {};
    (chartData?.data || []).forEach((d, i) => {
      cMap[d.name || ""] = getChartColor(i, theme.palette.charts);
    });

    setColourMap(cMap);
  }, [
    chartData,
    loading,
    theme.palette.charts,
    setAudienceNames,
    setAudienceCfgs,
    setColourMap,
  ]);

  useUnmount(() => {
    setColourMap({});
  });

  const renderSwitch = useCallback(() => {
    switch (activeInsight) {
      case Insight.overview.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <AudienceOverviewTab />
          </TabWrapper>
        );
      case Insight.engagement.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <AudienceEngagementTab />
          </TabWrapper>
        );
      case Insight.traffic_and_conversions.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <AudienceTrafficAndConversionsTab />
          </TabWrapper>
        );
      case Insight.geographic_insights.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <AudienceGeographicTab />
          </TabWrapper>
        );
      case Insight.new_vs_returning.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <AudienceNewVsReturningTab />
          </TabWrapper>
        );
      case Insight.devices_and_browsers.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <AudienceDeviceAndBrowsersTab />
          </TabWrapper>
        );

      default:
        return (
          <TabWrapper>
            <CenterPage calcHeight>
              <Typography variant="subtitle1">Not Implemented</Typography>
            </CenterPage>
          </TabWrapper>
        );
    }
  }, [activeInsight]);

  if (loading) {
    return (
      <CenterPage calcHeight>
        <CircularProgress />
        <Typography variant="h3" mt={2}>
          Fetching audience data
        </Typography>
      </CenterPage>
    );
  }

  return (
    <TabbedAnalysesPage
      title="Audience Comparison Insights"
      activeTab={activeInsight}
      setActiveTab={setActiveInsight}
      tabs={enumToArray(Insight)}
      renderSwitch={renderSwitch}
      hideAction
    />
  );
};

function AudienceReportPage() {
  const { insight } = useParams<{ insight: string }>();

  if (!insight || insight === "") {
    return <Redirect to={`./audience-comparison/overview`} />;
  }

  return (
    <AudienceAnalyticsProvider>
      <AudienceReportPageInner insight={insight} />
    </AudienceAnalyticsProvider>
  );
}

export default withDatepicker(AudienceReportPage);
