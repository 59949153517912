import {
  ApiProject,
  ApiReportResponse,
  ApiTabChartResponse,
  ApiTabResponse,
  ApiChartYAxisKey,
} from "@incendium/api";
import { HelpOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  Drawer,
  DrawerProps,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { chartService, reportService } from "Apis";
import { SiderBarHeader } from "Components/UI/Sidebar";
import { parseContextChartFromTabChart, useCharts } from "Hooks/useCharts";
import { IChart } from "Interfaces";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useReportBuilderContext } from "Providers/ReportBuilderProvider";
import { memo, useCallback, useEffect } from "react";
import { useState } from "react";
import EditReportChart from "./EditReportChart";
import Loading from "Components/Loading/Loading";
import { AnimatePresence } from "framer-motion";

interface IReportTabChartDrawerProps extends DrawerProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  project: ApiProject;
  report: ApiReportResponse;
  tab: ApiTabResponse;
  tabChart?: ApiTabChartResponse;
  reload: () => void;
}

function ReportTabChartDrawer({
  open,
  setOpen,
  project,
  report,
  tab,
  tabChart,
  reload,
}: IReportTabChartDrawerProps) {
  const theme = useTheme();
  const { chart, setChart } = useReportBuilderContext();
  const [loadedTabChart, setLoadedTabChart] = useState<ApiTabChartResponse>({});
  const { enqueueSnackbar } = useSnackbar();
  const { refetch } = useCharts();

  const load = useCallback(async () => {
    if (!tabChart) {
      return;
    }
    const res = await reportService.reportServiceReadTabChart({
      projectId: project?.id as number,
      reportId: report.id as number,
      tabId: tab.id as number,
      chartId: tabChart.id as number,
    });

    setLoadedTabChart(res);
  }, [tabChart]);

  useEffect(() => {
    if (!tabChart) {
      return;
    }

    load();
  }, [tabChart, load]);

  useEffect(() => {
    if (!loadedTabChart || !loadedTabChart?.chart) {
      return;
    }
    setChart(parseContextChartFromTabChart(loadedTabChart));
  }, [loadedTabChart]);

  const createChart = async () => {
    onSave(async () => {
      if (!tabChart) {
        return;
      }
      const newChartRes = await chartService.chartServiceCreateChart({
        projectId: project?.id as number,
        chartId: chart.id as number,
        payload: {
          ...chart,
          yAxisKeys: (chart.yAxisKeys || []).filter(
            (v) => typeof v !== "string"
          ) as ApiChartYAxisKey[], // filter should not be needed but here yo guarantee type
        },
      });

      const res = await reportService.reportServiceUpdateTabChart({
        projectId: project.id as number,
        reportId: report.id as number,
        tabId: tab.id as number,
        chartId: tabChart.id as number,
        payload: {
          chartId: newChartRes.id,
          removeOverridingChart: true, //wipe overriding
        },
      });

      setLoadedTabChart(res);
    }, `${chart.name} Saved`);
  };
  const updateChart = async () => {
    onSave(
      () =>
        chartService.chartServiceUpdateChart({
          projectId: project?.id as number,
          chartId: chart.id as number,
          payload: {
            ...chart,
            yAxisKeys: (chart.yAxisKeys || []).filter(
              (v) => typeof v !== "string"
            ) as ApiChartYAxisKey[], // filter should not be needed but here yo guarantee type
          },
        }),
      `${chart.name} Saved`
    );
  };

  const saveInstance = async () => {
    onSave(async () => {
      // compare fields of eited chart and original chart.
      // all changed fields get appened to override chart in tabChart
      if (!loadedTabChart?.chart || !tabChart) {
        return;
      }
      const originalChart = parseContextChartFromTabChart(loadedTabChart.chart);
      const changed = Object.fromEntries(
        Object.entries(chart).filter(([key, value]) => {
          return (
            JSON.stringify(value) !==
            JSON.stringify(originalChart[key as keyof IChart])
          );
        })
      );
      const res = await reportService.reportServiceUpdateTabChart({
        projectId: project.id as number,
        reportId: report.id as number,
        tabId: tab.id as number,
        chartId: tabChart.id as number,
        payload: {
          ...loadedTabChart,
          chartId: chart.id,
          overridingChart: {
            ...changed,
          },
        },
      });

      setLoadedTabChart(res);
    }, `${chart.name} Saved`);
  };

  const onSave = async (fn: () => void, success: string) => {
    try {
      await fn();
      setOpen(false);
      reload();
      refetch();

      enqueueSnackbar(success, {
        variant: "success",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    } catch (error) {
      enqueueSnackbar("Something Went Wrong", {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    }
  };

  return (
    <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
      <Stack sx={{ height: "100vh", width: "85vw" }}>
        <SiderBarHeader noBorder compact>
          <Typography variant="h3">{tabChart?.chart?.name}</Typography>
        </SiderBarHeader>
        <AnimatePresence>
          {!loadedTabChart || !loadedTabChart?.chart ? (
            <Loading />
          ) : (
            <>
              <Box
                sx={{
                  background: theme.palette.background.default,
                  height: "calc(100vh - 300px)",
                  flex: 1,
                  width: "100%",
                }}
              >
                <EditReportChart loadedTabChart={loadedTabChart} />
              </Box>
              <Box
                px={3}
                py={2}
                sx={{
                  background: theme.palette.background.default,
                  borderTop: `1px solid ${theme.palette.divider}`,
                }}
              >
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent="space-between"
                  alignItems={"center"}
                >
                  <Stack
                    direction={"row"}
                    spacing={2}
                    justifyContent="flex-start"
                  >
                    <Tooltip
                      arrow
                      title="Saves changes to chart, this will effect all uses of that chart in other reports."
                    >
                      <Button endIcon={<HelpOutline />} onClick={updateChart}>
                        Save Chart (Overwrite)
                      </Button>
                    </Tooltip>
                    <Tooltip
                      arrow
                      title="Saves as a new chart in the chart library, available for use in other reports."
                    >
                      <Button endIcon={<HelpOutline />} onClick={createChart}>
                        Save as New Chart
                      </Button>
                    </Tooltip>
                    <Tooltip
                      arrow
                      title="Saves as an override of this chart for this report only (not available in chart library). Does not affect the original chart and any other uses of it."
                    >
                      <Button endIcon={<HelpOutline />} onClick={saveInstance}>
                        Save Instance for This Report only
                      </Button>
                    </Tooltip>
                  </Stack>
                  {loadedTabChart &&
                    loadedTabChart.chart &&
                    loadedTabChart.chart.updatedAt && (
                      <Typography>
                        last saved on{" "}
                        {moment(loadedTabChart.chart.updatedAt).format("lll")}
                      </Typography>
                    )}
                </Stack>
              </Box>
            </>
          )}
        </AnimatePresence>
      </Stack>
    </Drawer>
  );
}

export default memo(ReportTabChartDrawer);
