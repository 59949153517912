/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiCampaignTypeFromJSON, ApiCampaignTypeToJSON, } from './ApiCampaignType';
export function ApiCreateUpdateCampaignPayloadFromJSON(json) {
    return ApiCreateUpdateCampaignPayloadFromJSONTyped(json, false);
}
export function ApiCreateUpdateCampaignPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'type': !exists(json, 'type') ? undefined : ApiCampaignTypeFromJSON(json['type']),
        'maxNumberOfLeads': !exists(json, 'maxNumberOfLeads') ? undefined : json['maxNumberOfLeads'],
        'probability': !exists(json, 'probability') ? undefined : json['probability'],
        'audienceIds': !exists(json, 'audienceIds') ? undefined : json['audienceIds'],
        'subdomainId': !exists(json, 'subdomainId') ? undefined : json['subdomainId'],
        'urls': !exists(json, 'urls') ? undefined : json['urls'],
        'fallbackUrl': !exists(json, 'fallbackUrl') ? undefined : json['fallbackUrl'],
    };
}
export function ApiCreateUpdateCampaignPayloadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'description': value.description,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'type': ApiCampaignTypeToJSON(value.type),
        'maxNumberOfLeads': value.maxNumberOfLeads,
        'probability': value.probability,
        'audienceIds': value.audienceIds,
        'subdomainId': value.subdomainId,
        'urls': value.urls,
        'fallbackUrl': value.fallbackUrl,
    };
}
