import { Typography } from "@mui/material";
import { CenterPage } from "Components/CenterPage/CenterPage";
import { TabWrapper } from "Components/UI/TabContainer";
import { enumToArray } from "Helpers/enumToText";
import { useCallback, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import SiteOverview from "./SiteOverview";
import ChannelInsights from "./ChannelInsights";
import AudienceInsights from "./AudienceInsights";
import ContentInsights from "./ContentInsights";
import DevicesInsights from "./DevicesInsights";
import TabbedAnalysesPage from "../TabbedAnalysesPage";

enum Insight {
  overview = "Site Overview",
  traffic = "Traffic",
  audiences = "Audiences",
  content = "Content",
  devices = "Devices",
}

function QuickInsightsOverviewPage() {
  const { insight } = useParams<{ insight: string }>();
  const [activeInsight, setActiveInsight] = useState(insight);

  const renderSwitch = useCallback(() => {
    switch (activeInsight) {
      case Insight.overview.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <SiteOverview />
          </TabWrapper>
        );
      case Insight.traffic.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <ChannelInsights />
          </TabWrapper>
        );
      case Insight.audiences.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <AudienceInsights />
          </TabWrapper>
        );
      case Insight.content.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <ContentInsights />
          </TabWrapper>
        );
      case Insight.devices.toLowerCase().replace(" ", "_"):
        return (
          <TabWrapper>
            <DevicesInsights />
          </TabWrapper>
        );
      default:
        return (
          <TabWrapper>
            <CenterPage calcHeight>
              <Typography variant="subtitle1">Not Implemented</Typography>
            </CenterPage>
          </TabWrapper>
        );
    }
  }, [activeInsight]);

  if (!insight || insight === "") {
    return <Redirect to={`./quick-insights/site_overview`} />;
  }

  return (
    <TabbedAnalysesPage
      title="Quick Insights"
      activeTab={activeInsight}
      setActiveTab={setActiveInsight}
      tabs={enumToArray(Insight)}
      renderSwitch={renderSwitch}
    />
  );
}

export default QuickInsightsOverviewPage;
