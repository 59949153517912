import { ILeftMenuActions } from "Actions";
import {
  CLOSE_MENU,
  CLOSE_SECONDARY_MENU,
  OPEN_MENU,
  OPEN_SECONDARY_MENU,
  SET_ACTIVE,
  SET_ANALYSIS,
  SET_SUB_ACTIVE,
  TOGGLE_ANALYSIS,
  TOGGLE_HOVER,
  TOGGLE_MENU,
  TOGGLE_SECONDARY_MENU,
} from "Actions/LeftMenuActions/leftMenuActionTypes";
import { Reducer } from "redux";

export interface IleftMenuReducer {
  isMenuOpen: boolean;
  key: string;
  subKey: string;
  isSecondaryOpen: boolean;
  isAnalysisActive: boolean;
  debouncedIsHover: boolean;
}

export const leftMenuReducer: Reducer<IleftMenuReducer, ILeftMenuActions> = (
  state = {
    isMenuOpen: true,
    key: "",
    subKey: "",
    isSecondaryOpen: false,
    isAnalysisActive: false,
    debouncedIsHover: false,
  },
  action
) => {
  switch (action.type) {
    case OPEN_MENU: {
      return {
        ...state,
        isMenuOpen: true,
      };
    }
    case CLOSE_MENU: {
      return {
        ...state,
        isMenuOpen: false,
      };
    }
    case TOGGLE_MENU: {
      return {
        ...state,
        isMenuOpen: !state.isMenuOpen,
      };
    }
    case TOGGLE_HOVER: {
      return {
        ...state,
        debouncedIsHover: !state.debouncedIsHover,
      };
    }
    case TOGGLE_SECONDARY_MENU: {
      return {
        ...state,
        isSecondaryOpen: !state.isSecondaryOpen,
      };
    }
    case OPEN_SECONDARY_MENU: {
      return {
        ...state,
        isSecondaryOpen: true,
      };
    }
    case CLOSE_SECONDARY_MENU: {
      return {
        ...state,
        isSecondaryOpen: false,
      };
    }
    case TOGGLE_ANALYSIS: {
      return {
        ...state,
        isAnalysisActive: !state.isAnalysisActive,
      };
    }
    case SET_ANALYSIS: {
      const { value } = action.payload;
      return {
        ...state,
        isAnalysisActive: value,
      };
    }
    case SET_ACTIVE: {
      const { key } = action.payload;
      return {
        ...state,
        key,
      };
    }
    case SET_SUB_ACTIVE: {
      const { key: subKey } = action.payload;
      return {
        ...state,
        subKey,
      };
    }
  }
  return state;
};
