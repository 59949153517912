import { ArrowForwardIosSharp } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionSummaryProps,
  alpha,
  Box,
  BoxProps,
  styled,
  Typography,
} from "@mui/material";
import { useState } from "react";

export const ChartBuilderAccordianSummary = styled(
  (props: AccordionSummaryProps) => (
    <AccordionSummary
      expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  )
)(({ theme }) => ({
  flexDirection: "row-reverse",
  minHeight: 16,
  padding: 0,
  "&.Mui-expanded": {
    marginBottom: theme.spacing(2),
  },
  transition: `margin-bottom 400ms cubic-bezier(0.4, 0, 0.2, 1), min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
    marginLeft: theme.spacing(2),
  },
}));

export const ChartBuilderSidebarBlock = styled(({ ...rest }: BoxProps) => (
  <Box {...rest} />
))(({ theme, ...x }) => {
  return {
    padding: theme.spacing(2, 3),
    display: "flex",
    flexDirection: "column",
    borderBottom: `1px solid ${alpha(theme.palette.secondary.main, 0.2)}`,
    borderTop: `1px solid  rgba(255, 255, 255, 0.7)`,
    "&:first-of-type": {
      borderTop: "none",
    },
    "&:not(:has(~ &))": {
      borderBottom: "none",
    },
  };
});

export const ChartBuilderSidebarContainer = styled(({ ...rest }: BoxProps) => (
  <Box {...rest} />
))(({ theme }) => ({
  overflowX: "hidden",
  overflowY: "auto",
  height: `100%`,
}));

interface IAccordianChartBuilderSidebarBlockProps {
  title: string | React.ReactNode;
  subTitle?: string | React.ReactNode;
  children: React.ReactNode;
  defaultClosed?: boolean;
}
export const AccordianChartBuilderSidebarBlock = ({
  title,
  subTitle,
  children,
  defaultClosed,
}: IAccordianChartBuilderSidebarBlockProps) => {
  const [open, setOpen] = useState(defaultClosed ? !defaultClosed : true);
  return (
    <ChartBuilderSidebarBlock>
      <Accordion
        disableGutters
        sx={{ background: "transparent", padding: 0 }}
        elevation={0}
        expanded={open}
        onChange={() => setOpen(!open)}
      >
        <ChartBuilderAccordianSummary>
          <Box>
            {typeof title === "function" ? (
              title
            ) : (
              <Typography variant="subtitle1">{title}</Typography>
            )}
            {subTitle && typeof subTitle === "function" ? (
              subTitle
            ) : (
              <Typography variant="body2">{subTitle}</Typography>
            )}
          </Box>
        </ChartBuilderAccordianSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Box mt={1}>{children}</Box>
        </AccordionDetails>
      </Accordion>
    </ChartBuilderSidebarBlock>
  );
};
