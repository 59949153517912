/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiCanActivateCampaignResponseFromJSON, ApiCreateUpdateCampaignPayloadToJSON, ApiCreateUpdateComponentPayloadToJSON, ApiCreateUpdatePersonalisationPayloadToJSON, ApiListCampaignsResponseFromJSON, ApiListComponentsResponseFromJSON, ApiListPersonalisationsResponseFromJSON, ApiReadCampaignResponseFromJSON, ApiReadComponentResponseFromJSON, ApiReadPersonalisationResponseFromJSON, } from '../models';
/**
 *
 */
export class PersonalisationServiceApi extends runtime.BaseAPI {
    /**
     */
    personalisationServiceActivateCampaignRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling personalisationServiceActivateCampaign.');
            }
            if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
                throw new runtime.RequiredError('campaignId', 'Required parameter requestParameters.campaignId was null or undefined when calling personalisationServiceActivateCampaign.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadStartDate !== undefined) {
                queryParameters['payload.startDate'] = requestParameters.payloadStartDate.toISOString();
            }
            if (requestParameters.payloadEndDate !== undefined) {
                queryParameters['payload.endDate'] = requestParameters.payloadEndDate.toISOString();
            }
            if (requestParameters.payloadType !== undefined) {
                queryParameters['payload.type'] = requestParameters.payloadType;
            }
            if (requestParameters.payloadMaxNumberOfLeads !== undefined) {
                queryParameters['payload.maxNumberOfLeads'] = requestParameters.payloadMaxNumberOfLeads;
            }
            if (requestParameters.payloadProbability !== undefined) {
                queryParameters['payload.probability'] = requestParameters.payloadProbability;
            }
            if (requestParameters.payloadAudienceIds) {
                queryParameters['payload.audienceIds'] = requestParameters.payloadAudienceIds;
            }
            if (requestParameters.payloadSubdomainId !== undefined) {
                queryParameters['payload.subdomainId'] = requestParameters.payloadSubdomainId;
            }
            if (requestParameters.payloadUrls) {
                queryParameters['payload.urls'] = requestParameters.payloadUrls;
            }
            if (requestParameters.payloadFallbackUrl !== undefined) {
                queryParameters['payload.fallbackUrl'] = requestParameters.payloadFallbackUrl;
            }
            if (requestParameters.token !== undefined) {
                queryParameters['token'] = requestParameters.token;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/personalisation/v1/projects/{projectId}/campaigns/{campaignId}/activate`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"campaignId"}}`, encodeURIComponent(String(requestParameters.campaignId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReadCampaignResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    personalisationServiceActivateCampaign(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.personalisationServiceActivateCampaignRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    personalisationServiceCanActivateCampaignRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling personalisationServiceCanActivateCampaign.');
            }
            if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
                throw new runtime.RequiredError('campaignId', 'Required parameter requestParameters.campaignId was null or undefined when calling personalisationServiceCanActivateCampaign.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadStartDate !== undefined) {
                queryParameters['payload.startDate'] = requestParameters.payloadStartDate.toISOString();
            }
            if (requestParameters.payloadEndDate !== undefined) {
                queryParameters['payload.endDate'] = requestParameters.payloadEndDate.toISOString();
            }
            if (requestParameters.payloadType !== undefined) {
                queryParameters['payload.type'] = requestParameters.payloadType;
            }
            if (requestParameters.payloadMaxNumberOfLeads !== undefined) {
                queryParameters['payload.maxNumberOfLeads'] = requestParameters.payloadMaxNumberOfLeads;
            }
            if (requestParameters.payloadProbability !== undefined) {
                queryParameters['payload.probability'] = requestParameters.payloadProbability;
            }
            if (requestParameters.payloadAudienceIds) {
                queryParameters['payload.audienceIds'] = requestParameters.payloadAudienceIds;
            }
            if (requestParameters.payloadSubdomainId !== undefined) {
                queryParameters['payload.subdomainId'] = requestParameters.payloadSubdomainId;
            }
            if (requestParameters.payloadUrls) {
                queryParameters['payload.urls'] = requestParameters.payloadUrls;
            }
            if (requestParameters.payloadFallbackUrl !== undefined) {
                queryParameters['payload.fallbackUrl'] = requestParameters.payloadFallbackUrl;
            }
            if (requestParameters.token !== undefined) {
                queryParameters['token'] = requestParameters.token;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/personalisation/v1/projects/{projectId}/campaigns/{campaignId}/can-activate`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"campaignId"}}`, encodeURIComponent(String(requestParameters.campaignId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiCanActivateCampaignResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    personalisationServiceCanActivateCampaign(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.personalisationServiceCanActivateCampaignRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    personalisationServiceCreateCampaignRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling personalisationServiceCreateCampaign.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling personalisationServiceCreateCampaign.');
            }
            const queryParameters = {};
            if (requestParameters.campaignId !== undefined) {
                queryParameters['campaignId'] = requestParameters.campaignId;
            }
            if (requestParameters.token !== undefined) {
                queryParameters['token'] = requestParameters.token;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/personalisation/v1/projects/{projectId}/campaigns`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiCreateUpdateCampaignPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReadCampaignResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    personalisationServiceCreateCampaign(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.personalisationServiceCreateCampaignRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    personalisationServiceCreateComponentRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling personalisationServiceCreateComponent.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling personalisationServiceCreateComponent.');
            }
            const queryParameters = {};
            if (requestParameters.componentId !== undefined) {
                queryParameters['componentId'] = requestParameters.componentId;
            }
            if (requestParameters.token !== undefined) {
                queryParameters['token'] = requestParameters.token;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/personalisation/v1/projects/{projectId}/components`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiCreateUpdateComponentPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReadComponentResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    personalisationServiceCreateComponent(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.personalisationServiceCreateComponentRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    personalisationServiceCreatePersonalisationRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling personalisationServiceCreatePersonalisation.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling personalisationServiceCreatePersonalisation.');
            }
            const queryParameters = {};
            if (requestParameters.personalisationId !== undefined) {
                queryParameters['personalisationId'] = requestParameters.personalisationId;
            }
            if (requestParameters.token !== undefined) {
                queryParameters['token'] = requestParameters.token;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/personalisation/v1/projects/{projectId}/personalisations`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiCreateUpdatePersonalisationPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReadPersonalisationResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    personalisationServiceCreatePersonalisation(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.personalisationServiceCreatePersonalisationRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    personalisationServiceDeactivateCampaignRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling personalisationServiceDeactivateCampaign.');
            }
            if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
                throw new runtime.RequiredError('campaignId', 'Required parameter requestParameters.campaignId was null or undefined when calling personalisationServiceDeactivateCampaign.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadStartDate !== undefined) {
                queryParameters['payload.startDate'] = requestParameters.payloadStartDate.toISOString();
            }
            if (requestParameters.payloadEndDate !== undefined) {
                queryParameters['payload.endDate'] = requestParameters.payloadEndDate.toISOString();
            }
            if (requestParameters.payloadType !== undefined) {
                queryParameters['payload.type'] = requestParameters.payloadType;
            }
            if (requestParameters.payloadMaxNumberOfLeads !== undefined) {
                queryParameters['payload.maxNumberOfLeads'] = requestParameters.payloadMaxNumberOfLeads;
            }
            if (requestParameters.payloadProbability !== undefined) {
                queryParameters['payload.probability'] = requestParameters.payloadProbability;
            }
            if (requestParameters.payloadAudienceIds) {
                queryParameters['payload.audienceIds'] = requestParameters.payloadAudienceIds;
            }
            if (requestParameters.payloadSubdomainId !== undefined) {
                queryParameters['payload.subdomainId'] = requestParameters.payloadSubdomainId;
            }
            if (requestParameters.payloadUrls) {
                queryParameters['payload.urls'] = requestParameters.payloadUrls;
            }
            if (requestParameters.payloadFallbackUrl !== undefined) {
                queryParameters['payload.fallbackUrl'] = requestParameters.payloadFallbackUrl;
            }
            if (requestParameters.token !== undefined) {
                queryParameters['token'] = requestParameters.token;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/personalisation/v1/projects/{projectId}/campaigns/{campaignId}/deactivate`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"campaignId"}}`, encodeURIComponent(String(requestParameters.campaignId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReadCampaignResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    personalisationServiceDeactivateCampaign(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.personalisationServiceDeactivateCampaignRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    personalisationServiceDeleteCampaignRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling personalisationServiceDeleteCampaign.');
            }
            if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
                throw new runtime.RequiredError('campaignId', 'Required parameter requestParameters.campaignId was null or undefined when calling personalisationServiceDeleteCampaign.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadStartDate !== undefined) {
                queryParameters['payload.startDate'] = requestParameters.payloadStartDate.toISOString();
            }
            if (requestParameters.payloadEndDate !== undefined) {
                queryParameters['payload.endDate'] = requestParameters.payloadEndDate.toISOString();
            }
            if (requestParameters.payloadType !== undefined) {
                queryParameters['payload.type'] = requestParameters.payloadType;
            }
            if (requestParameters.payloadMaxNumberOfLeads !== undefined) {
                queryParameters['payload.maxNumberOfLeads'] = requestParameters.payloadMaxNumberOfLeads;
            }
            if (requestParameters.payloadProbability !== undefined) {
                queryParameters['payload.probability'] = requestParameters.payloadProbability;
            }
            if (requestParameters.payloadAudienceIds) {
                queryParameters['payload.audienceIds'] = requestParameters.payloadAudienceIds;
            }
            if (requestParameters.payloadSubdomainId !== undefined) {
                queryParameters['payload.subdomainId'] = requestParameters.payloadSubdomainId;
            }
            if (requestParameters.payloadUrls) {
                queryParameters['payload.urls'] = requestParameters.payloadUrls;
            }
            if (requestParameters.payloadFallbackUrl !== undefined) {
                queryParameters['payload.fallbackUrl'] = requestParameters.payloadFallbackUrl;
            }
            if (requestParameters.token !== undefined) {
                queryParameters['token'] = requestParameters.token;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/personalisation/v1/projects/{projectId}/campaigns/{campaignId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"campaignId"}}`, encodeURIComponent(String(requestParameters.campaignId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    personalisationServiceDeleteCampaign(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.personalisationServiceDeleteCampaignRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    personalisationServiceDeleteComponentRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling personalisationServiceDeleteComponent.');
            }
            if (requestParameters.componentId === null || requestParameters.componentId === undefined) {
                throw new runtime.RequiredError('componentId', 'Required parameter requestParameters.componentId was null or undefined when calling personalisationServiceDeleteComponent.');
            }
            const queryParameters = {};
            if (requestParameters.payloadHtml !== undefined) {
                queryParameters['payload.html'] = requestParameters.payloadHtml;
            }
            if (requestParameters.payloadCss !== undefined) {
                queryParameters['payload.css'] = requestParameters.payloadCss;
            }
            if (requestParameters.payloadJs !== undefined) {
                queryParameters['payload.js'] = requestParameters.payloadJs;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadGrapeJs !== undefined) {
                queryParameters['payload.grapeJs'] = requestParameters.payloadGrapeJs;
            }
            if (requestParameters.payloadUiSource !== undefined) {
                queryParameters['payload.uiSource'] = requestParameters.payloadUiSource;
            }
            if (requestParameters.payloadHead !== undefined) {
                queryParameters['payload.head'] = requestParameters.payloadHead;
            }
            if (requestParameters.payloadSeoTitle !== undefined) {
                queryParameters['payload.seoTitle'] = requestParameters.payloadSeoTitle;
            }
            if (requestParameters.payloadSeoDescrition !== undefined) {
                queryParameters['payload.seoDescrition'] = requestParameters.payloadSeoDescrition;
            }
            if (requestParameters.payloadFavicon !== undefined) {
                queryParameters['payload.favicon'] = requestParameters.payloadFavicon;
            }
            if (requestParameters.token !== undefined) {
                queryParameters['token'] = requestParameters.token;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/personalisation/v1/projects/{projectId}/components/{componentId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"componentId"}}`, encodeURIComponent(String(requestParameters.componentId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    personalisationServiceDeleteComponent(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.personalisationServiceDeleteComponentRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    personalisationServiceDeletePersonalisationRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling personalisationServiceDeletePersonalisation.');
            }
            if (requestParameters.personalisationId === null || requestParameters.personalisationId === undefined) {
                throw new runtime.RequiredError('personalisationId', 'Required parameter requestParameters.personalisationId was null or undefined when calling personalisationServiceDeletePersonalisation.');
            }
            const queryParameters = {};
            if (requestParameters.payloadLocationId !== undefined) {
                queryParameters['payload.locationId'] = requestParameters.payloadLocationId;
            }
            if (requestParameters.payloadAppliedTo !== undefined) {
                queryParameters['payload.appliedTo'] = requestParameters.payloadAppliedTo;
            }
            if (requestParameters.payloadInsertionType !== undefined) {
                queryParameters['payload.insertionType'] = requestParameters.payloadInsertionType;
            }
            if (requestParameters.payloadCampaignId !== undefined) {
                queryParameters['payload.campaignId'] = requestParameters.payloadCampaignId;
            }
            if (requestParameters.payloadComponentId !== undefined) {
                queryParameters['payload.componentId'] = requestParameters.payloadComponentId;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.token !== undefined) {
                queryParameters['token'] = requestParameters.token;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/personalisation/v1/projects/{projectId}/personalisations/{personalisationId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"personalisationId"}}`, encodeURIComponent(String(requestParameters.personalisationId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    personalisationServiceDeletePersonalisation(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.personalisationServiceDeletePersonalisationRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * ------------- Campaigns ----------------
     */
    personalisationServiceListCampaignsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling personalisationServiceListCampaigns.');
            }
            const queryParameters = {};
            if (requestParameters.campaignId !== undefined) {
                queryParameters['campaignId'] = requestParameters.campaignId;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadStartDate !== undefined) {
                queryParameters['payload.startDate'] = requestParameters.payloadStartDate.toISOString();
            }
            if (requestParameters.payloadEndDate !== undefined) {
                queryParameters['payload.endDate'] = requestParameters.payloadEndDate.toISOString();
            }
            if (requestParameters.payloadType !== undefined) {
                queryParameters['payload.type'] = requestParameters.payloadType;
            }
            if (requestParameters.payloadMaxNumberOfLeads !== undefined) {
                queryParameters['payload.maxNumberOfLeads'] = requestParameters.payloadMaxNumberOfLeads;
            }
            if (requestParameters.payloadProbability !== undefined) {
                queryParameters['payload.probability'] = requestParameters.payloadProbability;
            }
            if (requestParameters.payloadAudienceIds) {
                queryParameters['payload.audienceIds'] = requestParameters.payloadAudienceIds;
            }
            if (requestParameters.payloadSubdomainId !== undefined) {
                queryParameters['payload.subdomainId'] = requestParameters.payloadSubdomainId;
            }
            if (requestParameters.payloadUrls) {
                queryParameters['payload.urls'] = requestParameters.payloadUrls;
            }
            if (requestParameters.payloadFallbackUrl !== undefined) {
                queryParameters['payload.fallbackUrl'] = requestParameters.payloadFallbackUrl;
            }
            if (requestParameters.token !== undefined) {
                queryParameters['token'] = requestParameters.token;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/personalisation/v1/projects/{projectId}/campaigns`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListCampaignsResponseFromJSON(jsonValue));
        });
    }
    /**
     * ------------- Campaigns ----------------
     */
    personalisationServiceListCampaigns(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.personalisationServiceListCampaignsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * ------------- Components ----------------
     */
    personalisationServiceListComponentsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling personalisationServiceListComponents.');
            }
            const queryParameters = {};
            if (requestParameters.componentId !== undefined) {
                queryParameters['componentId'] = requestParameters.componentId;
            }
            if (requestParameters.payloadHtml !== undefined) {
                queryParameters['payload.html'] = requestParameters.payloadHtml;
            }
            if (requestParameters.payloadCss !== undefined) {
                queryParameters['payload.css'] = requestParameters.payloadCss;
            }
            if (requestParameters.payloadJs !== undefined) {
                queryParameters['payload.js'] = requestParameters.payloadJs;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadGrapeJs !== undefined) {
                queryParameters['payload.grapeJs'] = requestParameters.payloadGrapeJs;
            }
            if (requestParameters.payloadUiSource !== undefined) {
                queryParameters['payload.uiSource'] = requestParameters.payloadUiSource;
            }
            if (requestParameters.payloadHead !== undefined) {
                queryParameters['payload.head'] = requestParameters.payloadHead;
            }
            if (requestParameters.payloadSeoTitle !== undefined) {
                queryParameters['payload.seoTitle'] = requestParameters.payloadSeoTitle;
            }
            if (requestParameters.payloadSeoDescrition !== undefined) {
                queryParameters['payload.seoDescrition'] = requestParameters.payloadSeoDescrition;
            }
            if (requestParameters.payloadFavicon !== undefined) {
                queryParameters['payload.favicon'] = requestParameters.payloadFavicon;
            }
            if (requestParameters.token !== undefined) {
                queryParameters['token'] = requestParameters.token;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/personalisation/v1/projects/{projectId}/components`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListComponentsResponseFromJSON(jsonValue));
        });
    }
    /**
     * ------------- Components ----------------
     */
    personalisationServiceListComponents(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.personalisationServiceListComponentsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * ------------- Personalisations ----------------
     */
    personalisationServiceListPersonalisationsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling personalisationServiceListPersonalisations.');
            }
            const queryParameters = {};
            if (requestParameters.personalisationId !== undefined) {
                queryParameters['personalisationId'] = requestParameters.personalisationId;
            }
            if (requestParameters.payloadLocationId !== undefined) {
                queryParameters['payload.locationId'] = requestParameters.payloadLocationId;
            }
            if (requestParameters.payloadAppliedTo !== undefined) {
                queryParameters['payload.appliedTo'] = requestParameters.payloadAppliedTo;
            }
            if (requestParameters.payloadInsertionType !== undefined) {
                queryParameters['payload.insertionType'] = requestParameters.payloadInsertionType;
            }
            if (requestParameters.payloadCampaignId !== undefined) {
                queryParameters['payload.campaignId'] = requestParameters.payloadCampaignId;
            }
            if (requestParameters.payloadComponentId !== undefined) {
                queryParameters['payload.componentId'] = requestParameters.payloadComponentId;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.token !== undefined) {
                queryParameters['token'] = requestParameters.token;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/personalisation/v1/projects/{projectId}/personalisations`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListPersonalisationsResponseFromJSON(jsonValue));
        });
    }
    /**
     * ------------- Personalisations ----------------
     */
    personalisationServiceListPersonalisations(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.personalisationServiceListPersonalisationsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    personalisationServiceReadCampaignRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling personalisationServiceReadCampaign.');
            }
            if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
                throw new runtime.RequiredError('campaignId', 'Required parameter requestParameters.campaignId was null or undefined when calling personalisationServiceReadCampaign.');
            }
            const queryParameters = {};
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadStartDate !== undefined) {
                queryParameters['payload.startDate'] = requestParameters.payloadStartDate.toISOString();
            }
            if (requestParameters.payloadEndDate !== undefined) {
                queryParameters['payload.endDate'] = requestParameters.payloadEndDate.toISOString();
            }
            if (requestParameters.payloadType !== undefined) {
                queryParameters['payload.type'] = requestParameters.payloadType;
            }
            if (requestParameters.payloadMaxNumberOfLeads !== undefined) {
                queryParameters['payload.maxNumberOfLeads'] = requestParameters.payloadMaxNumberOfLeads;
            }
            if (requestParameters.payloadProbability !== undefined) {
                queryParameters['payload.probability'] = requestParameters.payloadProbability;
            }
            if (requestParameters.payloadAudienceIds) {
                queryParameters['payload.audienceIds'] = requestParameters.payloadAudienceIds;
            }
            if (requestParameters.payloadSubdomainId !== undefined) {
                queryParameters['payload.subdomainId'] = requestParameters.payloadSubdomainId;
            }
            if (requestParameters.payloadUrls) {
                queryParameters['payload.urls'] = requestParameters.payloadUrls;
            }
            if (requestParameters.payloadFallbackUrl !== undefined) {
                queryParameters['payload.fallbackUrl'] = requestParameters.payloadFallbackUrl;
            }
            if (requestParameters.token !== undefined) {
                queryParameters['token'] = requestParameters.token;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/personalisation/v1/projects/{projectId}/campaigns/{campaignId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"campaignId"}}`, encodeURIComponent(String(requestParameters.campaignId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReadCampaignResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    personalisationServiceReadCampaign(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.personalisationServiceReadCampaignRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    personalisationServiceReadComponentRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling personalisationServiceReadComponent.');
            }
            if (requestParameters.componentId === null || requestParameters.componentId === undefined) {
                throw new runtime.RequiredError('componentId', 'Required parameter requestParameters.componentId was null or undefined when calling personalisationServiceReadComponent.');
            }
            const queryParameters = {};
            if (requestParameters.payloadHtml !== undefined) {
                queryParameters['payload.html'] = requestParameters.payloadHtml;
            }
            if (requestParameters.payloadCss !== undefined) {
                queryParameters['payload.css'] = requestParameters.payloadCss;
            }
            if (requestParameters.payloadJs !== undefined) {
                queryParameters['payload.js'] = requestParameters.payloadJs;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.payloadGrapeJs !== undefined) {
                queryParameters['payload.grapeJs'] = requestParameters.payloadGrapeJs;
            }
            if (requestParameters.payloadUiSource !== undefined) {
                queryParameters['payload.uiSource'] = requestParameters.payloadUiSource;
            }
            if (requestParameters.payloadHead !== undefined) {
                queryParameters['payload.head'] = requestParameters.payloadHead;
            }
            if (requestParameters.payloadSeoTitle !== undefined) {
                queryParameters['payload.seoTitle'] = requestParameters.payloadSeoTitle;
            }
            if (requestParameters.payloadSeoDescrition !== undefined) {
                queryParameters['payload.seoDescrition'] = requestParameters.payloadSeoDescrition;
            }
            if (requestParameters.payloadFavicon !== undefined) {
                queryParameters['payload.favicon'] = requestParameters.payloadFavicon;
            }
            if (requestParameters.token !== undefined) {
                queryParameters['token'] = requestParameters.token;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/personalisation/v1/projects/{projectId}/components/{componentId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"componentId"}}`, encodeURIComponent(String(requestParameters.componentId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReadComponentResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    personalisationServiceReadComponent(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.personalisationServiceReadComponentRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    personalisationServiceReadPersonalisationRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling personalisationServiceReadPersonalisation.');
            }
            if (requestParameters.personalisationId === null || requestParameters.personalisationId === undefined) {
                throw new runtime.RequiredError('personalisationId', 'Required parameter requestParameters.personalisationId was null or undefined when calling personalisationServiceReadPersonalisation.');
            }
            const queryParameters = {};
            if (requestParameters.payloadLocationId !== undefined) {
                queryParameters['payload.locationId'] = requestParameters.payloadLocationId;
            }
            if (requestParameters.payloadAppliedTo !== undefined) {
                queryParameters['payload.appliedTo'] = requestParameters.payloadAppliedTo;
            }
            if (requestParameters.payloadInsertionType !== undefined) {
                queryParameters['payload.insertionType'] = requestParameters.payloadInsertionType;
            }
            if (requestParameters.payloadCampaignId !== undefined) {
                queryParameters['payload.campaignId'] = requestParameters.payloadCampaignId;
            }
            if (requestParameters.payloadComponentId !== undefined) {
                queryParameters['payload.componentId'] = requestParameters.payloadComponentId;
            }
            if (requestParameters.payloadName !== undefined) {
                queryParameters['payload.name'] = requestParameters.payloadName;
            }
            if (requestParameters.payloadDescription !== undefined) {
                queryParameters['payload.description'] = requestParameters.payloadDescription;
            }
            if (requestParameters.token !== undefined) {
                queryParameters['token'] = requestParameters.token;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/personalisation/v1/projects/{projectId}/personalisations/{personalisationId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"personalisationId"}}`, encodeURIComponent(String(requestParameters.personalisationId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReadPersonalisationResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    personalisationServiceReadPersonalisation(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.personalisationServiceReadPersonalisationRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    personalisationServiceUpdateCampaignRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling personalisationServiceUpdateCampaign.');
            }
            if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
                throw new runtime.RequiredError('campaignId', 'Required parameter requestParameters.campaignId was null or undefined when calling personalisationServiceUpdateCampaign.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling personalisationServiceUpdateCampaign.');
            }
            const queryParameters = {};
            if (requestParameters.token !== undefined) {
                queryParameters['token'] = requestParameters.token;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/personalisation/v1/projects/{projectId}/campaigns/{campaignId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"campaignId"}}`, encodeURIComponent(String(requestParameters.campaignId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiCreateUpdateCampaignPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReadCampaignResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    personalisationServiceUpdateCampaign(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.personalisationServiceUpdateCampaignRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    personalisationServiceUpdateComponentRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling personalisationServiceUpdateComponent.');
            }
            if (requestParameters.componentId === null || requestParameters.componentId === undefined) {
                throw new runtime.RequiredError('componentId', 'Required parameter requestParameters.componentId was null or undefined when calling personalisationServiceUpdateComponent.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling personalisationServiceUpdateComponent.');
            }
            const queryParameters = {};
            if (requestParameters.token !== undefined) {
                queryParameters['token'] = requestParameters.token;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/personalisation/v1/projects/{projectId}/components/{componentId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"componentId"}}`, encodeURIComponent(String(requestParameters.componentId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiCreateUpdateComponentPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReadComponentResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    personalisationServiceUpdateComponent(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.personalisationServiceUpdateComponentRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    personalisationServiceUpdatePersonalisationRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling personalisationServiceUpdatePersonalisation.');
            }
            if (requestParameters.personalisationId === null || requestParameters.personalisationId === undefined) {
                throw new runtime.RequiredError('personalisationId', 'Required parameter requestParameters.personalisationId was null or undefined when calling personalisationServiceUpdatePersonalisation.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling personalisationServiceUpdatePersonalisation.');
            }
            const queryParameters = {};
            if (requestParameters.token !== undefined) {
                queryParameters['token'] = requestParameters.token;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/personalisation/v1/projects/{projectId}/personalisations/{personalisationId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))).replace(`{${"personalisationId"}}`, encodeURIComponent(String(requestParameters.personalisationId))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: ApiCreateUpdatePersonalisationPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiReadPersonalisationResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    personalisationServiceUpdatePersonalisation(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.personalisationServiceUpdatePersonalisationRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
/**
    * @export
    * @enum {string}
    */
export var PersonalisationServiceActivateCampaignPayloadTypeEnum;
(function (PersonalisationServiceActivateCampaignPayloadTypeEnum) {
    PersonalisationServiceActivateCampaignPayloadTypeEnum["CAMPAIGN_TYPE_NONE"] = "CampaignTypeNone";
    PersonalisationServiceActivateCampaignPayloadTypeEnum["CAMPAIGN_TYPE_AB"] = "CampaignTypeAB";
    PersonalisationServiceActivateCampaignPayloadTypeEnum["CAMPAIGN_TYPE_SPLIT"] = "CampaignTypeSplit";
})(PersonalisationServiceActivateCampaignPayloadTypeEnum || (PersonalisationServiceActivateCampaignPayloadTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PersonalisationServiceCanActivateCampaignPayloadTypeEnum;
(function (PersonalisationServiceCanActivateCampaignPayloadTypeEnum) {
    PersonalisationServiceCanActivateCampaignPayloadTypeEnum["CAMPAIGN_TYPE_NONE"] = "CampaignTypeNone";
    PersonalisationServiceCanActivateCampaignPayloadTypeEnum["CAMPAIGN_TYPE_AB"] = "CampaignTypeAB";
    PersonalisationServiceCanActivateCampaignPayloadTypeEnum["CAMPAIGN_TYPE_SPLIT"] = "CampaignTypeSplit";
})(PersonalisationServiceCanActivateCampaignPayloadTypeEnum || (PersonalisationServiceCanActivateCampaignPayloadTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PersonalisationServiceDeactivateCampaignPayloadTypeEnum;
(function (PersonalisationServiceDeactivateCampaignPayloadTypeEnum) {
    PersonalisationServiceDeactivateCampaignPayloadTypeEnum["CAMPAIGN_TYPE_NONE"] = "CampaignTypeNone";
    PersonalisationServiceDeactivateCampaignPayloadTypeEnum["CAMPAIGN_TYPE_AB"] = "CampaignTypeAB";
    PersonalisationServiceDeactivateCampaignPayloadTypeEnum["CAMPAIGN_TYPE_SPLIT"] = "CampaignTypeSplit";
})(PersonalisationServiceDeactivateCampaignPayloadTypeEnum || (PersonalisationServiceDeactivateCampaignPayloadTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PersonalisationServiceDeleteCampaignPayloadTypeEnum;
(function (PersonalisationServiceDeleteCampaignPayloadTypeEnum) {
    PersonalisationServiceDeleteCampaignPayloadTypeEnum["CAMPAIGN_TYPE_NONE"] = "CampaignTypeNone";
    PersonalisationServiceDeleteCampaignPayloadTypeEnum["CAMPAIGN_TYPE_AB"] = "CampaignTypeAB";
    PersonalisationServiceDeleteCampaignPayloadTypeEnum["CAMPAIGN_TYPE_SPLIT"] = "CampaignTypeSplit";
})(PersonalisationServiceDeleteCampaignPayloadTypeEnum || (PersonalisationServiceDeleteCampaignPayloadTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PersonalisationServiceDeleteComponentPayloadUiSourceEnum;
(function (PersonalisationServiceDeleteComponentPayloadUiSourceEnum) {
    PersonalisationServiceDeleteComponentPayloadUiSourceEnum["UNSPECIFIED"] = "ComponentUISource_Unspecified";
    PersonalisationServiceDeleteComponentPayloadUiSourceEnum["GRAPES"] = "ComponentUISource_grapes";
    PersonalisationServiceDeleteComponentPayloadUiSourceEnum["CODE"] = "ComponentUISource_code";
})(PersonalisationServiceDeleteComponentPayloadUiSourceEnum || (PersonalisationServiceDeleteComponentPayloadUiSourceEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PersonalisationServiceDeletePersonalisationPayloadInsertionTypeEnum;
(function (PersonalisationServiceDeletePersonalisationPayloadInsertionTypeEnum) {
    PersonalisationServiceDeletePersonalisationPayloadInsertionTypeEnum["IT_UNSPECIFIED"] = "IT_UNSPECIFIED";
    PersonalisationServiceDeletePersonalisationPayloadInsertionTypeEnum["APPEND"] = "APPEND";
    PersonalisationServiceDeletePersonalisationPayloadInsertionTypeEnum["PREPEND"] = "PREPEND";
    PersonalisationServiceDeletePersonalisationPayloadInsertionTypeEnum["REPLACE"] = "REPLACE";
})(PersonalisationServiceDeletePersonalisationPayloadInsertionTypeEnum || (PersonalisationServiceDeletePersonalisationPayloadInsertionTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PersonalisationServiceListCampaignsPayloadTypeEnum;
(function (PersonalisationServiceListCampaignsPayloadTypeEnum) {
    PersonalisationServiceListCampaignsPayloadTypeEnum["CAMPAIGN_TYPE_NONE"] = "CampaignTypeNone";
    PersonalisationServiceListCampaignsPayloadTypeEnum["CAMPAIGN_TYPE_AB"] = "CampaignTypeAB";
    PersonalisationServiceListCampaignsPayloadTypeEnum["CAMPAIGN_TYPE_SPLIT"] = "CampaignTypeSplit";
})(PersonalisationServiceListCampaignsPayloadTypeEnum || (PersonalisationServiceListCampaignsPayloadTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PersonalisationServiceListComponentsPayloadUiSourceEnum;
(function (PersonalisationServiceListComponentsPayloadUiSourceEnum) {
    PersonalisationServiceListComponentsPayloadUiSourceEnum["UNSPECIFIED"] = "ComponentUISource_Unspecified";
    PersonalisationServiceListComponentsPayloadUiSourceEnum["GRAPES"] = "ComponentUISource_grapes";
    PersonalisationServiceListComponentsPayloadUiSourceEnum["CODE"] = "ComponentUISource_code";
})(PersonalisationServiceListComponentsPayloadUiSourceEnum || (PersonalisationServiceListComponentsPayloadUiSourceEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PersonalisationServiceListPersonalisationsPayloadInsertionTypeEnum;
(function (PersonalisationServiceListPersonalisationsPayloadInsertionTypeEnum) {
    PersonalisationServiceListPersonalisationsPayloadInsertionTypeEnum["IT_UNSPECIFIED"] = "IT_UNSPECIFIED";
    PersonalisationServiceListPersonalisationsPayloadInsertionTypeEnum["APPEND"] = "APPEND";
    PersonalisationServiceListPersonalisationsPayloadInsertionTypeEnum["PREPEND"] = "PREPEND";
    PersonalisationServiceListPersonalisationsPayloadInsertionTypeEnum["REPLACE"] = "REPLACE";
})(PersonalisationServiceListPersonalisationsPayloadInsertionTypeEnum || (PersonalisationServiceListPersonalisationsPayloadInsertionTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PersonalisationServiceReadCampaignPayloadTypeEnum;
(function (PersonalisationServiceReadCampaignPayloadTypeEnum) {
    PersonalisationServiceReadCampaignPayloadTypeEnum["CAMPAIGN_TYPE_NONE"] = "CampaignTypeNone";
    PersonalisationServiceReadCampaignPayloadTypeEnum["CAMPAIGN_TYPE_AB"] = "CampaignTypeAB";
    PersonalisationServiceReadCampaignPayloadTypeEnum["CAMPAIGN_TYPE_SPLIT"] = "CampaignTypeSplit";
})(PersonalisationServiceReadCampaignPayloadTypeEnum || (PersonalisationServiceReadCampaignPayloadTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PersonalisationServiceReadComponentPayloadUiSourceEnum;
(function (PersonalisationServiceReadComponentPayloadUiSourceEnum) {
    PersonalisationServiceReadComponentPayloadUiSourceEnum["UNSPECIFIED"] = "ComponentUISource_Unspecified";
    PersonalisationServiceReadComponentPayloadUiSourceEnum["GRAPES"] = "ComponentUISource_grapes";
    PersonalisationServiceReadComponentPayloadUiSourceEnum["CODE"] = "ComponentUISource_code";
})(PersonalisationServiceReadComponentPayloadUiSourceEnum || (PersonalisationServiceReadComponentPayloadUiSourceEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PersonalisationServiceReadPersonalisationPayloadInsertionTypeEnum;
(function (PersonalisationServiceReadPersonalisationPayloadInsertionTypeEnum) {
    PersonalisationServiceReadPersonalisationPayloadInsertionTypeEnum["IT_UNSPECIFIED"] = "IT_UNSPECIFIED";
    PersonalisationServiceReadPersonalisationPayloadInsertionTypeEnum["APPEND"] = "APPEND";
    PersonalisationServiceReadPersonalisationPayloadInsertionTypeEnum["PREPEND"] = "PREPEND";
    PersonalisationServiceReadPersonalisationPayloadInsertionTypeEnum["REPLACE"] = "REPLACE";
})(PersonalisationServiceReadPersonalisationPayloadInsertionTypeEnum || (PersonalisationServiceReadPersonalisationPayloadInsertionTypeEnum = {}));
