import {
  Box,
  CircularProgress,
  Portal,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { CenterPage } from "Components/CenterPage/CenterPage";
import { ClickableTooltip } from "Components/ClickableTooltip";
import { StyledHelp } from "Components/ClickableTooltip/StyledHelp";
import FullWidthHeightGlass from "Components/Page/FullWidthHeightGlass";
import { StyledFlexBox } from "Components/UI/StylesFlexBox";
import { groupBy } from "Helpers/arrays";
import { useSelectedProject } from "Hooks";
import { useFromToContext } from "Providers/FromToProvider";
import { useMemo, useState } from "react";
import StyledTreeMap from "Components/Analytics/StyledTreeMap";
import { ChartLegend, formatMetric, useChartData } from "features/analytics";
import { ApiDimension, ApiMetric } from "@incendium/api";
import { percentageChange } from "Helpers/percentage";
import withDatepicker from "HoC/withDatepicker";
import { PageTitle, PageActionPreSlot } from "consts";
import { useLocalstorageState } from "Hooks/useLocalstorageState";

function ProductsPerformanceMapPage() {
  const { selectedProject } = useSelectedProject();
  const { chartOutput, chartComparisonOutput } = useFromToContext();
  const [useProductRevenue, setuseProductRevenue] = useState(true);
  const [mode, setMode] = useLocalstorageState<"category" | "brand">(
    "ProductsPerformanceMapPage-mode",
    "category"
  );

  const chart = useMemo(
    () => ({
      name: "",
      dimension: [
        mode === "category"
          ? ApiDimension.DIMENSION_PRODUCT_CATEGORY
          : ApiDimension.DIMENSION_PRODUCT_BRAND,
        ApiDimension.DIMENSION_PRODUCT_NAME,
      ],
      yAxisKeys: [
        {
          fields: [
            ApiMetric.METRIC_PRODUCT_REVENUE,
            ApiMetric.METRIC_SALES_REVENUE,
          ],
        },
      ],
      attributes: [],
      includeEmpty: false,
    }),
    [mode]
  );

  const metric = useMemo(
    () =>
      useProductRevenue
        ? ApiMetric.METRIC_PRODUCT_REVENUE
        : ApiMetric.METRIC_SALES_REVENUE,
    [useProductRevenue]
  );

  const { chartData, loading } = useChartData(
    selectedProject,
    chart,
    chartOutput
  );
  const { chartData: compData } = useChartData(
    selectedProject,
    chart,
    chartComparisonOutput
  );

  const parsedChartData = useMemo(() => {
    if (!chartData?.data) {
      return [];
    }
    const gg = groupBy(chartData?.data || [], "name");

    return Object.keys(gg).map((k) => ({
      name: k,
      children: gg[k].map((d) => {
        let diff = 0;
        const found = (compData?.data || []).find(
          (f) =>
            f[ApiDimension.DIMENSION_PRODUCT_NAME] ===
            d[ApiDimension.DIMENSION_PRODUCT_NAME]
        );
        if (found) {
          diff = percentageChange(
            Number(found[metric]) || 0,
            Number(d[metric]) || 0
          );
        }
        return {
          name: `${d[ApiDimension.DIMENSION_PRODUCT_NAME]}`,
          metric: Number(d[metric]) || 0,
          diff,
        };
      }),
    }));
  }, [chartData, metric, compData]);

  if (loading) {
    return (
      <CenterPage>
        <CircularProgress />
        <Typography variant="h3" mt={2}>
          Fetching product data
        </Typography>
      </CenterPage>
    );
  }

  return (
    <>
      <Portal container={() => document.getElementById(PageTitle)}>
        <Stack>
          <Typography variant="h1" mb={2}>
            Products Performance Map
          </Typography>
          <Stack direction={"row"} spacing={2}>
            <StyledFlexBox sx={{ maxWidth: 360 }} compact>
              <Box display="flex" alignItems="center">
                <Typography
                  color={!useProductRevenue ? "primary" : "secondary"}
                >
                  Basket Revenue
                </Typography>
                <Switch
                  checked={useProductRevenue}
                  onChange={(e, checked) => setuseProductRevenue(checked)}
                />
                <Typography color={useProductRevenue ? "primary" : "secondary"}>
                  Product Revenue
                </Typography>
              </Box>
              <ClickableTooltip
                placement="right"
                text={
                  <Stack>
                    <Typography color={"inherit"} mb={1}>
                      <strong>Product revenue</strong> - this is the revenue per
                      product
                    </Typography>
                    <Typography color={"inherit"}>
                      <strong>Basket revenue</strong> - this is the revenue from
                      a sale where this product was purchased (along side the
                      revenue of any other products purchased at the same time)
                    </Typography>
                  </Stack>
                }
                icon={<StyledHelp />}
              />
            </StyledFlexBox>
            <StyledFlexBox compact sx={{ maxWidth: 300 }}>
              <Box display="flex" alignItems="center">
                <Typography>Categories</Typography>
                <Switch
                  checked={mode === "brand"}
                  onChange={(e, checked) =>
                    setMode(checked ? "brand" : "category")
                  }
                />
                <Typography>Brands</Typography>
              </Box>
            </StyledFlexBox>
          </Stack>
        </Stack>
      </Portal>
      <Portal container={() => document.getElementById(PageActionPreSlot)}>
        <ChartLegend
          items={parsedChartData.map((ch) => ch.name)}
          flexAfter={3}
        />
      </Portal>
      <FullWidthHeightGlass height={"calc(100% - 4px)"}>
        <StyledTreeMap
          chartData={parsedChartData}
          formatValue={(v) => formatMetric(metric, v)}
        />
      </FullWidthHeightGlass>
    </>
  );
}

export default withDatepicker(ProductsPerformanceMapPage);
