import { useMemo } from "react";
import { metricTitles } from "Interfaces/enums";
import InteractionWheel from "Components/InteractionsWheel";
import { metricTitlesToProductDislpayName } from "Helpers/analytics";
import { metricTitlesToProductWheelPosition } from "Helpers/analytics";
import { useSelectedProject } from "Hooks";
import { useFromToContext } from "Providers/FromToProvider";
import { ApiChartAttribute, ApiDimension, ApiMetric } from "@incendium/api";
import { useChartData } from "features/analytics";

type TChartDataValue = {
  name: string | null;
  value: number;
};

interface IProductInteractionsWheelProps {
  attributes: ApiChartAttribute[];
}

function ProductInteractionsWheel({
  attributes,
}: IProductInteractionsWheelProps) {
  const { selectedProject } = useSelectedProject();
  const { chartOutput } = useFromToContext();

  const chart = useMemo(
    () => ({
      name: "",
      dimension: [ApiDimension.DIMENSION_CHANNEL],
      yAxisKeys: [
        {
          fields: [
            ApiMetric.METRIC_PRODUCT_IMPRESSION_COUNT,
            ApiMetric.METRIC_PRODUCT_IMPRESSION_RATE,
            ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_COUNT,
            ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_COUNT,
            ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_RATE,
            ApiMetric.METRIC_SESSION_SALES_COUNT,
            ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_RATE,
            ApiMetric.METRIC_VISIT_TO_PURCHASE_RATE,
            ApiMetric.METRIC_SALES_COMPLETION_RATE,
          ],
        },
      ],
      attributes,
    }),
    [attributes]
  );

  const { chartData: fetchedData, loading } = useChartData(
    selectedProject,
    chart,
    chartOutput
  );

  const data = useMemo(
    () =>
      [...(fetchedData?.data || [])].sort((a, b) =>
        (a.name || "").localeCompare(b.name || "")
      ),
    [fetchedData]
  );

  // use map to guarntee order
  const chartData = useMemo(() => {
    const m = new Map<metricTitles, TChartDataValue[]>();
    m.set(
      metricTitles.Sales,
      data.map((d) => ({
        name: d.name,
        value: Number(d[ApiMetric.METRIC_SESSION_SALES_COUNT]),
      }))
    );
    m.set(
      metricTitles.Add_to_carts,
      data.map((d) => ({
        name: d.name,
        value: Number(d[ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_COUNT]),
      }))
    );

    m.set(
      metricTitles.Click_Throughs,
      data.map((d) => ({
        name: d.name,
        value: Number(d[ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_COUNT]),
      }))
    );
    m.set(
      metricTitles.Impressions,
      data.map((d) => ({
        name: d.name,
        value: Number(d[ApiMetric.METRIC_PRODUCT_IMPRESSION_COUNT]),
      }))
    );

    return m;
  }, [data]);

  const ratesData = useMemo(() => {
    const m = new Map<metricTitles, TChartDataValue[]>();
    m.set(
      metricTitles.Sales,
      data.map((d) => ({
        name: d.name,
        value: Number(d[ApiMetric.METRIC_SALES_COMPLETION_RATE]),
      }))
    );
    m.set(
      metricTitles.Add_to_carts,
      data.map((d) => ({
        name: d.name,
        value: Number(d[ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_RATE]),
      }))
    );

    m.set(
      metricTitles.Click_Throughs,
      data.map((d) => ({
        name: d.name,
        value: Number(d[ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_RATE]),
      }))
    );
    m.set(
      metricTitles.Impressions,
      data.map((d) => ({
        name: d.name,
        value: Number(d[ApiMetric.METRIC_PRODUCT_IMPRESSION_RATE]),
      }))
    );

    return m;
  }, [data]);

  const channels = useMemo(() => {
    return Array.from(new Set(data.map((d) => d.name || "")));
  }, [data]);

  return (
    <>
      <InteractionWheel
        loading={loading}
        channels={channels}
        chartData={chartData}
        ratesData={ratesData}
        displayMetricFn={metricTitlesToProductDislpayName}
        positionMetricFn={metricTitlesToProductWheelPosition}
      />
    </>
  );
}

export default ProductInteractionsWheel;
