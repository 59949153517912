import {
  ApiAttributionClicksDimension,
  ApiAttributionClicksMetric,
  ApiChartAttribute,
  ApiProject,
} from "@incendium/api";
import { getAttributionAnalytics } from "features/attributionAnalytics";
import { IFromToChartOutput } from "Providers/FromToProvider";
import { useCallback, useRef, useState } from "react";
import { useDebounce } from "react-use";

const useAttributionClickChartData = (
  selectedProject: ApiProject | null,
  dimensions: ApiAttributionClicksDimension[],
  metrics: ApiAttributionClicksMetric[],
  attributes: ApiChartAttribute[],
  chartOutput: IFromToChartOutput,
  dontRun?: boolean
) => {
  const [chartData, setChartData] = useState<any[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>(undefined);
  const previousChartOutputRef = useRef({});

  const loadData = useCallback(
    async (
      projectId: number,

      dimensions: ApiAttributionClicksDimension[],
      metrics: ApiAttributionClicksMetric[],
      attributes: ApiChartAttribute[],
      from?: Date,
      to?: Date,
      lastNDays?: number
    ) => {
      setLoading(true);
      setError(undefined);

      try {
        const res = await getAttributionAnalytics(
          projectId,
          dimensions,
          metrics,
          attributes,
          from,
          to,
          lastNDays
        );

        setChartData(res);

        setTimeout(() => {
          setLoading(false);
          setError(undefined);
        }, 1);
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    },
    []
  );

  useDebounce(
    () => {
      if (!selectedProject?.id || dontRun) {
        return;
      }

      loadData(
        selectedProject.id,
        dimensions,
        metrics,
        attributes,
        chartOutput.from,
        chartOutput.to,
        chartOutput.lastNDays
      );

      previousChartOutputRef.current = chartOutput;
    },
    100,
    [selectedProject, dimensions, metrics, attributes, chartOutput, loadData]
  );

  return { chartData, loading, error };
};

export default useAttributionClickChartData;
