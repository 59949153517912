import { Box, Portal, Stack, Typography } from "@mui/material";
import {
  ImpressionsByDayChart,
  ProductBrandsDropdown,
} from "features/analytics";
import { CenterPage } from "Components/CenterPage/CenterPage";
import {
  ProductCategoriesDropdown,
  PrimaryTopMicroConversions,
  ProductsDropdown,
} from "features/analytics";
import { useSelectedProject } from "Hooks";
import { useProducts } from "Hooks/useProducts";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import {
  AnalyticsIncreaseDecreaseStatCard,
  ProductInteractionsWheel,
  ProductKeyStatsCard,
  formatMetric,
  percentageTopChangeByMetric,
} from "features/analytics";
import {
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiMetric,
} from "@incendium/api";
import { StatCardTypography } from "Components/UI/StatCard";
import IncreaseDecrease from "Components/IncreaseDecrease/IncreaseDecrease";
import { AnalyticsSpacing } from "consts";

interface IProductSinglePageProps {
  dimension: ApiDimension;
  selectedProductId?: number;
  setSelectedProductId?: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  selectedProductCategory?: string;
  setSelectedProductCategory?: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  selectedProductBrand?: string;
  setSelectedProductBrand?: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

function ProductSinglePage({
  dimension,
  selectedProductId,
  setSelectedProductId,
  selectedProductCategory,
  setSelectedProductCategory,
  selectedProductBrand,
  setSelectedProductBrand,
}: IProductSinglePageProps) {
  const { selectedProject, selectedClient } = useSelectedProject();
  const { products } = useProducts();

  const selectedProduct = useMemo(
    () => products.find((p) => p.id === selectedProductId),
    [selectedProductId, products]
  );

  const attributes = useMemo(
    () => [
      {
        key: dimension,
        value:
          selectedProduct?.name ||
          selectedProductCategory ||
          selectedProductBrand ||
          "",
      },
    ],
    [dimension, selectedProduct, selectedProductCategory, selectedProductBrand]
  );

  if (!selectedProduct && dimension === ApiDimension.DIMENSION_PRODUCT_NAME) {
    return (
      <CenterPage>
        <Typography variant="h3" mb={3}>
          Please Select a Product to Begin.
        </Typography>
        <ProductsDropdown
          sx={{ width: 300 }}
          label="Product"
          variant="outlined"
          value={selectedProductId}
          onChange={(n) => setSelectedProductId && setSelectedProductId(n)}
        />
      </CenterPage>
    );
  }
  if (
    !selectedProductCategory &&
    dimension === ApiDimension.DIMENSION_PRODUCT_CATEGORY
  ) {
    return (
      <CenterPage>
        <Typography variant="h3" mb={3}>
          Please Select a Product Category to Begin.
        </Typography>
        <ProductCategoriesDropdown
          sx={{ width: 300 }}
          label="Product Category"
          variant="outlined"
          value={selectedProductCategory}
          onChange={(n) =>
            setSelectedProductCategory && setSelectedProductCategory(n)
          }
        />
      </CenterPage>
    );
  }
  if (
    !selectedProductBrand &&
    dimension === ApiDimension.DIMENSION_PRODUCT_BRAND
  ) {
    return (
      <CenterPage>
        <Typography variant="h3" mb={3}>
          Please Select a Product Brand to Begin.
        </Typography>
        <ProductBrandsDropdown
          sx={{ width: 300 }}
          label="Product Brand"
          variant="outlined"
          value={selectedProductBrand}
          onChange={(n) =>
            setSelectedProductBrand && setSelectedProductBrand(n)
          }
        />
      </CenterPage>
    );
  }

  return (
    <>
      <Portal container={() => document.getElementById("pageTitle")}>
        <Stack>
          <Typography variant="h1">
            Single Product{" "}
            {dimension === ApiDimension.DIMENSION_PRODUCT_CATEGORY
              ? "Category"
              : dimension === ApiDimension.DIMENSION_PRODUCT_BRAND
              ? "Brand"
              : ""}{" "}
            Dashboard
          </Typography>
          <Stack direction="row" alignItems={"baseline"}>
            <Typography variant="h2" color={"secondary"}>
              {selectedProduct?.name ||
                selectedProductCategory ||
                selectedProductBrand}
            </Typography>
            {dimension === ApiDimension.DIMENSION_PRODUCT_NAME &&
              selectedProduct?.category && (
                <>
                  <Link
                    to={{
                      pathname: `/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/product-category-deep-dive`,
                      state: {
                        selectedProductCategory: selectedProduct?.category,
                      },
                    }}
                  >
                    {" - "}
                    {selectedProduct?.category}
                  </Link>
                </>
              )}
            {dimension === ApiDimension.DIMENSION_PRODUCT_NAME &&
              selectedProduct?.brand && (
                <>
                  <Link
                    to={{
                      pathname: `/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/standard-reports/product-brand-deep-dive`,
                      state: {
                        selectedProductBrand: selectedProduct?.brand,
                      },
                    }}
                  >
                    {" - "}
                    {selectedProduct?.brand}
                  </Link>
                </>
              )}
          </Stack>
        </Stack>
      </Portal>

      <Box
        sx={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: `repeat(18, 1fr)`,
          gridTemplateRows: `repeat(9, 1fr)`,
          rowGap: 3,
          height: 840,
          columnGap: AnalyticsSpacing,
        }}
      >
        <Box sx={{ gridArea: "1 / 1 / 10 / 5" }}>
          <Stack spacing={AnalyticsSpacing} sx={{ height: "100%" }}>
            <AnalyticsIncreaseDecreaseStatCard
              metric={ApiMetric.METRIC_PRODUCT_REVENUE}
              renderBody={(data, compData) => {
                if (!compData) {
                  return <>-</>;
                }

                const change = percentageTopChangeByMetric(
                  data,
                  compData,
                  ApiMetric.METRIC_PRODUCT_REVENUE
                );

                return (
                  <>
                    <StatCardTypography size="xs" opactity={0.8}>
                      Total Revenue
                    </StatCardTypography>
                    <StatCardTypography size="large">
                      {formatMetric(
                        ApiMetric.METRIC_PRODUCT_REVENUE,
                        Number(data[0][ApiMetric.METRIC_PRODUCT_REVENUE] || 0)
                      )}
                    </StatCardTypography>
                    <IncreaseDecrease
                      direction={change >= 0 ? "up" : "down"}
                      value={change}
                      fontSize={24}
                    />
                  </>
                );
              }}
              chart={{
                name: "",
                dimension: [],
                template: ApiChartTemplate.SNAPSHOT,
                type: ApiChartType.GRAPH,
                attributes,
                yAxisKeys: [
                  {
                    key: "l",
                    fields: [ApiMetric.METRIC_PRODUCT_REVENUE],
                  },
                ],
              }}
            />

            <ProductKeyStatsCard
              selectedDimension={
                selectedProduct?.name ||
                selectedProductCategory ||
                selectedProductBrand ||
                ""
              }
              productDimension={dimension}
            />
          </Stack>
        </Box>
        <Box sx={{ gridArea: "1 / 5 / 10 / 13" }}>
          <ProductInteractionsWheel attributes={attributes} />
        </Box>
        <Box sx={{ gridArea: "1 / 13 / 5 / 19" }}>
          <ImpressionsByDayChart
            dimension={dimension}
            impressionField={ApiMetric.METRIC_PRODUCT_IMPRESSION_COUNT}
            type="product"
            selected={
              selectedProduct?.name ||
              selectedProductCategory ||
              selectedProductBrand ||
              ""
            }
          />
        </Box>
        <Box sx={{ gridArea: "5 / 13 / 10/ 19" }}>
          <PrimaryTopMicroConversions
            dimension={dimension}
            byDimension={
              selectedProduct?.name ||
              selectedProductCategory ||
              selectedProductBrand ||
              ""
            }
            dimensionString={`product ${
              dimension === ApiDimension.DIMENSION_PRODUCT_CATEGORY
                ? "category"
                : dimension === ApiDimension.DIMENSION_PRODUCT_BRAND
                ? "brand"
                : ""
            }`}
          />
        </Box>
      </Box>
    </>
  );
}

export default ProductSinglePage;
