import {
  ApiAttributionClicksDimension,
  ApiAttributionClicksMetric,
  ApiChartAttribute,
} from "@incendium/api";
import { analyticsService } from "Apis";

export default async function getAttributionAnalytics(
  projectId: number,
  dimensions: ApiAttributionClicksDimension[],
  metrics: ApiAttributionClicksMetric[],
  attributes: ApiChartAttribute[],
  from?: Date,
  to?: Date,
  lastNDays?: number
): Promise<any> {
  const res =
    await analyticsService.analyticsServiceGetAttributionClicksMetrics({
      projectId,
      payload: {
        from,
        to,
        lastNDays,
        metrics,
        dimensions,
        attributes,
      },
    });
  if (!res.rows || res.rows?.length === 0) {
    return [];
  }

  let chartData = (res.rows || []).map((row) => {
    let dim: any = {};
    (row.dimensions || []).forEach((d, i) => {
      dim[(res.dimensions || [])[i]] = d;
    });

    let met: any = {};

    (row.metrics || []).forEach((m, i) => {
      met[(res.metrics || [])[i]] = m.type?.includes("Int")
        ? Number(m.value)
        : m.value;
    });

    const o = {
      ...dim,
      ...met,
    };

    return o;
  });
  return chartData;
}
