import {
  ApiConditionCustom,
  ApiConditionGroup,
  ApiConversion,
  ApiEngagementRule,
  ApiOperator,
  ApiProject,
  EngagementServiceUpdateEngagementRequest,
} from "@incendium/api";
import { engagementService } from "Apis";
import { useConditionGroupContext } from "Components/ConditionalGroup/ConditionGroupProvider";
import EngagementRules from "Components/Engagement/EngagementRules";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import EngagementSidebar from "./EngagementSidebar";
import { useEngagements } from "Hooks/useEngagements";
import produce from "immer";
import { isEmpty } from "Helpers/isEmpty";
import ConditionGroupsFormWrapper from "Components/ConditionalGroup/ConditionGroupsFormWrapper";

interface IEngagementFormProps {
  engagement: ApiEngagementRule;
  setEngagement: React.Dispatch<
    React.SetStateAction<ApiEngagementRule | undefined>
  >;
  conversions: ApiConversion[];
  project: ApiProject;
}

function EngagementForm({
  engagement,
  setEngagement,
  conversions,
  project,
}: IEngagementFormProps) {
  const { conditionGroups, setConditionGroups } = useConditionGroupContext();
  const { enqueueSnackbar } = useSnackbar();
  const { engagements, setEngagements } = useEngagements();

  const history = useHistory();

  useEffect(() => {
    return () => {
      setConditionGroups([]);
      setEngagement(undefined);
    };
  }, []);

  useEffect(() => {
    if (!engagement || conditionGroups.length) {
      return;
    }
    const groups = engagement.conditionGroups || [];
    setConditionGroups(
      groups && groups.length > 0
        ? [...groups]
        : [
            {
              conditions: [{}],
            },
          ]
    );
  }, [engagement, setConditionGroups, conditionGroups]);

  const onSubmit = async () => {
    const req: EngagementServiceUpdateEngagementRequest = {
      projectId: project.id as number,
      engagementId: engagement.id as number,
      payload: {
        name: engagement.name,
        score: engagement.score,
        maxScore: engagement.maxScore,
        locations: engagement.locations,
        conditionGroups: (conditionGroups as ApiConditionGroup[])
          .filter((c) => {
            return (
              !isEmpty(c) &&
              (c.conditions || []).filter((co) => !isEmpty(co)).length > 0
            );
          })
          .map((c) => ({
            conditions: (c.conditions || []).map((condition) => {
              if (
                condition.custom !== ApiConditionCustom.CONVERSION_TRIGGERED
              ) {
                return {
                  ...condition,
                  operator: ApiOperator.IS_GREATER_THAN_OR_EQUAL,
                };
              }

              return {
                ...condition,
                operator: ApiOperator.EQUAL,
              };
            }),
          })),
      },
    };

    const res = await engagementService.engagementServiceUpdateEngagement(req);
    enqueueSnackbar(`${res.name} Saved`, {
      variant: "success",
      autoHideDuration: 2000,
      anchorOrigin: { horizontal: "right", vertical: "top" },
    });

    // update list
    setEngagements(
      produce(engagements, (draft) => {
        const idx = engagements.findIndex((e) => e.id === engagement.id);
        if (idx < 0) {
          return;
        }
        draft[idx] = res;
      })
    );
  };

  const onCancel = () => {
    history.push(
      `/clients/${project.clientId}/projects/${project?.id}/train/lead-scoring`
    );
  };

  return (
    <ConditionGroupsFormWrapper
      onSubmit={onSubmit}
      onCancel={onCancel}
      rules={<EngagementRules conversions={conversions} />}
    >
      <EngagementSidebar
        engagement={engagement}
        setEngagement={setEngagement}
      />
    </ConditionGroupsFormWrapper>
  );
}

export default EngagementForm;
