import { CircularProgress } from "@mui/material";
import {
  initializeClientsThunk,
  fetchAllClientsAndProjectsThunk,
} from "Actions";
import {
  useClientIdFromUrl,
  useClientSelector,
  useProjectIdFromUrl,
  useProjectSelector,
} from "Hooks";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

export const withClientorProjects = <T extends object>(
  Comp: React.ComponentType<T>
) => {
  const ReturnedComp: React.FC<T> = (props) => {
    const clientId = useClientIdFromUrl();
    const projectId = useProjectIdFromUrl();
    const dispatch = useDispatch();
    const client = useClientSelector();
    const project = useProjectSelector();
    useEffect(() => {
      if (clientId) {
        dispatch(initializeClientsThunk());
      }
      if (clientId && projectId) {
        dispatch(
          fetchAllClientsAndProjectsThunk({
            clientId: Number(clientId),
            projectId: Number(projectId),
          })
        );
      }
    }, [clientId, projectId]);
    if (project && client) {
      return <Comp {...props} />;
    }
    if (!projectId && client) {
      return <Comp {...props} />;
    }
    if (!clientId && !projectId) {
      return <Comp {...props} />;
    }

    return <CircularProgress />;
  };
  return ReturnedComp;
};
