import { Grid, Typography, useTheme } from "@mui/material";
import { useSelectedProject } from "Hooks/useSelectedProject";
import { LocationList } from "Components/LocationList";
import { Route, Switch, useRouteMatch } from "react-router";
import { useLocations } from "Hooks/useLocations";
import { locationService } from "Apis";
import { ApiLocation } from "@incendium/api";
import produce from "immer";
import AnimatedFab from "Components/AnimatedFab/AnimatedFab";
import { useLayoutEffect, useState } from "react";
import PagesWithNoLocationDrawer from "Components/Pages/PagesWithNoLocationDrawer";
import { ChevronLeft } from "@mui/icons-material";
import { LocationPage } from "./LocationPage";
import GlassCard from "Components/GlassCard/GlassCard";
import { Box } from "@mui/system";
import { IndentDivider } from "Components/IndentDivider/IndentDivider";
import PagesWithNoLocationList from "Components/Pages/PagesWithNoLocationList";
import { makeStyles } from "@mui/styles";
import ProductsWithNoLocationList from "Components/Product/ProductsWithNoLocationsList";
import { useLayoutContext } from "Providers/LayoutProvider";
import { useDeleteConfirmation } from "Hooks/useDeleteConfirmation";

const useLocationsPageStyles = makeStyles((theme) => ({
  infoBox: {
    height: 312,
  },
}));

export const LocationsPage = () => {
  const theme = useTheme();
  const classes = useLocationsPageStyles();
  const { selectedProject } = useSelectedProject();
  const handleDelete = useDeleteConfirmation();
  const [visible, setVisible] = useState(true);

  const { path } = useRouteMatch();
  const { locations, setLocations } = useLocations();
  const { setPageTitle } = useLayoutContext();

  useLayoutEffect(() => {
    setPageTitle(`${selectedProject?.name} Locations`);
    return () => {
      setPageTitle("");
    };
  }, [selectedProject]);

  const onDelete = (location: ApiLocation) => {
    handleDelete({
      title: `Are you sure you want to delete this location`,
      body: `This action can not be undone`,
      callback: async () => {
        await locationService.locationServiceDeleteLocation({
          projectId: selectedProject?.id as number,
          locationId: location.id as number,
        });

        setLocations(
          produce(locations, (draft) => {
            const idx = locations.findIndex((l) => l.id === location.id);
            if (idx !== -1) draft.splice(idx, 1);
          })
        );

        return `${location.name} Deleted`;
      },
    });
  };

  return (
    <Box style={{ height: `calc(100% - ${theme.spacing(1)})` }}>
      <Switch>
        <Route path="/clients/:clientId/projects/:projectId/train/locations/:locationId">
          <LocationPage />

          <AnimatedFab
            onClick={() => {
              setVisible(!visible);
            }}
            variant="contained"
            color="secondary"
            visible={visible}
          >
            <ChevronLeft />
            See unassigned pages
          </AnimatedFab>
        </Route>
        <Route path={path}>
          <Grid container spacing={3}>
            {selectedProject ? (
              <>
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={6}
                >
                  <Grid item xs={6}>
                    <GlassCard className={classes.infoBox}>
                      <Box px={3} py={2}>
                        <Typography variant="subtitle2" mb={1}>
                          Pages Not Assigned to Locations
                        </Typography>
                        <IndentDivider />
                        <Box pt={3}>
                          <PagesWithNoLocationList
                            onShowMore={() => {
                              setVisible(false);
                            }}
                            limit={5}
                          />
                        </Box>
                      </Box>
                    </GlassCard>
                  </Grid>
                  <Grid item xs={6}>
                    <GlassCard className={classes.infoBox}>
                      <Box px={3} py={2}>
                        <Typography variant="subtitle2" mb={1}>
                          Products Not Assigned to Locations
                        </Typography>
                        <IndentDivider />
                        <Box pt={3}>
                          <ProductsWithNoLocationList limit={5} />
                        </Box>
                      </Box>
                    </GlassCard>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <LocationList onDelete={onDelete} />
                </Grid>
              </>
            ) : (
              "No Project selected"
            )}
          </Grid>
        </Route>
      </Switch>
      <PagesWithNoLocationDrawer open={!visible} setClose={setVisible} />
    </Box>
  );
};
