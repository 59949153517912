import {
  Chip,
  IconButton,
  lighten,
  Stack,
  Theme,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  DataGridProps,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import makeStyles from "@mui/styles/makeStyles";
import { forwardRef, useMemo } from "react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

interface IStyledDataGridProps extends DataGridProps {
  compactFooter?: boolean;
  hideFooter?: boolean;
  showSeperator?: boolean;
}

const useStyles = makeStyles<Theme, { showSeperator?: boolean }>(
  (theme: Theme) => ({
    root: (props) => ({
      border: 0,
      minWidth: 100,
      display: "grid",
      "& .total-row": {
        position: "sticky",
        bottom: 0,
        background: theme.palette.secondary.light,
        // background: "white",
        boxShadow: `rgba(0, 0, 0, 0.12) 0px -1px 8px 0px`,
        "& .MuiTypography-root, & .dimension .MuiTypography-root": {
          color: "white",
        },
        "&:hover": {
          // background: "white",
          background: theme.palette.secondary.light,
        },
      },
      "& .MuiDataGrid-columnSeparator": {
        display: props.showSeperator ? "block" : "none",
      },
      "& .MuiDataGrid-columnHeader": {
        "& .MuiDataGrid-columnHeaderTitleContainerContent": {
          "& .MuiDataGrid-columnHeaderTitle": {
            textWrap: "pretty",
          },
        },
      },
      "& .MuiDataGrid-iconButtonContainer": {
        position: "absolute",
        right: 0,
      },
      "& .MuiDataGrid-columnHeader:hover": {
        alignItems: "start",
        padding: 0,
        paddingLeft: 2,
        "& .MuiDataGrid-columnHeaderTitleContainerContent": {
          "& .MuiDataGrid-columnHeaderTitle": {
            textAlign: "left",
          },
        },
      },
      "& .MuiDataGrid-columnHeadersInner": {
        color: lighten(theme.palette.text.primary, 0.35),
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        overflow: "hidden",
        lineHeight: theme.typography.body2.lineHeight,
        whiteSpace: "normal",
        textAlign: "center",
        fontSize: "11px",
      },
      "& .MuiDataGrid-columnHeader--alignLeft .MuiDataGrid-columnHeaderTitle": {
        textAlign: "left",
      },
      "& .MuiDataGrid-footerContainer": {
        justifyContent: "center",
      },
      "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
        {
          outline: "none",
        },
      "& .MuiDataGrid-row > .dimension .MuiTypography-root": {
        color: theme.palette.primary.dark,
        fontWeight: 500,
      },
    }),
  })
);

const CustomPagination = (props: any) => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Stack
      mt={props.compactFooter ? 1 : 4}
      direction="row"
      alignItems={"center"}
    >
      <IconButton
        disabled={page === 0}
        onClick={() => apiRef.current.setPage(page - 1)}
        color="secondary"
      >
        <ChevronLeft />
      </IconButton>
      <Chip
        sx={{ background: "#E5EBFE", minWidth: 100, borderRadius: 2 }}
        label={
          <Typography>
            <strong>{page + 1}</strong> - {pageCount}
          </Typography>
        }
      />

      <IconButton
        disabled={page + 1 === pageCount}
        onClick={() => apiRef.current.setPage(page + 1)}
        color="secondary"
      >
        <ChevronRight />
      </IconButton>
    </Stack>
  );
};

const StyledDataGrid = forwardRef(
  (
    {
      rows,
      columns,
      compactFooter,
      hideFooter,
      showSeperator,
      ...rest
    }: IStyledDataGridProps,
    ref: React.Ref<HTMLDivElement> | undefined
  ) => {
    const classes = useStyles({ showSeperator });
    const limitedRows = useMemo(() => {
      if (rows.length > 100) {
        let dummyRow: any = {
          id: "dummy",
        };
        Object.keys(rows[0]).forEach((k) => {
          if (k === "id") {
            return;
          }
          dummyRow[k] = "...";
        });

        return [...rows.slice(0, 98), dummyRow, ...rows.slice(-1)];
      }
      return rows;
    }, [rows]);
    return (
      <DataGrid
        ref={ref}
        rowHeight={rest.rowHeight || 54}
        className={classes.root}
        rows={limitedRows}
        columnHeaderHeight={80}
        columns={columns}
        rowBuffer={hideFooter ? limitedRows.length : 3}
        disableRowSelectionOnClick
        hideFooter={hideFooter}
        slots={{
          pagination: (props) => CustomPagination({ ...props, compactFooter }),
          columnSortedAscendingIcon: () => <></>,
          columnSortedDescendingIcon: () => <></>,
        }}
        {...rest}
      />
    );
  }
);

export default StyledDataGrid;
