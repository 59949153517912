/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiAudienceFromJSON, ApiAudienceToJSON, } from './ApiAudience';
import { ApiContactFromJSON, ApiContactToJSON, } from './ApiContact';
import { ApiDataFromJSON, ApiDataToJSON, } from './ApiData';
import { ApiLeadConversionFromJSON, ApiLeadConversionToJSON, } from './ApiLeadConversion';
import { ApiLeadLinkFromJSON, ApiLeadLinkToJSON, } from './ApiLeadLink';
import { ApiLeadScoreFromJSON, ApiLeadScoreToJSON, } from './ApiLeadScore';
import { ApiLeadStatusFromJSON, ApiLeadStatusToJSON, } from './ApiLeadStatus';
import { ApiPredictionFromJSON, ApiPredictionToJSON, } from './ApiPrediction';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
import { ApiRetentionFromJSON, ApiRetentionToJSON, } from './ApiRetention';
import { ApiSaleFromJSON, ApiSaleToJSON, } from './ApiSale';
import { ApiSessionFromJSON, ApiSessionToJSON, } from './ApiSession';
import { ApiStaticClassificationFromJSON, ApiStaticClassificationToJSON, } from './ApiStaticClassification';
export function ApiLeadFromJSON(json) {
    return ApiLeadFromJSONTyped(json, false);
}
export function ApiLeadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'firstCaptured': !exists(json, 'firstCaptured') ? undefined : json['firstCaptured'],
        'staticClassifications': !exists(json, 'staticClassifications') ? undefined : (json['staticClassifications'].map(ApiStaticClassificationFromJSON)),
        'data': !exists(json, 'data') ? undefined : (json['data'].map(ApiDataFromJSON)),
        'sales': !exists(json, 'sales') ? undefined : (json['sales'].map(ApiSaleFromJSON)),
        'conversions': !exists(json, 'conversions') ? undefined : (json['conversions'].map(ApiLeadConversionFromJSON)),
        'leadLinks': !exists(json, 'leadLinks') ? undefined : (json['leadLinks'].map(ApiLeadLinkFromJSON)),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'sessions': !exists(json, 'sessions') ? undefined : (json['sessions'].map(ApiSessionFromJSON)),
        'contact': !exists(json, 'contact') ? undefined : ApiContactFromJSON(json['contact']),
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'audiences': !exists(json, 'audiences') ? undefined : (json['audiences'].map(ApiAudienceFromJSON)),
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'status': !exists(json, 'status') ? undefined : (json['status'].map(ApiLeadStatusFromJSON)),
        'contactId': !exists(json, 'contactId') ? undefined : json['contactId'],
        'lastActivityAt': !exists(json, 'lastActivityAt') ? undefined : (new Date(json['lastActivityAt'])),
        'retentions': !exists(json, 'retentions') ? undefined : (json['retentions'].map(ApiRetentionFromJSON)),
        'predictions': !exists(json, 'predictions') ? undefined : (json['predictions'].map(ApiPredictionFromJSON)),
        'analyticsSynced': !exists(json, 'analyticsSynced') ? undefined : json['analyticsSynced'],
        'scores': !exists(json, 'scores') ? undefined : (json['scores'].map(ApiLeadScoreFromJSON)),
        'fbp': !exists(json, 'fbp') ? undefined : json['fbp'],
    };
}
export function ApiLeadToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'key': value.key,
        'firstCaptured': value.firstCaptured,
        'staticClassifications': value.staticClassifications === undefined ? undefined : (value.staticClassifications.map(ApiStaticClassificationToJSON)),
        'data': value.data === undefined ? undefined : (value.data.map(ApiDataToJSON)),
        'sales': value.sales === undefined ? undefined : (value.sales.map(ApiSaleToJSON)),
        'conversions': value.conversions === undefined ? undefined : (value.conversions.map(ApiLeadConversionToJSON)),
        'leadLinks': value.leadLinks === undefined ? undefined : (value.leadLinks.map(ApiLeadLinkToJSON)),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'sessions': value.sessions === undefined ? undefined : (value.sessions.map(ApiSessionToJSON)),
        'contact': ApiContactToJSON(value.contact),
        'project': ApiProjectToJSON(value.project),
        'audiences': value.audiences === undefined ? undefined : (value.audiences.map(ApiAudienceToJSON)),
        'projectId': value.projectId,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'status': value.status === undefined ? undefined : (value.status.map(ApiLeadStatusToJSON)),
        'contactId': value.contactId,
        'lastActivityAt': value.lastActivityAt === undefined ? undefined : (value.lastActivityAt.toISOString()),
        'retentions': value.retentions === undefined ? undefined : (value.retentions.map(ApiRetentionToJSON)),
        'predictions': value.predictions === undefined ? undefined : (value.predictions.map(ApiPredictionToJSON)),
        'analyticsSynced': value.analyticsSynced,
        'scores': value.scores === undefined ? undefined : (value.scores.map(ApiLeadScoreToJSON)),
        'fbp': value.fbp,
    };
}
