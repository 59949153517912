/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiAdditionalMetricsListFromJSON, ApiAdditionalMetricsListToJSON, } from './ApiAdditionalMetricsList';
import { ApiChartLayoutFromJSON, ApiChartLayoutToJSON, } from './ApiChartLayout';
import { ApiDimensionFromJSON, ApiDimensionToJSON, } from './ApiDimension';
import { ApiMetricFromJSON, ApiMetricToJSON, } from './ApiMetric';
export function ApiChartDisplayOptionsFromJSON(json) {
    return ApiChartDisplayOptionsFromJSONTyped(json, false);
}
export function ApiChartDisplayOptionsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'rowsPerPage': !exists(json, 'rowsPerPage') ? undefined : json['rowsPerPage'],
        'noPagination': !exists(json, 'noPagination') ? undefined : json['noPagination'],
        'availableMetrics': !exists(json, 'availableMetrics') ? undefined : (json['availableMetrics'].map(ApiMetricFromJSON)),
        'pieTotalText': !exists(json, 'pieTotalText') ? undefined : json['pieTotalText'],
        'fills': !exists(json, 'fills') ? undefined : json['fills'],
        'noLegend': !exists(json, 'noLegend') ? undefined : json['noLegend'],
        'noReorder': !exists(json, 'noReorder') ? undefined : json['noReorder'],
        'metricsOrder': !exists(json, 'metricsOrder') ? undefined : json['metricsOrder'],
        'noFilters': !exists(json, 'noFilters') ? undefined : json['noFilters'],
        'showLabels': !exists(json, 'showLabels') ? undefined : json['showLabels'],
        'availableDimensions': !exists(json, 'availableDimensions') ? undefined : (json['availableDimensions'].map(ApiDimensionFromJSON)),
        'filterDropdowns': !exists(json, 'filterDropdowns') ? undefined : (json['filterDropdowns'].map(ApiDimensionFromJSON)),
        'layout': !exists(json, 'layout') ? undefined : ApiChartLayoutFromJSON(json['layout']),
        'noTitle': !exists(json, 'noTitle') ? undefined : json['noTitle'],
        'noAttributionToggle': !exists(json, 'noAttributionToggle') ? undefined : json['noAttributionToggle'],
        'noAxis': !exists(json, 'noAxis') ? undefined : json['noAxis'],
        'pieTotalFontSize': !exists(json, 'pieTotalFontSize') ? undefined : json['pieTotalFontSize'],
        'showTotals': !exists(json, 'showTotals') ? undefined : json['showTotals'],
        'statDesignIndex': !exists(json, 'statDesignIndex') ? undefined : json['statDesignIndex'],
        'statTitle': !exists(json, 'statTitle') ? undefined : json['statTitle'],
        'statTitle2': !exists(json, 'statTitle2') ? undefined : json['statTitle2'],
        'statNoBg': !exists(json, 'statNoBg') ? undefined : json['statNoBg'],
        'funnelUseLogFn': !exists(json, 'funnelUseLogFn') ? undefined : json['funnelUseLogFn'],
        'funnelAdditonalMetrics': !exists(json, 'funnelAdditonalMetrics') ? undefined : (json['funnelAdditonalMetrics'].map(ApiAdditionalMetricsListFromJSON)),
        'fullAttributesList': !exists(json, 'fullAttributesList') ? undefined : (json['fullAttributesList'].map(ApiDimensionFromJSON)),
    };
}
export function ApiChartDisplayOptionsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'rowsPerPage': value.rowsPerPage,
        'noPagination': value.noPagination,
        'availableMetrics': value.availableMetrics === undefined ? undefined : (value.availableMetrics.map(ApiMetricToJSON)),
        'pieTotalText': value.pieTotalText,
        'fills': value.fills,
        'noLegend': value.noLegend,
        'noReorder': value.noReorder,
        'metricsOrder': value.metricsOrder,
        'noFilters': value.noFilters,
        'showLabels': value.showLabels,
        'availableDimensions': value.availableDimensions === undefined ? undefined : (value.availableDimensions.map(ApiDimensionToJSON)),
        'filterDropdowns': value.filterDropdowns === undefined ? undefined : (value.filterDropdowns.map(ApiDimensionToJSON)),
        'layout': ApiChartLayoutToJSON(value.layout),
        'noTitle': value.noTitle,
        'noAttributionToggle': value.noAttributionToggle,
        'noAxis': value.noAxis,
        'pieTotalFontSize': value.pieTotalFontSize,
        'showTotals': value.showTotals,
        'statDesignIndex': value.statDesignIndex,
        'statTitle': value.statTitle,
        'statTitle2': value.statTitle2,
        'statNoBg': value.statNoBg,
        'funnelUseLogFn': value.funnelUseLogFn,
        'funnelAdditonalMetrics': value.funnelAdditonalMetrics === undefined ? undefined : (value.funnelAdditonalMetrics.map(ApiAdditionalMetricsListToJSON)),
        'fullAttributesList': value.fullAttributesList === undefined ? undefined : (value.fullAttributesList.map(ApiDimensionToJSON)),
    };
}
