/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ApiProjectFromJSON, ApiProjectToJSON, } from './ApiProject';
import { ApiProjectSettingKeyFromJSON, ApiProjectSettingKeyToJSON, } from './ApiProjectSettingKey';
export function ApiProjectSettingFromJSON(json) {
    return ApiProjectSettingFromJSONTyped(json, false);
}
export function ApiProjectSettingFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'project': !exists(json, 'project') ? undefined : ApiProjectFromJSON(json['project']),
        'key': !exists(json, 'key') ? undefined : ApiProjectSettingKeyFromJSON(json['key']),
        'value': !exists(json, 'value') ? undefined : json['value'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
    };
}
export function ApiProjectSettingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'project': ApiProjectToJSON(value.project),
        'key': ApiProjectSettingKeyToJSON(value.key),
        'value': value.value,
        'projectId': value.projectId,
    };
}
