/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export var ApiAttributionClicksMetric;
(function (ApiAttributionClicksMetric) {
    ApiAttributionClicksMetric["ATTRIBUTION_CLICKS_METRIC_UNDEFINED"] = "AttributionClicksMetricUndefined";
    ApiAttributionClicksMetric["ATTRIBUTION_CLICKS_METRIC_CLICKS"] = "AttributionClicksMetricClicks";
    ApiAttributionClicksMetric["ATTRIBUTION_CLICKS_METRIC_SPEND"] = "AttributionClicksMetricSpend";
    ApiAttributionClicksMetric["ATTRIBUTION_CLICKS_METRIC_AVG_CPC"] = "AttributionClicksMetricAvgCPC";
    ApiAttributionClicksMetric["ATTRIBUTION_CLICKS_METRIC_CONVERSIONS"] = "AttributionClicksMetricConversions";
    ApiAttributionClicksMetric["ATTRIBUTION_CLICKS_METRIC_ALL_CONVERSIONS"] = "AttributionClicksMetricAllConversions";
    ApiAttributionClicksMetric["ATTRIBUTION_CLICKS_METRIC_IMPRESSIONS"] = "AttributionClicksMetricImpressions";
    ApiAttributionClicksMetric["ATTRIBUTION_CLICKS_METRIC_CTR"] = "AttributionClicksMetricCTR";
})(ApiAttributionClicksMetric || (ApiAttributionClicksMetric = {}));
export function ApiAttributionClicksMetricFromJSON(json) {
    return ApiAttributionClicksMetricFromJSONTyped(json, false);
}
export function ApiAttributionClicksMetricFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ApiAttributionClicksMetricToJSON(value) {
    return value;
}
