import {
  Box,
  BoxProps,
  StackProps,
  Button,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import GlassCard from "Components/GlassCard/GlassCard";
import ChannelPicker from "Components/ChannelPicker/ChannelPicker";
import { useMemo, useState } from "react";
import { ApiChannel } from "@incendium/api";
import { TAttributionModel } from "Interfaces";
import { IGlassProps } from "Components/Glass";
import { useTheme } from "@mui/styles";
import { AnimatePresence, motion } from "framer-motion";

const ExpandedLi = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  paddingLeft: "38px",
  marginTop: "-6px",
  position: "relative",

  "& li": {
    position: "relative",
    listStyle: "none",
    fontSize: 12,
    marginBottom: "10px",
    "&::before": {
      content: "''",
      position: "absolute",
      top: "50%",
      transform: "translateY(calc(-50% - 7px))",
      bottom: 0,
      left: -24,
      width: 14,
      height: 14,
      borderLeft: `2px solid ${theme.palette.secondary.main}`,
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
  },

  "&::before": {
    content: "''",
    position: "absolute",
    top: -12,
    bottom: 20,
    left: 14,
    width: 2,
    background: theme.palette.secondary.main,
  },
}));

interface IAnalyticsCardItemProps extends React.HTMLAttributes<HTMLDivElement> {
  icon: React.ReactNode;
  expanded?:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | false;
}
export const AnalyticsCardItem = ({
  icon,
  expanded,
  children,
}: IAnalyticsCardItemProps) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        mb={2}
      >
        <Box mr={1} display="flex">
          act
          {icon}
        </Box>
        <Typography variant="body2">{children}</Typography>
      </Box>
      {expanded && <ExpandedLi>{expanded}</ExpandedLi>}
    </>
  );
};

export const AnalyticsCardTitleWrapper = ({
  children,
  ...rest
}: StackProps) => {
  return (
    <Stack
      mb={1}
      direction="row"
      justifyContent={"space-between"}
      alignItems={"flex-start"}
      spacing={1}
      {...rest}
    >
      {children}
    </Stack>
  );
};

export const AnalyticsCardTitle = ({ title }: { title: string }) => {
  return (
    <Box>
      <Typography variant="h4" color={"text.primary"} align="left">
        {title}
      </Typography>
    </Box>
  );
};

export const AnalyticsCardLegendContainer = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  return <Box mt={2}>{children}</Box>;
};

export const AnalyticsCardFilterContainer = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <Box ml={0.5} sx={{ flexShrink: 0 }}>
      {children}
    </Box>
  );
};

export const AnalyticsCardCustomListLabel = ({
  cx,
  cy,
  name,
  ...rest
}: any) => {
  const theme = useTheme();

  // split at every 13 characters wrapping words
  const labels = useMemo(() => {
    return name.split(" ").reduce((agg: string, p: string, i: number) => {
      const split = agg.split("\n");
      const combined = split[split.length - 1] + p;
      const b = i > 0 && combined.length > 13 ? " \n" : " ";
      return agg + b + p;
    }, "");
  }, [name]);

  // using a random number between 2 and 4 to make each lable a different angle, was just 2 before
  // const randPercentageX = Math.floor(Math.random() * (4 - 2 + 1)) + 2;
  // const randPercentageY = Math.floor(Math.random() * (4 - 2 + 1)) + 2;

  const endPointX = cx + rest.width / 2;
  const endPointY = cy + rest.height / 2;
  return (
    <>
      <text
        {...rest}
        pointerEvents={"none"}
        x={cx}
        fill={theme.palette.info.main}
        textAnchor="left"
      >
        {labels.split("\n").map((label: string, i: number) => (
          <tspan
            key={i}
            x={endPointX + 10}
            y={endPointY + i * 15 + 4}
            // y={rest.y}
            fontSize={12}
          >
            {label}
          </tspan>
        ))}
      </text>
      <line
        x1={cx}
        y1={cy}
        x2={endPointX}
        y2={endPointY}
        stroke={theme.palette.info.main}
      />
      <line
        x1={endPointX}
        x2={endPointX + 8}
        y1={endPointY}
        y2={endPointY}
        stroke={theme.palette.info.main}
      />
    </>
  );
};

export const AnalyticsCardActionsBlock = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 7,
  transform: `translate(-29px, 14px)`,
  background: theme.palette.info.main,
  width: 100,
  color: "white",
  "&::before": {
    content: "''",
    position: "absolute",
    bottom: 26,
    left: -5,
    height: 10,
    width: 10,
    borderTop: "3px solid transparent",
    borderBottom: "5px solid transparent",
    borderRight: `5px solid ${theme.palette.info.main}`,
  },
}));

export const AnalyticsCardSelectChannelsBtn = ({
  selected,
  setSelected,
  model,
  setModel,
}: {
  selected: ApiChannel[];
  setSelected: React.Dispatch<React.SetStateAction<ApiChannel[]>>;
  model: TAttributionModel;
  setModel: React.Dispatch<React.SetStateAction<TAttributionModel>>;
}) => {
  const [open, setOpen] = useState(false);
  const onSelected = (selected: ApiChannel[], model: TAttributionModel) => {
    setSelected(selected);
    setModel(model);
  };

  return (
    <>
      <Box mb={1}>
        <Button size="extraSmall" onClick={() => setOpen(true)}>
          select channels
        </Button>
      </Box>
      <ChannelPicker
        initialSelected={selected}
        initialModel={model}
        open={open}
        setOpen={setOpen}
        onSelected={onSelected}
      />
    </>
  );
};

export const AnalyticsBodyWrapper = ({ children }: BoxProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        flex: 1,
      }}
      component={motion.div}
      initial={{ opacity: 0, minHeight: 0, x: "-50%" }}
      animate={{ opacity: 1, x: 0 }}
    >
      <AnimatePresence mode="wait">{children}</AnimatePresence>
    </Box>
  );
};

interface IAnalyticsCardProps extends IGlassProps {
  noGlass?: boolean;
  noPadding?: boolean;
  flex?: boolean;
  // overflow?: boolean;
  minHeight?: number;
  justifyContent?: string;
}

function AnalyticsCard({
  children,
  sx,
  noGlass,
  opacity,
  noPadding,
  flex = true,
  // overflow = false,
  minHeight = 150,
  justifyContent = "center",
  ...rest
}: IAnalyticsCardProps) {
  const Compo = noGlass ? Box : GlassCard;
  return (
    <Compo sx={sx} {...rest} noShadow opacity={opacity || 0.6}>
      <Box
        sx={{
          minHeight,
          padding: !noPadding ? 2 : 0,
          height: "100%",
          display: flex ? "flex" : "block",
          flexDirection: "column",
          justifyContent,
          // overflow: overflow ? "auto" : "hidden",
        }}
      >
        {children}
      </Box>
    </Compo>
  );
}

export default AnalyticsCard;
