import { ApiDimension, ApiMetric } from "@incendium/api";
import { centsToDollars, formatNumber } from "Helpers/numbers";
import {
  AttributionMetric,
  AttributionType,
  TDimensionMetricMap,
} from "features/analytics/types/types";

const ecomExcludedDimension = [
  ApiDimension.DIMENSION_CRAWLER,
  ApiDimension.DIMENSION_PATH,
  ApiDimension.DIMENSION_LOCATION,
  ApiDimension.DIMENSION_JOB,
  ApiDimension.DIMENSION_JOB_CATEGORY,
  ApiDimension.DIMENSION_JOB_TITLE,
  ApiDimension.DIMENSION_LANDING_PAGE_LOCATION,
  // ApiDimension.DIMENSION_LANDING_PAGE_META_VALUE,
  ApiDimension.DIMENSION_LANDING_PAGE_PATH,
  ApiDimension.DIMENSION_LANDING_NEXT_PAGE_LOCATION,
  // ApiDimension.DIMENSION_LANDING_NEXT_PAGE_META_VALUE,
  ApiDimension.DIMENSION_LANDING_NEXT_PAGE_PATH,
  ApiDimension.DIMENSION_SESSION_JOURNEY,
  ApiDimension.DIMENSION_DAYS_TO_SECOND_VISIT,
];

const metricMap: { [key in ApiMetric]?: TDimensionMetricMap } = {
  [ApiMetric.METRIC_NOT_SET]: {
    originalName: "not set",
    name: "not set",
    description: "",
  },
  [ApiMetric.METRIC_PAGE_VIEWS_COUNT]: {
    originalName: "pageview_count",
    name: "Pageviews",
    description: "number of pageviews by dimension",
    excludedDimensions: [ApiDimension.DIMENSION_SESSION_JOURNEY], // todo add converting_sessions_journey, channel_macro_conversion_journey
  },
  [ApiMetric.METRIC_SESSIONS_COUNT]: {
    originalName: "session_count",
    name: "Sessions",
    description: "number of sessions by dimensions",
    excludedDimensions: [ApiDimension.DIMENSION_SESSION_JOURNEY], // todo add converting_sessions_journey, channel_macro_conversion_journey
  },
  [ApiMetric.METRIC_DESKTOP_SESSION_COUNT]: {
    originalName: "session_desktop_count",
    name: "Desktop Sessions",
    description: "number of sessions by dimensions on a desktop",
    excludedDimensions: [ApiDimension.DIMENSION_SESSION_JOURNEY], // todo add converting_sessions_journey, channel_macro_conversion_journey
  },
  [ApiMetric.METRIC_MOBILE_SESSION_COUNT]: {
    originalName: "session_mobile_count",
    name: "Mobile Sessions",
    description: "number of sessions by dimensions on a mobile",
    excludedDimensions: [ApiDimension.DIMENSION_SESSION_JOURNEY], // todo add converting_sessions_journey, channel_macro_conversion_journey
  },
  [ApiMetric.METRIC_LEADS_COUNT]: {
    originalName: "lead_count",
    name: "Leads",
    description: "number of leads by dimensions",
    excludedDimensions: [
      ApiDimension.DIMENSION_SESSION_JOURNEY,
      ApiDimension.DIMENSION_CRAWLER,
    ], // todo add converting_sessions_journey, channel_macro_conversion_journey
  },
  [ApiMetric.METRIC_NEW_LEADS_COUNT]: {
    originalName: "new_lead_count",
    name: "New Leads",
    description: "number of new leads by dimensions",
    excludedDimensions: [
      ApiDimension.DIMENSION_SESSION_JOURNEY,
      ApiDimension.DIMENSION_CRAWLER,
    ], // todo add visits_since_prior_macro_conversion, converting_sessions_journey, channel_macro_conversion_journey
  },
  [ApiMetric.METRIC_NEW_CAMPAIGN_LEAD_COUNT]: {
    originalName: "new_campaign_lead_count",
    name: "New Campaign Leads",
    description: "number of new leads that are new for a given campaign",
  },
  [ApiMetric.METRIC_RETURNING_LEADS_COUNT]: {
    originalName: "returning_lead_count",
    name: "Returning Leads",
    description: "number of non new leads by dimensions",
    excludedDimensions: [
      ApiDimension.DIMENSION_SESSION_JOURNEY,
      ApiDimension.DIMENSION_CRAWLER,
    ], // todo add visits_since_prior_macro_conversion, converting_sessions_journey, channel_macro_conversion_journey
  },
  [ApiMetric.METRIC_SESSION_REVENUE]: {
    originalName: "session_revenue",
    name: "Session Revenue",
    description: "revenue of sales per session per dimension",
    format: (v) => `$${centsToDollars(Number(v))}`,
  },
  [ApiMetric.METRIC_SESSION_COUNT_PER_LEAD]: {
    originalName: "session_count_per_lead",
    name: "Sessions Per Lead",
    description: "number of sessions by dimensions by lead",
    excludedDimensions: [
      ApiDimension.DIMENSION_CRAWLER,
      ApiDimension.DIMENSION_SESSION_JOURNEY,
      ApiDimension.DIMENSION_DAYS_TO_SECOND_VISIT,
    ], // todo add visits_since_prior_macro_conversion, converting_sessions_journey, channel_macro_conversion_journey
  },
  [ApiMetric.METRIC_NEW_SESSION_COUNT]: {
    originalName: "new_session_count",
    name: "New Sessions",
    description: "number of sessions where lead is new by dimensions",
    excludedDimensions: [
      ApiDimension.DIMENSION_CRAWLER,
      ApiDimension.DIMENSION_SESSION_JOURNEY,
      ApiDimension.DIMENSION_DAYS_TO_SECOND_VISIT,
    ], // todo add visits_since_prior_macro_conversion, converting_sessions_journey, channel_macro_conversion_journey
  },
  [ApiMetric.METRIC_RETURNING_SESSION_COUNT]: {
    originalName: "returning_session_count",
    name: "Returning Sessions",
    description: "number of sessions where lead is not new by dimensions",
    excludedDimensions: [
      ApiDimension.DIMENSION_CRAWLER,
      ApiDimension.DIMENSION_SESSION_JOURNEY,
      ApiDimension.DIMENSION_DAYS_TO_SECOND_VISIT,
    ], // todo add visits_since_prior_macro_conversion, converting_sessions_journey, channel_macro_conversion_journey
  },
  // [ApiMetric.METRIC_SESSION_PAGEVIEW_COUNT]: {
  //   originalName: "session_pageview_count",
  //   name: "Pageviews",
  //   description:
  //     "sum of number of pageviews in sessions where session in dimensions",
  // },
  [ApiMetric.METRIC_AVERAGE_PAGEVIEWS_PER_SESSION]: {
    originalName: "average_pageviews_per_session",
    name: "Avg. Pageviews Per Session",
    description: "average number of pageviews per each session by dimensions",
  },
  [ApiMetric.METRIC_AVERAGE_SESSION_DURATION]: {
    originalName: "average_session_duration",
    name: "Avg. Session Duration",
    description: "average no. of seconds a session lasted for",
    format: (v) => `${formatNumber(Number(v))}s`,
  },
  [ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT]: {
    originalName: "effective_session_count",
    name: "Effective Sessions",
    description:
      "number of sessions that was marked as effective by dimensions",
  },
  [ApiMetric.METRIC_EFFECTIVE_SESSION_COUNT_PER_LEAD]: {
    originalName: "effective_session_count_per_lead",
    name: "Effective Sessions Per Lead",
    description:
      "number of sessions that was marked as effective by dimensions by lead",
  },
  [ApiMetric.METRIC_EFFECTIVE_SESSION_RATE]: {
    originalName: "effective_session_rate",
    name: "Effective Session Rate",
    description: "effective_session_count / session_count",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_COUNT]: {
    originalName: "sum_effective_pageview_count_during_session",
    name: "Effective Pageviews",
    description:
      "sum of number of effective pageviews that belong to sessions by dimensions",
  },
  [ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_COUNT_PER_LEAD]: {
    originalName: "effective_pageview_count_per_lead",
    name: "Effective Pageviews Per Lead",
    description:
      "number of pageviews that was marked as effective by dimensions by lead",
  },
  [ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE]: {
    originalName: "effective_pageview_rate",
    name: "Effective Pageview Rate",
    description: "effective_pageview_count / pageview_count",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE]: {
    originalName: "average_time_on_page",
    name: "Avg. Pageview Time on Page",
    description: "average time spent per pageview per pageview by dimension",
    format: (v) => `${formatNumber(Number(v))}s`,
  },
  [ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE]: {
    originalName: "average_viewed_percentage",
    name: "Avg. Pageview Viewed Rate",
    description: "average page seen per pageview per pageview by dimension",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE]: {
    originalName: "average_scroll_percentage",
    name: "Avg. Pageview Scrolled Rate",
    description: "average page scrolled per pageview per pageview by dimension",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_AVERAGE_SCROLL_SPEED]: {
    originalName: "average_scroll_speed",
    name: "Avg. Pageview Scroll Speed",
    description:
      "average scroll velocity per pageview per pageview by dimension",
  },
  [ApiMetric.METRIC_RETURNING_LEAD_PERCENTAGE]: {
    originalName: "returning_lead_percentage",
    name: "Returning Leads %",
    description: "what percentage of leads are returning leads",
    format: (v) => `${formatNumber(Number(v))}%`,
    excludedDimensions: [
      ApiDimension.DIMENSION_SESSION_JOURNEY,
      ApiDimension.DIMENSION_CRAWLER,
    ], // todo add visits_since_prior_macro_conversion, converting_sessions_journey, channel_macro_conversion_journey
  },
  [ApiMetric.METRIC_EFFECTIVE_SESSION_TO_CONVERSION_RATE]: {
    originalName: "effective_session_to_conversion_rate",
    name: "Effective Sessions to Conversion Rate",
    description: "session_macro_conversion_count / effective_session_count",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_COUNT]: {
    originalName: "session_macro_conversion_count",
    name: "Conversions",
    description: "number of Conversions that happened in session but dimension",
  },
  [ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT]: {
    originalName: "session_micro_conversion_count",
    name: "Interactions",
    description:
      "number of Interactions that happened in session but dimension",
  },
  [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE]: {
    originalName: "session_macro_conversion_rate",
    name: "Conversion Rate",
    description: "Number of sessions with at least 1 Conversion / no sessions",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_SESSION_MICRO_CONVERSION_RATE]: {
    originalName: "session_micro_conversion_rate",
    name: "Interaction Rate",
    description: "Number of sessions with at least 1 Interaction / no sessions",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRICMACRO_CONVERSION_COUNT_PER_LEAD]: {
    originalName: "macro_conversion_count_per_lead",
    name: "Conversion Per Lead",
    description: "Number of Conversions by dimensions by lead",
  },
  [ApiMetric.METRICMICRO_CONVERSION_COUNT_PER_LEAD]: {
    originalName: "micro_conversion_count_per_lead",
    name: "Interaction Per Lead",
    description: "Number of Interactions by dimensions by lead",
  },
  [ApiMetric.METRICEFFECTIVE_PAGEVIEW_TO_CONVERSION_RATE]: {
    originalName: "effective_pageview_to_conversion_rate",
    name: "Effective Pageview to Conversion Rate",
    description: "page_macro_conversion_count / effective_pageview_count",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_PAGE_MACRO_CONVERSION_COUNT]: {
    originalName: "page_macro_conversion_count",
    name: "Pageview Conversions",
    description: "number of Conversions per page",
  },
  [ApiMetric.METRIC_PAGE_MICRO_CONVERSION_COUNT]: {
    originalName: "page_micro_conversion_count",
    name: "Pageview Interactions",
    description: "number of Interactions per page",
  },
  [ApiMetric.METRIC_PAGE_MACRO_CONVERSION_RATE]: {
    originalName: "page_macro_conversion_rate",
    name: "Pageview Conversion Rate",
    description: "page_macro_conversion_count / pageview_count",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_PAGE_MICRO_CONVERSION_RATE]: {
    originalName: "page_micro_conversion_rate",
    name: "Pageview Interaction Rate",
    description: "page_micro_conversion_count / pageview_count",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_LEAD_MACRO_CONVERSION_PERCENTAGE]: {
    originalName: "lead_macro_conversion_percentage",
    name: "Leads Conversion Rate",
    description: "percentage of leads that have a Conversion by dimension",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_COUNT_DURING_SESSION]: {
    originalName: "average_effective_pageview_count_during_session",
    name: "Avg. Effective Pageviews",
    description: "sum_effective_pageview_count_during_session / session_count",
  },
  [ApiMetric.METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_RATE_DURING_SESSION]: {
    originalName: "average_effective_pageview_rate_during_session",
    name: "Avg. Effective Pageview Rate",
    description:
      "average_effective_pageview_count_during_session / average_pageviews_per_session",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE_DURING_SESSION]: {
    originalName: "average_time_on_page_during_session",
    name: "Avg. Time Spent Per Session",
    description: "average time spent per pageview per session by dimension",
    format: (v) => `${formatNumber(Number(v))}s`,
  },
  [ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE_DURING_SESSION]: {
    originalName: "average_viewed_percentage_during_session",
    name: "Avg. Page Viewed Rate Per Session",
    description: "average page seen per pageview per session by dimension",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE_DURING_SESSION]: {
    originalName: "average_scroll_percentage_during_session",
    name: "Avg. Scrolled Rate Per Session",
    description: "average page scrolled per pageview per session by dimension",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_AVERAGE_SCROLL_SPEED_DURING_SESSION]: {
    originalName: "average_scroll_speed_during_session",
    name: "Avg. Scroll Speed",
    description:
      "average scroll velocity per pageview per session by dimension",
  },
  [ApiMetric.METRIC_NEW_CUSTOMER_COUNT]: {
    originalName: "new_customer_count",
    name: "New Customers",
    description:
      "number of leads whos first sale was in the selected date window",
    excludedDimensions: [
      ApiDimension.DIMENSION_SESSION_JOURNEY,
      ApiDimension.DIMENSION_CRAWLER,
    ], // todo add visits_since_prior_macro_conversion, converting_sessions_journey, channel_macro_conversion_journey
  },
  [ApiMetric.METRIC_RETURNING_CUSTOMER_COUNT]: {
    originalName: "returning_customer_count",
    name: "Returning Customers",
    description: "number of leads whomade a Purchase and returned to the site",
    excludedDimensions: [
      ApiDimension.DIMENSION_SESSION_JOURNEY,
      ApiDimension.DIMENSION_CRAWLER,
    ], // todo add visits_since_prior_macro_conversion, converting_sessions_journey, channel_macro_conversion_journey
  },
  [ApiMetric.METRIC_SESSION_SALES_COUNT]: {
    originalName: "session_sales_count",
    name: "Sales",
    description: "number of sales per session per dimension",
  },
  [ApiMetric.METRIC_SALES_COMPLETION_RATE]: {
    originalName: "sales_completion_rate",
    name: "Sales Completion Rate",
    description: "This is the products sales count / add to cart rate",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_COUNT]: {
    originalName: "click_through_count",
    name: "Product Click Through Count",
    description:
      "Number of impression that were clicked though to the product page",
  },
  [ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_RATE]: {
    originalName: "click_through_rate",
    name: "Product Click Through Rate",
    description:
      "Total number of impressions / Number of impression that were clicked though to the product page",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_PRODUCT_IMPRESSION_COUNT]: {
    originalName: "product_impression_count",
    name: "Product Impression Count",
    description: "Number of impression a user saw",
  },
  [ApiMetric.METRIC_PRODUCT_IMPRESSION_RATE]: {
    originalName: "product_impression_count",
    name: "Product Impression Rate",
    description: "percentage. of leads who saw impression",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_JOB_CLICK_THROUGH_COUNT]: {
    originalName: "job_click_through_count",
    name: "Job Click Through Count",
    description:
      "Number of impression that were clicked though to the job page",
  },
  [ApiMetric.METRIC_JOB_CLICK_THROUGH_RATE]: {
    originalName: "job_click_through_rate",
    name: "Job Click Through Rate",
    description:
      "Total number of impressions / Number of impression that were clicked though to the job page",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_JOB_IMPRESSION_COUNT]: {
    originalName: "job_impression_count",
    name: "Job Impression Count",
    description: "Number of impression a user saw",
  },
  [ApiMetric.METRIC_JOB_IMPRESSION_RATE]: {
    originalName: "job_impression_count",
    name: "Job Impression Rate",
    description: "percentage. of leads who saw impression",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_SALES_REVENUE]: {
    originalName: "sales_revenue",
    name: "Basket Revenue",
    description: "revenue grouped by sales",
    format: (v) => `$${centsToDollars(Number(v))}`,
  },
  [ApiMetric.METRIC_VISIT_TO_PURCHASE_RATE]: {
    originalName: "visit_to_purchase_rate",
    name: "Sessions to Purchase Rate",
    description: "session_sales_count / session_count",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_VISIT_TO_APPLICATION_RATE]: {
    originalName: "visit_to_application_rate",
    name: "Sessions to Application Rate",
    description: "application_count / session_count",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_AVERAGE_SALE_VALUE]: {
    originalName: "average_sale_value",
    name: "AOV",
    description: "(revenue / no. of sales)",
    format: (v) => `$${centsToDollars(Number(v))}`,
  },
  [ApiMetric.METRIC_FIRST_VISIT_SESSION_MACRO_CONVERSION_COUNT]: {
    originalName: "first_visit_session_macro_conversion_count",
    name: "First Session Conversions",
    description:
      "number of Conversions that happened in session when first visit",
  },
  [ApiMetric.METRIC_THIRTY_DAY_SESSION_MACRO_CONVERSION_COUNT]: {
    originalName: "thirty_day_session_macro_conversion_count",
    name: "All Conversions - 30 Days",
    description:
      "number of Conversions that happened in session when within 30 days of first visist",
  },
  [ApiMetric.METRIC_SIXTY_DAY_SESSION_MACRO_CONVERSION_COUNT]: {
    originalName: "sixty_day_session_macro_conversion_count",
    name: "All Conversions - 60 Days",
    description:
      "number of Conversions that happened in session when within 60 days of first visist",
  },
  [ApiMetric.METRIC_NINETY_DAY_SESSION_MACRO_CONVERSION_COUNT]: {
    originalName: "ninety_day_session_macro_conversion_count",
    name: "All Conversions - 90 Days",
    description:
      "number of Conversions that happened in session when within 90 days of first visist",
  },
  [ApiMetric.METRIC_THIRTY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL]: {
    originalName: "thirty_day_session_macro_conversion_count_additional",
    name: "Added Conversions - 30 Days",
    description:
      "number of Conversions that happened in session when within 30 days of first visist (no top of initial)",
  },
  [ApiMetric.METRIC_SIXTY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL]: {
    originalName: "sixty_day_session_macro_conversion_count_additional",
    name: "Added Conversions - 60 Days",
    description:
      "number of Conversions that happened in session when within 60 days of first visist (no top of initial)",
  },
  [ApiMetric.METRIC_NINETY_DAY_SESSION_MACRO_CONVERSION_COUNT_ADDITIONAL]: {
    originalName: "ninety_day_session_macro_conversion_count_additional",
    name: "Added Conversions - 90 Days",
    description:
      "number of Conversions that happened in session when within 90 days of first visist (no top of initial)",
  },
  [ApiMetric.METRIC_FIRST_VISIT_SESSION_SALES_COUNT]: {
    originalName: "first_visit_session_sales_count",
    name: "First Session Sales",
    description: "number of sales that happened in session when first visit",
  },
  [ApiMetric.METRIC_THIRTY_DAY_SESSION_SALES_COUNT]: {
    originalName: "thirty_day_session_sales_count",
    name: "All 30 Day Session Sales",
    description:
      "number of sales that happened in session when within 30 days of first visist",
  },
  [ApiMetric.METRIC_SIXTY_DAY_SESSION_SALES_COUNT]: {
    originalName: "sixty_day_session_sales_count",
    name: "All 60 Day Session Sales",
    description:
      "number of sales that happened in session when within 60 days of first visist",
  },
  [ApiMetric.METRIC_NINETY_DAY_SESSION_SALES_COUNT]: {
    originalName: "ninety_day_session_sales_count",
    name: "All 90 Day Session Sales",
    description:
      "number of sales that happened in session when within 90 days of first visist",
  },
  [ApiMetric.METRIC_THIRTY_DAY_SESSION_SALES_COUNT_ADDITIONAL]: {
    originalName: "thirty_day_session_sales_count_additional",
    name: "Added 30 Day Session Sales",
    description:
      "number ofsales that happened in session when within 30 days of first visist (no top of initial)",
  },
  [ApiMetric.METRIC_SIXTY_DAY_SESSION_SALES_COUNT_ADDITIONAL]: {
    originalName: "sixty_day_session_sales_count_additional",
    name: "Added 60 Day Session Sales",
    description:
      "number of sales that happened in session when within 60 days of first visist (no top of initial)",
  },
  [ApiMetric.METRIC_NINETY_DAY_SESSION_SALES_COUNT_ADDITIONAL]: {
    originalName: "ninety_day_session_sales_count_additional",
    name: "Added 90 Day Session Sales",
    description:
      "number of sales that happened in session when within 90 days of first visist (no top of initial)",
  },
  [ApiMetric.METRIC_FIRST_VISIT_PAGE_MACRO_CONVERSION_COUNT]: {
    originalName: "first_visit_page_macro_conversion_count",
    name: "First Session Pageview Conversions",
    description: "number of Conversions per page where is first visit",
  },
  [ApiMetric.METRI_THIRTY_DAY_MACRO_CONVERSION_COUNT]: {
    originalName: "thirty_day_page_macro_conversion_count",
    name: "All Page Conversions - 30 Days",
    description:
      "number of Conversions per page within 30 days or first session",
  },
  [ApiMetric.METRIC_SIXTY_DAY_MACRO_CONVERSION_COUNT]: {
    originalName: "sixty_day_page_macro_conversion_count",
    name: "All Pageview Conversions - 60 Days",
    description:
      "number of Conversions per page within 60 days or first session",
  },
  [ApiMetric.METRIC_NINETY_DAY_MACRO_CONVERSION_COUNT]: {
    originalName: "ninety_day_page_macro_conversion_count",
    name: "All Pageview Conversions - 90 Days",
    description:
      "number of Conversions per page within 90 days or first session",
  },
  [ApiMetric.METRIC_THIRTY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL]: {
    originalName: "thirty_day_page_macro_conversion_count_additional",
    name: "Added 30 Day Pageview Conversions",
    description:
      "number of Conversions per page within 30 days or first session (no top of initial)",
  },
  [ApiMetric.METRIC_SIXTY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL]: {
    originalName: "sixty_day_page_macro_conversion_count_additional",
    name: "Added 60 Day Pageview Conversions",
    description:
      "number of Conversions per page within 60 days or first session (no top of initial)",
  },
  [ApiMetric.METRIC_NINETY_DAY_MACRO_CONVERSION_COUNT_ADDITIONAL]: {
    originalName: "ninety_day_page_macro_conversion_count_additional",
    name: "Added 90 Day Pageview Conversions",
    description:
      "number of Conversions per page within 90 days or first session (no top of initial)",
  },
  [ApiMetric.METRIC_PRODUCT_REVENUE]: {
    originalName: "product_revenue",
    name: "Product Revenue",
    description: "revenue grouped by sales items",
    format: (v) => `$${centsToDollars(Number(v))}`,
  },
  [ApiMetric.METRIC_RETURNING_NINETY_DAY_CUSTOMER_COUNT]: {
    originalName: "returning_ninety_day_customer_count",
    name: "Returning Customers - 90 Days",
    description:
      "number of leads that had a visit after purchasing in the last 90 days",
    excludedDimensions: [
      ApiDimension.DIMENSION_SESSION_JOURNEY,
      ApiDimension.DIMENSION_CRAWLER,
    ], // todo add visits_since_prior_macro_conversion, converting_sessions_journey, channel_macro_conversion_journey
  },
  [ApiMetric.METRIC_RETURNING_NINETY_DAY_CUSTOMER_PERCENTAGE]: {
    originalName: "returning_ninety_day_customer_percentage",
    name: "Returning Customers Percentage - 90 Days",
    description:
      "what percentage of leads had a visit after purchasing in the last 90 days",
    excludedDimensions: [
      ApiDimension.DIMENSION_SESSION_JOURNEY,
      ApiDimension.DIMENSION_CRAWLER,
    ], // todo add visits_since_prior_macro_conversion, converting_sessions_journey, channel_macro_conversion_journey
  },
  [ApiMetric.METRIC_PRODUCT_PAGEVIEW_COUNT]: {
    originalName: "product_pageview_count",
    name: "Product Pageviews",
    description:
      "number of pageviews by dimension that are linked to a product",
    excludedDimensions: [
      ApiDimension.DIMENSION_JOB,
      ApiDimension.DIMENSION_JOB_CATEGORY,
      ApiDimension.DIMENSION_JOB_TITLE,
      ApiDimension.DIMENSION_SESSION_JOURNEY,
      ApiDimension.DIMENSION_DAYS_TO_SECOND_VISIT,
    ], // todo add visits_since_prior_macro_conversion, converting_sessions_journey, channel_macro_conversion_journey
  },
  [ApiMetric.METRIC_JOB_PAGEVIEW_COUNT]: {
    originalName: "job_pageview_count",
    name: "Job Pageviews",
    description: "number of pageviews by dimension that are linked to a job",
    excludedDimensions: [
      ApiDimension.DIMENSION_PRODUCT,
      ApiDimension.DIMENSION_PRODUCT_CATEGORY,
      ApiDimension.DIMENSION_PRODUCT_NAME,
      ApiDimension.DIMENSION_PRODUCT_BRAND,
      ApiDimension.DIMENSION_SESSION_JOURNEY,
      ApiDimension.DIMENSION_DAYS_TO_SECOND_VISIT,
    ], // todo add visits_since_prior_macro_conversion, converting_sessions_journey, channel_macro_conversion_journey
  },
  [ApiMetric.METRIC_LANDING_SESSION_COUNT]: {
    originalName: "landing_session_count",
    name: "Landing Sessions",
    description:
      "number of sessions by dimension where dimension is a landing page",
  },
  [ApiMetric.METRIC_CRAWLER_VISIT_COUNT]: {
    originalName: "crawler_visit_count",
    name: "Crawler Sessions",
    description:
      "number of sessions by a crawler, note only works if bot type is bots or both",
  },
  [ApiMetric.METRIC_OUT_OF_STOCK_PAGEVIEW_COUNT]: {
    originalName: "out_of_stock_pageview_count",
    name: "Out of Stock Pageviews",
    description:
      "number of pageviews that had pageview_meta out of stock at time of viewing",
  },
  [ApiMetric.METRIC_OUT_OF_STOCK_PAGES_COUNT]: {
    originalName: "out_of_stock_pages_count",
    name: "Out of Stock Pages",
    description:
      "number of unique pages that were visited that were out of stock at somepoint during selected time range",
  },
  [ApiMetric.METRIC_AVERAGE_DAYS_TO_SECOND_VISIT]: {
    originalName: "average_days_to_second_visit",
    name: "Avg. Days to Second Session",
    description:
      "average number of days a returning lead took for their secon visit",
  },
  [ApiMetric.METRIC_SESSION_JOB_APPLICATIONS_COUNT]: {
    originalName: "session_job_applications_count",
    name: "Session Job Applications",
    description:
      "number of conversions with reserved job application in session",
  },
  [ApiMetric.METRIC_SESSION_JOB_APPLY_CLICK_COUNT]: {
    originalName: "session_job_apply_click_count",
    name: "Session Click Job Apply Count",
    description:
      "number of conversions with reserved job Apply click in session",
  },
  [ApiMetric.METRIC_SESSION_ADD_TO_CART_CLICK_COUNT]: {
    originalName: "session_add_to_cart_count",
    name: "Session Add to Cart Count",
    description: "unique add to cart clicks in session",
  },
  [ApiMetric.METRIC_SESSION_ADD_TO_CART_CLICK_RATE]: {
    originalName: "session_add_to_cart_rate",
    name: "Session Add to Cart Rate",
    description:
      "(Unique ADD-TO-CART CLICKS / PRODUCT PAGE VIEWS ) x 100 in session",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_COUNT]: {
    originalName: "job_applications_count",
    name: "Job Applications",
    description: "number of conversions with reserved job application",
  },
  [ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_RATE]: {
    originalName: "job_applications_rate",
    name: "Job Application Rate",
    description: "number of conversions with reserved job application",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_COUNT]: {
    originalName: "job_apply_click_count",
    name: "Click Job Apply Count",
    description: "number of conversions with reserved job Apply click",
  },
  [ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_RATE]: {
    originalName: "job_apply_click_rate",
    name: "Click Job Apply Rate",
    description: "number of conversions with reserved job Apply click",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_COUNT]: {
    originalName: "add_to_cart_count",
    name: "Add to Cart Count",
    description: "unique add to cart clicks",
  },
  [ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_RATE]: {
    originalName: "add_to_cart_rate",
    name: "Add to Cart Rate",
    description: "(Unique ADD-TO-CART CLICKS / PRODUCT PAGE VIEWS ) x 100",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE_RANK]: {
    originalName: "effective_pageview_rate_rank",
    name: "Effective Pageview Rank Percentage",
    description: "(effective_pageview_count / pageview_count) ranked 0 to 100",
  },

  [ApiMetric.METRIC_SESSION_ALL_INCLUSIVE_COST]: {
    originalName: "All inclusive cost",
    name: "Total Cost",
    description: "session_cost + shipping + margin",
    excludedDimensions: ecomExcludedDimension,
    format: (v) => `$${centsToDollars(Number(v))}`,
  },
  [ApiMetric.METRIC_SPEND]: {
    originalName: "spend",
    name: "Media Spend",
    description: "cost per dimension, this is calculated from session_cost",
    excludedDimensions: ecomExcludedDimension,
    format: (v) => `$${centsToDollars(Number(v))}`,
  },
  [ApiMetric.METRIC_CPA]: {
    originalName: "CPA",
    name: "CPA",
    description: "total_revenue / first_conversion_count",
    excludedDimensions: ecomExcludedDimension,
    format: (v) => `$${centsToDollars(Number(v))}`,
  },
  [ApiMetric.METRIC_CAC]: {
    originalName: "CAC",
    name: "CAC",
    description: "spend / new customers",
    excludedDimensions: ecomExcludedDimension,
    format: (v) => `$${centsToDollars(Number(v))}`,
  },
  [ApiMetric.METRIC_ECR]: {
    originalName: "ECR",
    name: "ECR",
    description: "no. sales / visits",
    excludedDimensions: ecomExcludedDimension,
  },
  [ApiMetric.METRIC_CPNV]: {
    originalName: "CPNV",
    name: "CPNV (Cost Per New Visit)",
    description:
      "cost per new visit (visit from new person, this is no. of sessions that is leads first session)",
    excludedDimensions: ecomExcludedDimension,
    format: (v) => `$${centsToDollars(Number(v))}`,
  },
  [ApiMetric.METRIC_PREDICTED_ROAS]: {
    originalName: "predicted_ROAS",
    name: "Predicted Linear LTV ROAS - 90 Days",
    description: "ROAS but using ltv revenue + 90 day predicted ltv",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_LINEAR_PREDICTED30_DAY_LTV_REVENUE]: {
    originalName: "linear_predicted_day30_ltv_revenue",
    name: "Predicted Linear LTV Revenue - 30 Days",
    description:
      "30 days in the future (from now) predicted ltv, this is linear, so is by lead and will fall into all dimensions that lead belongs to",
    format: (v) => `$${centsToDollars(Number(v))}`,
  },
  [ApiMetric.METRIC_LINEAR_PREDICTED60_DAY_LTV_REVENUE]: {
    originalName: "linear_predicted_day60_ltv_revenue",
    name: "Predicted Linear LTV Revenue - 60 Days",
    description:
      "60 days in the future (from now) predicted ltv, this is linear, so is by lead and will fall into all dimensions that lead belongs to",
    format: (v) => `$${centsToDollars(Number(v))}`,
  },
  [ApiMetric.METRIC_LINEAR_PREDICTED90_DAY_LTV_REVENUE]: {
    originalName: "linear_predicted_day90_ltv_revenue",
    name: "Predicted Linear LTV Revenue - 90 Days",
    description:
      "90 days in the future (from now) predicted ltv, this is linear, so is by lead and will fall into all dimensions that lead belongs to",
    format: (v) => `$${centsToDollars(Number(v))}`,
  },
  [ApiMetric.METRIC_SESSION_CONTRIBUTION_MARGIN]: {
    originalName: "CM",
    name: "CM (Contribution Margin)",
    description:
      "NET REVENUE - COST OF DELIVERY (all involved costs eg delivery costs, margin etc) - SPEND",
    format: (v) => `$${centsToDollars(Number(v))}`,
  },
  [ApiMetric.METRIC_SESSION_EFFICIENCY_RATION]: {
    originalName: "MER",
    name: "Marketing Efficiency Ratio",
    description: "REVENUE / MARKETING SPEND",
    format: (v) => `${formatNumber(Number(v))}`,
  },
  [ApiMetric.METRIC_EXISTING_CUSTOMER_REVENUE_GROWTH_RATE]: {
    originalName: "ECRGR",
    name: "Existing customer revenue growth rate (ECRGR)",
    description:
      "((REVENUE FROM EXISTING CUSTOMERS AT THE END OF THE PERIOD - REVENUE FROM EXISTING CUSTOMERS AT THE START OF THE PERIOD) / REVENUE FROM EXISTING CUSTOMERS AT THE START OF THE PERIOD) x 100",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_LTVCAC_RATIO]: {
    originalName: "LTVCAC",
    name: "LTV:CAC Ratio",
    description: "customer ltv / customer acquisition cost",
  },
  [ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_SALES_COUNT]: {
    originalName: "first_sales_count",
    name: "First Click Sales Count",
    description: "",
    attribtionType: AttributionType.FIRST,
    attribtionMetric: AttributionMetric.SALES,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_SALES_COUNT]: {
    originalName: "last_sales_count",
    name: "Last Click Sales Count",
    description: "",
    attribtionType: AttributionType.LAST,
    attribtionMetric: AttributionMetric.SALES,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_SALES_COUNT]: {
    originalName: "last_non_sales_count",
    name: "Last Non Direct Sales Count",
    description: "",
    attribtionType: AttributionType.LAST_NON,
    attribtionMetric: AttributionMetric.SALES,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LINEAR_SALES_COUNT]: {
    originalName: "linear_sales_count",
    name: "Linear Sales Count",
    description: "",
    attribtionType: AttributionType.LINEAR,
    attribtionMetric: AttributionMetric.SALES,
  },
  [ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_SALES_COUNT]: {
    originalName: "effected_sales_count",
    name: "Impacted Sales Count",
    description: "",
    attribtionType: AttributionType.IMPACTED,
    attribtionMetric: AttributionMetric.SALES,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_SALES_COUNT]: {
    originalName: "position_sales_count",
    name: "Position Sales Count",
    description: "",
    attribtionType: AttributionType.POSITION,
    attribtionMetric: AttributionMetric.SALES,
  },
  [ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_MACRO_CONVERSION_COUNT]: {
    originalName: "first_macro_conversion_count",
    name: "First Click Conversion Count",
    description: "",
    attribtionType: AttributionType.FIRST,
    attribtionMetric: AttributionMetric.MACRO,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_MACRO_CONVERSION_COUNT]: {
    originalName: "last_macro_conversion_count",
    name: "Last Click Conversion Count",
    description: "",
    attribtionType: AttributionType.LAST,
    attribtionMetric: AttributionMetric.MACRO,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_MACRO_CONVERSION_COUNT]: {
    originalName: "last_non_macro_conversion_count",
    name: "Last Non Direct Conversion Count",
    description: "",
    attribtionType: AttributionType.LAST_NON,
    attribtionMetric: AttributionMetric.MACRO,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LINEAR_MACRO_CONVERSION_COUNT]: {
    originalName: "linear_macro_conversion_count",
    name: "Linear Conversion Count",
    description: "",
    attribtionType: AttributionType.LINEAR,
    attribtionMetric: AttributionMetric.MACRO,
  },
  [ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT]: {
    originalName: "effected_macro_conversion_count",
    name: "Impacted Conversion Count",
    description: "",
    attribtionType: AttributionType.IMPACTED,
    attribtionMetric: AttributionMetric.MACRO,
  },
  [ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_RATE]: {
    originalName: "effected_macro_conversion_rate",
    name: "Impacted Conversion Rate",
    description: "",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_MACRO_CONVERSION_COUNT]: {
    originalName: "position_macro_conversion_count",
    name: "Position Conversion Count",
    description: "",
    attribtionType: AttributionType.POSITION,
    attribtionMetric: AttributionMetric.MACRO,
  },
  [ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_REVENUE]: {
    originalName: "first_revenue",
    name: "First Click Revenue",
    description: "",
    attribtionType: AttributionType.FIRST,
    attribtionMetric: AttributionMetric.REVENUE,
    format: (v) => `$${centsToDollars(Number(v))}`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_REVENUE]: {
    originalName: "last_revenue",
    name: "Last Click Revenue",
    description: "",
    attribtionType: AttributionType.LAST,
    attribtionMetric: AttributionMetric.REVENUE,
    format: (v) => `$${centsToDollars(Number(v))}`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_REVENUE]: {
    originalName: "last_non_direct_revenue",
    name: "Last Non Direct Revenue",
    description: "",
    attribtionType: AttributionType.LAST_NON,
    attribtionMetric: AttributionMetric.REVENUE,
    format: (v) => `$${centsToDollars(Number(v))}`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LINEAR_REVENUE]: {
    originalName: "linear_revenue",
    name: "Linear Revenue",
    description: "",
    attribtionType: AttributionType.LINEAR,
    attribtionMetric: AttributionMetric.REVENUE,
    format: (v) => `$${centsToDollars(Number(v))}`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_REVENUE]: {
    originalName: "effected_revenue",
    name: "Impacted Revenue",
    description: "",
    attribtionType: AttributionType.IMPACTED,
    attribtionMetric: AttributionMetric.REVENUE,
    format: (v) => `$${centsToDollars(Number(v))}`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_REVENUE]: {
    originalName: "position_revenue",
    name: "Position Revenue",
    description: "",
    attribtionType: AttributionType.POSITION,
    attribtionMetric: AttributionMetric.REVENUE,
    format: (v) => `$${centsToDollars(Number(v))}`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_ROI]: {
    originalName: "first_ROI",
    name: "First Click ROI",
    description: "",
    attribtionType: AttributionType.FIRST,
    attribtionMetric: AttributionMetric.ROI,
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROI]: {
    originalName: "last_ROI",
    name: "Last Click ROI",
    description: "",
    attribtionType: AttributionType.LAST,
    attribtionMetric: AttributionMetric.ROI,
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROI]: {
    originalName: "last_non_ROI",
    name: "Last Non Direct ROI",
    description: "",
    attribtionType: AttributionType.LAST_NON,
    attribtionMetric: AttributionMetric.ROI,
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LINEAR_ROI]: {
    originalName: "linear_ROI",
    name: "Linear ROI",
    description: "",
    attribtionType: AttributionType.LINEAR,
    attribtionMetric: AttributionMetric.ROI,
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_ROI]: {
    originalName: "effected_ROI",
    name: "Impacted ROI",
    description: "",
    attribtionType: AttributionType.IMPACTED,
    attribtionMetric: AttributionMetric.ROI,
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROI]: {
    originalName: "position_ROI",
    name: "Position ROI",
    description: "",
    attribtionType: AttributionType.POSITION,
    attribtionMetric: AttributionMetric.ROI,
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_LTV]: {
    originalName: "first_LTV",
    name: "First Click LTV",
    description: "",
    attribtionType: AttributionType.FIRST,
    attribtionMetric: AttributionMetric.LTV,
    format: (v) => `$${centsToDollars(Number(v))}`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_LTV]: {
    originalName: "last_LTV",
    name: "Last Click LTV",
    description: "",
    attribtionType: AttributionType.LAST,
    attribtionMetric: AttributionMetric.LTV,
    format: (v) => `$${centsToDollars(Number(v))}`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_LTV]: {
    originalName: "last_non_LTV",
    name: "Last Non Direct LTV",
    description: "",
    attribtionType: AttributionType.LAST_NON,
    attribtionMetric: AttributionMetric.LTV,
    format: (v) => `$${centsToDollars(Number(v))}`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LINEAR_LTV]: {
    originalName: "linear_LTV",
    name: "Linear LTV",
    description: "",
    attribtionType: AttributionType.LINEAR,
    attribtionMetric: AttributionMetric.LTV,
    format: (v) => `$${centsToDollars(Number(v))}`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_LTV]: {
    originalName: "effected_LTV",
    name: "Impacted LTV",
    description: "",
    attribtionType: AttributionType.IMPACTED,
    attribtionMetric: AttributionMetric.LTV,
    format: (v) => `$${centsToDollars(Number(v))}`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_LTV]: {
    originalName: "position_LTV",
    name: "Position LTV",
    description: "",
    attribtionType: AttributionType.POSITION,
    attribtionMetric: AttributionMetric.LTV,
    format: (v) => `$${centsToDollars(Number(v))}`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_ROILTV]: {
    originalName: "first_ROILTV",
    name: "First Click ROILTV",
    description: "",
    attribtionType: AttributionType.FIRST,
    attribtionMetric: AttributionMetric.ROILTV,
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROILTV]: {
    originalName: "last_ROILTV",
    name: "Last Click ROILTV",
    description: "",
    attribtionType: AttributionType.LAST,
    attribtionMetric: AttributionMetric.ROILTV,
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROILTV]: {
    originalName: "last_non_ROILTV",
    name: "Last Non Direct ROILTV",
    description: "",
    attribtionType: AttributionType.LAST_NON,
    attribtionMetric: AttributionMetric.ROILTV,
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LINEAR_ROILTV]: {
    originalName: "linear_ROILTV",
    name: "Linear ROILTV",
    description: "",
    attribtionType: AttributionType.LINEAR,
    attribtionMetric: AttributionMetric.ROILTV,
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_ROILTV]: {
    originalName: "effected_ROILTV",
    name: "Impacted ROILTV",
    description: "",
    attribtionType: AttributionType.IMPACTED,
    attribtionMetric: AttributionMetric.ROILTV,
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROILTV]: {
    originalName: "position_ROILTV",
    name: "Position ROILTV",
    description: "",
    attribtionType: AttributionType.POSITION,
    attribtionMetric: AttributionMetric.ROILTV,
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_ROAS]: {
    originalName: "first_ROAS",
    name: "First Click ROAS",
    description: "",
    attribtionType: AttributionType.FIRST,
    attribtionMetric: AttributionMetric.ROAS,
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROAS]: {
    originalName: "last_ROAS",
    name: "Last Click ROAS",
    description: "",
    attribtionType: AttributionType.LAST,
    attribtionMetric: AttributionMetric.ROAS,
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROAS]: {
    originalName: "last_non_ROAS",
    name: "Last Non Direct ROAS",
    description: "",
    attribtionType: AttributionType.LAST_NON,
    attribtionMetric: AttributionMetric.ROAS,
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LINEAR_ROAS]: {
    originalName: "linear_ROAS",
    name: "Linear ROAS",
    description: "",
    attribtionType: AttributionType.LINEAR,
    attribtionMetric: AttributionMetric.ROAS,
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_ROAS]: {
    originalName: "effected_ROAS",
    name: "Impacted ROAS",
    description: "",
    attribtionType: AttributionType.IMPACTED,
    attribtionMetric: AttributionMetric.ROAS,
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROAS]: {
    originalName: "position_ROAS",
    name: "Position ROAS",
    description: "",
    attribtionType: AttributionType.POSITION,
    attribtionMetric: AttributionMetric.ROAS,
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_ROASLTV]: {
    originalName: "first_ROASLTV",
    name: "First Click ROASLTV",
    description: "",
    attribtionType: AttributionType.FIRST,
    attribtionMetric: AttributionMetric.ROASLTV,
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LAST_CLICK_ROASLTV]: {
    originalName: "last_ROASLTV",
    name: "Last Click ROASLTV",
    description: "",
    attribtionType: AttributionType.LAST,
    attribtionMetric: AttributionMetric.ROASLTV,
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LAST_NON_DIRECT_CLICK_ROASLTV]: {
    originalName: "last_non_ROASLTV",
    name: "Last Non Direct ROASLTV",
    description: "",
    attribtionType: AttributionType.LAST_NON,
    attribtionMetric: AttributionMetric.ROASLTV,
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LINEAR_ROASLTV]: {
    originalName: "linear_ROASLTV",
    name: "Linear ROASLTV",
    description: "",
    attribtionType: AttributionType.LINEAR,
    attribtionMetric: AttributionMetric.ROASLTV,
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_ROASLTV]: {
    originalName: "effected_ROASLTV",
    name: "Impacted ROASLTV",
    description: "",
    attribtionType: AttributionType.IMPACTED,
    attribtionMetric: AttributionMetric.ROASLTV,
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_ATTRIBUTION_LAST_POSITION_CLICK_ROASLTV]: {
    originalName: "position_ROASLTV",
    name: "Position ROASLTV",
    description: "",
    attribtionType: AttributionType.POSITION,
    attribtionMetric: AttributionMetric.ROASLTV,
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_RANK]: {
    originalName: "rank",
    name: "Rank",
    description: "",
    format: (v) => `${formatNumber(Number(v) * 100)}%`,
  },
  [ApiMetric.METRIC_PRODUCT_GATEWAY_LTV]: {
    originalName: "product_ltv",
    name: "Product Gateway LTV",
    description: "",
    format: (v) => `$${centsToDollars(Number(v))}`,
  },
  [ApiMetric.METRIC_LEADS_COUNT_WITH_LEADS_SCORE]: {
    originalName: "leads_with_score",
    name: "Leads with Lead Score",
    description: "",
  },
  [ApiMetric.METRIC_AVERAGE_LEAD_SCORE]: {
    originalName: "average_lead_score",
    name: "Avg. Lead Score",
    description: "",
  },
  [ApiMetric.METRIC_LANDING_PAGE_SESSION_MACRO_CONVERSION_COUNT]: {
    originalName: "METRIC_LANDING_PAGE_SESSION_MACRO_CONVERSION_COUNT",
    name: "Landing Page Conversions",
    description: "",
  },
  [ApiMetric.METRIC_LANDING_PAGE_SESSION_MACRO_CONVERSION_RATE]: {
    originalName: "METRIC_LANDING_PAGE_SESSION_MACRO_CONVERSION_RATE",
    name: "Landing Page Conversion Rate",
    description: "",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_AVERAGE_LCP_SCORE]: {
    originalName: "METRIC_AVERAGE_LCP_SCORE",
    name: "Largest Contentful Paint (LCP)",
    description:
      "Measures the time it takes for the largest visible element (such as an image or a block of text) to load and become visible within the viewport.",
  },
  [ApiMetric.METRIC_AVERAGE_CLS_SCORE]: {
    originalName: "METRIC_AVERAGE_CLS_SCORE",
    name: "Cumulative Layout Shift (CLS)",
    description:
      "Quantifies the amount of unexpected layout shifts that occur during the entire lifespan of the page, helping to assess visual stability.",
  },
  [ApiMetric.METRIC_AVERAGE_INP_SCORE]: {
    originalName: "METRIC_AVERAGE_INP_SCORE",
    name: "Interaction to Next Paint (INP)",
    description:
      "Evaluates the time from user interaction to the next visual update, reflecting responsiveness.",
  },
  [ApiMetric.METRIC_AVERAGE_FCP_SCORE]: {
    originalName: "METRIC_AVERAGE_FCP_SCORE",
    name: "First Contentful Paint (FCP)",
    description:
      "Indicates the time from when the page starts loading to when any part of the page's content is rendered on the screen.",
  },
  [ApiMetric.METRIC_AVERAGE_CWV_PERFORMANCE_SCORE]: {
    originalName: "METRIC_AVERAGE_CWV_PERFORMANCE_SCORE",
    name: "Core Web Vitals Score",
    description:
      "The Core Web Vitals (CWV) Performance Score is a weighted average metric that evaluates the overall user experience of a webpage based on loading speed, visual stability, responsiveness, and initial content rendering.",
  },
  [ApiMetric.METRIC_BOUNCED_SESSION_COUNT]: {
    originalName: "METRIC_BOUNCED_SESSION_COUNT",
    name: "Bounced Sessions Count",
    description: "Number of sessions that bounced (had only 1 pageview)",
  },
  [ApiMetric.METRIC_BOUNCE_RATE]: {
    originalName: "METRIC_BOUNCE_RATE",
    name: "Bounced Rate",
    description:
      "Number of sessions that bounced (had only 1 pageview) / total no. of sessions per dimension",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_EXIT_PAGEVIEW_COUNT]: {
    originalName: "METRIC_EXIT_PAGEVIEW_COUNT",
    name: "Exit Pageview Count",
    description: " Number of pageviews that were the last in a sessions",
  },
  [ApiMetric.METRIC_EXIT_RATE]: {
    originalName: "METRIC_EXIT_RATE",
    name: "Exit Rate",
    description:
      "Number of pageviews that were the last in a sessions / total no. of pageviews per dimension",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_PUBLISHER_CLICKS]: {
    originalName: "METRIC_PUBLISHER_CLICKS",
    name: "Publisher Clicks",
    description:
      "Number of Clicks the publisher says a campaign has had, only available with attribution dimensions",
    compatibleDimensions: [
      ApiDimension.DIMENSION_CHANNEL,
      ApiDimension.DIMENSION_SOURCE_BUCKET,
      ApiDimension.DIMENSION_SOURCE,
      ApiDimension.DIMENSION_MEDIUM,
      ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
      ApiDimension.DIMENSION_CONTENT,
      ApiDimension.DIMENSION_TERM,
    ],
  },
  [ApiMetric.METRIC_PUBLISHER_CONVERSIONS]: {
    originalName: "METRIC_PUBLISHER_CONVERSIONS",
    name: "Publisher Conversions",
    description:
      "Number of Converisons the publisher says a campaign has had, only available with attribution dimensions",
    compatibleDimensions: [
      ApiDimension.DIMENSION_CHANNEL,
      ApiDimension.DIMENSION_SOURCE_BUCKET,
      ApiDimension.DIMENSION_SOURCE,
      ApiDimension.DIMENSION_MEDIUM,
      ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
      ApiDimension.DIMENSION_CONTENT,
      ApiDimension.DIMENSION_TERM,
    ],
  },
  [ApiMetric.METRIC_PUBLISHER_ALL_CONVERSIONS]: {
    originalName: "METRIC_PUBLISHER_ALL_CONVERSIONS",
    name: "Publisher (All) Conversions",
    description:
      "Number of (All) Converisons the publisher says a campaign has had, only available with attribution dimensions",
    compatibleDimensions: [
      ApiDimension.DIMENSION_CHANNEL,
      ApiDimension.DIMENSION_SOURCE_BUCKET,
      ApiDimension.DIMENSION_SOURCE,
      ApiDimension.DIMENSION_MEDIUM,
      ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
      ApiDimension.DIMENSION_CONTENT,
      ApiDimension.DIMENSION_TERM,
    ],
  },
  [ApiMetric.METRIC_PUBLISHER_IMPRESSIONS]: {
    originalName: "METRIC_PUBLISHER_IMPRESSIONS",
    name: "Publisher Impressions",
    description:
      "Number of impressions reported by publisher for ads for given dimension",
    compatibleDimensions: [
      ApiDimension.DIMENSION_CHANNEL,
      ApiDimension.DIMENSION_SOURCE_BUCKET,
      ApiDimension.DIMENSION_SOURCE,
      ApiDimension.DIMENSION_MEDIUM,
      ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
      ApiDimension.DIMENSION_CONTENT,
      ApiDimension.DIMENSION_TERM,
    ],
  },
  [ApiMetric.METRIC_PUBLISHER_CTR]: {
    originalName: "METRIC_PUBLISHER_CTR",
    name: "Publisher Impressions",
    description:
      "Number of impressions / number of clicks reported by publisher for ads for given dimension",
    format: (v) => `${formatNumber(Number(v))}%`,
    compatibleDimensions: [
      ApiDimension.DIMENSION_CHANNEL,
      ApiDimension.DIMENSION_SOURCE_BUCKET,
      ApiDimension.DIMENSION_SOURCE,
      ApiDimension.DIMENSION_MEDIUM,
      ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
      ApiDimension.DIMENSION_CONTENT,
      ApiDimension.DIMENSION_TERM,
    ],
  },
  [ApiMetric.METRIC_PUBLISHER_CPC]: {
    originalName: "METRIC_PUBLISHER_CPC",
    name: "Publisher Avg CPC",
    description: "CPC recording by publisher",
    format: (v) => `$${centsToDollars(Number(v))}`,
    compatibleDimensions: [
      ApiDimension.DIMENSION_CHANNEL,
      ApiDimension.DIMENSION_SOURCE_BUCKET,
      ApiDimension.DIMENSION_SOURCE,
      ApiDimension.DIMENSION_MEDIUM,
      ApiDimension.DIMENSION_EXTERNAL_CAMPAIGN,
      ApiDimension.DIMENSION_CONTENT,
      ApiDimension.DIMENSION_TERM,
    ],
  },
  [ApiMetric.METRIC_TO_NEW_CUSTOMER_RATE]: {
    originalName: "METRIC_TO_NEW_CUSTOMER_RATE",
    name: "To Customer Rate",
    description:
      "no. of new customers / no. of all non returning customer leads (total leads - returning customers)",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_RETURNING_CUSTOMER_RATE]: {
    originalName: "METRIC_TO_NEW_CUSTOMER_RATE",
    name: "Returning Customer Rate",
    description: "no. or returning customers / total customers",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_RETURNING_CUSTOMER_SESSION_COUNT]: {
    originalName: "METRIC_RETURNING_CUSTOMER_SESSION_COUNT",
    name: "Returning Customer Session Count",
    description:
      "no. of sessions by returning customers (sessions after first sale)",
  },
  [ApiMetric.METRIC_RETURNING_CUSTOMER_PAGE_MACRO_CONVERSION_COUNT]: {
    originalName: "METRIC_RETURNING_CUSTOMER_PAGE_MACRO_CONVERSION_COUNT",
    name: "Returning Customer Page Conversion Count",
    description:
      "no. of pageview macro conversions by returning customers (sessions after first sale)",
  },
  [ApiMetric.METRIC_RETURNING_CUSTOMER_SESSION_MACRO_CONVERSION_COUNT]: {
    originalName: "METRIC_RETURNING_CUSTOMER_SESSION_MACRO_CONVERSION_COUNT",
    name: "Returning Customer Session Conversion Count",
    description:
      "no. of session macro conversions by returning customers (sessions after first sale)",
  },
  [ApiMetric.METRIC_RETURNING_CUSTOMER_SESSION_MACRO_CONVERSION_RATE]: {
    originalName: "METRIC_RETURNING_CUSTOMER_SESSION_MACRO_CONVERSION_RATE",
    name: "Returning Customer Session Conversion Rate",
    description:
      "returning customer session macro conversions / returning customer sessions",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_CHECKOUT_START_COUNT]: {
    originalName: "METRIC_CHECKOUT_START_COUNT",
    name: "Checkout Start Count",
    description:
      "This is the number of pageviews where it's location has the reserved checkout page type. But, only counted once per session",
  },
  [ApiMetric.METRIC_CHECKOUT_START_RATE]: {
    originalName: "METRIC_CHECKOUT_START_RATE",
    name: "Checkout Start Rate",
    description:
      "The rate at which visitors start checkouts. Calculated: (MetricCheckoutStartCount / total sessions) *100",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_CHECKOUT_COMPLETION_RATE]: {
    originalName: "METRIC_CHECKOUT_COMPLETION_RATE",
    name: "Checkout Completion Rate",
    description:
      "The rate at which started checkout processes get completed: (total session sales / MetricCheckoutStartCount )*100",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_PAID_SESSION_COUNT]: {
    originalName: "METRIC_PAID_SESSION_COUNT",
    name: "Paid Sessions",
    description: "sessions from a paid channel",
  },
  [ApiMetric.METRIC_ORGANIC_SESSION_COUNT]: {
    originalName: "METRIC_ORGANIC_SESSION_COUNT",
    name: "Organic Sessions",
    description: "sessions not from a paid channel",
  },
  [ApiMetric.METRIC_PRODUCT_PAGE_EFFECTIVENESS_RATE]: {
    originalName: "METRIC_PRODUCT_PAGE_EFFECTIVENESS_RATE",
    name: "PDP Effectiveness Rate",
    description: "pageview effectivness for product pages only",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_CART_ABANDONMENT_RATE]: {
    originalName: "METRIC_CART_ABANDONMENT_RATE",
    name: "Cart Abandonment Rate",
    description:
      "(number of sessions with an add to cart without sales / Total number of sessions with an add to cart) x100",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
  [ApiMetric.METRIC_CHECKOUT_ABANDONMENT_RATE]: {
    originalName: "METRIC_CHECKOUT_ABANDONMENT_RATE",
    name: "Checkout Abandonment Rate",
    description:
      "(number of checkout starts without sales / total checkout starts) x100",
    format: (v) => `${formatNumber(Number(v))}%`,
  },
};

export const metricConfigByName = (metric: ApiMetric) =>
  metricMap[metric] || metricMap[metricOriginalNameToEnum(metric) as ApiMetric];

export const metricToName = (metric: ApiMetric) =>
  metricConfigByName(metric)?.name || metric;

export const metricOriginalNameToEnum = (originalName: string) => {
  const metricKeys = Object.keys(metricMap) as ApiMetric[];
  const foundKey = metricKeys.find(
    (key) => metricMap[key]?.originalName === originalName
  );
  return foundKey || originalName;
};

export const formatMetric = (metric: ApiMetric, value?: number) => {
  const m = metricMap[((metric as string) || "").split("\\")[0] as ApiMetric];
  return m && m.format ? m.format(value || 0) : formatNumber(value || 0);
};

export const metricIsCompatible = (
  metric: ApiMetric,
  dimensions: ApiDimension[]
): boolean => {
  return metricMap[metric]?.excludedDimensions
    ? !(dimensions || []).some((d) =>
        metricMap[metric]?.excludedDimensions?.includes(d)
      )
    : metricMap[metric]?.compatibleDimensions
    ? (dimensions || []).every((d) =>
        metricMap[metric]?.compatibleDimensions?.includes(d)
      )
    : true;
};

export const metricByAttribution = (
  type: AttributionType,
  metric: AttributionMetric
) => {
  const metricKeys = Object.keys(metricMap) as ApiMetric[];
  return metricKeys.find(
    (key) =>
      metricMap[key]?.attribtionType === type &&
      metricMap[key]?.attribtionMetric === metric
  );
};
export const metricsByAttributionType = (type: AttributionType) => {
  const metricKeys = Object.keys(metricMap) as ApiMetric[];
  return metricKeys.filter((key) => metricMap[key]?.attribtionType === type);
};

export const allAttributionMetrics = (
  Object.keys(metricMap) as ApiMetric[]
).filter((k) => metricMap[k]?.attribtionType);

export const averageMetrics = [
  ApiMetric.METRIC_SESSION_COUNT_PER_LEAD,
  ApiMetric.METRIC_AVERAGE_PAGEVIEWS_PER_SESSION,
  ApiMetric.METRIC_AVERAGE_SESSION_DURATION,
  ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
  ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_COUNT_PER_LEAD,
  ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE,
  ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
  ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE,
  ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE,
  ApiMetric.METRIC_AVERAGE_SCROLL_SPEED,
  ApiMetric.METRIC_RETURNING_LEAD_PERCENTAGE,
  ApiMetric.METRIC_EFFECTIVE_SESSION_TO_CONVERSION_RATE,
  ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
  ApiMetric.METRIC_SESSION_MICRO_CONVERSION_RATE,
  ApiMetric.METRICMACRO_CONVERSION_COUNT_PER_LEAD,
  ApiMetric.METRICMICRO_CONVERSION_COUNT_PER_LEAD,
  ApiMetric.METRICEFFECTIVE_PAGEVIEW_TO_CONVERSION_RATE,
  ApiMetric.METRIC_PAGE_MACRO_CONVERSION_RATE,
  ApiMetric.METRIC_PAGE_MICRO_CONVERSION_RATE,
  ApiMetric.METRIC_LEAD_MACRO_CONVERSION_PERCENTAGE,
  ApiMetric.METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_COUNT_DURING_SESSION,
  ApiMetric.METRIC_AVERAGE_EFFECTIVE_PAGEVIEW_RATE_DURING_SESSION,
  ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE_DURING_SESSION,
  ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE_DURING_SESSION,
  ApiMetric.METRIC_AVERAGE_SCROLL_PERCENTAGE_DURING_SESSION,
  ApiMetric.METRIC_AVERAGE_SCROLL_SPEED_DURING_SESSION,
  ApiMetric.METRIC_VISIT_TO_PURCHASE_RATE,
  ApiMetric.METRIC_RETURNING_NINETY_DAY_CUSTOMER_PERCENTAGE,
  ApiMetric.METRIC_AVERAGE_DAYS_TO_SECOND_VISIT,
  ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_RATE_RANK,
  ApiMetric.METRIC_SESSION_ADD_TO_CART_CLICK_RATE,
  ApiMetric.METRIC_PAGE_ADD_TO_CART_CLICK_RATE,
  ApiMetric.METRIC_SALES_COMPLETION_RATE,
  ApiMetric.METRIC_PRODUCT_IMPRESSION_RATE,
  ApiMetric.METRIC_PRODUCT_CLICK_THROUGH_RATE,
  ApiMetric.METRIC_LANDING_PAGE_SESSION_MACRO_CONVERSION_RATE,
  ApiMetric.METRIC_CPA,
  ApiMetric.METRIC_CAC,
  ApiMetric.METRIC_ECR,
  ApiMetric.METRIC_CPNV,
  ApiMetric.METRIC_SESSION_EFFICIENCY_RATION,
  ApiMetric.METRIC_LTVCAC_RATIO,
  ApiMetric.METRIC_EXISTING_CUSTOMER_REVENUE_GROWTH_RATE,
  ApiMetric.METRIC_RANK,
  ApiMetric.METRIC_JOB_IMPRESSION_RATE,
  ApiMetric.METRIC_VISIT_TO_APPLICATION_RATE,
  ApiMetric.METRIC_PAGE_JOB_APPLY_CLICK_RATE,
  ApiMetric.METRIC_PAGE_JOB_APPLICATIONS_RATE,
  ApiMetric.METRIC_JOB_CLICK_THROUGH_RATE,
  ApiMetric.METRIC_AVERAGE_LEAD_SCORE,
  ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_RATE,
  ApiMetric.METRIC_AVERAGE_LCP_SCORE,
  ApiMetric.METRIC_AVERAGE_INP_SCORE,
  ApiMetric.METRIC_AVERAGE_CLS_SCORE,
  ApiMetric.METRIC_AVERAGE_FCP_SCORE,
  ApiMetric.METRIC_AVERAGE_CWV_PERFORMANCE_SCORE,
  ApiMetric.METRIC_BOUNCE_RATE,
  ApiMetric.METRIC_EXIT_RATE,
];
