import {
  APITokenServiceApi,
  BucketServiceApi,
  CSVServiceApi,
  ProductServiceApi,
  SyncServiceApi,
  ChartServiceApi,
  EffectivenessRuleServiceApi,
  LeadScoreAdjustmentRuleServiceApi,
  ReportServiceApi,
  ScheduledReportServiceApi,
  SessionEffectivenessRuleServiceApi,
  AuthApi,
  ClientServiceApi,
  Configuration,
  ConfigurationParameters,
  LocationServiceApi,
  ProjectsServiceApi,
  UserServiceApi,
  ChannelServiceApi,
  AudienceServiceApi,
  EngagementServiceApi,
  CustomisationApi,
  MediaServiceApi,
  LeadServiceApi,
  AlertServiceApi,
  LeadStatusServiceApi,
  PageviewMetaServiceApi,
  AttributionTagsServiceApi,
  JobServiceApi,
  AnalyticsServiceApi,
  PersonalisationServiceApi,
  LandingDomainServiceApi,
  LandersServiceApi,
  UTMServiceApi,
} from "@incendium/api";
import { checkLogin, logout } from "Actions";
import { BaseURL } from "config";
import { getAuthToken } from "Helpers";
import store from "store";

const createConfig = ({
  basePath = BaseURL,
  ...rest
}: ConfigurationParameters = {}) => {
  return new Configuration({
    ...rest,
    basePath,
    middleware: [
      {
        post: async (res) => {
          const dispatch = store.dispatch;

          if (res.response.status === 401) {
            if (res.url.includes("api/refresh-token")) {
              dispatch(logout());
            }

            dispatch(checkLogin());
          }
        },
      },
    ],
    //@ts-ignore
    fetchApi: (req, init) => {
      const tokens = getAuthToken();

      const headers: any = init?.headers ? init?.headers : {};
      if (tokens) {
        headers["Authorization"] = "bearer " + tokens.token;
      }

      return fetch(req, { ...init, headers });
    },
  });
};

const config = createConfig();

export const userService = new UserServiceApi(config);
export const clientService = new ClientServiceApi(config);
export const projectService = new ProjectsServiceApi(config);
export const authService = new AuthApi(config);
export const locationService = new LocationServiceApi(config);
export const channelService = new ChannelServiceApi(config);
export const trafficBucketService = new BucketServiceApi(config);
export const audienceService = new AudienceServiceApi(config);
export const engagementService = new EngagementServiceApi(config);
export const effectivenessRuleService = new EffectivenessRuleServiceApi(config);
export const sessionEffectivenessRuleService =
  new SessionEffectivenessRuleServiceApi(config);
export const mediaService = new MediaServiceApi(config);
export const leadService = new LeadServiceApi(config);
export const alertService = new AlertServiceApi(config);
export const leadStatusService = new LeadStatusServiceApi(config);
export const chartService = new ChartServiceApi(config);
export const reportService = new ReportServiceApi(config);
export const scheduledReportService = new ScheduledReportServiceApi(config);
export const leadScoreAdjustmentService = new LeadScoreAdjustmentRuleServiceApi(
  config
);
export const APITokenService = new APITokenServiceApi(config);
export const CSVService = new CSVServiceApi(config);
export const productService = new ProductServiceApi(config);
export const syncService = new SyncServiceApi(config);
export const pageviewMetaService = new PageviewMetaServiceApi(config);
export const attributionTagService = new AttributionTagsServiceApi(config);
export const jobService = new JobServiceApi(config);
export const analyticsService = new AnalyticsServiceApi(config);
export const personalisationService = new PersonalisationServiceApi(config);
export const landingDomainService = new LandingDomainServiceApi(config);
export const landersService = new LandersServiceApi(config);
export const utmService = new UTMServiceApi(config);

// thi is old service wihich wil change soon
export const customisationService = new CustomisationApi(config);
