import { Box, Button, Divider, Portal, Stack } from "@mui/material";
import { useCallback, useRef } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { SidebarContainer } from "../UI/Sidebar";

interface ISidebarFormProps extends React.HTMLAttributes<HTMLDivElement> {
  rules: React.ReactNode;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
}

function ConditionGroupsFormWrapper({
  children,
  rules,
  onSubmit,
  onCancel,
}: ISidebarFormProps) {
  const formRef = useRef<HTMLFormElement | any>();

  const save = useCallback(() => {
    formRef.current.submit();
  }, [formRef]);

  const saveAndExit = useCallback(() => {
    save();
    onCancel();
  }, [save, onCancel]);

  return (
    <ValidatorForm onSubmit={onSubmit} ref={formRef}>
      <Box style={{ width: 865, margin: "auto" }}>{rules}</Box>
      <Portal container={() => document.getElementById("rightSideBar")}>
        <SidebarContainer>
          <>{children}</>
          <Divider />
          <Stack p={2} spacing={2}>
            <Stack
              spacing={2}
              direction={"row"}
              sx={{ background: "rgba(255,255,255,0.4)" }}
            >
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={onCancel}
              >
                cancel
              </Button>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={save}
              >
                save
              </Button>
            </Stack>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={saveAndExit}
            >
              save & exit
            </Button>
          </Stack>
        </SidebarContainer>
      </Portal>
    </ValidatorForm>
  );
}

export default ConditionGroupsFormWrapper;
