import { ApiService, ApiSyncStatus, ApiSyncType } from "@incendium/api";
import { Check, Delete, Settings, WarningAmber } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { syncService } from "Apis";
import GlassCard from "Components/GlassCard/GlassCard";
import StyledDrawer from "Components/UI/StyledDrawer";
import ConnectButton from "features/dataConnectors/components/ConnectButton";
import { ISyncConfigComponentProps } from "features/dataConnectors/types";
import { motion } from "framer-motion";
import { useNotification } from "Hooks";
import { useExternalSyncs } from "Hooks/useExternalSyncs";
import { useSyncClients } from "Hooks/useSyncClients";
import { useUser } from "Hooks/useUser";
import produce from "immer";
import Image from "mui-image";
import { useConfirmationContext } from "Providers/ConfirmationProvider";
import { ComponentType, useCallback, useMemo, useState } from "react";

interface ISyncCardProps {
  logo: string;
  title: string;
  description: string;
  service: ApiService;
  type: ApiSyncType;
  configComponent: ComponentType<ISyncConfigComponentProps>;
  imageSx?: SxProps<Theme>;
}

function SyncCard({
  logo,
  title,
  description,
  service,
  type,
  configComponent: ConfigComponent,
  imageSx,
}: ISyncCardProps) {
  const { syncClients, setSyncClients } = useSyncClients();
  const { externalSyncs, setExternalSyncs } = useExternalSyncs();
  const { user } = useUser();

  const { showSuccessNotification, showErrorNotification } = useNotification();
  const [openConfig, setOpenConfig] = useState(false);
  const { openConfirmation, closeConfirmation } = useConfirmationContext();

  const connection = useMemo(() => {
    if (!syncClients) {
      return undefined;
    }

    return syncClients[service]?.find((f) => f.user?.id === user.id);
  }, [syncClients, service, user]);

  const isReady = useMemo(() => {
    return connection?.readyToTypes?.includes(type);
  }, [connection, type]);

  const syncs = useMemo(() => {
    return externalSyncs.filter((ex) => ex.type === type);
  }, [externalSyncs, type]);

  const handleOnConfigure = useCallback(async () => {
    setOpenConfig(true);
  }, []);

  const onDeleteConnection = useCallback(
    (id: number) => {
      openConfirmation({
        title: `Are you sure you want to delete this connection?`,
        body: `This action will remove this connection and any data related to it`,
        callback: async () => {
          try {
            await syncService.syncServiceDeleteClient({
              oauthClientId: id,
            });
            setSyncClients(
              produce(syncClients, (draft) => {
                const idx = draft[service]?.findIndex((f) => f.id === id);
                if (idx >= 0) {
                  draft[service].splice(idx, 1);
                  if (draft[service].length === 0) {
                    delete draft[service];
                  }
                }
              })
            );

            setExternalSyncs(
              produce(externalSyncs, (draft) => {
                const ids = syncs.map((s) => s.id);
                return draft.filter((d) => !ids.includes(d.id));
              })
            );

            showSuccessNotification("Connection removed.");
          } catch (error) {
            showErrorNotification(
              "Failed to remove connection, please try again."
            );
          }

          closeConfirmation();
        },
      });
    },
    [
      syncs,
      externalSyncs,
      setExternalSyncs,
      openConfirmation,
      closeConfirmation,
      showSuccessNotification,
      showErrorNotification,
      syncClients,
      setSyncClients,
      service,
    ]
  );

  return (
    <GlassCard boxProps={{ p: 2 }} height={252}>
      <Stack spacing={2}>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
          spacing={4}
          mb={2}
        >
          <motion.div
            initial={{
              opacity: 0,
              x: -50,
            }}
            animate={{
              opacity: 1,
              x: 0,
            }}
            transition={{ delay: 0.6 }}
          >
            <Image
              height={40}
              src={logo}
              duration={50}
              fit={"contain"}
              objectPosition={"left"}
              sx={{ objectPosition: "left", ...imageSx }}
            />
          </motion.div>

          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
            spacing={3}
            component={motion.div}
            initial={{
              opacity: 0,
              scale: 0.9,
            }}
            animate={{
              opacity: 1,
              scale: 1,
            }}
          >
            <Button
              size="small"
              variant="outlined"
              startIcon={<Settings />}
              onClick={handleOnConfigure}
            >
              configure
            </Button>
          </Stack>
        </Stack>
        <Divider />
        <Box sx={{ height: 76 }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="subtitle1">{title}</Typography>

            {connection && (
              <>
                {isReady ? (
                  <Check color={"success"} fontSize={"small"} />
                ) : (
                  <WarningAmber color={"warning"} fontSize={"small"} />
                )}
              </>
            )}
          </Stack>
          <Typography variant="body2" color={"text.secondary"}>
            {description}
          </Typography>
        </Box>
        <Divider />
        <Stack direction={"row"} alignItems="center" spacing={2}>
          {(connection || syncs.length > 0) && (
            <>
              {connection && (
                <>
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<Delete />}
                    onClick={() => onDeleteConnection(connection.id as number)}
                  >
                    Remove
                  </Button>
                  {syncs.length > 0 &&
                  syncs[0].status === ApiSyncStatus.ERROR ? (
                    <Typography
                      variant="body2"
                      color={"error"}
                      fontWeight={600}
                    >
                      The Connection is invalid, please reconnect from within
                      confirgure
                    </Typography>
                  ) : isReady ? (
                    <Typography variant="body2">
                      Remove {title} from {user.name}
                    </Typography>
                  ) : (
                    <Typography variant="body2">
                      Incorrect permissions granted, please remove and reconnect
                    </Typography>
                  )}
                </>
              )}
            </>
          )}
          {!connection && (syncs || []).length === 0 && (
            <>
              <ConnectButton
                service={service}
                syncClients={syncClients}
                setSyncClients={setSyncClients}
              />
              <Typography variant="body2">
                Connect {title} to {user.name}
              </Typography>
            </>
          )}
        </Stack>
      </Stack>
      <StyledDrawer open={openConfig} onClose={() => setOpenConfig(false)}>
        <ConfigComponent
          oauthClient={connection}
          syncs={syncs}
          open={openConfig}
          setOpen={setOpenConfig}
        />
      </StyledDrawer>
    </GlassCard>
  );
}

export default SyncCard;
