import { TChartData } from "Interfaces";

export const groupBy = <T, K extends keyof T>(
  array: T[],
  groupOn: K | ((i: T) => string)
): Record<string, T[]> => {
  const groupFn =
    typeof groupOn === "function" ? groupOn : (o: T) => o[groupOn];

  return Object.fromEntries(
    array.reduce((acc, obj) => {
      const groupKey = groupFn(obj);
      return acc.set(groupKey, [...(acc.get(groupKey) || []), obj]);
    }, new Map())
  ) as Record<string, T[]>;
};

export const chartDataStackingOrder = (data: TChartData[]) => {
  const o: { [key: string]: number } = {};
  data.forEach((d, i) => {
    const { name, ...rest } = d;
    Object.keys(rest).forEach((k: string) => {
      if (!o.hasOwnProperty(k)) {
        o[k] = 0;
      }
      o[k] = o[k] + (data[i][k] as number);
    });
  });

  return Object.keys(o).sort((a, b) => {
    return o[a] < o[b] ? 1 : o[a] > o[b] ? -1 : 0;
  });
};

export const chunk = (arr: any[], chunkSize: number) => {
  if (chunkSize <= 0) throw "Invalid chunk size";
  var R: any[] = [];
  for (var i = 0, len = arr.length; i < len; i += chunkSize)
    R.push(arr.slice(i, i + chunkSize));
  return R;
};

export const getMostFrequentInArray = <T>(arr: T[]) => {
  let [maxFreq, result] = [-1, ""];

  arr
    .reduce((acu, cur) => {
      acu.set(cur, (acu.has(cur) ? acu.get(cur) : 0) + 1);
      return acu;
    }, new Map())
    .forEach((value, key) => {
      if (value > maxFreq) {
        maxFreq = value;
        result = key;
      }
    });

  return result;
};

export function findLastIndex<T>(
  array: Array<T>,
  predicate: (value: T, index: number, obj: T[]) => boolean
): number {
  let l = array.length;
  while (l--) {
    if (predicate(array[l], l, array)) return l;
  }
  return -1;
}

export function moveItem<T>(
  array: T[],
  oldIndex: number,
  newIndex: number
): T[] {
  const length = array.length;

  if (newIndex >= length) {
    const k = newIndex - length + 1;
    array = array.concat(new Array(k));
  }

  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
  return array;
}

export function mergeArraysByKey<T>(arr1: T[], arr2: T[], key: keyof T): T[] {
  return arr1.map((obj1) => {
    const obj2 = arr2.find((obj2) => obj2[key] === obj1[key]);
    return { ...obj1, ...obj2 };
  });
}

export const cumulativeSumArray = (inputArray, field) =>
  inputArray.map((obj, index, array) => {
    const cumulativeSum = array
      .slice(0, index + 1)
      .reduce((acc, currentObj) => {
        return acc + currentObj[field];
      }, 0);

    return {
      ...obj,
      [field]: cumulativeSum,
    };
  });

export const uniqueArray = <T>(arr: T[]) => {
  return Array.from(new Set(arr));
};

export const sortAlpha = <T>(arr: T[], field: keyof T) => {
  return [...arr].sort((a, b) =>
    `${a[field] || ""}`.localeCompare(`${b[field] || ""}`)
  );
};
export const sortByDate = <T>(arr: T[], field: keyof T, asc?: boolean) => {
  return [...arr].sort((a, b) => {
    const dateA = a[field];
    const dateB = b[field];

    if (dateA instanceof Date && dateB instanceof Date) {
      return asc
        ? dateA.getTime() - dateB.getTime()
        : dateB.getTime() - dateA.getTime();
    }

    return 0;
  });
};
