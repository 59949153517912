import { ApiAccount, ApiSync } from "@incendium/api";
import { CloudSync } from "@mui/icons-material";
import { Autocomplete, Button, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { capitalize } from "lodash";
import { useCallback, useMemo, useState } from "react";

interface ISyncAccountSelectorProps {
  connectionAccounts: ApiAccount[];
  syncs: ApiSync[];
  provider: string;
  onLinkAccount: (a: ApiAccount) => void;
}

function SyncAccountSelector({
  connectionAccounts,
  syncs,
  provider,
  onLinkAccount,
}: ISyncAccountSelectorProps) {
  const [selectedAccount, setSelectedAccount] = useState<ApiAccount | null>(
    null
  );

  const options = useMemo(() => {
    const syncIds = syncs.map((s) => s.account);
    return connectionAccounts.filter((c) => !syncIds.includes(c.externalId));
  }, [syncs, connectionAccounts]);

  const linkAccount = useCallback(() => {
    onLinkAccount(selectedAccount!);
    setSelectedAccount(null);
  }, [selectedAccount, onLinkAccount]);

  return (
    <Box>
      <Autocomplete
        size="small"
        onChange={(e, value) => {
          setSelectedAccount(value);
        }}
        value={selectedAccount}
        options={options}
        getOptionLabel={(o) => `${o.name} - ${o.externalId}`}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={`${capitalize(provider)} Ads account`}
          />
        )}
      />
      <Stack mt={2} direction="row" spacing={2} justifyContent="flex-end">
        <Button
          startIcon={<CloudSync />}
          onClick={linkAccount}
          disabled={!selectedAccount}
        >
          Sync Account
        </Button>
      </Stack>
    </Box>
  );
}

export default SyncAccountSelector;
