import { ApiService, ApiSyncType } from "@incendium/api";
import { CircularProgress, Grid, Typography } from "@mui/material";
import Google_Ads_logo from "Assets/Images/ThirdParty/Google_Ads_logo.png";
import Bing_Ads_logo from "Assets/Images/ThirdParty/Bing_Ads_2016_logo.svg";
import Meta_Ads_logo from "Assets/Images/ThirdParty/Meta_lockup_positive primary_RGB.svg";
import { CenterPage } from "Components/CenterPage/CenterPage";
import { useSyncClients } from "Hooks/useSyncClients";
import { motion } from "framer-motion";
import { BingAds, GoogleAds, SyncCard, MetaAds } from "features/dataConnectors";

const listVariants = {
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.15,
      when: "beforeChildren",
    },
  },
};

const itemVariants = {
  hidden: { scale: 0.7, opacity: 0 },
  visible: { scale: 1, opacity: 1 },
};

function DataConnectionsPage() {
  const { loading } = useSyncClients();

  if (loading) {
    return (
      <CenterPage>
        <CircularProgress />
        <Typography variant="h3" mt={2}>
          Fetching connection data
        </Typography>
      </CenterPage>
    );
  }

  return (
    <>
      <Grid
        container
        spacing={3}
        component={motion.div}
        variants={listVariants}
        initial="hidden"
        animate="visible"
      >
        <Grid item xs={4} component={motion.div} variants={itemVariants}>
          <SyncCard
            logo={Google_Ads_logo}
            type={ApiSyncType.GOOGLE_ADS}
            title="Google Ads"
            description="Link with Google Ads to synchronize user visits from campaigns, retrieve cost data for ROAS calculations, and unlock additional insights."
            service={ApiService.GOOGLE}
            configComponent={GoogleAds}
          />
        </Grid>
        <Grid item xs={4} component={motion.div} variants={itemVariants}>
          <SyncCard
            logo={Bing_Ads_logo}
            type={ApiSyncType.BING_ADS}
            title="Bing Ads"
            description="Connect with Bing Ads to synchronize user visits from campaigns, access cost data for precise ROAS calculations, and gain valuable insights to optimize performance."
            service={ApiService.BING}
            configComponent={BingAds}
            imageSx={{ maxHeight: 30 }}
          />
        </Grid>
        <Grid item xs={4} component={motion.div} variants={itemVariants}>
          <SyncCard
            logo={Meta_Ads_logo}
            type={ApiSyncType.FACEBOOK_ADS}
            title="Meta / Facebook Ads"
            description="Connect with Meta Ads to synchronize user visits from campaigns across Facebook, Instagram, and Messenger, access cost data for precise ROAS calculations, and gain valuable insights to optimize performance."
            service={ApiService.FACEBOOK}
            configComponent={MetaAds}
            imageSx={{ transform: `translateX(-12px)` }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default DataConnectionsPage;
