/* tslint:disable */
/* eslint-disable */
/**
 * Incendium AI API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ApiAPITokenPayloadToJSON, ApiAPITokenResponseFromJSON, ApiListAPITokenResponseFromJSON, } from '../models';
/**
 *
 */
export class APITokenServiceApi extends runtime.BaseAPI {
    /**
     */
    aPITokenServiceCreateAPITokenRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling aPITokenServiceCreateAPIToken.');
            }
            if (requestParameters.payload === null || requestParameters.payload === undefined) {
                throw new runtime.RequiredError('payload', 'Required parameter requestParameters.payload was null or undefined when calling aPITokenServiceCreateAPIToken.');
            }
            const queryParameters = {};
            if (requestParameters.tokenId !== undefined) {
                queryParameters['tokenId'] = requestParameters.tokenId;
            }
            if (requestParameters.projectId !== undefined) {
                queryParameters['projectId'] = requestParameters.projectId;
            }
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/services/auth/v1/{clientId}/token`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ApiAPITokenPayloadToJSON(requestParameters.payload),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiAPITokenResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    aPITokenServiceCreateAPIToken(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.aPITokenServiceCreateAPITokenRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    aPITokenServiceDeleteAPITokenRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling aPITokenServiceDeleteAPIToken.');
            }
            if (requestParameters.tokenId === null || requestParameters.tokenId === undefined) {
                throw new runtime.RequiredError('tokenId', 'Required parameter requestParameters.tokenId was null or undefined when calling aPITokenServiceDeleteAPIToken.');
            }
            const queryParameters = {};
            if (requestParameters.projectId !== undefined) {
                queryParameters['projectId'] = requestParameters.projectId;
            }
            if (requestParameters.payloadType !== undefined) {
                queryParameters['payload.type'] = requestParameters.payloadType;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/auth/v1/{clientId}/token/{tokenId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"tokenId"}}`, encodeURIComponent(String(requestParameters.tokenId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    aPITokenServiceDeleteAPIToken(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.aPITokenServiceDeleteAPITokenRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    aPITokenServiceGetAPITokenRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling aPITokenServiceGetAPIToken.');
            }
            if (requestParameters.tokenId === null || requestParameters.tokenId === undefined) {
                throw new runtime.RequiredError('tokenId', 'Required parameter requestParameters.tokenId was null or undefined when calling aPITokenServiceGetAPIToken.');
            }
            const queryParameters = {};
            if (requestParameters.projectId !== undefined) {
                queryParameters['projectId'] = requestParameters.projectId;
            }
            if (requestParameters.payloadType !== undefined) {
                queryParameters['payload.type'] = requestParameters.payloadType;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/auth/v1/{clientId}/token/{tokenId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"tokenId"}}`, encodeURIComponent(String(requestParameters.tokenId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiAPITokenResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    aPITokenServiceGetAPIToken(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.aPITokenServiceGetAPITokenRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    aPITokenServiceListAPITokensRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling aPITokenServiceListAPITokens.');
            }
            const queryParameters = {};
            if (requestParameters.tokenId !== undefined) {
                queryParameters['tokenId'] = requestParameters.tokenId;
            }
            if (requestParameters.projectId !== undefined) {
                queryParameters['projectId'] = requestParameters.projectId;
            }
            if (requestParameters.payloadType !== undefined) {
                queryParameters['payload.type'] = requestParameters.payloadType;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/auth/v1/{clientId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListAPITokenResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    aPITokenServiceListAPITokens(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.aPITokenServiceListAPITokensRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    aPITokenServiceListProjectAPITokensRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
                throw new runtime.RequiredError('clientId', 'Required parameter requestParameters.clientId was null or undefined when calling aPITokenServiceListProjectAPITokens.');
            }
            if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
                throw new runtime.RequiredError('projectId', 'Required parameter requestParameters.projectId was null or undefined when calling aPITokenServiceListProjectAPITokens.');
            }
            const queryParameters = {};
            if (requestParameters.tokenId !== undefined) {
                queryParameters['tokenId'] = requestParameters.tokenId;
            }
            if (requestParameters.payloadType !== undefined) {
                queryParameters['payload.type'] = requestParameters.payloadType;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/services/auth/v1/{clientId}/project/{projectId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ApiListAPITokenResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    aPITokenServiceListProjectAPITokens(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.aPITokenServiceListProjectAPITokensRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
/**
    * @export
    * @enum {string}
    */
export var APITokenServiceDeleteAPITokenPayloadTypeEnum;
(function (APITokenServiceDeleteAPITokenPayloadTypeEnum) {
    APITokenServiceDeleteAPITokenPayloadTypeEnum["API_TOKEN_TYPE_NONE"] = "APITokenTypeNone";
    APITokenServiceDeleteAPITokenPayloadTypeEnum["API_TOKEN_TYPE_WOOCOMMERCE"] = "APITokenTypeWoocommerce";
    APITokenServiceDeleteAPITokenPayloadTypeEnum["API_TOKEN_TYPE_SHOPIFY"] = "APITokenTypeShopify";
    APITokenServiceDeleteAPITokenPayloadTypeEnum["API_TOKEN_TYPE_STRIPE"] = "APITokenTypeStripe";
})(APITokenServiceDeleteAPITokenPayloadTypeEnum || (APITokenServiceDeleteAPITokenPayloadTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var APITokenServiceGetAPITokenPayloadTypeEnum;
(function (APITokenServiceGetAPITokenPayloadTypeEnum) {
    APITokenServiceGetAPITokenPayloadTypeEnum["API_TOKEN_TYPE_NONE"] = "APITokenTypeNone";
    APITokenServiceGetAPITokenPayloadTypeEnum["API_TOKEN_TYPE_WOOCOMMERCE"] = "APITokenTypeWoocommerce";
    APITokenServiceGetAPITokenPayloadTypeEnum["API_TOKEN_TYPE_SHOPIFY"] = "APITokenTypeShopify";
    APITokenServiceGetAPITokenPayloadTypeEnum["API_TOKEN_TYPE_STRIPE"] = "APITokenTypeStripe";
})(APITokenServiceGetAPITokenPayloadTypeEnum || (APITokenServiceGetAPITokenPayloadTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var APITokenServiceListAPITokensPayloadTypeEnum;
(function (APITokenServiceListAPITokensPayloadTypeEnum) {
    APITokenServiceListAPITokensPayloadTypeEnum["API_TOKEN_TYPE_NONE"] = "APITokenTypeNone";
    APITokenServiceListAPITokensPayloadTypeEnum["API_TOKEN_TYPE_WOOCOMMERCE"] = "APITokenTypeWoocommerce";
    APITokenServiceListAPITokensPayloadTypeEnum["API_TOKEN_TYPE_SHOPIFY"] = "APITokenTypeShopify";
    APITokenServiceListAPITokensPayloadTypeEnum["API_TOKEN_TYPE_STRIPE"] = "APITokenTypeStripe";
})(APITokenServiceListAPITokensPayloadTypeEnum || (APITokenServiceListAPITokensPayloadTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var APITokenServiceListProjectAPITokensPayloadTypeEnum;
(function (APITokenServiceListProjectAPITokensPayloadTypeEnum) {
    APITokenServiceListProjectAPITokensPayloadTypeEnum["API_TOKEN_TYPE_NONE"] = "APITokenTypeNone";
    APITokenServiceListProjectAPITokensPayloadTypeEnum["API_TOKEN_TYPE_WOOCOMMERCE"] = "APITokenTypeWoocommerce";
    APITokenServiceListProjectAPITokensPayloadTypeEnum["API_TOKEN_TYPE_SHOPIFY"] = "APITokenTypeShopify";
    APITokenServiceListProjectAPITokensPayloadTypeEnum["API_TOKEN_TYPE_STRIPE"] = "APITokenTypeStripe";
})(APITokenServiceListProjectAPITokensPayloadTypeEnum || (APITokenServiceListProjectAPITokensPayloadTypeEnum = {}));
