import {
  ApiCampaignType,
  ApiChartTemplate,
  ApiChartType,
  ApiDimension,
  ApiLocation,
  ApiMetric,
  ApiSimpleCampaign,
  ApiSimplePersonalisation,
} from "@incendium/api";
import {
  appendQueryParams,
  noTrackParam,
  testPersonalisationParam,
} from "@incendium/inc-ts-helpers";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Portal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { CenterPage } from "Components/CenterPage/CenterPage";
import Loading from "Components/Loading/Loading";
import { AnalyticsSpacing, PageActionPreSlot } from "consts";
import { AnalyticsCard, desktop, mobile, table4Rows } from "features/analytics";
import { AnimatePresence, motion } from "framer-motion";
import withDatepicker from "HoC/withDatepicker";
import { useLocations, useSelectedProject } from "Hooks";
import { useCampaigns } from "Hooks/useCampaigns";
import IframeResizer from "iframe-resizer-react";
import moment from "moment";
import { useFromToContext } from "Providers/FromToProvider";
import { Suspense, useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

interface IRezonateReportPageProps {
  personalisation: ApiSimplePersonalisation;
  locations: ApiLocation[];
  from: moment.Moment;
  to: moment.Moment;
}

function RezonateReportPageSelector({
  campaigns,
  value,
  width,
  size,
}: {
  campaigns: ApiSimpleCampaign[];
  value?: ApiSimplePersonalisation | null;
  width: number;
  size?: "small" | "medium";
}) {
  const { selectedClient, selectedProject } = useSelectedProject();
  const history = useHistory();
  const onSelect = useCallback(
    (id) => {
      history.push(
        `/clients/${selectedClient?.id}/projects/${selectedProject?.id}/analyse/optimization/rezonate-report/${id}`
      );
    },
    [history, selectedClient, selectedProject]
  );

  const personalisations = useMemo(() => {
    const arr: ApiSimplePersonalisation[] = [];
    campaigns.forEach((c) => {
      (c.personalisations || []).forEach((p) => {
        arr.push({
          ...p,
          campaign: c,
        });
      });
    });
    return arr;
  }, [campaigns]);
  return (
    <Autocomplete
      sx={{ width }}
      groupBy={(option) => option.campaign!.name!}
      disableClearable
      onChange={(e, v) => {
        onSelect(v.id);
      }}
      value={value || {}}
      options={personalisations}
      getOptionLabel={(option) => option.name || ""}
      renderInput={(params) => (
        <TextField
          {...params}
          id="outlined-basic"
          label="Select a Personalisation"
          variant="outlined"
          size={size}
        />
      )}
    />
  );
}

function RezonateReportPageInner({
  personalisation,
  locations,
  to,
  from,
}: IRezonateReportPageProps) {
  const [tmpUrl, setTmpUrl] = useState(
    locations.find((l) => l.id === personalisation.locationId)?.urlMatch || ""
  );
  const [url, setUrl] = useState(tmpUrl);

  const frameUrl = useMemo(() => {
    if (!url || url === "") {
      return null;
    }
    return appendQueryParams(
      url,
      [testPersonalisationParam, `${personalisation.id}`],
      [noTrackParam, ``]
    );
  }, [url, personalisation]);

  const trendDimension = useMemo(() => {
    const diff = to.diff(from, "days");
    return diff > 90
      ? ApiDimension.DIMENSION_SESSION_START_BY_MONTH
      : ApiDimension.DIMENSION_SESSION_START_BY_DAY;
  }, [from, to]);

  const filter = useMemo(() => {
    return [
      {
        key: ApiDimension.DIMENSION_CAMPAIGN,
        value: personalisation.campaign?.name || "",
      },
      {
        key: ApiDimension.DIMENSION_PERSONALISATION,
        value: personalisation?.name || "",
      },
    ];
  }, [personalisation]);

  const useURL = useCallback(() => {
    setUrl(tmpUrl);
  }, [tmpUrl]);

  return (
    <Box
      mb={2}
      component={motion.div}
      initial={{ scale: 0.7, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
    >
      <Grid container spacing={AnalyticsSpacing}>
        <Grid item xs={7} sx={{ overflow: "hidden" }}>
          <Stack spacing={1} alignItems="flex-end" sx={{ height: "100%" }}>
            <Stack direction={"row"} spacing={1}>
              <TextField
                sx={{ width: 400 }}
                label="URL"
                size="small"
                value={tmpUrl}
                onChange={(e) => setTmpUrl(e.target.value)}
              />
              <Button size="small" onClick={useURL}>
                Set URL
              </Button>
            </Stack>

            <Box sx={{ flex: 1, width: "100%" }}>
              {frameUrl && (
                <IframeResizer
                  src={frameUrl || ""}
                  scrolling
                  autoResize
                  style={{
                    width: 1,
                    minWidth: "200%",
                    minHeight: "200%",
                    transform: "scale(0.5)",
                    transformOrigin: `top left`,
                    border: 0,
                  }}
                />
              )}
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={5}>
          <Grid container spacing={AnalyticsSpacing}>
            <Grid item xs={12} sx={{ minHeight: table4Rows }}>
              <AnalyticsCard
                chart={{
                  name: "Rezonate - Primary Metrics",
                  dimension: [trendDimension],
                  template: ApiChartTemplate.TREND,
                  type: ApiChartType.TABLE,
                  attributes: filter,
                  includeSubdomains: true,
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [
                        ApiMetric.METRIC_SESSIONS_COUNT,
                        ApiMetric.METRIC_PAGE_VIEWS_COUNT,
                        ApiMetric.METRIC_EFFECTIVE_PAGEVIEW_COUNT,
                        ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE,
                        ApiMetric.METRIC_AVERAGE_VIEWED_PERCENTAGE,
                        ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT,
                        ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE,
                        ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT,
                        ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_RATE,
                      ],
                    },
                  ],
                  displayOptions: {
                    noAttributionToggle: true,
                    rowsPerPage: 4,
                    noFilters: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ minHeight: 370 }}>
              <AnalyticsCard
                chart={{
                  name: "Rezonate Traffic & Conversion Trend",
                  dimension: [trendDimension],
                  template: ApiChartTemplate.TREND,
                  type: ApiChartType.GRAPH,
                  attributes: filter,
                  includeSubdomains: true,
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                    },
                    {
                      key: "r",
                      fields: [ApiMetric.METRIC_SESSION_MACRO_CONVERSION_RATE],
                    },
                  ],
                  displayOptions: {
                    noFilters: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} sx={{ minHeight: 270 }}>
              <AnalyticsCard
                chart={{
                  name: "Mobile Traffic Composition %",
                  dimension: [ApiDimension.DIMENSION_CHANNEL],
                  template: ApiChartTemplate.SNAPSHOT,
                  type: ApiChartType.PIE,
                  attributes: [
                    ...filter,
                    {
                      key: ApiDimension.DIMENSION_DEVICE,
                      value: mobile,
                    },
                  ],
                  includeSubdomains: true,
                  limit: 5,
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                    },
                  ],
                  displayOptions: {
                    noAttributionToggle: true,
                    noFilters: true,
                    showTotals: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} sx={{ minHeight: 270 }}>
              <AnalyticsCard
                chart={{
                  name: "Mobile Conversion Composition %",
                  dimension: [ApiDimension.DIMENSION_CHANNEL],
                  template: ApiChartTemplate.SNAPSHOT,
                  type: ApiChartType.PIE,
                  attributes: [
                    ...filter,
                    {
                      key: ApiDimension.DIMENSION_DEVICE,
                      value: mobile,
                    },
                  ],
                  includeSubdomains: true,
                  limit: 5,
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [
                        ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT,
                      ],
                    },
                  ],
                  displayOptions: {
                    noAttributionToggle: true,
                    noFilters: true,
                    showTotals: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} sx={{ minHeight: 270 }}>
              <AnalyticsCard
                chart={{
                  name: "Desktop Traffic Composition %",
                  dimension: [ApiDimension.DIMENSION_CHANNEL],
                  template: ApiChartTemplate.SNAPSHOT,
                  type: ApiChartType.PIE,
                  attributes: [
                    ...filter,
                    {
                      key: ApiDimension.DIMENSION_DEVICE,
                      value: desktop,
                    },
                  ],
                  includeSubdomains: true,
                  limit: 5,
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [ApiMetric.METRIC_SESSIONS_COUNT],
                    },
                  ],
                  displayOptions: {
                    noAttributionToggle: true,
                    noFilters: true,
                    showTotals: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} sx={{ minHeight: 270 }}>
              <AnalyticsCard
                chart={{
                  name: "Desktop Conversion Composition %",
                  dimension: [ApiDimension.DIMENSION_CHANNEL],
                  template: ApiChartTemplate.SNAPSHOT,
                  type: ApiChartType.PIE,
                  attributes: [
                    ...filter,
                    {
                      key: ApiDimension.DIMENSION_DEVICE,
                      value: desktop,
                    },
                  ],
                  includeSubdomains: true,
                  limit: 5,
                  yAxisKeys: [
                    {
                      key: "l",
                      fields: [
                        ApiMetric.METRIC_ATTRIBUTION_IMPACTED_CLICK_MACRO_CONVERSION_COUNT,
                      ],
                    },
                  ],
                  displayOptions: {
                    noAttributionToggle: true,
                    noFilters: true,
                    showTotals: true,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

function RezonateReportPage() {
  const { personalisationId } = useParams<{ personalisationId: string }>();
  const { campaigns, loading } = useCampaigns();
  const { locations, loading: locationsLoading } = useLocations();
  const { calculatedFrom, to } = useFromToContext();

  const rezonateCamapigns = useMemo(() => {
    return campaigns.filter(
      (c) => c.type === ApiCampaignType.CAMPAIGN_TYPE_NONE
    );
  }, [campaigns]);

  const selectedPersonalisation = useMemo(() => {
    if (
      !personalisationId ||
      !rezonateCamapigns ||
      rezonateCamapigns.length === 0
    ) {
      return null;
    }
    for (const campaign of rezonateCamapigns) {
      const personalisation = (campaign.personalisations || []).find(
        (personalisation) => personalisation.id === Number(personalisationId)
      );
      if (personalisation) {
        return { ...personalisation, campaign } as ApiSimplePersonalisation;
      }
    }
    return null;
  }, [rezonateCamapigns, personalisationId]);

  const renderBody = useCallback(() => {
    if (loading || locationsLoading) {
      return <Loading />;
    }

    if (
      !personalisationId ||
      personalisationId === "" ||
      !selectedPersonalisation
    ) {
      return (
        <CenterPage calcHeight>
          <Typography variant="subtitle1" mb={2}>
            Select a Campaign Personalisation to get started.
          </Typography>
          <RezonateReportPageSelector
            width={350}
            campaigns={rezonateCamapigns}
          />
        </CenterPage>
      );
    }

    return (
      <>
        <AnimatePresence>
          <RezonateReportPageInner
            personalisation={selectedPersonalisation}
            locations={locations}
            from={calculatedFrom}
            to={to || moment().utc()}
          />
        </AnimatePresence>
      </>
    );
  }, [
    loading,
    locationsLoading,
    personalisationId,
    rezonateCamapigns,
    selectedPersonalisation,
    locations,
    to,
    calculatedFrom,
  ]);

  return (
    <Suspense fallback={<Loading />}>
      <Portal container={() => document.getElementById(PageActionPreSlot)}>
        <RezonateReportPageSelector
          size="small"
          width={250}
          campaigns={rezonateCamapigns}
          value={selectedPersonalisation}
        />
      </Portal>

      {renderBody()}
    </Suspense>
  );
}

export default withDatepicker(RezonateReportPage);
