import { ApiDimension, ApiMetric } from "@incendium/api";
import { CircularProgress, Typography, useTheme } from "@mui/material";
import { CenterPage } from "Components/CenterPage/CenterPage";
import { useSelectedProject } from "Hooks";
import { TColourMap, useAnalyticsContext } from "Providers/AnalyticsProvider";
import AudienceAnalyticsProvider, {
  useAudienceAnalyticsContext,
} from "Providers/AudienceAnalyticsProvider";
import { useFromToContext } from "Providers/FromToProvider";
import {
  AudienceOverviewTab,
  getChartColor,
  useChartData,
} from "features/analytics";
import { useEffect } from "react";

const chart = {
  dimension: [ApiDimension.DIMENSION_LEAD_AUDIENCE],
  yAxisKeys: [
    {
      fields: [ApiMetric.METRIC_LEADS_COUNT],
    },
  ],
  attributes: [],
  limit: 5,
};

function AudienceInsightsInner() {
  const { setColourMap } = useAnalyticsContext();
  const { setAudienceNames, setAudienceCfgs } = useAudienceAnalyticsContext();
  const theme = useTheme();
  const { selectedProject } = useSelectedProject();
  const { chartOutput } = useFromToContext();

  const { chartData, loading } = useChartData(
    selectedProject,
    chart,
    chartOutput
  );

  useEffect(() => {
    if (loading || !chartData?.data) {
      return;
    }
    setAudienceNames((chartData?.data || []).map((d) => d.name));
    setAudienceCfgs(
      (chartData?.data || []).map((d, i) => ({
        name: d.name || "",
        fill: getChartColor(i, theme.palette.charts),
      }))
    );
    let cMap: TColourMap = {};
    (chartData?.data || []).forEach((d, i) => {
      cMap[d.name || ""] = getChartColor(i, theme.palette.charts);
    });

    setColourMap(cMap);
  }, [
    chartData,
    loading,
    theme.palette.charts,
    setAudienceNames,
    setAudienceCfgs,
    setColourMap,
  ]);

  if (loading) {
    return (
      <CenterPage calcHeight>
        <CircularProgress />
        <Typography variant="h3" mt={2}>
          Fetching audience data
        </Typography>
      </CenterPage>
    );
  }

  return <AudienceOverviewTab />;
}

function AudienceInsights() {
  return (
    <AudienceAnalyticsProvider>
      <AudienceInsightsInner />
    </AudienceAnalyticsProvider>
  );
}

export default AudienceInsights;
