import {
  ApiAccount,
  ApiProjectSettingKey,
  ApiService,
  ApiSimpleUTM,
  ApiSyncStatus,
  ApiSyncType,
} from "@incendium/api";
import {
  Alert,
  AlertTitle,
  alpha,
  Box,
  Button,
  capitalize,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { syncService } from "Apis";
import {
  StyledDrawerActions,
  StyledDrawerContainer,
} from "Components/UI/StyledDrawer";
import { useNotification, useSelectedProject } from "Hooks";
import { useExternalSyncs } from "Hooks/useExternalSyncs";
import { useSyncClients } from "Hooks/useSyncClients";
import produce from "immer";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useConfirmationContext } from "Providers/ConfirmationProvider";
import { ISyncConfigComponentProps } from "features/dataConnectors/types";
import { AccordianChartBuilderSidebarBlock } from "features/chartLibrary";
import {
  bulkDeleteUTMs,
  bulkSaveUTMs,
  UtmGuide,
  UtmMappingList,
} from "features/utms";
import { useUTMs } from "Hooks/useUTMs";
import { compareUtms } from "features/utms/utils";
import ConnectButton from "features/dataConnectors/components/ConnectButton";
import SyncAccountSelector from "features/dataConnectors/components/SyncAccountSelector";
import { ProjectOptionsDrawerForm, saveProjectSetting } from "features/project";
import { useDispatch } from "react-redux";
import { fetchAllClientsAndProjectsThunk } from "Actions";

const StyledListItem = styled(ListItem)<{ error?: boolean }>(
  ({ theme, error }) =>
    error && {
      background: alpha(theme.palette.error.main, 0.2),
      paddingTop: 16,
      paddingBottom: 16,
      "&::before": {
        content: "''",
        height: "100%",
        position: "absolute",
        width: 40,
        left: -40,
        background: alpha(theme.palette.error.main, 0.2),
      },
      "&::after": {
        content: "''",
        height: "100%",
        position: "absolute",
        width: 40,
        right: -40,
        background: alpha(theme.palette.error.main, 0.2),
      },
    }
);

interface ISyncAccountConfigDrawerProps extends ISyncConfigComponentProps {
  provider: string;
  type: ApiSyncType;
  service: ApiService;
}

function SyncAccountConfigDrawer({
  oauthClient,
  syncs,
  provider,
  type,
  open,
  setOpen,
  service,
}: ISyncAccountConfigDrawerProps) {
  const { selectedProject, projectSettings, setProjectSettings } =
    useSelectedProject();
  const {
    getAccounts,
    accounts,
    accountsLoading,
    syncClients,
    setSyncClients,
  } = useSyncClients();
  const { externalSyncs, setExternalSyncs } = useExternalSyncs();
  const { utms, setUTMs, refetch } = useUTMs();
  const [originalUtms, setOriginalUtms] = useState<ApiSimpleUTM[] | null>(null);
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const { showSuccessNotification, showErrorNotification } = useNotification();
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const connectionAccounts = useMemo(() => {
    return oauthClient?.id ? accounts[oauthClient.id] : [];
  }, [accounts, oauthClient?.id]);

  useEffect(() => {
    if (open && utms && !originalUtms) {
      setOriginalUtms(utms);
    } else if (!open) {
      setOriginalUtms(null);
      refetch();
    }
  }, [open, utms, refetch, originalUtms]);

  useEffect(() => {
    const fetchData = async (oauthClientId) => {
      try {
        await getAccounts(oauthClientId);
        setError(false);
      } catch (error) {
        setError(true);
      }
    };

    if (oauthClient?.id) {
      fetchData(oauthClient.id);
    }
  }, [oauthClient?.id, getAccounts]);

  const isReady = useMemo(() => {
    return oauthClient?.readyToTypes?.includes(type);
  }, [oauthClient, type]);

  const linkAccount = useCallback(
    async (selectedAccount: ApiAccount) => {
      if (!selectedAccount?.externalId || !oauthClient?.id) {
        return;
      }

      try {
        const res = await syncService.syncServiceLinkAccount({
          projectId: selectedProject?.id as number,
          oauthClientId: oauthClient.id,
          payload: {
            externalId: selectedAccount?.externalId,
            type,
          },
        });

        setExternalSyncs(
          produce(externalSyncs, (draft) => {
            draft.push(res);
          })
        );

        showSuccessNotification(`Account ${selectedAccount.name} Linked.`);
      } catch (error) {
        showErrorNotification(`Failed to link ${selectedAccount.name}.`);
      }
    },
    [
      selectedProject,
      oauthClient?.id,
      showSuccessNotification,
      showErrorNotification,
      externalSyncs,
      setExternalSyncs,
      type,
    ]
  );

  const removeAccount = useCallback(
    async (id: number) => {
      const sync = externalSyncs.find((d) => d.id === id);
      openConfirmation({
        title: (
          <>
            <Typography variant="subtitle1">
              Are you sure you want to remove this Account?
            </Typography>
            <Typography variant="body2">
              Doing so shall remove all data associated with this account
            </Typography>
            <Typography variant="body2">
              This includes {sync?.campaignsCount} campaigns
            </Typography>
            <Typography variant="body2">
              and {sync?.clicksCount} clicks.
            </Typography>
          </>
        ),
        callback: async () => {
          try {
            await syncService.syncServiceDeleteSync({
              projectId: selectedProject?.id as number,
              syncId: id,
            });

            setExternalSyncs(
              produce(externalSyncs, (draft) => {
                const idx = draft.findIndex((d) => d.id === id);
                if (idx >= 0) {
                  draft.splice(idx, 1);
                }
              })
            );

            showSuccessNotification(`Account removed.`);
          } catch (error) {
            showErrorNotification(`Failed to remove account.`);
          }

          closeConfirmation();
        },
      });
    },
    [
      openConfirmation,
      closeConfirmation,
      selectedProject,
      showSuccessNotification,
      showErrorNotification,
      externalSyncs,
      setExternalSyncs,
    ]
  );

  const saveUTMs = useCallback(
    async (close: boolean) => {
      try {
        const { utmsToSave, utmsToDelete } = compareUtms(
          originalUtms || [],
          utms
        );
        if (utmsToSave && utmsToSave.length > 0) {
          await bulkSaveUTMs(selectedProject!.id!, utmsToSave);
        }
        if (utmsToDelete && utmsToDelete.length > 0) {
          await bulkDeleteUTMs(
            selectedProject!.id!,
            utmsToDelete.map((u) => u.id!)
          );
        }

        showSuccessNotification(`UTM Mapping updated`);
        if (close) {
          setOpen(false);
        }
      } catch (error) {
        showErrorNotification(
          `Internal Error updating UTM mapping, please try again`
        );
      }
    },
    [
      utms,
      originalUtms,
      showSuccessNotification,
      showErrorNotification,
      selectedProject,
      setOpen,
    ]
  );

  const saveProjectSettings = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      try {
        await saveProjectSetting(
          {
            key: ApiProjectSettingKey.FB_PIXEL,
            value: projectSettings[ApiProjectSettingKey.FB_PIXEL],
          },
          selectedProject!
        );

        showSuccessNotification(`Pixel Settings Saved`);
        dispatch(
          fetchAllClientsAndProjectsThunk({
            clientId: selectedProject!.clientId as number,
            projectId: selectedProject!.id as number,
          })
        );
      } catch (error) {
        showErrorNotification(
          `Internal Error saving Pixel settings, please try again`
        );
      }
    },
    [
      dispatch,
      projectSettings,
      selectedProject,
      showSuccessNotification,
      showErrorNotification,
    ]
  );

  return (
    <Box sx={{ maxWidth: 600 }}>
      <StyledDrawerContainer>
        {oauthClient?.id && !isReady ? (
          <Alert severity="warning">
            <AlertTitle>
              The {provider} connection you created lacks the necessary
              permissions to manage your ad account.
            </AlertTitle>
            Please remove the connection and reconnecting making sure the "See,
            edit, create and delete your Google Ads accounts and data."
            permission is selected.
          </Alert>
        ) : error ? (
          <Alert severity="warning">
            <AlertTitle>
              There was an error fetching data from your {provider} ad's account
            </AlertTitle>
            Please try again later.
          </Alert>
        ) : oauthClient?.id && isReady ? (
          <>
            <Typography variant="subtitle1">
              Configure {capitalize(provider)} Ads
            </Typography>
            <Typography variant="body2">
              Select an Ad account to link to this project.
            </Typography>
            <Typography variant="body2">
              This will the start to pull attribution and cost data for your
              ads.
            </Typography>
            <Box mt={3}>
              {accountsLoading && <CircularProgress size={20} />}
              {accounts && connectionAccounts && (
                <>
                  {connectionAccounts.length === 0 && oauthClient.id ? (
                    <Alert severity="warning">
                      It looks like you dont have any ad accounts.
                    </Alert>
                  ) : (
                    <SyncAccountSelector
                      connectionAccounts={connectionAccounts}
                      provider={provider}
                      onLinkAccount={linkAccount}
                      syncs={syncs || []}
                    />
                  )}
                </>
              )}
            </Box>
          </>
        ) : (
          <Alert severity="warning">
            <AlertTitle>
              You have not connected your {provider} profile to incendium
            </AlertTitle>
            <Box mb={2}>
              {(syncs || []).length > 0 ? (
                <>
                  It looks like a different user has connected their {provider}{" "}
                  profile and added an ads account. If you would like to add one
                  of your own accounts please connect your {provider} account
                </>
              ) : (
                <>
                  To be able to Link a account to incendium you must first
                  connect your {provider} account, close this dialog and click
                  connect on the {provider} ads tile to connect.
                </>
              )}
            </Box>
            <ConnectButton
              service={service}
              syncClients={syncClients}
              setSyncClients={setSyncClients}
            />
          </Alert>
        )}
      </StyledDrawerContainer>

      {(syncs || []).length > 0 && (
        <>
          <Divider />
          <StyledDrawerContainer>
            <Box>
              <Typography variant="subtitle1">
                {capitalize(provider)} Ads connections
              </Typography>
              <Typography variant="body2">
                Select an account to remove, warning this will also remove all
                associated data .
              </Typography>
              <List>
                {(syncs || []).map((s) => (
                  <Fragment key={s.id}>
                    <StyledListItem
                      disableGutters
                      error={s.status === ApiSyncStatus.ERROR}
                      secondaryAction={
                        <Stack spacing={1}>
                          {s.status === ApiSyncStatus.ERROR && oauthClient && (
                            <ConnectButton
                              service={service}
                              syncClients={syncClients}
                              setSyncClients={setSyncClients}
                              oauthClientId={s.oauthClientId}
                            />
                          )}
                          <Button
                            size="extraSmall"
                            variant="outlined"
                            onClick={() => removeAccount(s.id as number)}
                          >
                            Remove synced Account
                          </Button>
                        </Stack>
                      }
                    >
                      <ListItemText
                        primary={`${s.accountName} - ${s.account}`}
                        secondary={
                          s.status === ApiSyncStatus.ERROR ? (
                            <Typography variant="subtitle2">
                              This connection is no longer valid
                              {oauthClient && ", please reconnect"}
                            </Typography>
                          ) : (
                            <>
                              <Typography variant="body2">
                                {s.client?.user?.name}
                              </Typography>
                              <Typography variant="body2" color={"secondary"}>
                                clicks - {s.clicksCount}, campaigns -{" "}
                                {s.campaignsCount}
                              </Typography>
                            </>
                          )
                        }
                      />
                    </StyledListItem>
                  </Fragment>
                ))}
              </List>
            </Box>
          </StyledDrawerContainer>
        </>
      )}
      {type === ApiSyncType.FACEBOOK_ADS && (
        <ProjectOptionsDrawerForm
          onSubmit={saveProjectSettings}
          projectSettings={projectSettings}
          setProjectSettings={setProjectSettings}
          showSettings={[ApiProjectSettingKey.FB_PIXEL]}
          hideUTMS
          saveText="Save Pixel Settings"
        />
      )}
      <Divider />
      <AccordianChartBuilderSidebarBlock
        title="Suggested UTM Setup"
        subTitle="To ensure accurate tracking and attribution of your campaigns, add one of the following tracking templates."
      >
        <UtmGuide syncType={type} />
      </AccordianChartBuilderSidebarBlock>
      <AccordianChartBuilderSidebarBlock
        title="Custom UTM Mapping"
        subTitle="Customize UTM mappings for this provider by overriding default settings. This can be achieved by creating one or multiple catch-all configurations. Our system will search through each mapping until the correct one is found."
      >
        <UtmMappingList syncType={type} utms={utms} setUTMs={setUTMs} />
      </AccordianChartBuilderSidebarBlock>
      <StyledDrawerActions justifyContent={"flex-end"}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => saveUTMs(false)}
        >
          Save UTM's
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => saveUTMs(true)}
        >
          Save UTM's and close
        </Button>
      </StyledDrawerActions>
    </Box>
  );
}

export default SyncAccountConfigDrawer;
