import {
  ApiAttributionClicksMetric,
  ApiDimension,
  ApiMetric,
} from "@incendium/api";
import {
  alpha,
  styled,
  TableCell,
  TableRow,
  TableRowProps,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  formatDimension,
  formatMetric,
  metricByAttribution,
} from "features/analytics";
import {
  AttributionMetric,
  AttributionType,
} from "features/analytics/types/types";
import { primaryToAttribution } from "features/attributionAnalytics/services/atributionClicksDimensions";
import { formatAttributionMetric } from "features/attributionAnalytics/services/attributionClickMetricsFormater";
import { HTMLMotionProps, motion } from "framer-motion";
import { formatPercentage, percentageChange } from "Helpers/percentage";
import { TFramerAnimationProps } from "Interfaces";
import { useCallback, useMemo, useState } from "react";

interface IStyledTableRowProps
  extends TableRowProps,
    Pick<HTMLMotionProps<any>, TFramerAnimationProps> {
  hovered?: boolean;
  actionHovered?: boolean;
  active?: boolean;
}
const StyledTableRow = styled(TableRow)<IStyledTableRowProps>(
  ({ theme, hovered, actionHovered, active }) => ({
    background: active
      ? theme.palette.secondary.main
      : actionHovered
      ? alpha(theme.palette.primary.main, 0.8)
      : hovered
      ? alpha(theme.palette.secondary.main, 0.8)
      : undefined,
    "& .MuiTableCell-root": {
      color: hovered ? "white" : undefined,
    },
  })
);
const StyledTableCell = styled(TableCell)<{
  clickable?: boolean;
  active?: boolean;
}>(({ theme, clickable, active }) => ({
  cursor: clickable ? "pointer" : undefined,
  color: active ? " white" : theme.palette.secondary.main,
}));

interface ICampaignAnalysisTableRowProps {
  current: boolean;
  data: any;
  firstCols: { key: ApiDimension; text: string }[];
  onClick?: (v: string) => void;
  publisherConversion: ApiMetric;
  attributionModel: AttributionType;
  conversionMetric: AttributionMetric;
  selecteValues: string[];
  dimension: ApiDimension;
}

// tmp mapping needed until we sort proper full outer join for the primary query
const tmpPublisherMapping = {
  [ApiMetric.METRIC_PUBLISHER_CONVERSIONS]:
    ApiAttributionClicksMetric.ATTRIBUTION_CLICKS_METRIC_CONVERSIONS,
  [ApiMetric.METRIC_PUBLISHER_ALL_CONVERSIONS]:
    ApiAttributionClicksMetric.ATTRIBUTION_CLICKS_METRIC_ALL_CONVERSIONS,
};

function CampaignAnalysisTableRow({
  current,
  data,
  firstCols,
  onClick,
  publisherConversion,
  attributionModel,
  conversionMetric,
  selecteValues,
  dimension,
}: ICampaignAnalysisTableRowProps) {
  const [hovered, setHovered] = useState(false);
  const [actionHovered, setActionHovered] = useState(false);

  const dimensionText = useCallback(
    (dimension: ApiDimension) => {
      return data[dimension] || data[primaryToAttribution(dimension)];
    },
    [data]
  );

  const difference = useCallback(
    (compareValue: number, currentValue: number) => {
      const change = percentageChange(compareValue, currentValue);
      return formatPercentage(change);
    },
    []
  );

  const attributionMetric = useMemo(() => {
    return (
      metricByAttribution(attributionModel, conversionMetric) ||
      ApiMetric.METRIC_ATTRIBUTION_FIRST_CLICK_MACRO_CONVERSION_COUNT
    );
  }, [attributionModel, conversionMetric]);

  // todo: this method is needed whilst the be does not use a proper full out join, once sorted we can use just primary and remove and referrebce to attribution query
  // todo, tmp hack, issue with primary and not-set, ignoreing primary and using attribution first
  const getValue = useCallback(
    (metric: ApiMetric, attMetric: ApiAttributionClicksMetric) => {
      const value = Number(data[attMetric] || 0);
      return value > 0
        ? formatAttributionMetric(attMetric, value)
        : formatMetric(metric, Number(data[metric] || 0));
      // ? formatMetric(metric, value)
      // : formatAttributionMetric(attMetric, Number(data[attMetric] || 0));
    },
    [data]
  );

  // tmp hack whilst primary wrong
  const getDifference = useCallback(
    (metrics: ApiMetric[], attMetric: ApiAttributionClicksMetric) => {
      const value1 = Number(data[attMetric] || 0);
      const value2 = Number(data[metrics[1]] || 0);
      return value1 > 0
        ? difference(value1, value2)
        : difference(Number(data[metrics[0]] || 0), Number(value2));

      // const value1 = Number(data[metrics[0]] || 0);
      // const value2 = Number(data[metrics[1]] || 0);
      // return value1 > 0
      //   ? difference(value1, value2)
      //   : difference(
      //       Number(data[attMetric] || 0),
      //       Number(data[metrics[1]] || 0)
      //     );
    },
    [data, difference]
  );

  return current ? (
    <StyledTableRow
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      key={`${dimensionText(firstCols[0].key)}`}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      hovered={hovered}
      actionHovered={actionHovered}
    >
      {firstCols.map((firstCol) => (
        <StyledTableCell
          align="center"
          onClick={(e) => {
            e.preventDefault();
            onClick && onClick(`${dimensionText(firstCol.key)}`);
          }}
          onMouseOver={() => onClick && setActionHovered(true)}
          onMouseLeave={() => onClick && setActionHovered(false)}
          clickable={typeof onClick !== "undefined"}
        >
          <strong>
            {formatDimension(firstCol.key, `${dimensionText(firstCol.key)}`)}
          </strong>
        </StyledTableCell>
      ))}

      <TableCell align="center">
        {getValue(
          ApiMetric.METRIC_PUBLISHER_CLICKS,
          ApiAttributionClicksMetric.ATTRIBUTION_CLICKS_METRIC_CLICKS
        )}
      </TableCell>
      <TableCell align="center">
        {formatMetric(
          ApiMetric.METRIC_SESSIONS_COUNT,
          Number(data[ApiMetric.METRIC_SESSIONS_COUNT] || 0)
        )}
      </TableCell>
      <TableCell align="center">
        {getDifference(
          [ApiMetric.METRIC_PUBLISHER_CLICKS, ApiMetric.METRIC_SESSIONS_COUNT],
          ApiAttributionClicksMetric.ATTRIBUTION_CLICKS_METRIC_CLICKS
        )}
      </TableCell>
      <TableCell align="center">
        {getValue(
          ApiMetric.METRIC_PUBLISHER_CPC,
          ApiAttributionClicksMetric.ATTRIBUTION_CLICKS_METRIC_AVG_CPC
        )}
      </TableCell>
      <TableCell align="center">
        {getValue(
          ApiMetric.METRIC_PUBLISHER_IMPRESSIONS,
          ApiAttributionClicksMetric.ATTRIBUTION_CLICKS_METRIC_IMPRESSIONS
        )}
      </TableCell>
      <TableCell align="center">
        {getValue(
          ApiMetric.METRIC_PUBLISHER_CTR,
          ApiAttributionClicksMetric.ATTRIBUTION_CLICKS_METRIC_CTR
        )}
      </TableCell>
      <TableCell align="center">
        {getValue(
          ApiMetric.METRIC_SPEND,
          ApiAttributionClicksMetric.ATTRIBUTION_CLICKS_METRIC_SPEND
        )}
      </TableCell>
      <TableCell align="center">
        {formatMetric(
          ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE_DURING_SESSION,
          Number(
            data[ApiMetric.METRIC_AVERAGE_TIME_ON_PAGE_DURING_SESSION] || 0
          )
        )}
      </TableCell>
      <TableCell align="center">
        {formatMetric(
          ApiMetric.METRIC_EFFECTIVE_SESSION_RATE,
          Number(data[ApiMetric.METRIC_EFFECTIVE_SESSION_RATE] || 0)
        )}
      </TableCell>
      <TableCell align="center">
        {formatMetric(
          ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT,
          Number(data[ApiMetric.METRIC_SESSION_MICRO_CONVERSION_COUNT] || 0)
        )}
      </TableCell>
      <TableCell align="center">
        {getValue(
          publisherConversion,
          tmpPublisherMapping[publisherConversion]
        )}
      </TableCell>
      <TableCell align="center">
        {formatMetric(attributionMetric, Number(data[attributionMetric] || 0))}
      </TableCell>
    </StyledTableRow>
  ) : (
    <StyledTableRow
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      key={`${dimensionText(firstCols[0].key)}`}
      hovered={hovered}
      active={selecteValues.includes(`${dimensionText(firstCols[0].key)}`)}
    >
      {firstCols.map((firstCol) => (
        <Tooltip
          arrow
          key={firstCol.key}
          title={formatDimension(dimension, `${dimensionText(firstCol.key)}`)}
        >
          <StyledTableCell
            onClick={() => onClick && onClick(`${dimensionText(firstCol.key)}`)}
            clickable={typeof onClick !== "undefined"}
            active={selecteValues.includes(`${dimensionText(firstCol.key)}`)}
            onMouseOver={() => onClick && setHovered(true)}
            onMouseLeave={() => onClick && setHovered(false)}
          >
            <Typography
              variant="body2"
              fontWeight={"bold"}
              color="inherit"
              noWrap
            >
              {formatDimension(dimension, `${dimensionText(firstCol.key)}`)}
            </Typography>
          </StyledTableCell>
        </Tooltip>
      ))}
    </StyledTableRow>
  );
}

export default CampaignAnalysisTableRow;
