import { ApiLocation, ApiProject, ApiProjectType } from "@incendium/api";
import {
  Box,
  CircularProgress,
  Portal,
  styled,
  Typography,
  Switch,
  FormControlLabel,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Divider,
} from "@mui/material";
import { PageDataTab } from "Components/PageDataTab";
import { ConversionTab } from "Components/ConversionTab";
import { FormHandlersTab } from "Components/FormHandlersTab";
import { IFrameContext, SmartIframe, TIframeJob } from "Components/SmartIframe";
import { TabButton } from "Components/UI/TabButton";
import { TabContainer } from "Components/UI/TabContainer";
import { useState } from "react";
import theme from "theme";
import { useUpdateEffect } from "react-use";
import {
  SidebarContainer,
  SidebarContentWrapper,
  SiderBarHeader,
} from "Components/UI/Sidebar";
import {
  ContactPage,
  DataObject,
  Edit,
  FindInPage,
  Mouse,
} from "@mui/icons-material";
import EditLocationURLDialog from "Components/Location/EditLocationURLDialog";
import { useUrlQuery } from "Hooks/useUrlQuery";
import PageMetaTab from "./PageMetaTab";
import { motion } from "framer-motion";
import { THighlight } from "@incendium/inc-ts-helpers";
import { useHasProjectType } from "features/project";

const MainWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  height: `calc(100% - 100px)`,
  marginTop: 40,
}));
const IFrameWrapper = styled(Box)(({ theme }) => ({
  borderRadius: 10,
  overflow: "hidden",
  height: "100%",
  margin: "auto",
}));

const ContentContainer = styled(Box)({
  padding: theme.spacing(2, 3),
  height: `calc(100% - 55px)`,
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
});

export const StyledButton = styled(Button)(({ theme }) => ({
  "&:not(:last-child)": {
    marginRight: theme.spacing(2),
  },
}));

export const TextFieldHeading = styled(Typography)(({ theme }) => ({
  lineHeight: 1,
  fontSize: theme.spacing(2.2),
}));

export type LocationTabs =
  | "Page Tab"
  | "Conversion Tab"
  | "Form Tab"
  | "Page Meta Tab";

export const TagPages = ({
  location,
  setLocation,
  project,
}: {
  location: ApiLocation;
  setLocation: (d: ApiLocation) => void;
  project: ApiProject;
}) => {
  const [selectedSelector, setSelected] = useState("");
  const [productSchemaFound, setProductSchemaFound] = useState(false);
  const [targetText, setTargetText] = useState("");
  const [active, setActive] = useState(false);
  const [repeated, setRepeated] = useState(false);
  const [useTextMode, setUseTextMode] = useState(false);
  const [highlightCollection, sethighlightCollection] = useState<
    THighlight[] | undefined
  >([]);
  const [job, setJob] = useState<TIframeJob | undefined>(undefined);
  const [isMobile, setIsMobile] = useState(false);
  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState("100%");
  const [targets, setTargets] = useState<string[] | undefined>(undefined);
  const [url, setUrl] = useState<string | undefined>(location.urlMatch);
  const query = useUrlQuery();
  const [selectedTab, setSelectedTab] = useState<LocationTabs>(
    query.has("tab") && query.get("tab")
      ? (query.get("tab") as LocationTabs)
      : "Page Tab"
  );
  const hasProjectType = useHasProjectType();

  useUpdateEffect(() => {
    setWidth(isMobile ? "420px" : "100%");
  }, [isMobile]);

  return (
    <>
      <IFrameContext.Provider
        value={{
          activateIframe: (
            targets,
            selector,
            repeated,
            targetText,
            useTextMode,
            highlightCollection,
            job
          ) => {
            setActive(true);
            setTargets(targets);
            setSelected(selector || "");
            setRepeated(repeated || false);
            setTargetText(targetText || "");
            setUseTextMode(useTextMode || false);
            sethighlightCollection(highlightCollection);
            setJob(job);
          },
          deactivateIframe: () => {
            setActive(false);
            setSelected("");
            setTargetText("");
            setRepeated(false);
            setUseTextMode(false);
            setJob(undefined);
          },
          selectedSelector,
          selectedText: targetText,
        }}
      >
        <Portal container={() => document.getElementById("pageAction")}>
          <Stack alignItems={"flex-end"}>
            <FormControlLabel
              style={{ minWidth: 136 }}
              control={
                <Switch
                  checked={isMobile}
                  onChange={(e, c) => setIsMobile(c)}
                />
              }
              label="mobile view"
            />
            <Stack
              spacing={1}
              direction="row"
              alignItems={"center"}
              sx={{ maxWidth: 520 }}
            >
              <Typography fontWeight={"bold"}>viewing:</Typography>
              <Tooltip arrow title={url}>
                <Typography noWrap sx={{ direction: "rtl" }}>
                  {url}
                </Typography>
              </Tooltip>
              <IconButton size="small" onClick={() => setOpen(true)}>
                <Edit fontSize="small" />
              </IconButton>
            </Stack>
          </Stack>
        </Portal>
        {url ? (
          <MainWrapper>
            <IFrameWrapper
              sx={{
                width,
              }}
            >
              <SmartIframe
                src={url}
                active={active}
                targets={targets}
                selector={selectedSelector}
                targetText={targetText}
                repeated={repeated}
                useTextMode={useTextMode}
                highlightCollection={highlightCollection}
                job={job}
                onClick={(e) => {
                  if (e.text && e.text !== "") {
                    setTargetText(e.text);
                  }
                  setSelected(e.selector);
                }}
                onAction={(action) => {
                  if (action === "productschema") {
                    setProductSchemaFound(true);
                  }
                }}
              />
            </IFrameWrapper>
          </MainWrapper>
        ) : (
          <CircularProgress />
        )}
        <Portal container={() => document.getElementById("rightSideBar")}>
          <SidebarContainer>
            <SiderBarHeader>
              <Typography variant="h3" gutterBottom>
                {selectedTab === "Page Tab" && "tag this page"}
                {selectedTab === "Conversion Tab" && "conversions"}
                {selectedTab === "Page Meta Tab" && "Page Meta"}
                {selectedTab === "Form Tab" && "form handlers"}
              </Typography>
              <Typography variant="body1">
                {selectedTab === "Page Tab" && (
                  <>train incendium to recognise page items</>
                )}
                {selectedTab === "Conversion Tab" &&
                  "train incendium to recognise conversions"}
                {selectedTab === "Page Meta Tab" &&
                  "train incendium to capture on visit page meta data, such as if a product is in stock etc"}
                {selectedTab === "Form Tab" &&
                  "train incendium to recognise populate contact info from form fields"}
              </Typography>
            </SiderBarHeader>
            <Divider />

            <SidebarContentWrapper>
              <Box px={3}>
                <TabContainer fullWidth noGrow>
                  <TabButton
                    onClick={() => setSelectedTab("Page Tab")}
                    isActive={selectedTab === "Page Tab"}
                  >
                    <Tooltip
                      arrow
                      title={
                        <>
                          <Typography variant="subtitle2" color={"inherit"}>
                            Page Data
                          </Typography>
                          <Typography variant="body2" color={"inherit"}>
                            train incendium to recognise page items
                          </Typography>
                        </>
                      }
                    >
                      <FindInPage />
                    </Tooltip>
                  </TabButton>
                  <TabButton
                    onClick={() => setSelectedTab("Conversion Tab")}
                    isActive={selectedTab === "Conversion Tab"}
                  >
                    <Tooltip
                      arrow
                      title={
                        <>
                          <Typography variant="subtitle2" color={"inherit"}>
                            Conversions
                          </Typography>
                          <Typography variant="body2" color={"inherit"}>
                            train incendium to recognise conversions
                          </Typography>
                        </>
                      }
                    >
                      <Mouse />
                    </Tooltip>
                  </TabButton>

                  {/* currently only meta is the out of stock, so we only show option if ecom */}
                  {hasProjectType(ApiProjectType.TYPE_ECOMMERCE) && (
                    <TabButton
                      onClick={() => setSelectedTab("Page Meta Tab")}
                      isActive={selectedTab === "Page Meta Tab"}
                    >
                      <Tooltip
                        arrow
                        title={
                          <>
                            <Typography variant="subtitle2" color={"inherit"}>
                              Page Meta
                            </Typography>
                            <Typography variant="body2" color={"inherit"}>
                              train incendium to capture on visit page meta
                              data, such as if a product is in stock etc
                            </Typography>
                          </>
                        }
                      >
                        <DataObject />
                      </Tooltip>
                    </TabButton>
                  )}
                  <TabButton
                    onClick={() => setSelectedTab("Form Tab")}
                    isActive={selectedTab === "Form Tab"}
                  >
                    <Tooltip
                      arrow
                      title={
                        <>
                          <Typography variant="subtitle2" color={"inherit"}>
                            Contacts
                          </Typography>
                          <Typography variant="body2" color={"inherit"}>
                            train incendium to recognise populate contact info
                            from form fields
                          </Typography>
                        </>
                      }
                    >
                      <ContactPage />
                    </Tooltip>
                  </TabButton>
                </TabContainer>
              </Box>

              <ContentContainer>
                <Box
                  key={selectedTab}
                  component={motion.div}
                  initial={{
                    opacity: 0,
                    x: 50,
                  }}
                  animate={{ opacity: 1, x: 0 }}
                >
                  {selectedTab === "Page Tab" && (
                    <PageDataTab
                      location={location}
                      setLocation={setLocation}
                      project={project}
                      productSchemaFound={productSchemaFound}
                      active={active}
                    />
                  )}
                  {selectedTab === "Conversion Tab" && (
                    <ConversionTab
                      location={location}
                      project={project}
                      isMobile={isMobile}
                    />
                  )}
                  {selectedTab === "Page Meta Tab" && (
                    <PageMetaTab location={location} project={project} />
                  )}
                  {selectedTab === "Form Tab" && (
                    <FormHandlersTab location={location} project={project} />
                  )}
                </Box>
              </ContentContainer>
            </SidebarContentWrapper>
          </SidebarContainer>
        </Portal>
      </IFrameContext.Provider>
      <EditLocationURLDialog
        open={open}
        setOpen={setOpen}
        setUrl={setUrl}
        url={url}
        location={location}
        project={project}
      />
    </>
  );
};
